import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { pluck, switchMap, takeWhile } from 'rxjs/operators';
import Image from 'src/app/models/image.model';
import { CatalogService } from 'src/app/_services/catalog.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';

@Component({
  selector: 'app-models-image-check',
  templateUrl: './models-image-check.component.html',
  styleUrls: ['./models-image-check.component.css']
})
export class ModelsImageCheckComponent implements OnInit, OnDestroy, ToolbarController {
  images! : Map<Number, Image>;

  title = new BehaviorSubject<string>('Generate Model Image Check');

  isLoading = true;

  constructor(
    private router: Router,
    private toolbarService: ToolbarService,
    private catalogService: CatalogService,
  ) {    
    catalogService.getGeneratedImages(["circle"]).subscribe({
      next: (readImages: Map<Number, Image>) => {
        this.images = readImages;
        this.isLoading = false;
      }
    });
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  selectModel(modelId : number) : void {
    this.router.navigate(['/catalog', 'models', modelId]);
  }
}
