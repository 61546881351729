import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BuyersService } from 'src/app/_services/buyers.service';
import { OrdersService } from 'src/app/_services/orders.service';
import Order from 'src/app/models/order.model';

export interface DialogData {
  order: Order,
  onAddressChanged: () => void
}

@Component({
  selector: 'app-change-order-address-dialog',
  templateUrl: './change-order-address-dialog.component.html',
  styleUrls: ['./change-order-address-dialog.component.css']
})
export class ChangeOrderAddressDialogComponent {
  private order: Order;

  fullName = new FormControl<string | null>(null);
  line1 = new FormControl<string | null>(null);
  line2 = new FormControl<string | null>(null);
  line3 = new FormControl<string | null>(null);
  city = new FormControl<string | null>(null);
  state = new FormControl<string | null>(null);
  postalCode = new FormControl<string | null>(null);
  country = new FormControl<string | null>("US");
  phoneNumber = new FormControl<string | null>(null);

  isSaving = false

  constructor(
    public dialogRef: MatDialogRef<ChangeOrderAddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private buyersService: BuyersService,
    private ordersService: OrdersService
  ) { 
    this.order = data.order;

    this.fullName.setValue(this.order.deliveryAddress.fullName);
    this.line1.setValue(this.order.deliveryAddress.line1);
    this.line2.setValue(this.order.deliveryAddress.line2);
    this.line3.setValue(this.order.deliveryAddress.line3);
    this.city.setValue(this.order.deliveryAddress.city);
    this.state.setValue(this.order.deliveryAddress.state);
    this.postalCode.setValue(this.order.deliveryAddress.postalCode);
    this.country.disable();
    this.phoneNumber.setValue(this.order.deliveryAddress.phoneNumber);
  }

  onClickSave(): void {
    this.isSaving = true
    var address = {
      fullName: this.fullName.value,
      line1: this.line1.value,
      line2: this.line2.value,
      line3: this.line3.value,
      city: this.city.value,
      state: this.state.value,
      postalCode: this.postalCode.value,
      phoneNumber: this.phoneNumber.value
    }
    this.buyersService.addMailingAddress(this.order.buyerProfile.id, address, false).subscribe({
      next: address => {
        this.ordersService.updateOrder(this.order.id, {deliveryAddress: address.id}).subscribe({
          next: order => {
            this.data.onAddressChanged();
            this.dialogRef.close();
          },
          error: error => {
            console.log(error);
            this.isSaving = false;
          }
        })
      },
      error: error => {
        console.log(error);
        this.isSaving = false;
      }
    });
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }
}
