import { E } from '@angular/cdk/keycodes';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, debounceTime, distinctUntilChanged, pluck } from 'rxjs';
import { CatalogService } from 'src/app/_services/catalog.service';
import { AuthenticationReportsService } from 'src/app/_services/authentication-reports.service';
import { ListingsService } from 'src/app/_services/listings.service';
import { OrdersService } from 'src/app/_services/orders.service';
import { ReturnsService } from 'src/app/_services/returns.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { VerificationReportsService } from 'src/app/_services/verification-reports.service';
import Accessory from 'src/app/models/accessory.model';
import Brand from 'src/app/models/brand.model';
import AuthenticationReport, { AUTHENTICATION_REPORT_PRIORITIES, AUTHENTICATION_REPORT_PRIORITY_DESCRIPTIONS, AUTHENTICATION_REPORT_RESULTS, AUTHENTICATION_REPORT_RESULT_DESCRIPTIONS } from 'src/app/models/authentication-report.model';
import Listing from 'src/app/models/listing.model';
import Order from 'src/app/models/order.model';
import VerificationReport from 'src/app/models/verification-report.model';
import { BrandsService } from 'src/app/_services/brands.service';

@Component({
  selector: 'app-authentication-report-detail',
  templateUrl: './authentication-report-detail.component.html',
  styleUrls: ['./authentication-report-detail.component.css']
})
export class AuthenticationReportDetailComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Authentication Reports", path: ['/verification', 'authentication-reports'] }]);
  title = new BehaviorSubject<string>("?");

  authenticationReport!: AuthenticationReport | null;
  _authenticationReportId: number | null = null;

  verificationReport!: VerificationReport | null;

  authenticationReportPriorities: object;
  authenticationReportPriorityDescriptions: object;
  authenticationReportResults: object;
  authenticationReportResultDescriptions: object;

  isPerformingAction = () => {
    return this.isSaving
        || this.isFinalizing
        || this.isCancelling
  }
  isSaving = false
  isFinalizing = false
  isCancelling = false

  referenceTypeControl = new FormControl<string | null>(null);
  referenceIdControl = new FormControl<string | null>(null);
  brandControl = new FormControl<number | null>(null);
  referenceNumberControl = new FormControl<string | null>(null);
  authenticationReasonControl = new FormControl<string | null>(null);
  braceletNotesControl = new FormControl<string | null>(null);
  resultControl = new FormControl<string | null>(null);
  formGroup = new FormGroup({
    referenceType: this.referenceTypeControl,
    referenceId: this.referenceIdControl,
    brand: this.brandControl,
    referenceNumber: this.referenceNumberControl,
    authenticationReason: this.authenticationReasonControl,
    result: this.resultControl,
  });

  allBrands: Brand[] = []
  allAccessories: Accessory[] = []

  referencedOrder: Order | null = null;
  referencedListing: Listing | null = null

  constructor(
    private toolbarService: ToolbarService,
    private route: ActivatedRoute,
    private authenticationReportsService: AuthenticationReportsService,
    private verificationReportsService: VerificationReportsService,
    private catalogService: CatalogService,
    private brandsService: BrandsService,
    private ordersService: OrdersService,
    private listingsService: ListingsService,
    private returnsService: ReturnsService,
    private router: Router,
    private dialog: MatDialog
    ) {
      this.authenticationReportPriorities = AUTHENTICATION_REPORT_PRIORITIES;
      this.authenticationReportPriorityDescriptions = AUTHENTICATION_REPORT_PRIORITY_DESCRIPTIONS;
      this.authenticationReportResults = AUTHENTICATION_REPORT_RESULTS;
      this.authenticationReportResultDescriptions = AUTHENTICATION_REPORT_RESULT_DESCRIPTIONS;
      
      this.route.params.pipe(
        pluck("authenticationReportId")
      ).subscribe({
        next: authenticationReportId => {
          if (this._authenticationReportId == authenticationReportId) {
            return;
          }
  
          this._authenticationReportId = authenticationReportId;
          this.title.next(authenticationReportId.toString());
          this.reloadAuthenticationReport();
        }
      })

      brandsService.getBrands().subscribe({
        next: brands => {
          this.allBrands = brands.sort((first,second) => first.displayName!.localeCompare(second.displayName!))
        }
      })
      catalogService.getAccessories().subscribe({
        next: accessories => {
          this.allAccessories = accessories.sort((first,second) => first.name!.localeCompare(second.name!))
        }
      })

      this.referenceTypeControl.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe({
        next: value => {
            this.tryLoadingReferences()
        }
      })
      this.referenceIdControl.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe({
        next: value => {
            this.tryLoadingReferences()
        }
      })
    }

    private reloadAuthenticationReport() {
      this.authenticationReport = null;
      this.authenticationReportsService.getAuthenticationReport(this._authenticationReportId!).subscribe({
        next: (authenticationReport: AuthenticationReport) => {
          this.authenticationReport = authenticationReport;

          if (this.authenticationReport.status == 'PENDING') {
            this.formGroup.enable()
          } else {
            this.formGroup.disable()
          }    

          this.referenceTypeControl.setValue(authenticationReport.formData.referenceType)
          this.referenceIdControl.setValue(authenticationReport.formData.referenceId)
          this.brandControl.setValue(authenticationReport.formData.modelBrandId)
          this.referenceNumberControl.setValue(authenticationReport.formData.modelReferenceNumber)
          this.authenticationReasonControl.setValue(authenticationReport.formData.authenticationReason);
          this.resultControl.setValue(authenticationReport.result);

          if (this.authenticationReport.order) {
            this.referenceTypeControl.setValue('ORDER')
            this.referenceIdControl.setValue(String(authenticationReport.order.id))
            this.referenceTypeControl.disable()
            this.referenceIdControl.disable()
            this.referencedOrder = authenticationReport.order
            this.referencedListing = authenticationReport.order.listing
          } else if (this.authenticationReport.listing) {
            this.referenceTypeControl.setValue('LISTING')
            this.referenceIdControl.setValue(String(authenticationReport.listing.id))
            this.referenceTypeControl.disable()
            this.referenceIdControl.disable()
            this.referencedOrder = null
            this.referencedListing = authenticationReport.listing
          } else {
            this.referenceTypeControl.setValue(authenticationReport.formData.referenceType)
            this.referenceIdControl.setValue(authenticationReport.formData.referenceId)
          }
          this.tryLoadingReferences()
          this.handleReferencesChanged()

          this.formGroup.markAsPristine()

          this.reloadVerificationReport()
        },
        error: (error: any) => {
          console.log(error);
        }
      });
    }

    private reloadVerificationReport() {
      this.verificationReportsService.getVerificationReports(null, null, null, this._authenticationReportId, null, null, 1, 0).subscribe({
        next: (response: any) => {
          this.verificationReport = response.data.length > 0 ? response.data[0] : null;
        },
        error: (error: any) => {
          console.log(error);
          this.verificationReport = null
        }
      });
    }

    tryLoadingReferences(): void {
      if (this.authenticationReport?.order || this.authenticationReport?.listing) {
        // Don't allow overriding the order or listing references if linked to report
        return
      }

      if (this.referenceIdControl.value && this.referenceIdControl.value.length > 0) {
        if (this.referenceTypeControl.value == 'LISTING') {
          this.listingsService.getListing(Number(this.referenceIdControl.value)).subscribe({
            next: listing => {
              this.referencedOrder = null
              this.referencedListing = listing;
              this.handleReferencesChanged();
            }, 
            error: error => {
              console.log(error)
              this.referencedOrder = null;
              this.referencedListing = null;
            }
          })
        } else if (this.referenceTypeControl.value == 'ORDER') {
          this.ordersService.getOrder(Number(this.referenceIdControl.value)).subscribe({
            next: order => {
              console.log(order)
              this.referencedOrder = order
              this.referencedListing = order.listing;
              this.handleReferencesChanged();
            }, 
            error: error => {
              console.log(error)
              this.referencedOrder = null;
              this.referencedListing = null;
            }
          })
        } else if (this.referenceTypeControl.value == 'RETURN') {
          this.returnsService.getReturn(Number(this.referenceIdControl.value)).subscribe({
            next: r => {
              this.referencedOrder = r.order
              this.referencedListing = r.order.listing;
              this.handleReferencesChanged();
            }, 
            error: error => {
              console.log(error)
              this.referencedOrder = null;
              this.referencedListing = null;
            }
          })
        } else {
          this.referencedOrder = null;
          this.referencedListing = null;
          this.handleReferencesChanged();
        }
      } else {
        this.referencedOrder = null;
        this.referencedListing = null;
        this.handleReferencesChanged();
      }
    }


    private handleReferencesChanged(): void {
      if (this.referencedOrder) {
        this.brandControl.setValue(this.referencedOrder.listing.model.brand.id)
        this.brandControl.disable()
        this.referenceNumberControl.setValue(this.referencedOrder.listing.model.referenceNumber)
        this.referenceNumberControl.disable()
        this.authenticationReasonControl.setValue('ORDER')
        this.authenticationReasonControl.disable()
      } else if (this.referencedListing) {
        this.brandControl.setValue(this.referencedListing.model.brand.id)
        this.brandControl.disable()
        this.referenceNumberControl.setValue(this.referencedListing.model.referenceNumber)
        this.referenceNumberControl.disable()
        if (this.referencedListing.activePricingModel == 'AUCTION') {
          this.authenticationReasonControl.setValue('AUCTION')
          this.authenticationReasonControl.disable()
        }
      } else if (this.formGroup.enabled) {
        this.referenceTypeControl.enable()
        this.referenceIdControl.enable()
        this.brandControl.enable()
        this.referenceNumberControl.enable()
        this.authenticationReasonControl.enable()
      }
    }
    
    ngOnInit(): void {
      this.toolbarService.setController(this);
    }
    
    ngOnDestroy(): void {
      this.toolbarService.removeController(this);
    }

    onSelectNewPriority(newPriority: string): void {
      var originalPriority = this.authenticationReport!.priority
      this.authenticationReport!.priority = newPriority
      this.authenticationReportsService.modifyAuthenticationReport(this._authenticationReportId!, {
        priority: newPriority
      }).subscribe({
        next: () => {
          // Success!
        },
        error: (error: any) => {
          console.log(error);
          this.authenticationReport!.priority = originalPriority
        }
      });
    }

    markCancelled(): void {
      this.isCancelling = true
      this.authenticationReportsService.modifyAuthenticationReport(this._authenticationReportId!, {status: 'CANCELLED'}).subscribe({
        next: () => {
          this.reloadAuthenticationReport()
          this.isCancelling = false
        },
        error: (error: any) => {
          console.log(error);
          this.isCancelling = false
        }
      });
    }
    
    saveDetails(): void {
      this.isSaving = true
      this.authenticationReportsService.modifyAuthenticationReport(this._authenticationReportId!, {
        result: this.resultControl.dirty ? this.resultControl.value : undefined,
        formData: {
          referenceType: this.referenceTypeControl.dirty ? this.referenceTypeControl.value : undefined,
          referenceId: this.referenceIdControl.dirty ? this.referenceIdControl.value : undefined,
          modelBrandId: this.brandControl.value ? this.brandControl.value : null,
          modelReferenceNumber: this.referenceNumberControl.value ? this.referenceNumberControl.value : null,
          authenticationReason: this.authenticationReasonControl.value ? this.authenticationReasonControl.value : null,
        },
      }).subscribe({
        next: () => {
          this.reloadAuthenticationReport()
          this.isSaving = false
        },
        error: (error: any) => {
          console.log(error);
          this.isSaving = false
        }
      });
    }

    markComplete(): void {
      this.isFinalizing = true
      this.authenticationReportsService.modifyAuthenticationReport(this._authenticationReportId!, {status: 'COMPLETE'}).subscribe({
        next: () => {
          this.reloadAuthenticationReport()
          this.isFinalizing = false
        },
        error: (error: any) => {
          console.log(error);
          this.isFinalizing = false
        }
      });
    }
}
