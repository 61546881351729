import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import Collection from 'src/app/models/collection.model';

@Component({
    selector: 'collection-card',
    templateUrl: './collection-card.component.html',
    styleUrls: ['./collection-card.component.css'],
})
export class CollectionCard {
    @Input('collection') collection!: Collection;
    @Input('clickable') clickable: boolean = true;

    constructor(
        private router: Router
    ) { }

    onClick(): void {
        if (!this.clickable) {
            return;
        }
        this.router.navigate(['/content/collections', this.collection.id]);
    }
}
