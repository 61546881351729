import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorDialog } from 'src/app/common/error-dialog/error-dialog.component';
import Highlight from 'src/app/models/highlight.model';
import Model from 'src/app/models/model.model';
import { HighlightsService } from 'src/app/_services/highlights.service';
import { ImageService } from 'src/app/_services/image.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { ModelsService } from 'src/app/_services/models.service';

interface ImageData {
  existingId: number | null,
  image: InstanceType<typeof Image>
}

@Component({
  selector: 'app-create-highlight',
  templateUrl: './create-highlight.component.html',
  styleUrls: ['./create-highlight.component.css']
})
export class CreateHighlightComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath!: BehaviorSubject<TitlePathElement[]>;
  title!: BehaviorSubject<string>;

  loading: boolean = true;
  editing: boolean = false;
  submitting: boolean = false;

  highlightId: number | null = null;
  private _highlight: Highlight | null = null;

  searchResults: BehaviorSubject<Model[]> = new BehaviorSubject<Model[]>([]);
  private mouseHoveringOverResults: boolean = false;
  hoveredModel!: Model | null
  private searchBarFocused: boolean = false;
  hideSearchResults: boolean = false;
  modelText!: string | null

  formattedTitle = new FormControl("", [Validators.required]);
  description = new FormControl("", [Validators.required]);
  model = new FormControl(0, [Validators.required]);
  priority = new FormControl(0);
  image = new FormControl<ImageData | null>(null, [Validators.required]);
  formGroup = new FormGroup({
    formattedTitle: this.formattedTitle,
    description: this.description,
    model: this.model,
    priority: this.priority,
    image: this.image,
  });

  private _destroyed = new ReplaySubject<boolean>(1);

  constructor(
    private toolbarService: ToolbarService,
    private highlightsService: HighlightsService,
    private modelsService: ModelsService,
    private imageService: ImageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) { 
    const routeParams = this.route.snapshot.paramMap;
    if (routeParams.get('highlightId')) {
      this.highlightId = Number(routeParams.get('highlightId'));
      this.editing = true;
    
      this.titlePath = new BehaviorSubject<TitlePathElement[]>([
        { title: "Highlights", path: ['/content/highlights'] },
        { title: this.highlightId.toString(), path: ['/content/highlights', this.highlightId.toString()] }
      ]);
      this.title = new BehaviorSubject<string>("Edit");

      highlightsService.getHighlight(this.highlightId).subscribe({
        next: highlight => {
          this._highlight = highlight;
          this.titlePath.next([
            { title: "Highlights", path: ['/content/highlights'] },
            { title: highlight.formattedTitle, path: ['/content/highlights', highlight.id.toString()] }
          ]);

          this.formattedTitle.setValue(highlight.formattedTitle);
          this.description.setValue(highlight.description);
          this.model.setValue(highlight.model.id);
          this.modelText = `${highlight.model.brand.displayName ?? highlight.model.brand.name} ${highlight.model.displayName ?? highlight.model.name}`;
          this.priority.setValue(highlight.priority);

          var img = new Image();
          img.src = highlight.image.rawUrl;
          this.image.setValue({existingId: highlight.image.id, image: img});

          this.loading = false;
        },
        error: error => {
          console.log(error)
        }
      })
    } else {
      this.editing = false;
      this.loading = false;

      this.titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Highlights", path: ['/content/highlights'] }]);
      this.title = new BehaviorSubject<string>("Create");
    }
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  onCancel(): void {
    if (this.highlightId) {
      this.router.navigate(['/content/highlights', this.highlightId])
    } else {
      this.router.navigate(['/content/highlights'])
    }
  }

  onSearchTextChanged(query: string) {
    if (query.length < 2) {
      this.searchResults.next([]);
      return;
    }

    this.modelsService.searchModels(query).subscribe({
      next: models => {
        this.searchResults.next(models);
      },
      error: error => {
        console.log(error)
      }
    })
  }

  onSearchResultClicked(result: Model) {
    this.model.setValue(result.id);
    this.model.markAsDirty();
    this.modelText = `${result.brand.displayName ?? result.brand.name} ${result.displayName ?? result.name}`;
    this.hideSearchResults = true;
    this.searchResults.next([]);
  }

  onFocus() {
    this.searchBarFocused = true;
    this.calculateSearchResultVisibilityState();
  }

  onBlur() {
    this.searchBarFocused = false;
    this.calculateSearchResultVisibilityState();
  }

  mouseEnterSearchResults(result: Model) {
    this.mouseHoveringOverResults = true;
    this.hoveredModel = result
    this.calculateSearchResultVisibilityState();
  }

  mouseExitSearchResults() {
    this.mouseHoveringOverResults = false;
    this.hoveredModel = null
    this.calculateSearchResultVisibilityState();
  }

  private calculateSearchResultVisibilityState() {
    this.hideSearchResults = (!this.mouseHoveringOverResults && !this.searchBarFocused);
  }

  clearModelSelection = () => {
    this.model.reset();
    this.modelText = null;
  }

  onUploadImage(target: EventTarget | null): void {
    var image = this.extractImage(target)
    if (!image) {
      return
    }

    this.image.setValue({existingId: null, image: image});
    this.image.markAsDirty();
  }

  onRemoveImage(presentation: string): void {
    this.image.reset()
  }

  private extractImage(target: EventTarget | null): InstanceType<typeof Image> | null {
    if (!(target instanceof HTMLInputElement)) {
      return null;
    }

    var input = target as HTMLInputElement;
    var file = input.files![input.files!.length - 1]
    var img = new Image();
    var reader = new FileReader();
    reader.onload = event => {
      img.src = event.target!.result as string;
    }
    reader.readAsDataURL(file);
    return img;
  }

  onSubmit(): void {
    if (this.submitting) {
      return;
    }
    this.submitting = true;

    this.uploadImages().subscribe({
      next: success => {
        if (!success) {
          console.log("Failed to upload all images.");
          return;
        }

        var params: any = {}
        if (this.formattedTitle.dirty) {
          params.formattedTitle = this.formattedTitle.value;
        }
        if (this.description.dirty) {
          params.description = this.description.value;
        }
        if (this.model.dirty) {
          params.modelId = this.model.value;
        }
        if (this.priority.dirty) {
          params.priority = this.priority.value;
        }
        if (this.image.dirty && this.image.value != null) {
          params.imageId = this.image.value.existingId;
        }
    
        if (this.highlightId) {
          this.updateHighlight(params);
        } else {
          this.createHighlight(params);
        }
      }
    })
  }

  createHighlight = (params: any) => {
    this.highlightsService.createHighlight(params).subscribe({
      next: (highlight: Highlight) => {
        this.router.navigate(['/content/highlights', highlight.id]);
      },
      error: response => {
        console.log(response.error);
        this.showError(response.error);
        this.submitting = false;
      }
    })
  };

  updateHighlight = (params: any) => {
    this.highlightsService.updateHighlight(this.highlightId!, params).subscribe({
      next: (highlight: Highlight) => {
        this.router.navigate(['/content/highlights', highlight.id]);
      },
      error: response => {
        console.log(response.error);
        this.showError(response.error);
        this.submitting = false;
      }
    })
  };

  private uploadImages(): Observable<boolean> {
    var ids = [];

    if (this.image.value && this.image.value.image && !this.image.value.existingId) {
      ids.push(this.imageService.uploadImage(this.image.value.image));
    }

    if (ids.length == 0) {
      return of(true);
    }
    
    return forkJoin(ids).pipe(
      map(imageIds => {
        if (imageIds.length != ids.length) {
          return false;
        }

        var offset = 0;
        if (this.image.value && this.image.value.image && !this.image.value.existingId) {
          this.image.value.existingId = imageIds[offset];
          offset += 1;
        }
        return true;
      })
    );
  }

  private showError(error: {error: string, description?: string }) {
    this.dialog.open(ErrorDialog,  {
      width: '500px',
      data: {
        error: error
      }
    });
  }
}
