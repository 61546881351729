import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorDialog } from 'src/app/common/error-dialog/error-dialog.component';
import Article from 'src/app/models/article.model';
import List from 'src/app/models/list.model';
import { ArticlesService } from 'src/app/_services/articles.service';
import { ImageService } from 'src/app/_services/image.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';

@Component({
  selector: 'app-create-article',
  templateUrl: './create-article.component.html',
  styleUrls: ['./create-article.component.css']
})
export class CreateArticleComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath!: BehaviorSubject<TitlePathElement[]>;
  title!: BehaviorSubject<string>;
  
  loading: boolean = true;
  editing: boolean = false;
  submitting: boolean = false;

  articleId: number | null = null;
  private _article: Article | null = null;

  titleControl = new FormControl("", [Validators.required]);
  url = new FormControl("", [Validators.required])
  publisher = new FormControl("", [Validators.required])
  published = new FormControl(new Date(), [Validators.required])
  bannerImage: {
    existingId: number | null,
    image: InstanceType<typeof Image> | null,
  } = {
    existingId: null,
    image: null
  };

  imagesDirty = false;
  formGroup = new FormGroup({
    title: this.titleControl,
    url: this.url,
    publisher: this.publisher,
    published: this.published,
  });

  private _destroyed = new ReplaySubject<boolean>(1);
  
  constructor(
    private toolbarService: ToolbarService,
    private articlesService: ArticlesService,
    private imagesService: ImageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) { 
    const routeParams = this.route.snapshot.paramMap;
    if (routeParams.get('articleId')) {
      this.articleId = Number(routeParams.get('articleId'));
      this.editing = true;
    
      this.titlePath = new BehaviorSubject<TitlePathElement[]>([
        { title: "Articles", path: ['/content/articles'] },
        { title: this.articleId.toString(), path: ['/content/articles', this.articleId.toString()] }
      ]);
      this.title = new BehaviorSubject<string>("Edit");

      articlesService.getArticle(this.articleId).subscribe({
        next: article => {
          this._article = article;
          this.titlePath.next([
            { title: "Articles", path: ['/content/articles'] },
            { title: article.title, path: ['/content/articles', article.id.toString()] }
          ]);

          this.titleControl.setValue(article.title);
          this.url.setValue(article.url);
          this.publisher.setValue(article.publisher);
          this.published.setValue(article.published);

          if (article.bannerImage) {
            var img = new Image();
            img.src = article.bannerImage.rawUrl;

            this.bannerImage.existingId = article.bannerImage.id;
            this.bannerImage.image = img;
          }

          this.loading = false;
        },
        error: error => {
          console.log(error)
        }
      })
    } else {
      this.editing = false;
      this.loading = false;

      this.titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Articles", path: ['/content/articles'] }]);
      this.title = new BehaviorSubject<string>("Create");
    }
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  hasValidForm(): boolean {
    return this.formGroup.valid;
  }

  onUploadBannerImage(target: EventTarget | null): void {
    this.imagesDirty = true;
    
    var image = this.extractImage(target)
    if (!image) {
      return
    }

    this.bannerImage = {existingId: null, image: image}
  }

  onRemoveBannerImage(presentation: string): void {
    this.imagesDirty = true;
    this.bannerImage = { existingId: null, image: null}
  }

  private extractImage(target: EventTarget | null): InstanceType<typeof Image> | null {
    if (!(target instanceof HTMLInputElement)) {
      return null;
    }

    var input = target as HTMLInputElement;
    var file = input.files![input.files!.length - 1]
    var img = new Image();
    var reader = new FileReader();
    reader.onload = event => {
      img.src = event.target!.result as string;
    }
    reader.readAsDataURL(file);
    return img;
  }

  onCancel(): void {
    if (this.articleId) {
      this.router.navigate(['/content/articles', this.articleId])
    } else {
      this.router.navigate(['/content/articles'])
    }
  }

  onSubmit(): void {
    if (this.submitting) {
      return;
    }
    this.submitting = true;

    this.uploadImages().subscribe({
      next: success => {
        if (!success) {
          console.log("Failed to upload all images.");
          return;
        }

        var params: any = {}
        if (this.titleControl.dirty) {
          params.title = this.titleControl.value;
        }
        if (this.url.dirty) {
          params.url = this.url.value;
        }
        if (this.publisher.dirty) {
          params.publisher = this.publisher.value;
        }
        if (this.published.dirty) {
          params.published = this.published.value;
        }

        if (this.bannerImage && this.bannerImage.existingId) {
          params.bannerImage = this.bannerImage.existingId;
        } else {
          params.bannerImage = null;
        }

        if (this.articleId) {
          this.updateArticle(params);
        } else {
          this.createArticle(params);
        }
      }
    })
  }

  createArticle = (params: any) => {
    this.articlesService.createArticle(params).subscribe({
      next: (article: Article) => {
        this.router.navigate(['/content/articles', article.id]);
      },
      error: response => {
        console.log(response.error);
        this.showError(response.error);
        this.submitting = false;
      }
    })
  };

  updateArticle = (params: any) => {
    this.articlesService.updateArticle(this.articleId!, params).subscribe({
      next: (article: Article) => {
        this.router.navigate(['/content/articles', article.id]);
      },
      error: response => {
        console.log(response.error);
        this.showError(response.error);
        this.submitting = false;
      }
    })
  };

  private uploadImages(): Observable<boolean> {
    var ids = [];

    if (this.bannerImage && this.bannerImage.image && !this.bannerImage.existingId) {
      ids.push(this.imagesService.uploadImage(this.bannerImage.image));
    }

    if (ids.length == 0) {
      return of(true);
    }
    
    return forkJoin(ids).pipe(
      map(imageIds => {
        if (imageIds.length != ids.length) {
          return false;
        }

        var offset = 0;
        if (this.bannerImage && this.bannerImage.image && !this.bannerImage.existingId) {
          this.bannerImage.existingId = imageIds[offset];
          offset += 1;
        }

        return true;
      })
    );
  }

  private showError(error: {error: string, description?: string }) {
    this.dialog.open(ErrorDialog,  {
      width: '500px',
      data: {
        error: error
      }
    });
  }
}

