<div class="content-container">
    <div class="form-container">
        <form [formGroup]="formGroup">
            <div class="row">
                <div class="col-lg">
                    <div class="form-section">
                        <div class="form-section-title">
                            General
                        </div>
                        <div class="form-section-content">
                            <mat-form-field appearance="outline">
                                <mat-label>Name</mat-label>
                                <input matInput placeholder="Oyster Perpetual Datejust" formControlName="name" required="true">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Display Name</mat-label>
                                <input matInput placeholder="Datejust" formControlName="displayName">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Brand</mat-label>
                                <mat-select formControlName="brand" required>
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let brand of brands" [value]="brand.id">
                                        {{brand.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Series</mat-label>
                                <mat-select formControlName="series" [disabled]="!formControls.brand.value">
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let series of filteredSeries" [value]="series.id">
                                        {{series.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Reference Number</mat-label>
                                <input matInput placeholder="RF10012" formControlName="referenceNumber" required="true">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Gender</mat-label>
                                <mat-select formControlName="gender" required>
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let gender of genders" [value]="gender.id">
                                        {{gender.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Categories</mat-label>
                                <mat-select formControlName="categories" multiple>
                                    <mat-option *ngFor="let category of categories" [value]="category.id">
                                        {{category.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Description</mat-label>
                                <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="6"
                                    formControlName="description"></textarea>
                            </mat-form-field>
                            <table style="table-layout: fixed; width: 100%;">
                                <tr>
                                    <td>
                                        <mat-form-field appearance="outline">
                                            <mat-label>Release Year</mat-label>
                                            <input matInput formControlName="releaseYear" placeholder="1984" required>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="outline">
                                            <mat-label>Discontinuation Year</mat-label>
                                            <input matInput formControlName="discontinuationYear" placeholder="2014">
                                        </mat-form-field>
                                    </td>
                                </tr>
                            </table>
                            <div class="form-subsection">
                                <div class="form-subsection-title">
                                    Images
                                </div>
                                <div class="form-subsection-content">
                                    <div class="image-grid">
                                        <div class="image-grid-item" *ngFor="let image of images">
                                            <image-view [image]="image" [removable]="!submitting"
                                                (remove)="onImageRemove($event)"></image-view>
                                        </div>
                                        <div class="image-grid-item">
                                            <button mat-stroked-button type="button" color="primary" (click)="uploader.click()"
                                                class="add-image-button">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                            <input hidden type="file" accept=".png,.jpg,.jpeg" multiple
                                                (change)="onImageUpload($event.target)" #uploader>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-section">
                        <div class="form-section-title">
                            Case
                        </div>
                        <div class="form-section-content">
                            <mat-form-field appearance="outline">
                                <mat-label>Case Materials</mat-label>
                                <mat-select formControlName="caseMaterials" multiple required>
                                    <mat-option *ngFor="let material of materials" [value]="material.id">
                                        {{material.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Size (mm)</mat-label>
                                <input matInput placeholder="24.2 (circular) or 24.2 x 24.1 (rectangular)"
                                    formControlName="caseSize" required="true">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Thickness (mm)</mat-label>
                                <input matInput placeholder="16" formControlName="thickness">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Water Resistance (meters)</mat-label>
                                <input matInput placeholder="16" formControlName="waterResistance">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Bezel Type</mat-label>
                                <mat-select formControlName="bezelType">
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let bezelType of bezelTypes" [value]="bezelType.id">
                                        {{bezelType.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Bezel Material</mat-label>
                                <mat-select formControlName="bezelMaterial" required>
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let material of materials" [value]="material.id">
                                        {{material.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Crystal</mat-label>
                                <mat-select formControlName="crystal" required>
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let material of materials" [value]="material.id">
                                        {{material.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Lug Width (mm)</mat-label>
                                <input matInput placeholder="24.2" formControlName="lugWidth">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-lg">
                    <div class="form-section">
                        <div class="form-section-title">
                            Dial
                        </div>
                        <div class="form-section-content">
                            <mat-form-field appearance="outline">
                                <mat-label>Color</mat-label>
                                <mat-select formControlName="dialColor" required>
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let color of colors" [value]="color.id">
                                        {{color.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Numerals</mat-label>
                                <mat-select formControlName="dialNumerals" required>
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let numeral of numeralTypes" [value]="numeral.id">
                                        {{numeral.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Complications</mat-label>
                                <mat-select formControlName="complications" multiple>
                                    <mat-option *ngFor="let complication of complications" [value]="complication.id">
                                        {{complication.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-section">
                        <div class="form-section-title">
                            Movement
                        </div>
                        <div class="form-section-content">
                            <mat-form-field appearance="outline">
                                <mat-label>Movement Type</mat-label>
                                <mat-select formControlName="movementType" required>
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let type of movementTypes" [value]="type.id">
                                        {{type.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Caliber</mat-label>
                                <input matInput placeholder="15573-B"
                                    formControlName="caliber">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Power Reserve</mat-label>
                                <input matInput placeholder="16" formControlName="powerReserve">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Number of Jewels</mat-label>
                                <input matInput placeholder="16" formControlName="numberOfJewels" required="true">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-section">
                        <div class="form-section-title">
                            Bracelet
                        </div>
                        <div class="form-section-content">
                            <mat-form-field appearance="outline">
                                <mat-label>Style</mat-label>
                                <mat-select formControlName="braceletStyle" [disabled]="!formControls.brand.value">
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let braceletStyle of filteredBraceletStyles" [value]="braceletStyle.id">
                                        {{braceletStyle.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Bracelet Materials</mat-label>
                                <mat-select formControlName="braceletMaterials" multiple>
                                    <mat-option *ngFor="let material of materials" [value]="material.id">
                                        {{material.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Color</mat-label>
                                <mat-select formControlName="braceletColor">
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let color of colors" [value]="color.id">
                                        {{color.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Clasp Type</mat-label>
                                <mat-select formControlName="claspType">
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let type of claspTypes" [value]="type.id">
                                        {{type.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Clasp Materials</mat-label>
                                <mat-select formControlName="claspMaterials" multiple>
                                    <mat-option *ngFor="let material of materials" [value]="material.id">
                                        {{material.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section">
                <div class="form-section-content">
                    <button type="submit" [disabled]="!formGroup.valid || submitting" (click)="onSubmit()"
                        [class.spinner]="submitting" mat-raised-button color="primary" class="bottom-button">Submit</button>
                    <button type="button" [disabled]="submitting" (click)="onClear()" mat-stroked-button color="accent"
                        style="margin-left: 10px" class="bottom-button">Clear</button>
                </div>
            </div>
        </form>
    </div>
</div>
