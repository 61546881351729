<div class="d-flex flex-column h-100">
    <mat-card class="m-2">
        <mat-card-content class="d-flex flex-md-row flex-column">
            <mat-form-field appearance="outline" class="flex-fill mx-1">
                <mat-label>Brands</mat-label>
                <mat-select [compareWith]="compareIds" [(ngModel)]='brandFilter' (selectionChange)="handleFiltersChanged()" multiple>
                    <mat-option *ngFor="let brand of brands" [value]="brand.id">
                        {{brand.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="flex-fill mx-1">
                <mat-label>Series</mat-label>
                <mat-select [compareWith]="compareIds" [(ngModel)]='seriesFilter' (selectionChange)="handleFiltersChanged()" multiple [disabled]="brandFilter.length != 1">
                    <mat-option *ngFor="let series of filteredSeries" [value]="series.id">
                        {{series.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="flex-fill mx-1">
                <mat-label>Categories</mat-label>
                <mat-select [compareWith]="compareIds" [(ngModel)]='categoryFilter' (selectionChange)="handleFiltersChanged()" multiple>
                    <mat-option *ngFor="let category of categories" [value]="category.id">
                        {{category.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="flex-fill mx-1">
                <mat-label>Image Count</mat-label>
                <mat-select [compareWith]="compareIds" [(ngModel)]='imageCountFilter' (selectionChange)="handleFiltersChanged()">
                    <mat-option>--</mat-option>
                    <mat-option [value]="0">0</mat-option>
                    <mat-option [value]="1">1</mat-option>
                    <mat-option [value]="2">2</mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card-content>
    </mat-card>
    <mat-card class="mx-2 mb-2 p-0 flex-fill d-flex flex-column overflow-scroll">
        <div class="flex-fill position-relative overflow-scroll">
            <div *ngIf="isLoading"
                class="w-100 h-100 position-absolute d-flex align-items-center justify-content-center"
                style="z-index: 1;">
                <div *ngIf="brands.length > 0" class="w-100 h-100 position-absolute"
                    style="background-color: rgba(0, 0, 0, 0.1);"></div>
                <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
            </div>
            <div class="h-100 w-100 overflow-scroll">
                <table mat-table [dataSource]="models" class="w-100">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> Id </th>
                        <td mat-cell *matCellDef="let model"> {{model.id}} </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let model"> {{model.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="displayName">
                        <th mat-header-cell *matHeaderCellDef> Display Name </th>
                        <td mat-cell *matCellDef="let model"> {{model.displayName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="referenceNumber">
                        <th mat-header-cell *matHeaderCellDef> Reference Number </th>
                        <td mat-cell *matCellDef="let model"> {{model.referenceNumber}} </td>
                    </ng-container>
                    <ng-container matColumnDef="brand">
                        <th mat-header-cell *matHeaderCellDef> Brand </th>
                        <td mat-cell *matCellDef="let model">
                            <mat-chip-list>
                                <mat-chip [routerLink]="['/catalog/brands', model.brand.id]">
                                    {{model.brand.name}}
                                </mat-chip>
                            </mat-chip-list>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="series">
                        <th mat-header-cell *matHeaderCellDef> Series </th>
                        <td mat-cell *matCellDef="let model">
                            <mat-chip-list *ngIf="model.series">
                                <mat-chip [routerLink]="['/catalog/series', model.series.id]">
                                    {{model.series.displayName || model.series.name }}
                                </mat-chip>
                            </mat-chip-list>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="lifespan">
                        <th mat-header-cell *matHeaderCellDef> Lifespan </th>
                        <td mat-cell *matCellDef="let model"> {{ model.releaseYear }} - {{ model.discontinuationYear }}<ng-container
                                *ngIf="model.discontinuationYear == null">Present</ng-container>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let model; columns: displayedColumns;" [routerLink]="['/catalog/models/', model.id]"></tr>
                </table>
            </div>
        </div>
        <mat-paginator [length]="length" [pageIndex]="page" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageChangeEvent($event)" aria-label="Select page">
        </mat-paginator>
    </mat-card>
  </div>
  <button *ngIf="showAddModelButton"  mat-fab class="fab" [routerLink]="['/catalog/models/new']"><mat-icon>add</mat-icon></button>
