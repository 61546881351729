import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import BillingAccount from '../models/billing-account.model';
import User from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class BillingAccountsService {
  constructor(
    private http: HttpClient
  ) { }

  public getBillingAccounts(pageSize: number, offset: number, sortKey: string | null): Observable<any> {
    var path = `/payment/billing-accounts?pageSize=${pageSize}&offset=${offset}&expand[]=owner`;
    if (!!sortKey) {
      path += `&sort=${sortKey}`
    }
    return this.http.get<BillingAccount[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
        map(response => {
          return {
            data: response.body!.map(b => this.toBillingAccount(b)), 
            totalCount: response.headers.get('X-Total-Count')}
        })
      );
  }

  public getBillingAccount(id: number): Observable<BillingAccount> {
    var path = `/payment/billing-accounts/${id}?expand[]=owner`;
    return this.http.get<BillingAccount>(environment.apiUrl + path).pipe(
      map(billingAccount => this.toBillingAccount(billingAccount))
    );
  }

  private toBillingAccount(billingAccount: any): BillingAccount {
    var billingAccount = Object.assign(new BillingAccount(), billingAccount)
    if (billingAccount.owner) {
      billingAccount.owner = Object.assign(new User(), billingAccount.owner)
    }
    return billingAccount
  }
}
