import { Component, Input, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResourceChangeHistory } from 'src/app/models/resource-interaction.model';
import { ChangeHistoryService } from 'src/app/_services/change-history.service';
import { ResourceChangeHistoryDialog } from 'src/app/common/change-history-dialog/history-dialog.component'

@Component({
  selector: 'history-button',
  templateUrl: './history-button.component.html',
  styleUrls: ['./history-button.component.css']
})
export class ResourceChangeHistoryButtonComponent {
  @Input('type') resourceType!: string;
  @Input('resourceId') resourceId?: number;

  service = inject(ChangeHistoryService);
  history?: ResourceChangeHistory;
  loading: boolean = false;
  parsedBase: string = "";
  
  constructor(
    private dialog: MatDialog
  ) {}


  ngOnInit(): void {
    if (this?.history || !this?.resourceId || this.loading || this.resourceId == -1) {
      return;
    }
    this.loading = true;

    if (this.resourceType == "listing") {
      this.service.getListingHistory(this.resourceId).subscribe({
        next: (changes: ResourceChangeHistory[]) => {
          this.history = changes[0];
        },
        error: (error: any) => {
          console.log(error);
        }
      });
    } else if (this.resourceType == "proxyBid") {
      this.service.getProxyBidHistory(this.resourceId).subscribe({
        next: (changes: ResourceChangeHistory[]) => {
          this.history = changes[0];
        },
        error: (error: any) => {
          console.log(error);
        }
      });
    }
  }

  viewButtonPressed(): void {
    this.dialog.open(ResourceChangeHistoryDialog, {data: this.history});
  }
}
