import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { pluck } from 'rxjs/operators';
import HomeFeed, { Section } from 'src/app/models/home-feed.model';
import { HomeFeedsService } from 'src/app/_services/home-feeds.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { DeleteDialog } from 'src/app/common/delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AddSectionComponent } from '../add-section/add-section.component';
import { UpdateSectionComponent } from '../update-section/update-section.component';

@Component({
  selector: 'app-home-feed-detail',
  templateUrl: './home-feed-detail.component.html',
  styleUrls: ['./home-feed-detail.component.scss']
})
export class HomeFeedDetailComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Home feeds", path: ['/content', 'home-feeds'] }]);
  title = new BehaviorSubject<string>("?");

  isLoading = true;
  removingSection: any | null = null;

  homeFeed!: HomeFeed | null;
  private _homeFeedId!: number;
  
  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private homeFeedService: HomeFeedsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) { 
    this.route.params.pipe(
      pluck("homeFeedId")
    ).subscribe({
      next: homeFeedId => {
        if (this._homeFeedId == homeFeedId) {
          return;
        }

        this._homeFeedId = homeFeedId;
        this.title.next(homeFeedId.toString());
        this.reloadHomeFeed();
      }
    })
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  private reloadHomeFeed() {
    this.isLoading = true;

    this.homeFeed = null;
    this.homeFeedService.getHomeFeed(this._homeFeedId).subscribe({
      next: homeFeed => {
        this.isLoading = false;
        this.homeFeed = homeFeed;
        console.log(this.homeFeed)
        this.handleHomeFeedChanged();
      },
      error: error => {
        console.log(error)
      }
    })
  }

  private handleHomeFeedChanged() {
    if (this.homeFeed?.name) {
      this.title.next(this.homeFeed.name)
    } else if (this._homeFeedId) {
      this.title.next(this._homeFeedId.toString())
    } else {
      this.title.next('?')
    }
  }

  addSectionButtonPressed() {
    var dialogRef = this.dialog.open(AddSectionComponent, {
      width: '300px',
      data: {homeFeed: this.homeFeed}
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result.added) {
        this.reloadHomeFeed()
      }
    });
  }

  editSectionButtonPressed(section: Section): void {
    var dialogRef = this.dialog.open(UpdateSectionComponent, {
      width: '300px',
      data: {
        homeFeed: this.homeFeed,
        section: section
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result.updated) {
        this.reloadHomeFeed()
      }
    });
  }

  increasePositionOfSection(section: Section): void {
    var prevSections = [...this.homeFeed!.sections]
    var index = this.homeFeed!.sections.indexOf(section)
    this.homeFeed!.sections = this.homeFeed!.sections.filter(s => s.id !== section.id)
    this.homeFeed!.sections.splice(index - 1, 0, section)

    var sectionIds = this.homeFeed!.sections.map(s => s.id)
    this.homeFeedService.updateHomeFeed(this._homeFeedId, {sections: sectionIds}).subscribe({
      next: () => { 

      },
      error: error => {
        console.log(error);
        this.homeFeed!.sections = prevSections;
      }
    })
  }

  decreasePositionOfSection(section: Section): void {
    var prevSections = [...this.homeFeed!.sections]
    var index = this.homeFeed!.sections.indexOf(section)
    this.homeFeed!.sections = this.homeFeed!.sections.filter(s => s.id !== section.id)
    this.homeFeed!.sections.splice(index + 1, 0, section)

    var sectionIds = this.homeFeed!.sections.map(s => s.id)
    this.homeFeedService.updateHomeFeed(this._homeFeedId, {sections: sectionIds}).subscribe({
      next: () => { 
        
      },
      error: error => {
        console.log(error);
        this.homeFeed!.sections = prevSections;
      }
    })
  }

  removeSectionButtonPressed(section: Section): void {
    this.removingSection = section;
    this.homeFeedService.removeSection(this._homeFeedId, section.id).subscribe({
      next: () => {
        this.homeFeed!.sections = this.homeFeed!.sections.filter(s => s.id !== section.id)
        this.removingSection = null;
      },
      error: error => {
        console.log(error);
        this.removingSection = null;
      }
    })
  }
  
  deleteButtonPressed(): void {
    this.dialog.open(DeleteDialog, {
      width: '20vw',
      data: {name: this.homeFeed?.name, onClickConfirm: (callback: () => void) => this.deleteHomeFeed(callback)}
    })
  }

  public deleteHomeFeed(callback: () => void) {
    this.homeFeedService.deleteHomeFeed(this._homeFeedId).subscribe({
      next: () => {
        callback();
        this.router.navigate(['/content/home-feeds'])
      },
      error: error => {
        console.log(error)
        callback();
      }
    })
  }
}
