<div class="dialog-content">
  <h3 style="font-weight: bold">Are you sure you want to {{data.action ?? "delete"}} {{data.name}}?</h3>
  <div class="d-flex justify-content-between">
    <button [disabled]="loading" mat-raised-button color="primary" (click)="onCancel()">Close</button>
    <button mat-raised-button color="warn" (click)="onClickConfirm()">
      <span *ngIf="loading">
        <mat-spinner diameter="30" class="loading-spinner"></mat-spinner>
      </span>
      <span *ngIf="!loading">Confirm</span>
    </button>
  </div>
</div>