import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import ProxyBid from '../models/proxy-bid.model';
import Listing from '../models/listing.model';
import Transaction from '../models/transaction.model';
import BuyerProfile from '../models/buyer-profile.model';

@Injectable({
  providedIn: 'root'
})
export class ProxyBidsService {
  constructor(private http: HttpClient) { }

  public getProxyBids(pageSize: number, offset: number, status: string | null, listing: number | null, buyer: number | null, client: string | null, sortKey: string | null): Observable<any> {
    var path = `/marketplace/proxy-bids?offset=${offset}&pageSize=${pageSize}&expand[]=listing.model&expand[]=buyerProfile.user`;
    if (!!listing) {
      path += `&listing[]=${listing}`;
    }
    if (!!buyer) {
      path += `&buyerProfile[]=${buyer}`;
    }
    if (!!status) {
      path += `&status[]=${status}`;
    }
    if (!!client) {
      path += `&client[]=${client}`;
    }
    if (!!sortKey) {
      path += `&sort=${sortKey}`
    }
    return this.http.get<ProxyBid[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
      map(response => {
        return {
          data: response.body!.map(o => this.toProxyBid(o)),
          totalCount:  response.headers.get('X-Total-Count')
        }
      })
    );
  }

  public getProxyBid(proxyBidId: number): Observable<ProxyBid> {
    return this.http.get<ProxyBid>(environment.apiUrl + `/marketplace/proxy-bids/${proxyBidId}?expand[]=listing.model&expand[]=buyerProfile.user&expand[]=transaction`).pipe(
      map(fetchedProxyBid => this.toProxyBid(fetchedProxyBid))
    );
  }

  public updateProxyBid(proxyBidId: number, params: any) {
    return this.http.patch(environment.apiUrl + `/marketplace/proxy-bids/${proxyBidId}`, params)
  }

  private toProxyBid(fetchedProxyBid: any): ProxyBid {
    var proxyBid = Object.assign(new ProxyBid(), fetchedProxyBid);
    if (proxyBid.listing) {
      proxyBid.listing = Object.assign(new Listing(), proxyBid.listing)
    }
    if (proxyBid.buyer) {
      proxyBid.buyer = Object.assign(new BuyerProfile(), proxyBid.buyer)
    }
    if (proxyBid.transaction) {
      proxyBid.transaction = Object.assign(new Transaction(), proxyBid.transaction)
    }
    return proxyBid;
  }
}
