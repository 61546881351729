<div class="container" *ngIf="!loading else spinner">
    <mat-card class="my-2">
        <mat-card-title class="mb-4">
            <ng-container *ngIf="!editing">Create article</ng-container>
            <ng-container *ngIf="editing">Edit article</ng-container>
        </mat-card-title>
        <mat-card-content>
            <div class="d-flex flex-column">
                <mat-form-field appearance="outline" class="flex-fill">
                    <mat-label>Title</mat-label>
                    <input matInput type="string" placeholder="This Week's Best: Submariner Alternatives" [formControl]="titleControl" required>
                </mat-form-field>
                <mat-form-field appearance="outline" class="flex-fill">
                    <mat-label>URL</mat-label>
                    <input matInput type="string" placeholder="https://www.getbezel.com/post/submariner-alternatives" [formControl]="url" required>
                </mat-form-field>
                <mat-form-field appearance="outline" class="flex-fill">
                    <mat-label>Publisher</mat-label>
                    <input matInput type="string" placeholder="Bezel" [formControl]="publisher" required>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Published</mat-label>
                    <input matInput [matDatepicker]="picker1" [formControl]="published" required>
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>   
                <table style="text-align: center; margin-bottom: 12px; border-collapse: separate; border-spacing: 10px;">
                    <th colspan="3" style="padding: 14px">
                        Images
                    </th>
                    <tr>
                        <td style="width: 500px">Banner</td>
                        <td style="min-width: 300px">
                            <ng-container *ngIf="bannerImage?.image else addBannerImage">
                                <image-view [image]="bannerImage?.image" (remove)="onRemoveBannerImage()" [removable]="true"></image-view>
                            </ng-container>
                            <ng-template #addBannerImage>
                                <button mat-stroked-button type="button" color="primary" (click)="bannerImageUploader.click()" class="add-image-button">
                                    <mat-icon>add</mat-icon>
                                </button>
                                <input hidden type="file" accept=".png,.jpg,.jpeg" (change)="onUploadBannerImage($event.target)" #bannerImageUploader>
                            </ng-template>
                        </td>
                    </tr>
                </table>
            </div>
        </mat-card-content>
        <mat-card-actions class="d-flex justify-content-between">
            <button mat-raised-button color="warn" (click)="onCancel()" [disabled]="submitting">Cancel</button>
            <div>
                <button mat-raised-button color="primary" (click)="onSubmit()"
                    [disabled]="!hasValidForm() || submitting || (!formGroup.dirty && !imagesDirty)" [class.spinner]="submitting">
                    <ng-container *ngIf="!editing">Submit</ng-container>
                    <ng-container *ngIf="editing">Save</ng-container>
                </button>
            </div>
        </mat-card-actions>
    </mat-card>
</div>
<ng-template #spinner>
    <mat-card class="m-2 d-flex flex-column align-items-center">
        <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
    </mat-card>
</ng-template>
