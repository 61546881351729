import { formatDate  } from "@angular/common";
import BuyerProfile from "./buyer-profile.model";
import Listing from "./listing.model";
import SellerProfile from "./seller-profile.model";

export default class Offer {
  id!: number;
  status!: string;
  listing!: Listing;
  priceCents!: number;
  sellerProfile!: SellerProfile;
  created!: Date;
  updated!: Date;
  expiration!: Date;
  buyerProfile!: BuyerProfile;
  transaction!: {
    id: number,
    type: string,
    status: string,
    stripeDashboardLink: string,
    baseAmountCents: number,
    creditsApplied: number,
    paymentAmountCents: number
  };
  apiVersion!: number;
  client!: string;
  priceHistory!: [any]

  get displayStatus() {
    return allStatuses[this.status];
  }

  get displayCreated(): string {
    return formatDate(this.created, 'medium', 'en-US');
  }

  get displayUpdated(): string {
    return formatDate(this.updated, 'medium', 'en-US');
  }

  get displayExpiration(): string {
    return formatDate(this.expiration, 'medium', 'en-US');
  }

  get canAccept(): boolean {
    return this.status === 'AWAITING_SELLER_RESPONSE' || this.status === 'AWAITING_BUYER_RESPONSE';
  }
  
  get canDecline(): boolean {
    return this.status === 'AWAITING_SELLER_RESPONSE' || this.status === 'AWAITING_BUYER_RESPONSE';
  }

  get canCounter(): boolean {
    return this.status === 'AWAITING_SELLER_RESPONSE' || this.status === 'AWAITING_BUYER_RESPONSE';
  }

  get canManuallyCancel(): boolean {
    return this.status === 'AWAITING_PAYMENT' || this.status === 'AWAITING_BUYER_RESPONSE' || this.status === 'AWAITING_SELLER_RESPONSE' || this.status === 'ACCEPTED';
  }
}

export const offerStatuses = [
  'ALL',
  'AWAITING_PAYMENT',
  'AWAITING_BUYER_RESPONSE',
  'AWAITING_SELLER_RESPONSE',
  'ACCEPTED',
  'ORDERED',
  'CANCELLED'
]

export const allStatuses: {[key: string]: string } = {
  'ALL': 'All',
  'AWAITING_PAYMENT': 'Waiting for payment',
  'AWAITING_BUYER_RESPONSE': 'Awaiting buyer response',
  'AWAITING_SELLER_RESPONSE': 'Awaiting seller response',
  'ACCEPTED': 'Accepted',
  'ORDERED': 'Ordered',
  'CANCELLED': 'Cancelled'
}

export const offerVersions = [
  1, 2
]