<div class="d-flex flex-column h-100">
  <mat-card class="m-2 p-0 flex-fill d-flex flex-column overflow-scroll">
      <div class="flex-fill position-relative overflow-scroll">
          <div *ngIf="isLoading"
              class="w-100 h-100 position-absolute d-flex align-items-center justify-content-center"
              style="z-index: 1;">
              <div *ngIf="brands.length > 0" class="w-100 h-100 position-absolute"
                  style="background-color: rgba(0, 0, 0, 0.1);"></div>
              <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
          </div>
          <div class="h-100 w-100 overflow-scroll">
              <table mat-table [dataSource]="brands" class="w-100">
                  <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef> Id </th>
                      <td mat-cell *matCellDef="let brand"> {{brand.id}} </td>
                  </ng-container>
                  <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef> Name </th>
                      <td mat-cell *matCellDef="let brand"> {{brand.name}} </td>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                      <th mat-header-cell  *matHeaderCellDef >  </th>
                      <td mat-cell *matCellDef="let brand" style="text-align: right;">
                           <button mat-icon-button (click)="onClickDelete(brand)"><mat-icon>delete</mat-icon></button>
                      </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let brand; columns: displayedColumns;" [routerLink]="['/catalog/brands/', brand.id]">
              </table>
          </div>
      </div>
      <mat-paginator [length]="length" [pageIndex]="page" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
          (page)="pageChangeEvent($event)" aria-label="Select page" disabled>
      </mat-paginator>
  </mat-card>
</div>
<button mat-fab class="fab" [routerLink]="['/catalog/brands/new']"><mat-icon>add</mat-icon></button>
