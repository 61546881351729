import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { DeleteDialog } from 'src/app/common/delete-dialog/delete-dialog.component';
import Highlight from 'src/app/models/highlight.model';
import { HighlightsService } from 'src/app/_services/highlights.service';
import { TitlePathElement, ToolbarService } from 'src/app/_services/toolbar.service';

@Component({
  selector: 'app-highlight-detail',
  templateUrl: './highlight-detail.component.html',
  styleUrls: ['./highlight-detail.component.css']
})
export class HighlightDetailComponent implements OnInit {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Highlights", path: ['/content', 'highlights'] }]);
  title = new BehaviorSubject<string>("?");

  isLoading = true;
  
  highlight!: Highlight | null;
  private _highlightId!: number;

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private highlightsService: HighlightsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) { 
    this.route.params.pipe(
      pluck("highlightId")
    ).subscribe({
      next: highlightId => {
        if (this._highlightId == highlightId) {
          return;
        }

        this._highlightId = highlightId;
        this.title.next(highlightId.toString());
        this.reloadHighlight();
      }
    })
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  private reloadHighlight() {
    this.isLoading = true;

    this.highlight = null;
    this.highlightsService.getHighlight(this._highlightId).subscribe({
      next: highlight => {
        this.isLoading = false;
        this.highlight = highlight;
        this.handleHighlightChanged();
      },
      error: error => {
        console.log(error)
      }
    })
  }

  private handleHighlightChanged() {
    if (this.highlight?.formattedTitle) {
      this.title.next(this.highlight.formattedTitle)
    } else if (this._highlightId) {
      this.title.next(this._highlightId.toString())
    } else {
      this.title.next('?')
    }
  }

  deleteButtonPressed(): void {
    this.dialog.open(DeleteDialog, {
      width: '20vw',
      data: {name: this.highlight?.formattedTitle, onClickConfirm: (callback: () => void) => this.deleteArticle(callback)}
    })
  }

  public deleteArticle(callback: () => void) {
    this.highlightsService.deleteHighlight(this._highlightId).subscribe({
      next: () => {
        callback();
        this.router.navigate(['/content/highlights'])
      },
      error: error => {
        console.log(error)
        callback();
      }
    })
  }
}
