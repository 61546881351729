export default class User {
    id!: number;
    uuid!: string;
    givenName?: string;
    familyName?: string;
    email!: string;
    created!: Date;
  
    get displayName() {
      var labelParts: string[] = [];
      if (this.givenName) {
        labelParts.push(this.givenName);
      }
      if (this.familyName) {
        labelParts.push(this.familyName);
      }
      labelParts.push(`(${this.email})`);
  
      return labelParts.join(' ');
    }
  }
  