import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Feature } from 'src/app/models/feature.model';
import { FeaturesService } from 'src/app/_services/features.service';

@Component({
  selector: 'feature-select',
  templateUrl: './feature-select.component.html',
})
export class FeatureSelectComponent implements OnInit {
    @Input('control') control!: FormControl;
    @Input('required') required: boolean = false;
    @Input('label') label: string = "Collection";
    @Input('placeholder') placeholder: string = "Pick a collection";
    
    autocompleteResults: Feature[] = []

    constructor(private featuresService: FeaturesService) {

    }

    ngOnInit(): void {
        this.control.valueChanges.pipe(
            debounceTime(100),
            distinctUntilChanged()
        ).subscribe({
            next: value => {
                if (value instanceof Feature) {
                    return;
                }

                this.featuresService.getFeatures(10, 0, null, '%' + value + '%', null).subscribe({
                    next: features => {
                        this.autocompleteResults = features
                    }
                })
            }
        })
    }

    displayFeature(feature: Feature): string {
        return feature?.name ?? ""
    }
}
