import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import Article from '../models/article.model';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {
  constructor(
    private http: HttpClient
  ) { }

  public getArticles(pageSize: number, offset: number, title: string | null, sortKey: string | null): Observable<Article[]> {
    var path = `/content/articles?pageSize=${pageSize}&offset=${offset}&expand[]=bannerImage`;
    if (!!title) {
      path += `&title[]=${title}`;
    }
    if (!!sortKey) {
      path += `&sort=${sortKey}`
    }
    return this.http.get<Article[]>(environment.apiUrl + path).pipe(
      map(articles => articles.map(a => this.toArticle(a)))
    );
  }

  public getArticle(id: number): Observable<Article> {
    var path = `/content/articles/${id}?expand[]=bannerImage`;
    return this.http.get<Article>(environment.apiUrl + path).pipe(
      map(article => this.toArticle(article))
    );
  }

  public createArticle(params: any): Observable<Article> {
    var path = `/content/articles`;
    return this.http.post<Article>(environment.apiUrl + path, params);
  }

  public updateArticle(id: number, updates: any): Observable<Article> {
    var path = `/content/articles/${id}`;
    return this.http.patch<Article>(environment.apiUrl + path, updates);
  }

  public deleteArticle(id: number): Observable<void> {
    var path = `/content/articles/${id}`;
    return this.http.delete<void>(environment.apiUrl + path);
  }

  private toArticle(article: any): Article {
    var article = Object.assign(new Article(), article)
    if (article.bannerImage) {
        article.bannerImage = Object.assign(new Image(), article.bannerImage)
      }
    return article
  }
}
