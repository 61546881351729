import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import Program from '../models/program.model';

@Injectable({
  providedIn: 'root'
})
export class ProgramsService {
  constructor(
    private http: HttpClient
  ) { }

  public getPrograms(pageSize: number, offset: number): Observable<Program[]> {
    var path = `/partnership/programs?pageSize=${pageSize}&offset=${offset}`;
    return this.http.get<Program[]>(environment.apiUrl + path).pipe(
      map(programs => programs.map(program => this.toProgram(program)))
    );
  }

  public getProgram(key: string): Observable<Program> {
    var path = `/partnership/programs/${key}`;
    return this.http.get<Program>(environment.apiUrl + path).pipe(
      map(program => this.toProgram(program))
    );
  }

  private toProgram(program: any): Program {
    var program = Object.assign(new Program(), program)
    return program
  }
}
