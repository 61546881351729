import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DndSortableModule, SortableSpec, DraggedItem } from '@ng-dnd/sortable';
import Drop from 'src/app/models/drop.model';
import Listing from 'src/app/models/listing.model';

@Component({
  selector: 'sortable-drop',
  templateUrl: './sortable-drop.component.html',
  styleUrls: ['./sortable-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortableDropComponent {
  @Input() drop! : Drop | null

  draggableItems!: Listing[];
  flaggedItems!: Set<number>;
  deleteFlaggedItemCallback?: (id : number) => void;
  dragToReorderSpec: SortableSpec<Listing> = {
    type: 'DRAGLIST',
    trackBy: x => x.id,
    hover: item => {
      if (this.drop?.listings != null) {
        this.draggableItems = this.move(item);
      }
    },
    drop: item => {
      if (this.drop?.listings != null) {
        this.draggableItems = this.move(item);
        this.drop.listings = this.draggableItems;
      }
    },
    endDrag: item => {
      if (this.drop?.listings != null) {
        this.draggableItems = this.drop.listings;
      }
    },
  };

  constructor(
    private changeDetector: ChangeDetectorRef
  ) {
    this.draggableItems = [];
    this.flaggedItems = new Set<number>();
  }

  refreshList() : void {
    if (this.drop != null) {
      this.draggableItems = this.drop.listings.slice(0);
      this.changeDetector.markForCheck();
    }
  }

  deleteItemButtonPressed(item: any) {
    this.drop!.listings = this.drop!.listings.filter(s => s.id !== item.id)
    this.draggableItems = this.drop!.listings ?? [];
    if (this.deleteFlaggedItemCallback != null) {
      this.deleteFlaggedItemCallback(item.id);
    }
  }

  move(item: DraggedItem<Listing>) : Listing[] {
    if (this.drop?.listings != null) {
        const temp = this.drop.listings.slice(0);
        temp.splice(item.index, 1);
        temp.splice(item.hover.index, 0, item.data);
        return temp;
    }
    return [];
  }

  flagItem(listingId : number, flag : boolean, deleteCallback : (id : number) => void) {
    if (flag) {
      this.flaggedItems.add(listingId);
    } else {
      this.flaggedItems.delete(listingId);
    }
    this.deleteFlaggedItemCallback = deleteCallback;
  }

  shouldWarn(listing: any) : boolean {
    if (listing == null || listing == undefined) {
      console.log("Warn False " + listing);
      return false;
    }
    console.log("Warn " + listing.id + " " + this.flaggedItems.has(listing.id));
    return this.flaggedItems.has(listing.id);
  }
}
