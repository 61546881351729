import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import BuyerProfile from '../models/buyer-profile.model';
import Address from '../models/address.model';

@Injectable({
  providedIn: 'root'
})
export class BuyersService {
  constructor(private http: HttpClient) { }

  public getBuyerProfiles(pageSize: number, offset: number, blocked: boolean | null, sortKey: string | null): Observable<any> {
    var path = `/marketplace/buyer-profiles?pageSize=${pageSize}&offset=${offset}&expand[]=user`;
    if (!!sortKey) {
      path += `&sort=${sortKey}`
    }
    if (!!blocked) {
      path += `&blocked=${blocked}`;
    }
    return this.http.get<BuyerProfile[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
      map(response => {
        return {
          data: response.body!.map(b => this.toBuyerProfile(b)), 
          totalCount: response.headers.get('X-Total-Count')}
      })
    );
  }

  public getBuyerProfile(buyerId: number): Observable<BuyerProfile> {
    return this.http.get<BuyerProfile[]>(environment.apiUrl + `/marketplace/buyer-profiles/${buyerId}?expand[]=user`).pipe(
      map(buyer => this.toBuyerProfile(buyer))
    );
  }

  public updateBuyerProfile(id: number, updates: any): Observable<BuyerProfile> {
    var path = `/marketplace/buyer-profiles/${id}`;
    return this.http.patch<BuyerProfile>(environment.apiUrl + path, updates);
  }

  public addMailingAddress(id: number, address: any, validate: boolean): Observable<Address> {
    var path = `/marketplace/buyer-profiles/${id}/addresses?validate=${validate}`;
    return this.http.post<Address>(environment.apiUrl + path, address);
  }

  private toBuyerProfile(buyerProfile: any): BuyerProfile {
    var buyerProfile = Object.assign(new BuyerProfile(), buyerProfile)
    return buyerProfile
  }
}
    