import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import Model from 'src/app/models/model.model';

@Component({
  selector: 'model-chip',
  templateUrl: './model-chip.component.html',
  styleUrls: ['./model-chip.component.css']
})
export class ModelChipComponent implements OnInit, OnDestroy {
  @Input('model') model?: Model;

  constructor() { }

  ngOnInit(): void { }

  ngOnDestroy(): void { }
}
