import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import SellerProfile from 'src/app/models/seller-profile.model';

@Component({
  selector: 'seller-chip',
  templateUrl: './seller-chip.component.html',
  styleUrls: ['./seller-chip.component.css']
})
export class SellerChipComponent implements OnInit, OnDestroy {
  private _seller?: SellerProfile;
  @Input('seller') set seller(value: SellerProfile | undefined ) {
    if (value) {
      this._seller = Object.assign(new SellerProfile(), value)
    } else {
      this._seller = value
    }
  }
  get seller(): SellerProfile | undefined {
    return this._seller;
  }

  constructor() { }

  ngOnInit(): void { }

  ngOnDestroy(): void { }
}
