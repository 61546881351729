<div class="labeled-box" *ngFor="let event of events | slice:0:max; let i=index;">
    <div class="label">
        {{ event.timestamp | date:'M/d/yy, h:mm a' }}
    </div>
    {{ event.text }}
</div>
<div class="w100 d-flex flex-row justify-content-between">
    <div>
        <button (click)="loadMore()" mat-button [disabled]="max >= events!.length">Load more</button>
    </div>
    <div>
        <button (click)="showLess()" mat-button  [disabled]="max == 4">Show less</button>
    </div>
</div>
