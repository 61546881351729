import PurchaseRequest from './purchase-request.model';
import Listing from './listing.model';

export default class PurchaseRequestOffer {
    id!: number;
    purchaseRequest!: PurchaseRequest;
    listing!: Listing;
    offerPriceCents!: number;
    strikethroughPriceCents!: number;
    status!: string;
    get statusEng(): string {
        return PURCHASE_REQUEST_OFFER_STATUS_ENG[PURCHASE_REQUEST_OFFER_STATUS.indexOf(this.status.toUpperCase())];
      }
    onRejectAction!: string;
    expiration!: Date;
    created!: Date;
    updated!: Date;
  }
  
  export const PURCHASE_REQUEST_OFFER_STATUS = [
    "AWAITING_RESPONSE",
    "ACCEPTED",
    "REJECTED"
  ]

  export const PURCHASE_REQUEST_OFFER_STATUS_ENG = [
    "Awaiting Response from Buyer",
    "Accepted",
    "Rejected"
  ]

export const ON_REJECT_ACTION = [
    "BECOME_PUBLIC",
    "ARCHIVE"
]
