import { Component, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BrandsService } from 'src/app/_services/brands.service';
import Brand from 'src/app/models/brand.model';

@Component({
  selector: 'brand-select',
  templateUrl: './brand-select.component.html',
})
export class BrandSelectComponent {
    @Input('control') control!: FormControl;
    @Input('required') required: boolean = false;
    @Input('label') label: string = "Brand";

    brands: Brand[] = []

    constructor(private brandsService: BrandsService) {
        this.brandsService.getBrands().subscribe({
          next: (brands: Brand[]) => {
            this.brands = brands;
          },
          error: (error: any) => {
            console.log(error);
          }
        });
    }
}
