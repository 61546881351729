import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthorizationService, Permissions, Privilege } from 'src/app/_services/authorization.service';
import { SearchOverlayComponent } from '../search/search-overlay/search-overlay.component';
import { environment } from 'src/environments/environment';

enum Section {
  Catalog = 0,
  Content,
  Marketplace,
  Verification,
  Payments,
  Notifications
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SidebarComponent implements OnInit {
  selectedSection: Section = Section.Catalog;
  selectedItemInSection: number = 0;

  environment = environment;

  constructor(
    public router: Router,
    public authorizationService: AuthorizationService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.updateButtonSelection(this.router.url);
    this.authorizationService.initIfNeeded();
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe(event => {
      const startEvent = event as NavigationStart;
      this.updateButtonSelection(startEvent.url);
    });
  }

  private updateButtonSelection(url: string) {
    if (url.indexOf("catalog") !== -1) {
      this.selectedSection = Section.Catalog;
      if (url.indexOf("brands") !== -1) {
        this.selectedItemInSection = 0;
      } else if (url.indexOf("series") !== -1) {
        this.selectedItemInSection = 1;
      } else if (url.indexOf("models") !== -1) {
        this.selectedItemInSection = 2;
      } else if (url.indexOf("colors") !== -1) {
        this.selectedItemInSection = 3;
      } else if (url.indexOf("complications") !== -1) {
        this.selectedItemInSection = 4;
      } else if (url.indexOf("materials") !== -1) {
        this.selectedItemInSection = 5;
      } else if (url.indexOf("categories") !== -1) {
        this.selectedItemInSection = 6;
      } else if (url.indexOf("image-check") !== -1) {
        this.selectedItemInSection = 7;
      }
      return;
    } else if (url.indexOf("content") !== -1) {
      this.selectedSection = Section.Content;
      if (url.indexOf("lists") !== -1) {
        this.selectedItemInSection = 0;
      } else if (url.indexOf("collections") !== -1) {
        this.selectedItemInSection = 1;
      } else if (url.indexOf("articles") !== -1) {
        this.selectedItemInSection = 2;
      } else if (url.indexOf("features") !== -1) {
        this.selectedItemInSection = 3;
      } else if (url.indexOf("home-feeds") !== -1) {
        this.selectedItemInSection = 4;
      } else if (url.indexOf("highlights") !== -1) {
        this.selectedItemInSection = 5;
      }
      return;
    } else if (url.indexOf("marketplace") !== -1) {
      this.selectedSection = Section.Marketplace;
      if (url.indexOf("buyers") !== -1) {
        this.selectedItemInSection = 0;
      } else if (url.indexOf("sellers") !== -1) {
        this.selectedItemInSection = 1;
      } else if (url.indexOf("listings") !== -1) {
        this.selectedItemInSection = 2;
      } else if (url.indexOf("auctions") !== -1) {
        this.selectedItemInSection = 3;
      } else if (url.indexOf("drops") !== -1) {
        this.selectedItemInSection = 4;
      } else if (url.indexOf("offers") !== -1) {
        this.selectedItemInSection = 5;
      } else if (url.indexOf("max-bids") !== -1) {
        this.selectedItemInSection = 6;
      } else if (url.indexOf("orders") !== -1) {
        this.selectedItemInSection = 7;
      } else if (url.indexOf("returns") !== -1) {
        this.selectedItemInSection = 8;
      } else if (url.indexOf("discounts") !== -1) {
        this.selectedItemInSection = 9;
      } else if (url.indexOf("ingestion-requests") !== -1) {
        this.selectedItemInSection = 10;
      } else if (url.indexOf("purchase-requests") !== -1) {
        this.selectedItemInSection = 11;
      }
      return;
    } else if (url.indexOf("verification") !== -1) {
      this.selectedSection = Section.Verification;
      if (url.indexOf("verification-reports") !== -1) {
        this.selectedItemInSection = 0;
      } else if (url.indexOf("intake-reports") !== -1) {
        this.selectedItemInSection = 1;
      } else if (url.indexOf("diagnostic-reports") !== -1) {
        this.selectedItemInSection = 2;
      } else if (url.indexOf("authentication-reports") !== -1) {
        this.selectedItemInSection = 3;
      }
      return;
    } else if (url.indexOf("payments") !== -1) {
      this.selectedSection = Section.Payments;
      this.selectedItemInSection = 0;
      return;
    } else if (url.indexOf("notifications") !== -1) {
      this.selectedSection = Section.Notifications;
      this.selectedItemInSection = 0;
      return;
    } else {
      this.selectedSection = Section.Catalog;
      this.selectedItemInSection = 0;
    }
  }

  searchPressed() {
    var dialogRef = this.dialog.open(SearchOverlayComponent, {
      width: '600px',
      position: {
        top: "200px"
      },
      data: {},
    });
  }

  onCatalogBrandsPressed() {
      this.router.navigate(['catalog', 'brands']);
  }

  onCatalogSeriesPressed() {
    this.router.navigate(['catalog', 'series']);
  }

  onCatalogModelsPressed() {
      this.router.navigate(['catalog', 'models']);
  }

  onCatalogColorsPressed() {
    this.router.navigate(['catalog', 'colors']);
  }

  onCatalogComplicationsPressed() {
    this.router.navigate(['catalog', 'complications']);
  }

  onCatalogMaterialsPressed() {
    this.router.navigate(['catalog', 'materials']);
  }

  onCatalogCategoriesPressed() {
    this.router.navigate(['catalog', 'categories']);
  }

  onCatalogCheckImagesPressed() {
    this.router.navigate(['catalog', 'model-image-check']);
  }

  onMarketplaceBuyersPressed() {
    this.router.navigate(['marketplace', 'buyers']);
  }

  onMarketplaceSellersPressed() {
    this.router.navigate(['marketplace', 'sellers']);
  }

  onContentListsPressed() {
    this.router.navigate(['content', 'lists']);
  }

  onContentCollectionsPressed() {
    this.router.navigate(['content', 'collections']);
  }

  onContentArticlesPressed() {
    this.router.navigate(['content', 'articles']);
  }

  onContentFeaturesPressed() {
    this.router.navigate(['content', 'features']);
  }

  onContentHomeFeedsPressed() {
    this.router.navigate(['content', 'home-feeds']);
  }

  onContentHighlightsPressed() {
    this.router.navigate(['content', 'highlights']);
  }

  onMarketplaceListingsPressed() {
    this.router.navigate(['marketplace', 'listings']);
  }

  onMarketplaceAuctionsPressed() {
    this.router.navigate(['marketplace', 'auctions']);
  }

  onMarketplaceOrdersPressed() {
    this.router.navigate(['marketplace', 'orders']);
  }

  onMarketplaceReturnsPressed() {
    this.router.navigate(['marketplace', 'returns']);
  }

  onMarketplaceOffersPressed() {
    this.router.navigate(['marketplace', 'offers']);
  }

  onMarketplaceMaxBidsPressed() {
    this.router.navigate(['marketplace', 'max-bids']);
  }

  onMarketplaceDropsPressed() {
    this.router.navigate(['marketplace', 'drops']);
  }

  onMarketplaceDiscountsPressed() {
    this.router.navigate(['marketplace', 'discounts']);
  }

  onMarketplaceIngestionRequestsPressed() {
    this.router.navigate(['marketplace', 'ingestion-requests']);
  }

  onMarketplacePurchaseRequestsPressed() {
    this.router.navigate(['marketplace', 'purchase-requests']);
  }

  onVerificationReportsPressed() {
    this.router.navigate(['verification', 'verification-reports']);
  }

  onIntakeReportsPressed() {
    this.router.navigate(['verification', 'intake-reports']);
  }

  onDiagnosticReportsPressed() {
    this.router.navigate(['verification', 'diagnostic-reports']);
  }

  onAuthenticationReportsPressed() {
    this.router.navigate(['verification', 'authentication-reports']);
  }

  onPaymentsBillingAccountsPressed() {
    this.router.navigate(['payments', 'billing-accounts']);
  }

  get canAccessCatalog() {
    if (this.authorizationService.didInit && this.authorizationService.hasPermission(Permissions.CATALOG_ALL, Privilege.READ)) {
      return true;
    }
    return false;
  }

  get canAccessMarketplace() {
    if (!this.authorizationService.didInit) {
      return false
    }

    if (this.authorizationService.hasPermission(Permissions.MARKETPLACE_ALL, Privilege.READ)) {
      return true
    }

    if (this.authorizationService.hasPermission(Permissions.MARKETPLACE_SELLER_PROFILES, Privilege.READ)) {
      return true
    }

    if (this.authorizationService.hasPermission(Permissions.MARKETPLACE_LISTINGS, Privilege.READ)) {
      return true
    }

    if (this.authorizationService.hasPermission(Permissions.MARKETPLACE_INGESTION_REQUESTS, Privilege.READ)) {
      return true
    }

    return false
  }

  get canAccessVerification() {
    if (!this.authorizationService.didInit) {
      return false
    }

    if (this.authorizationService.hasPermission(Permissions.VERIFICATION_ALL, Privilege.READ)) {
      return true
    }

    if (this.authorizationService.hasPermission(Permissions.VERIFICATION_VERIFICATION_REPORTS, Privilege.READ)) {
      return true
    }

    return false
  }

  get canAccessContent() {
    if (this.authorizationService.didInit && this.authorizationService.hasPermission(Permissions.EDITORIAL_ALL, Privilege.READ)) {
      return true;
    }
    return false;
  }

  get canAccessPayments() {
    if (this.authorizationService.didInit && this.authorizationService.hasPermission(Permissions.PAYMENT_ALL, Privilege.READ)) {
      return true;
    }
    return false;
  }
}
