import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AuthorizationService, Permissions, Privilege } from 'src/app/_services/authorization.service';
import { Color, CatalogService } from 'src/app/_services/catalog.service';
import { ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteDialog } from 'src/app/common/delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-colors-home',
  templateUrl: './colors-home.component.html',
  styleUrls: ['./colors-home.component.css']
})
export class ColorsHomeComponent implements OnInit, OnDestroy, ToolbarController {
  title = new BehaviorSubject<string>("Colors");
  showAddColorButton: boolean;
  showDeleteColorButton: boolean;

  displayedColumns: string[] = ['id', 'name', 'actions'];
  colors: Color[] = [];
  refreshingColors: boolean = false;

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private catalogService: CatalogService,
    private router: Router,
    private authorizationService: AuthorizationService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.refreshColors();

    this.showAddColorButton = authorizationService.hasPermission(Permissions.CATALOG_ALL, Privilege.WRITE);
    this.showDeleteColorButton = authorizationService.hasPermission(Permissions.CATALOG_ALL, Privilege.WRITE);
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  public onAddColorButtonPressed() {
    this.router.navigate(['catalog', 'colors', 'new']);
  }

  public deleteColor(color: Color, callback: () => void) {
    this.catalogService.deleteColor(color, {
      success: () => {
        this.refreshColors();
        callback();
      },
      error: error => {
        this.snackBar.open("Can't delete this color!", '', {duration: 2000});
        callback();
      }
    })
  }

  public onClickDelete(color: Color) {
    this.dialog.open(DeleteDialog, {
      width: '20vw',
      height: '10vh',
      data: {name: color.name, onClickConfirm: (callback: () => void) => this.deleteColor(color, callback)}
    })
  }

  private refreshColors() {
    if (this.refreshingColors) {
      return;
    }

    this.refreshingColors = true;
    this.catalogService.getColors().pipe(takeWhile(val => !this._destroyed.getValue())).subscribe({
      next: (colors: Color[]) => {
        this.colors = colors;
        this.refreshingColors = false;
      },
      error: (error: any) => {
        this.refreshingColors = false;
        console.log(error);
      }
    });
  }
}
