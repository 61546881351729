import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import List from 'src/app/models/list.model';
import { ListsService } from 'src/app/_services/lists.service';

@Component({
  selector: 'list-select',
  templateUrl: './list-select.component.html',
})
export class ListSelectComponent implements OnInit {
    @Input('control') control!: FormControl;
    @Input('types') types: string[] | null = null;
    @Input('required') required: boolean = false;
    @Input('content') content: string | null = null;

    autocompleteResults: List[] = []

    constructor(private listsService: ListsService) {

    }

    ngOnInit(): void {
        this.control.valueChanges.pipe(
            debounceTime(100),
            distinctUntilChanged()
        ).subscribe({
            next: value => {
                if (value instanceof List) {
                    return;
                }
                this.listsService.getLists(10, 0, this.types, '%' + value + '%', this.content, null).subscribe({
                    next: lists => {
                        this.autocompleteResults = lists
                    }
                })
            }
        })
    }

    displayList(list: List): string {
        return list?.name ?? ""
    }
}
