import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Brand from '../models/brand.model';

@Injectable({
  providedIn: 'root'
})
export class BrandsService {
  constructor(private http: HttpClient) { }

  public getBrands(): Observable<Brand[]> {
    var path = `/catalog/brands`;
    return this.http.get<Brand[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
      map(response => {
        return response.body!.map(o => this.toBrand(o))
      })
    );
  }

  public getBrand(brandId: number): Observable<Brand> {
    return this.http.get<Brand>(environment.apiUrl + `/catalog/brands/${brandId}`).pipe(
      map(fetchedBrand => this.toBrand(fetchedBrand))
    );
  }

  public createBrand(params: any): Observable<Brand> {
    return this.http.post<Brand>(environment.apiUrl + `/catalog/brands`, params).pipe(
      map(fetchedBrand => this.toBrand(fetchedBrand))
    );
  }

  public updateBrand(brandId: number, params: any): Observable<Brand> {
    return this.http.patch(environment.apiUrl + `/catalog/brands/${brandId}`, params).pipe(
      map(fetchedBrand => this.toBrand(fetchedBrand))
    );
  }

  public deleteBrand(brandId: number) {
    return this.http.delete(environment.apiUrl + `/catalog/brands/${brandId}`)
  }

  private toBrand(fetchedBrand: any): Brand {
    var brand = Object.assign(new Brand(), fetchedBrand);
    return brand;
  }
}
