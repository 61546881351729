<div class="container">
    <mat-card class="my-2">
        <mat-card-title>
            Create category
        </mat-card-title>
        <mat-card-content>
            <div class="d-flex flex-column">
                <mat-form-field appearance="outline" class="flex-fill">
                    <mat-label>Name</mat-label>
                    <input matInput type="string" placeholder="Dive" [formControl]="name" required>
                </mat-form-field>
            </div>
        </mat-card-content>
        <mat-card-actions class="d-flex justify-content-between mt-2">
            <button mat-raised-button color="warn" (click)="onCancel()" [disabled]="submitting">Cancel</button>
            <div>
                <button mat-raised-button color="primary" (click)="onSubmit()"
                    [disabled]="!formGroup.valid || submitting || !formGroup.dirty" [class.spinner]="submitting">
                    <ng-container>Submit</ng-container>
                </button>
            </div>
        </mat-card-actions>
    </mat-card>
</div>
