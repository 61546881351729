export default class HomeFeed {
    public id!: number;
    public name?: string;
    public sections!: Section[];
    public audience!: string;
    public created!: Date;
    public updated!: Date;
}

export class Section {
    public id!: number;
    public title?: string;
    public type!: string;
    public object!: object;
    public position!: number;
}