<div class="container" *ngIf="!loading else spinner">
    <mat-card class="my-2">
        <mat-card-title class="mb-4">
            <ng-container *ngIf="!editing">Create highlight</ng-container>
            <ng-container *ngIf="editing">Edit highlight</ng-container>
        </mat-card-title>
        <mat-card-content>
            <div class="d-flex flex-column">
                <mat-form-field appearance="outline" class="flex-fill">
                    <mat-label>Model</mat-label>
                    <input matInput type="search" placeholder="Select model..." class="w-100 h-100" (input)='onSearchTextChanged($event.target.value)' (blur)="onBlur()" (focus)="onFocus()" [(ngModel)]="modelText" required="true">
                    <button *ngIf="modelText" matSuffix mat-icon-button aria-label="Clear" (click)="clearModelSelection()">
                      <mat-icon>close</mat-icon>
                    </button>
                    <div class="search-results-container" *ngIf="(searchResults | async)!.length > 0 && !hideSearchResults">
                      <div class="search-result" *ngFor="let model of (searchResults | async)" (click)="onSearchResultClicked(model)" (mouseenter)="mouseEnterSearchResults(model)" (mouseleave)="mouseExitSearchResults()">
                        <div class="image" *ngIf="model.images?.[0]?.url">
                          <img [src]="model.images?.[0]?.url">
                        </div>
                        <div class="title">
                          {{model.brand.displayName ?? model.brand.name}} {{model.displayName ?? model.name}} {{model.referenceNumber}}
                        </div>
                      </div>
                      <div *ngIf="hoveredModel" class="search-results-container" style="position: absolute; left: calc(100% + 18px); top: 0; width: 400px">
                        <img [src]="hoveredModel.images?.[0]?.url" style="object-fit: contain; width: 100%;">
                      </div>
                    </div>
                </mat-form-field>
                <mat-form-field appearance="outline" class="flex-fill">
                    <mat-label>Formatted Title</mat-label>
                    <input matInput type="string" placeholder="The baguettes" [formControl]="formattedTitle" [maxLength]="1023" required>
                    <mat-hint align="end">{{formattedTitle.value?.length || 0}}/1023</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" class="flex-fill">
                    <mat-label>Description</mat-label>
                    <textarea matInput placeholder="Given its status as one of the very last 5513's, this example's jet black glossy dial features 18k white gold hour marker" [formControl]="description" [maxLength]="2046" required></textarea>
                    <mat-hint align="end">{{description.value?.length || 0}}/2046</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" class="flex-fill">
                    <mat-label>Priority</mat-label>
                    <input matInput type="number" placeholder="0" [formControl]="priority">
                </mat-form-field>
                <table style="text-align: center; margin-bottom: 12px; border-collapse: separate; border-spacing: 10px;">
                    <tr>
                        <td style="width: 500px">Image</td>
                        <td style="min-width: 300px">
                            <ng-container *ngIf="image.value else addImage">
                                <image-view [image]="image.value.image" (remove)="onRemoveImage()" [removable]="true"></image-view>
                            </ng-container>
                            <ng-template #addImage>
                                <button mat-stroked-button type="button" color="primary" (click)="imageUploader.click()" class="add-image-button">
                                    <mat-icon>add</mat-icon>
                                </button>
                                <input hidden type="file" accept=".png,.jpg,.jpeg" (change)="onUploadImage($event.target)" #imageUploader>
                            </ng-template>
                        </td>
                    </tr>
                </table>
            </div>
        </mat-card-content>
        <mat-card-actions class="d-flex justify-content-between">
            <button mat-raised-button color="warn" (click)="onCancel()" [disabled]="submitting">Cancel</button>
            <div>
                <button mat-raised-button color="primary" (click)="onSubmit()"
                    [disabled]="!formGroup.valid || submitting || !formGroup.dirty" [class.spinner]="submitting">
                    <ng-container *ngIf="!editing">Submit</ng-container>
                    <ng-container *ngIf="editing">Save</ng-container>
                </button>
            </div>
        </mat-card-actions>
    </mat-card>
</div>
<ng-template #spinner>
    <mat-card class="m-2 d-flex flex-column align-items-center">
        <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
    </mat-card>
</ng-template>