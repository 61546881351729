<h3>Oh no! An error has occurred...</h3>
<div>
    <b>Error: </b>{{ data.error.error }}
</div>
<div>
    <b>Description: </b>{{ data.error.description }}
</div>
<br />
<div>
  <button mat-raised-button color="primary" (click)="closeButtonPressed()">Close</button>
</div>
