import List from "./list.model";
import Image from "./image.model";
import Program from "./program.model";

export default class Collection {
  public id!: number;
  public name!: string;
  public formattedTitle?: string;
  public description?: string;
  public style!: string;
  public foregroundImages?: Record<string, Image>;
  public backgroundImages?: Record<string, Image>;
  public list!: List;
  public requiresAuthentication!: boolean;
  public partnershipProgram?: Program;
  public created!: Date;
  public updated!: Date;
}

export const styles: string[] = [
  'SIMPLE',
  'FLOATING_IMAGE',
]

export const presentations: string[] = [
  'SQUARE_CARD',
  'PORTRAIT_CARD',
  'MOBILE_BANNER_CAROUSEL',
  'BANNER_CAROUSEL',
  'MOBILE_FULLSCREEN',
  'DESKTOP_FULLSCREEN',
]
