
<div class="container" *ngIf="user else loading">
  <div class="w100 d-flex flex-row justify-content-center">
    <div style="width: 100%; max-width: 800px;">
      <mat-card class="m-2">
        <mat-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <span class="d-flex" style="gap: 8px">
              <span>
                <mat-icon color="warn" *ngIf="listing.flagged">flag</mat-icon> General Information
              </span>
              <span>
                <mat-chip-list>
                  <mat-chip *ngIf="listing.activePricingModel === 'FIXED_PRICE'"
                    (click)="handlePricingModelClick($event, listing)">Fixed Price</mat-chip>
                  <mat-chip *ngIf="listing.activePricingModel === 'AUCTION'"
                    (click)="handlePricingModelClick($event, listing)"><mat-icon
                      matChipAvatar>bolt</mat-icon>Auction</mat-chip>
                </mat-chip-list>
              </span>
            </span>
            <span class="d-flex align-items-center" style="gap: 8px">
              <button mat-icon-button [matMenuTriggerFor]="linksMenu"><mat-icon>link</mat-icon></button>
              <mat-menu #linksMenu="matMenu">
                <button mat-menu-item *ngIf="listing.status === 'ORDERED'"
                  [routerLink]="['/marketplace/orders', order?.id ?? 0]" [class.spinner]="!order" [disabled]="!order">
                  View latest order ({{order.id}})
                  <mat-icon>toll</mat-icon>
                </button>
                <button mat-menu-item *ngIf="listing.isPublic()" (click)="onViewInBezel()">
                  View in Bezel
                  <mat-icon iconPositionEnd>exit_to_app</mat-icon>
                </button>
                <button mat-menu-item *ngIf="ingestionRequest"
                  [routerLink]="['/marketplace/ingestion-requests', ingestionRequest?.id]">
                  View listing request
                  <mat-icon>toll</mat-icon>
                </button>
              </mat-menu>
              <span>
                <button *ngIf="!listing.flagged" (click)="onFlag(true)" mat-raised-button color="warn" [disabled]="isFlagging"
                  [class.spinner]="isFlagging">Flag</button>
                <button *ngIf="listing.flagged" (click)="onFlag(false)" mat-raised-button color="accent" [disabled]="isFlagging"
                  [class.spinner]="isFlagging">Unflag</button>
              </span>
              <button mat-raised-button color="primary" (click)="onDuplicate()">Duplicate</button>
              <button mat-raised-button color="primary" (click)="onEdit()">Edit</button>
            </span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="labeled-box">
            <div class="label">
              Status
            </div>
            <span>
              {{listing.status}}
              <ng-container *ngIf="listing.activePricingModel === 'AUCTION'">
                <ng-container *ngIf="listing.status === 'PUBLISHED' && !listing.auctionInfo?.live && !listing.auctionInfo?.ended"> / UNSTARTED</ng-container>
                <ng-container *ngIf="listing.status === 'PUBLISHED' && listing.auctionInfo?.live"> / LIVE</ng-container>
                <ng-container *ngIf="listing.status === 'PUBLISHED' && listing.auctionInfo?.ended"> / ENDED</ng-container>
              </ng-container>
            </span>
          </div>
          <div class="labeled-box">
            <div class="label">
              Created
            </div>
            <span>{{listing.displayCreated}}</span>
          </div>
          <div class="labeled-box">
            <div class="label">
              Last Updated
            </div>
            <span>{{listing.updated | date:'medium'}}</span>
          </div>
          <br />
          <div class="labeled-box">
            <div class="label">
              Seller
            </div>
            <mat-chip-list>
              <seller-chip [seller]="listing.sellerProfile"></seller-chip>
            </mat-chip-list>
          </div>
          <div class="labeled-box">
            <div class="label">
              Model
            </div>
            <span>
              <mat-chip-list>
                <model-chip [model]="listing.model"></model-chip>
              </mat-chip-list>
            </span>
          </div>
          <br />
          <div class="labeled-box">
            <div class="label">
              Price
            </div>
            {{(listing.priceCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}
            <s *ngIf="listing.strikethroughPriceCents">{{(listing.strikethroughPriceCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</s>
          </div>
          <div class="labeled-box">
            <div class="label">
              Condition
            </div>
            <span>{{listing.displayCondition}}</span>
          </div>
          <div class="labeled-box" *ngIf="listing.manufactureYear">
            <div class="label">
              Year
            </div>
            <span>{{listing.manufactureYear}}</span>
          </div>
          <div class="labeled-box">
            <div class="label">
              Challenge
            </div>
            <span>{{listing.listingChallenge.hour}}:{{listing.listingChallenge.minute}}</span>
          </div>
          <div *ngIf="listing.desiredNetPayoutCents" class="labeled-box">
            <div class="label">
              Desired Net Payout
            </div>
            {{(listing.desiredNetPayoutCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}
          </div>
          <div class="labeled-box" *ngIf="listing.inventoryNumber">
            <div class="label">
              Seller SKU
            </div>
            <span>{{listing.inventoryNumber}}</span>
          </div>
          <div class="labeled-box" *ngIf="listing.externalUrl">
            <div class="label">
              External URL
            </div>
            <span><a [href]="listing.externalUrl" target="_blank">{{listing.externalUrl}}</a></span>
          </div>
          <br />
          <div class="labeled-box" style="width: 100%; text-align: left">
            <div class="label">
              Accessories
            </div>
            <div *ngIf="listing.accessories.length > 0">
              <ul>
                <li *ngFor="let accessory of listing.accessories">{{accessory.name}}</li>
              </ul>
            </div>
            <div *ngIf="listing.accessories.length == 0">
              No accessories
            </div>
          </div>
          <br />
          <div class="labeled-box" style="width: 100%">
            <div class="label">
              Issues
            </div>
            <div class="d-flex flex-row flex-wrap">
              <div *ngFor="let issue of listing.issues">
                <div *ngIf="issues.length > 0" class="issue-card border rounded p-2 d-flex flex-column justify-content-between">
                  <h5>{{getIssueById(issue.issue.id)?.category}} - {{getIssueById(issue.issue.id)?.name}}</h5>
                  <span class="mb-4">{{issue.answer}}</span>
                </div>
              </div>
              <div *ngIf="listing.issues.length == 0">
                No issues
              </div>
            </div>
          </div>
          <div *ngIf="listing?.sellerNotes" class="labeled-box" style="width: 100%">
            <div class="label">
              Seller Notes
            </div>
            <div class="d-flex flex-row flex-wrap">
                {{listing.sellerNotes}}
            </div>
          </div>
          <div class="labeled-box" style="width: 100%" *ngIf="listing.tags.length != 0">
            <div class="label">
              Tags
            </div>
            <mat-chip-list class="vals-chip-list">
              <mat-chip *ngFor="let tag of listing.tags">
                {{tag.displayValue}}
              </mat-chip>
            </mat-chip-list>
          </div>
          <br />
          <div class="labeled-box" style="width: 100%" *ngIf="listing.images">
            <div class="label">
              Images
            </div>
            <div class="d-flex flex-wrap">
              <div *ngFor="let img of listing.images; index as i">
                <div class="mx-2" (click)="onClickImage(i)">
                  <h5 class="mb-0">{{presentImageKey(img.type)}}</h5>
                  <div class="image-grid">
                    <img [src]="img.image.rawUrl" />
                  </div>
                </div>
              </div>
              <div *ngIf="listing.images.length == 0">
                No images
              </div>
            </div>
          </div>
          <br />
        </mat-card-content>
      </mat-card>
      <mat-card class="m-2" *ngIf="listing.fixedPriceInfo">
        <mat-card-title>
          <div class="d-flex flex-row" style="gap: 10px">
            <span>Fixed Price Information</span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="labeled-box">
            <div class="label">
              Listed Price
            </div>
            {{(listing.listedPriceCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}
          </div>
          <div class="labeled-box" *ngIf="listing.promotion?.type">
            <div class="label">
              Promotion ({{listing.promotion.type}})
            </div>
            <span>{{(listing.promotion?.priceCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</span>
          </div>
          <div class="labeled-box">
            <div class="label">
              Offers Allowed?
            </div>
            <span style="text-transform: capitalize;">{{listing.offersAllowed}}</span>
          </div>
          <div class="labeled-box" *ngIf="listing.autoDeclineOffersUnderCents">
            <div class="label">
              Auto Decline Offers Under
            </div>
            <span>${{listing.autoDeclineOffersUnderCents / 100}}</span>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="m-2" *ngIf="listing.auctionInfo">
        <mat-card-title>
          <div class="d-flex flex-row justify-content-between">
            <div class="d-flex flex-row" style="gap: 10px">
              <span>Auction Information</span>
            </div>
            <div class="d-flex flex-row" style="gap: 10px">
              <div *ngIf="listing.auctionInfo.requestsGuaranteedBid" [ngClass]="{
                'badge-narrow-red': !listing.auctionInfo.guaranteedBidCents || !listing.auctionInfo.guarantorBuyerProfile,
                'badge-narrow-green': listing.auctionInfo.guaranteedBidCents && listing.auctionInfo.guarantorBuyerProfile
                }">
                Requests Guaranteed Bid
              </div>
            </div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="labeled-box" *ngIf="listing.auctionInfo?.startDate">
            <div class="label">
              Start Date
            </div>
            <span>{{listing.auctionInfo?.startDate | date:'medium'}}</span>
          </div>
          <div class="labeled-box" *ngIf="listing.auctionInfo?.endDate">
            <div class="label">
              End Date
            </div>
            <span>{{listing.auctionInfo?.endDate | date:'medium'}}</span>
          </div>
          <div class="labeled-box">
            <div class="label">
              Live
            </div>
            <span>{{listing.auctionInfo?.live ? "Yes" : "No"}}</span>
          </div>
          <div class="labeled-box">
            <div class="label">
              Ended
            </div>
            <span>{{listing.auctionInfo?.ended ? "Yes" : "No"}}</span>
          </div>
          <br />
          <div class="labeled-box" *ngIf="listing.auctionInfo?.startingPriceCents">
            <div class="label">
              Starting Price
            </div>
            <span>${{listing.auctionInfo?.startingPriceCents / 100}}</span>
          </div>
          <div class="labeled-box" *ngIf="listing.auctionInfo?.highestBid">
            <div class="label">
              Highest Bid
            </div>
            <span>${{listing.auctionInfo?.highestBid.priceCents / 100}}</span>
          </div>
          <div class="labeled-box" *ngIf="listing.auctionInfo?.nextBidCents">
            <div class="label">
              Next Bid
            </div>
            <span>${{listing.auctionInfo?.nextBidCents / 100}}</span>
          </div>
          <div class="labeled-box" *ngIf="listing.auctionInfo?.guarantorBuyerProfile">
            <div class="label">
              Guarantor
            </div>
            <buyer-chip [buyer]="listing.auctionInfo.guarantorBuyerProfile"></buyer-chip>
          </div>
          <div class="labeled-box" *ngIf="listing.auctionInfo?.guaranteedBidCents">
            <div class="label">
              Guaranteed Bid
            </div>
            <span>${{listing.auctionInfo?.guaranteedBidCents / 100}}</span>
          </div>
          <div class="labeled-box" *ngIf="listing.auctionInfo.hasReserve">
            <div class="label">
              Reserve
            </div>
            ${{listing.auctionInfo?.reserveCents / 100}}
          </div>
          <div class="labeled-box" *ngIf="listing.auctionInfo.metReserve != unknown">
            <div class="label">
              Met Reserve?
            </div>
            This auction {{listing.auctionInfo.metReserve ? "met" : "did not meet"}} its reserve.
          </div>
          <div class="labeled-box">
            <div class="label">
              Number of Bids
            </div>
            <span>
              <button *ngIf="listing.auctionInfo?.numberOfBids > 0" (click)="onClickBids()">{{listing.auctionInfo?.numberOfBids}}</button>
              <ng-container *ngIf="(listing.auctionInfo?.numberOfBids ?? 0) == 0" >0</ng-container>
            </span>
          </div>
          <ng-conatiner *ngIf="listing.auctionInfo?.estimatedValueRange">
            <br />
            <div class="labeled-box">
              <div class="label">
                Estimated Value Range
              </div>
              <span>${{listing.auctionInfo?.estimatedValueRange?.lowerBoundCents / 100}} -
                ${{listing.auctionInfo?.estimatedValueRange?.upperBoundCents / 100}}</span>
            </div>
          </ng-conatiner>
          <br />
          <div class="labeled-box" style="width: 100%; text-align: left">
            <div class="label">
              Disclaimers
            </div>
            <div *ngIf="listing?.auctionInfo.disclaimers.length > 0">
              <ul>
                <li *ngFor="let disclaimer of listing?.auctionInfo.disclaimers">{{disclaimer}}</li>
              </ul>
            </div>
            <div *ngIf="listing?.auctionInfo.disclaimers == 0">
              No disclaimers
            </div>
          </div>
          <br />
          <div class="labeled-box">
            <div class="label">
              Lot Essay
            </div>
            <span *ngIf="listing.auctionInfo?.essay?.length > 0">{{listing.auctionInfo?.essay}}</span>
            <span *ngIf="(listing.auctionInfo?.essay?.length ?? 0) === 0">No lot essay</span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div style="width: 100%; max-width: 500px">
      <mat-card class="m-2">
        <mat-card-title class="d-flex justify-content-between">
          <div>History</div>
          <history-button [type]="'listing'" [resourceId]="_listingId"></history-button>
        </mat-card-title>
        <mat-card-content>
          <timeline [events]="statusHistory"></timeline>
        </mat-card-content>
      </mat-card>
      <mat-card class="m-2">
        <mat-card-title>
          Review
        </mat-card-title>
        <mat-card-content>
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Leave a comment on the rationale behind your decision</mat-label>
            <textarea matInput placeholder="Overpriced" [(ngModel)]="rationale"
              [disabled]="listing.status != 'PENDING_REVIEW' && !listing.canArchive()"></textarea>
          </mat-form-field>
        </mat-card-content>
        <mat-card-footer class="m-3 d-flex justify-content-between">
          <div>
            <button *ngIf="listing.status !== 'ARCHIVED'" mat-raised-button color="secondary" (click)="onArchive()"
              [disabled]="!listing.canArchive() || isArchiving" [class.spinner]="isArchiving">Archive</button>
            <button *ngIf="listing.status === 'ARCHIVED'" mat-raised-button color="secondary" (click)="onUnarchive()"
              [disabled]="!listing.canUnarchive() || isUnarchiving" [class.spinner]="isUnarchiving">Unarchive</button>
          </div>
          <div class="d-flex" style="gap: 8px">
            <button mat-raised-button color="warn" (click)="onReject()" [disabled]="!listing.canApproveOrReject()"
              [disabled]="listing.status != 'PENDING_REVIEW' || isRejecting"
              [class.spinner]="isRejecting">Reject</button>
            <button mat-raised-button color="primary" (click)="onApprove()" [disabled]="!listing.canApproveOrReject()"
              [disabled]="listing.status != 'PENDING_REVIEW' || isApproving"
              [class.spinner]="isApproving">Approve</button>
          </div>
        </mat-card-footer>
      </mat-card>
      <mat-card class="m-2">
        <mat-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <span>Notes</span>
            <span>
              <button class="m-1" *ngIf="!isEditingNotes" mat-stroked-button color="primary"
                (click)="startEditingNotes()">Edit</button>
              <button class="m-1" *ngIf="isEditingNotes" mat-stroked-button color="warn"
                (click)="cancelEditingNotes()">Cancel</button>
              <button class="m-1" *ngIf="isEditingNotes" mat-stroked-button color="primary"
                [disabled]="isSavingNotes || !internalNotes.dirty" [class.spinner]="isSavingNotes"
                (click)="saveNotesEdits()">Save</button>
            </span>
          </div>
        </mat-card-title>
        <mat-card-content style="max-height: 340px; overflow-y: scroll">
          <ng-container *ngIf="!isEditingNotes"><span style="white-space:pre-wrap;">{{ listing.internalNotes
              }}</span></ng-container>
          <mat-form-field appearance="outline" class="w-100" *ngIf="isEditingNotes">
            <textarea matInput [formControl]="internalNotes" [maxLength]="1024" style="min-height: 160px;"></textarea>
            <mat-hint align="end">{{internalNotes.value?.length || 0}}/1024</mat-hint>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="d-flex flex-column align-items-center m-5">
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
  </div>
</ng-template>
