import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";


@Injectable()
export class ClientHeaderInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!req.url.includes(environment.apiUrl)) {
      return next.handle(req);
    }

    const modifiedReq = req.clone({
      headers: req.headers.set('X-Client', 'cloud-console')
    });
    return next.handle(modifiedReq);
  }
}
