<div class="container" *ngIf="!loading else spinner">
    <mat-card class="my-2">
        <mat-card-title class="mb-4">
            <ng-container>Initiate return</ng-container>
        </mat-card-title>
        <mat-card-content>
            <div class="d-flex flex-column">
                <mat-form-field appearance="outline" class="flex-fill">
                    <mat-label>Order ID</mat-label>
                    <input matInput type="number" placeholder="1223" [formControl]="orderId" required>
                </mat-form-field>
                <mat-form-field appearance="outline" class="flex-fill">
                    <mat-label>Restocking Fee ($)</mat-label>
                    <input matInput type="number" placeholder="500.50" [formControl]="restockingFee" required>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Refund Method</mat-label>
                    <mat-select [formControl]="refundMethod" required>
                        <mat-option *ngFor="let refundMethod of refundMethodOptions" [value]="refundMethod">{{refundMethod}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Reason for return</mat-label>
                    <mat-select [formControl]="reason" required>
                        <mat-option *ngFor="let returnReason of returnReasons" [value]="returnReason">{{returnReasonDescriptions[returnReason]}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-card-content>
        <mat-card-actions class="d-flex justify-content-between">
            <button mat-raised-button color="warn" (click)="onCancel()" [disabled]="submitting">Cancel</button>
            <div>
                <button mat-raised-button color="primary" (click)="onSubmit()"
                    [disabled]="!formGroup.valid || submitting || !formGroup.dirty" [class.spinner]="submitting">
                    Submit
                </button>
            </div>
        </mat-card-actions>
    </mat-card>
</div>
<ng-template #spinner>
    <mat-card class="m-2 d-flex flex-column align-items-center">
        <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
    </mat-card>
</ng-template>