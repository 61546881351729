<div class="card" *ngIf="model" (click)="onClick()" [class.clickable]="clickable">
    <div class="image">
        <img [src]="model.images[0].optimizedUrl" *ngIf="model.images.length > 0" draggable="false">
    </div>
    <div class="text">
        <div class="brand">
            {{model.brand.displayName ?? model.brand.name | uppercase }} 
        </div>
        <div class="name">
            {{model.displayName ?? model.name}} {{model.referenceNumber}}
        </div>
    </div>
</div>