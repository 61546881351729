import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ReturnsService } from 'src/app/_services/returns.service';
import { ShipmentsService } from 'src/app/_services/shipments.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { DeleteDialog } from 'src/app/common/delete-dialog/delete-dialog.component';
import Return from 'src/app/models/return.model';

@Component({
  selector: 'app-returns-detail',
  templateUrl: './returns-detail.component.html',
  styleUrls: ['./returns-detail.component.scss']
})
export class ReturnsDetailComponent implements OnInit, OnDestroy, ToolbarController {
  return!: Return | null;
  _returnId: number | null = null;

  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Returns", path: ['/marketplace', 'returns'] }]);
  title = new BehaviorSubject<string>("?");

  isPerformingAction = () => {
    return this.isCancelling
      || this.isFlagging
      || this.isSavingNotes
      || this.isMarkingShipmentAsDelivered
      || this.isMarkingAsAccepted
      || this.isMarkingAsRejected
  }
  isCancelling = false
  isFlagging = false
  isMarkingShipmentAsDelivered = false
  isMarkingAsAccepted = false
  isMarkingAsRejected = false
  isEditingNotes = false
  isSavingNotes = false

  internalNotes = new FormControl();

  constructor(
    private toolbarService: ToolbarService,
    private route: ActivatedRoute,
    private returnsService: ReturnsService,
    private shipmentsService: ShipmentsService,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.route.params.pipe(
      pluck("returnId")
    ).subscribe({
      next: returnId => {
        if (this._returnId == returnId) {
          return;
        }

        this._returnId = returnId;
        this.title.next(returnId.toString());
        this.reloadReturn();
      }
    })
  }

  private reloadReturn() {
    this.return = null;
    this.returnsService.getReturn(this._returnId!).subscribe({
      next: (r: Return) => {
        this.return = r;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  cancel(): void {
    this.dialog.open(DeleteDialog, {
      width: '20vw',
      data: { action: 'cancel', name: 'this return', onClickConfirm: (callback: () => void) => this.cancelReturn(callback) }
    })
  }

  public cancelReturn(callback: () => void) {
    this.isCancelling = true
    this.returnsService.cancelReturn(this._returnId!).subscribe({
      next: () => {
        window.location.reload()
      },
      error: (error: any) => {
        console.log(error);
        this.isCancelling = false
        callback()
      }
    });
  }

  onFlag(flagged: boolean): void {
    this.isFlagging = true
    this.returnsService.updateReturn(this._returnId!, { flagged: flagged }).subscribe({
      next: () => {
        this.isFlagging = false
        this.return!.flagged = flagged
      },
      error: error => {
        console.log(error);
        this.isFlagging = false
      }
    });
  }

  startEditingNotes() {
    this.isEditingNotes = true
    this.internalNotes.setValue(this.return?.internalNotes)
  }

  cancelEditingNotes() {
    this.isEditingNotes = false
    this.internalNotes.reset()
  }

  saveNotesEdits() {
    this.isSavingNotes = true
    this.internalNotes.disable()
    this.returnsService.updateReturn(this._returnId!, { internalNotes: this.internalNotes.value }).subscribe({
      next: () => {
        this.isSavingNotes = false
        this.isEditingNotes = false
        this.internalNotes.enable()
        this.return!.internalNotes = this.internalNotes.value
      },
      error: error => {
        console.log(error);
        this.isSavingNotes = false
        this.internalNotes.enable()
      }
    });
  }

  markShipmentAsDelivered(): void {
    this.isMarkingShipmentAsDelivered = true
    this.shipmentsService.markAsReceived(this.return!.shipment.id).subscribe({
      next: () => {
        window.location.reload()
      },
      error: (error: any) => {
        console.log(error);
        this.isMarkingShipmentAsDelivered = false
      }
    });
  }

  markAsAccepted(): void {
    this.isMarkingAsAccepted = true
    this.returnsService.acceptReturn(this._returnId!).subscribe({
      next: () => {
        window.location.reload()
      },
      error: (error: any) => {
        console.log(error);
        this.isMarkingAsAccepted = false
      }
    });
  }

  markAsRejected(): void {
    this.isMarkingAsRejected = true
    this.returnsService.rejectReturn(this._returnId!).subscribe({
      next: () => {
        window.location.reload()
      },
      error: (error: any) => {
        console.log(error);
        this.isMarkingAsRejected = false
      }
    });
  }

  onViewRefundInStripe(): void {
    window.open(this.return?.refund.transaction.stripeDashboardLink, "_blank");
  }
}
