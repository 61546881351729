import { formatDate } from '@angular/common';
import Address from "./address.model";
import Invoice from "./invoice.model";
import Listing from "./listing.model";
import Shipment from "./shipment.model";
import User from './user.model';
import BuyerProfile from './buyer-profile.model';
import Transaction from './transaction.model';
import VerificationReport from './verification-report.model';

export default class Order {
  id!: number;
  listing!: Listing;
  buyerProfile!: BuyerProfile;
  invoice!: Invoice
  salePriceCents!: number;
  commissionRateBips!: number;
  sellerFeeCents!: number;
  minTotalFeeCents!: number;
  payoutAmountCents!: number;
  deliveryAddress!: Address;
  status!: string;
  holdTransaction?: Transaction;
  paymentTransaction!: Transaction;
  receiptShipment!: Shipment;
  deliveryShipment!: Shipment;
  created!: Date;
  updated!: Date;
  expiration!: string;
  cancellationReason!: string;
  flagged!: boolean;
  internalNotes!: string;
  confirmed?: Boolean;
  confirmedAt?: Date;
  confirmedBy?: User;
  finalizedAt!: Date;
  finalizedBy!: User;
  verificationReport!: VerificationReport;
  verifiedAt!: Date;
  verifiedBy!: User;
  client!: string;
  checks!: {
    key: string;
    status: string;
    dashboardLink: string;
  }[];

  get displayStatus(): string {
    return allStatuses[this.status];
  }

  get modelDisplayName(): string {
    return this.listing.model.displayName;
  }

  get formattedDeliveryAddress(): string {
    return this.formatAddress(this.deliveryAddress);
  }

  get outboundShipmentTrackingLink(): string {
    return `https://www.fedex.com/fedextrack/?tracknumbers=${this.deliveryShipment.fedexTrackingNumber}`
  }

  get inboundShipmentTrackingLink(): string {
    return `https://www.fedex.com/fedextrack/?tracknumbers=${this.receiptShipment.fedexTrackingNumber}`
  }

  get authPending(): boolean {
    return this.status === 'AWAITING_AUTH';
  }

  get wasAuthenticated(): boolean {
    return this.verifiedAt != null || this.status === 'AWAITING_SHIPMENT' || this.status === 'AWAITING_DELIVERY' || this.status === 'DELIVERED' || (this.status === 'CANCELLED' && this.cancellationReason === 'FAILED_AUTH');
  }

  get canFinalize(): boolean {
    return this.status === 'PROCESSING';
  }

  get canConfirm(): boolean {
    return this.status === 'AWAITING_CONFIRMATION';
  }

  get canCancel(): boolean {
    return this.status === 'PROCESSING' || this.status === 'AWAITING_CONFIRMATION' || this.status === 'AWAITING_PAYMENT' || this.status === 'AWAITING_RECEIPT';
  }

  get canEditPayoutAmount(): boolean {
    return this.status === 'PROCESSING' || this.status === 'AWAITING_CONFIRMATION' || this.status === 'AWAITING_PAYMENT' || this.status === 'AWAITING_RECEIPT' || this.status === 'AWAITING_AUTH';
  }

  get hitMinimumComission(): boolean {
    return (this.salePriceCents - this.payoutAmountCents) == this.minTotalFeeCents;
  }

  get canEditDeliveryAddress(): boolean {
    return this.status === 'PROCESSING' || this.status === 'AWAITING_CONFIRMATION' || this.status === 'AWAITING_PAYMENT' || this.status === 'AWAITING_RECEIPT' || this.status === 'AWAITING_AUTH';
  }

  get displayCreated(): string {
    return formatDate(this.created, 'medium', 'en-US');
  }

  get displayExpiration(): string {
    return formatDate(this.expiration, 'medium', 'en-US');
  }
  
  get fromAuction(): boolean {
    return this.listing.activePricingModel == "AUCTION";
  }

  formatAddress(address: Address): string {
    var formattedAddress = `${address.fullName}\n`;
    formattedAddress += `${address.line1}\n`;
    if (address.line2) {
      formattedAddress += `${address.line2}\n`
    }
    if (address.line3) {
      formattedAddress += `${address.line3}\n`
    }
    formattedAddress += `${address.city}, ${address.state} ${address.postalCode}\n`;
    formattedAddress += `${address.phoneNumber}\n`

    return formattedAddress
  }
  
  static toOrder(order: any): Order {
    var order = Object.assign(new Order(), order)
    if (order.listing) {
      order.listing = Object.assign(new Listing(), order.listing)
    }
    if (order.invoice) {
      order.invoice = Object.assign(new Invoice(), order.invoice)
    }
    if (order.buyerProfile) {
      order.buyerProfile = Object.assign(new BuyerProfile(), order.buyerProfile)
    }
    if (order.receiptShipment) {
      order.receiptShipment = Object.assign(new Shipment(), order.receiptShipment)
    }
    if (order.deliveryShipment) {
      order.deliveryShipment = Object.assign(new Shipment(), order.deliveryShipment)
    }
    if (order.confirmedBy) {
      order.confirmedBy = Object.assign(new User(), order.confirmedBy);
    }
    if (order.finalizedBy) {
      order.finalizedBy = Object.assign(new User(), order.finalizedBy);
    }
    if (order.verifiedBy) {
      order.verifiedBy = Object.assign(new User(), order.verifiedBy);
    }
    return order
  }
}

export const orderedStatuses = [
  'ALL',
  'PROCESSING',
  'AWAITING_CONFIRMATION',
  'AWAITING_PAYMENT',
  'AWAITING_RECEIPT',
  'AWAITING_AUTH',
  'AWAITING_SHIPMENT',
  'AWAITING_DELIVERY',
  'DELIVERED',
  'CANCELLED'
]

export const allStatuses: {[key: string]: string } = {
  'ALL': 'All',
  'PROCESSING': 'Processing',
  'AWAITING_CONFIRMATION': 'Waiting for confirmation',
  'AWAITING_PAYMENT': 'Waiting for payment',
  'CANCELLED': 'Cancelled',
  'AWAITING_RECEIPT': 'Waiting to receive watch',
  'AWAITING_AUTH': 'Authentication pending',
  'AWAITING_SHIPMENT': 'Awaiting shipment',
  'AWAITING_DELIVERY': 'In transit to buyer',
  'DELIVERED': 'Delivered',
}
