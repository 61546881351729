<div class="container" *ngIf="!loading; else spinner">
  <mat-card class="m-2">
    <mat-card-title>{{pageTitle}}</mat-card-title>
    <mat-card-content>
      <ng-container>
        <div class="m-2 d-flex flex-column" style="gap: 10px">
          <div class="flex-fill d-flex" style="gap: 10px">
              <ng-container  *ngIf="(selectedModel == null); else modelchip">
                <div class="flex-fill" style="position: relative;">
                  <mat-form-field appearance="outline" class="mx-1 flex-fill">
                    <mat-label>Model</mat-label>
                    <input matInput placeholder="Add models..." class="w-100 h-100 d-flex" [formControl]="modelFilterControl" [matAutocomplete]="modelFilterAutocomplete" required="true">
                    <button *ngIf="modelFilterControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearModelSelection()">
                      <mat-icon>close</mat-icon>
                    </button>
                    <mat-autocomplete #modelFilterAutocomplete="matAutocomplete" (optionSelected)="onModelClicked($event)">
                      <mat-option *ngFor="let model of modelSearchResults | async" [value]="model">
                        <div class="w-100 h-100 d-flex">
                          <img [src]="model.images?.[0]?.url" class="image" *ngIf="model.images?.[0]?.url" style="max-height: 40px;">
                          <span class="title">
                            {{model.brand.displayName ?? model.brand.name}} {{model.displayName ?? model.name}} {{model.referenceNumber}}
                          </span>
                        </div>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </ng-container>
              <ng-template #modelchip>
                <div class="m-2 d-flex flex-column" style="gap: 10px">
                  <model-chip [model]="selectedModel"></model-chip>
                  <button *ngIf="modelFilterControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearModelSelection()"></button>
                </div>
              </ng-template>
            <buyer-select [control]="buyerFilterControl" class="flex-fill"></buyer-select>
          </div>
          <div class="m-2 d-flex flex-column" style="gap: 10px">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Requested Price</mat-label>
              <input matInput type="text" placeholder="1999.99" [formControl]="priceFormControl" required="true">
            </mat-form-field>
          </div>
          <div class="m-2 d-flex flex-column" style="gap: 10px">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Unworn Restriction</mat-label>
              <mat-select [(value)]="unwornRequirementSelect">
                <mat-option value="NONE">None</mat-option>
                <mat-option value="PREFER">Prefer Unworn</mat-option>
                <mat-option value="REQUIRE">Require Unworn</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="m-2 d-flex flex-column" style="gap: 10px">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Year Restriction</mat-label>
              <input matInput type="text" placeholder="2024" [formControl]="yearRestrict" required="false">
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </mat-card-content>
    <mat-card-footer class="m-3 d-flex justify-content-between">
      <div>
        <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
      </div>
      <div class="d-flex" style="gap: 10px">
        {{errText}}
      </div>
      <div class="d-flex" style="gap: 10px">
        <button mat-raised-button color="primary" (click)="onSubmit()"
          *ngIf="!editing">Create</button>
      </div>
    </mat-card-footer>
  </mat-card>
</div>
<ng-template #spinner>
  <mat-card class="m-2 d-flex flex-column align-items-center">
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
  </mat-card>
</ng-template>