import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { SellersService } from 'src/app/_services/sellers.service';
import { Observable, of } from 'rxjs';
import SellerProfile, { SELLER_PROFILE_TYPES }  from 'src/app/models/seller-profile.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { type } from 'os';

@Component({
  selector: 'app-sellers-home',
  templateUrl: './sellers-home.component.html',
  styleUrls: ['./sellers-home.component.css']
})
export class SellersHomeComponent implements OnInit, OnDestroy, ToolbarController {
  title = new BehaviorSubject<string>('Sellers');

  allTypes: string[];

  typeFilter: string | null = null;
  private needsReload = false

  displayedColumns: string[] = ['id', 'name', 'email', 'created'];

  sellers: Observable<SellerProfile[]> = of([])
  
  dataSource?: MatTableDataSource<SellerProfile>
  
  private _paginator!: MatPaginator
  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this._paginator = value
    if (this._paginator) {
      this._paginator.pageIndex = this.page
    }
    if (this.dataSource) {
      this.dataSource!.paginator = value
    }
  }
  get paginator() {
    return this._paginator
  }
  
  private page = 0
  private sortKey: string | null = '-created';

  constructor(
    private toolbarService: ToolbarService,
    private sellersService: SellersService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {   
    this.allTypes = SELLER_PROFILE_TYPES;
    
    this.fetchSellers();

    this.activatedRoute.queryParams.subscribe(params => {
      this.typeFilter = params.type ? params.type : null;
      this.page = params.page ? params.page : 0;
      if (this._paginator) {
        this._paginator.pageIndex = this.page
      }

      if (this.needsReload) {
        this.fetchSellers();
      }
    })
  }

  private fetchSellers() {
    this.sellers = this.sellersService.getSellerProfiles(this.typeFilter, this.sortKey)
    this.sellers.subscribe(sellers => {
        this.dataSource = new MatTableDataSource<SellerProfile>(sellers)
        if (this.paginator) {
          this.dataSource!.paginator = this.paginator
        }
        this.needsReload = false
      }  
    )
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }
  
  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  onChangeTypeFilter(event: any): void {
    this.needsReload = true
    this.updateUrlParametersIfNeeded();
  }

  onPageChanged(event: PageEvent): void {
    if (!this.dataSource?.paginator) {
      return
    }

    this.page = this.dataSource!.paginator!.pageIndex
    this.updateUrlParametersIfNeeded()
  }

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      type: this.typeFilter,
      page: this.page == 0 ? null : this.page
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }
}
