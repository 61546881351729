<div class="content-container">
    <div class="form-container">
        <form [formGroup]="formGroup">
            <div class="form-section">
                <div class="form-section-title">
                    Details
                </div>
                <div class="form-section-content">
                    <mat-form-field appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Date" formControlName="name" required="true">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Description</mat-label>
                        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="6"
                            formControlName="description"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-section">
                <div class="form-section-content">
                    <button type="submit" [disabled]="!formGroup.valid || submitting" (click)="onSubmit()"
                        [class.spinner]="submitting" mat-raised-button color="primary">Submit</button>
                    <button type="button" [disabled]="submitting" (click)="onClear()" mat-stroked-button color="accent"
                        style="margin-left: 10px">Clear</button>
                </div>
            </div>
        </form>
    </div>
</div>