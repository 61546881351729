import { formatDate } from '@angular/common';
import Listing from 'src/app/models/listing.model';


export default class Drop {
  id!: number;
  status!: string;
  date!: Date;
  listings!: Listing[];
  created!: Date;

  get listingIds() {
    return this.listings.map(value => value.id);
  }

  get statusNum() {
    let status = this.status.toLowerCase();
    if (status == "draft") {
      return 0;
    } else if (status == "scheduled") {
      return 1;
    } else if (status == "dropped") {
      return 2;
    } else if (status == "cancelled") {
      return 3;
    }
    return 4;
  }

  // Make a deep copy of the mutable fields (date, listings list)
  // but don't worry about the individual listings or other fields.
  public clone() : Drop {
    let myClone = new Drop();
    myClone.id = this.id;
    myClone.created = this.created;
    myClone.status = this.status;
    if (this.date) {
      myClone.date = new Date(this.date);
    }
    myClone.listings = [];
    for (let listing of this.listings) {
      myClone.listings.push(listing);
    }
    myClone.listings.reverse();
    return myClone;
  }
}

export const DROP_STATUSES = [
  'DRAFT',
  'SCHEDULED',
  'DROPPED',
  'CANCELLED'
]

export const DROP_STATUS_DESCRIPTIONS: {[key: string]: string } = {
  'DRAFT': 'Draft',
  'SCHEDULED': 'Scheduled',
  'DROPPED': 'Dropped',
  'CANCELLED': 'Cancelled'
}

export const DROP_VALIDATION_STATE = [
  'VALID',
  'NOT_EXISTS',
  'NOT_PUBLISHED',
  'NOT_AUCTION',
  'ALREADY_IN_DROP'
]

export class DropValidationResponse {
  state!: string;
  dropId?: number;
}
