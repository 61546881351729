import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthorizationService, Permissions, Privilege } from 'src/app/_services/authorization.service';
import { BrandsService } from 'src/app/_services/brands.service';
import { CategoryService } from 'src/app/_services/category.service';
import { ModelsService } from 'src/app/_services/models.service';
import { SeriesService } from 'src/app/_services/series.service';
import { ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import Brand from 'src/app/models/brand.model';
import Category from 'src/app/models/category.model';
import Model from 'src/app/models/model.model';
import Series from 'src/app/models/series.model';

@Component({
  selector: 'app-browse-models',
  templateUrl: './browse-models.component.html',
  styleUrls: ['./browse-models.component.css']
})
export class BrowseModelsComponent implements OnInit, OnDestroy, ToolbarController {
  private static readonly DEFAULT_PAGE_SIZE = 20

  title = new BehaviorSubject<string>("Models");

  showAddModelButton: boolean;

  brands: Brand[] | null = [];
  series: Series[] | null = [];
  filteredSeries: Series[] | null = [];
  categories: Category[] | null = [];

  brandFilter: number[] = [];
  seriesFilter: number[] = [];
  categoryFilter: number[] = [];
  imageCountFilter: number | null = null;

  page = 0;
  length = 0;
  pageSize = BrowseModelsComponent.DEFAULT_PAGE_SIZE;
  pageSizeOptions: number[] = [20, 40, 60, 80, 100];
  displayedColumns: string[] = ['id', 'name', 'displayName', 'referenceNumber', 'brand', 'series', 'lifespan'];

  models: Model[] = [];
  isLoading = true
  private loadingSubscription: Subscription | null = null

  constructor(
    private toolbarService: ToolbarService,
    private modelsService: ModelsService,
    private brandsService: BrandsService,
    private seriesService: SeriesService,
    private categoryService: CategoryService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authorizationService: AuthorizationService,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.pageSize = params.pageSize ? params.pageSize : BrowseModelsComponent.DEFAULT_PAGE_SIZE;
      this.page = params.page ? params.page : 0;
      this.brandFilter = params.brands ? (typeof params.brands === 'number' || typeof params.brands === 'string' ? [params.brands] : params.brands) : [];
      this.seriesFilter = params.series ? (typeof params.series === 'number' || typeof params.series === 'string' ? [params.series] : params.series) : [];
      this.categoryFilter = params.categories ? (typeof params.categories === 'number' || typeof params.categories === 'string' ? [params.categories] : params.categories) : [];
      this.imageCountFilter = params.imageCount ? params.imageCount : null;
      this.updateFiltereredSeriesIfNeeded();
      this.fetchModels();
    })

    brandsService.getBrands().subscribe({
      next: (brands: Brand[]) => {
        this.brands = brands.sort((a, b) => a.name.localeCompare(b.name));
      }, 
      error: error => {
        console.log(error);
      }
    })
    seriesService.listSeries(null).subscribe({
      next: (series: Series[]) => {
        this.series = series.sort((a, b) => a.name.localeCompare(b.name));
        this.updateFiltereredSeriesIfNeeded();
      }, 
      error: error => {
        console.log(error);
      }
    })
    categoryService.getCategories(-1, 0).subscribe({
      next: (categories: Category[]) => {
        this.categories = categories.sort((a, b) => a.name.localeCompare(b.name));
      }, 
      error: error => {
        console.log(error);
      }
    })

    this.showAddModelButton = authorizationService.hasPermission(Permissions.CATALOG_ALL, Privilege.WRITE);
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  fetchModels(): void {
    this.isLoading = true

    if (this.loadingSubscription != null) {
      this.loadingSubscription.unsubscribe();
    }
    this.loadingSubscription = this.modelsService.listModels(this.pageSize, this.page * this.pageSize, this.brandFilter, this.seriesFilter, this.categoryFilter, this.imageCountFilter).subscribe({
      next: (models: Model[]) => {
        this.models = models;
        this.length = Number.MAX_VALUE;
        this.isLoading = false
        this.loadingSubscription = null;
      },
      error: (error: any) => {
        console.log(error);
        this.models = [];
        this.length = 0;
        this.isLoading = false
        this.loadingSubscription = null;
      }
    });
  };

  pageChangeEvent(event: PageEvent): void {
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updateUrlParametersIfNeeded();
  }

  handleFiltersChanged(): void {
    this.updateUrlParametersIfNeeded();
    this.updateFiltereredSeriesIfNeeded();
  }

  private updateFiltereredSeriesIfNeeded(): void {
    if (this.brandFilter.length == 1) {
      this.filteredSeries = this.series?.filter(s => s.brand.id == this.brandFilter[0]) ?? []
    } else {
      this.filteredSeries = [];
    }
  }

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      page: this.page == 0 ? null : this.page,
      pageSize: this.pageSize == BrowseModelsComponent.DEFAULT_PAGE_SIZE ? null : this.pageSize,
      brands: this.brandFilter.length > 0 ? this.brandFilter : null,
      series: this.seriesFilter.length > 0 ? this.seriesFilter : null,
      categories: this.categoryFilter.length > 0 ? this.categoryFilter : null,
      imageCount: this.imageCountFilter,
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }

  compareIds(id1: number, id2: number) {
    return id1 == id2;
  }
}
