import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { DeleteDialog } from 'src/app/common/delete-dialog/delete-dialog.component';
import Article from 'src/app/models/article.model';
import { ArticlesService } from 'src/app/_services/articles.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';

@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.css']
})
export class ArticleDetailComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Articles", path: ['/content', 'articles'] }]);
  title = new BehaviorSubject<string>("?");

  isLoading = true;
  
  article!: Article | null;
  private _articleId!: number;

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private articlesService: ArticlesService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) { 
    this.route.params.pipe(
      pluck("articleId")
    ).subscribe({
      next: articleId => {
        if (this._articleId == articleId) {
          return;
        }

        this._articleId = articleId;
        this.title.next(articleId.toString());
        this.reloadArticle();
      }
    })
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  private reloadArticle() {
    this.isLoading = true;

    this.article = null;
    this.articlesService.getArticle(this._articleId).subscribe({
      next: article => {
        this.isLoading = false;
        this.article = article;
        this.handleArticleChanged();
      },
      error: error => {
        console.log(error)
      }
    })
  }

  private handleArticleChanged() {
    if (this.article?.title) {
      this.title.next(this.article.title)
    } else if (this._articleId) {
      this.title.next(this._articleId.toString())
    } else {
      this.title.next('?')
    }
  }

  deleteButtonPressed(): void {
    this.dialog.open(DeleteDialog, {
      width: '20vw',
      data: {name: this.article?.title, onClickConfirm: (callback: () => void) => this.deleteArticle(callback)}
    })
  }

  public deleteArticle(callback: () => void) {
    this.articlesService.deleteArticle(this._articleId).subscribe({
      next: () => {
        callback();
        this.router.navigate(['/content/articles'])
      },
      error: error => {
        console.log(error)
        callback();
      }
    })
  }
}
