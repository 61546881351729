import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorDialog } from 'src/app/common/error-dialog/error-dialog.component';
import Collection, { styles, presentations } from 'src/app/models/collection.model';
import List from 'src/app/models/list.model';
import { CollectionsService } from 'src/app/_services/collections.service';
import { ImageService } from 'src/app/_services/image.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';

@Component({
  selector: 'app-create-collection',
  templateUrl: './create-collection.component.html',
  styleUrls: ['./create-collection.component.css']
})
export class CreateCollectionComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath!: BehaviorSubject<TitlePathElement[]>;
  title!: BehaviorSubject<string>;
  
  loading: boolean = true;
  editing: boolean = false;
  submitting: boolean = false;

  collectionId: number | null = null;
  private _collection: Collection | null = null;

  name = new FormControl("", [Validators.required]);
  formattedTitle = new FormControl("")
  description = new FormControl("")
  style = new FormControl("", [Validators.required])
  list = new FormControl(new List(), [Validators.required])
  requiresAuthentication = new FormControl<boolean>(false);
  program = new FormControl<string>("")
  backgroundImages: Record<string, {
    existingId: number | null,
    image: InstanceType<typeof Image> | null,
  }> = {};
  foregroundImages: Record<string, {
    existingId: number | null,
    image: InstanceType<typeof Image> | null,
  }> = {};
  imagesDirty = false;
  formGroup = new FormGroup({
    name: this.name,
    formattedTitle: this.formattedTitle,
    description: this.description,
    style: this.style,
    list: this.list,
    requiresAuthentication: this.requiresAuthentication,
    program: this.program,
  });

  allStyles: string[];
  allPresentations: string[];

  private _destroyed = new ReplaySubject<boolean>(1);
  
  constructor(
    private toolbarService: ToolbarService,
    private collectionsService: CollectionsService,
    private imagesService: ImageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) { 
    this.allStyles = styles;
    this.allPresentations = presentations;

    const routeParams = this.route.snapshot.paramMap;
    if (routeParams.get('collectionId')) {
      this.collectionId = Number(routeParams.get('collectionId'));
      this.editing = true;
    
      this.titlePath = new BehaviorSubject<TitlePathElement[]>([
        { title: "Collections", path: ['/content/collections'] },
        { title: this.collectionId.toString(), path: ['/content/collections', this.collectionId.toString()] }
      ]);
      this.title = new BehaviorSubject<string>("Edit");

      collectionsService.getCollection(this.collectionId).subscribe({
        next: collection => {
          this._collection = collection;
          this.titlePath.next([
            { title: "Collections", path: ['/content/collections'] },
            { title: collection.name, path: ['/content/collections', collection.id.toString()] }
          ]);

          this.name.setValue(collection.name);
          this.formattedTitle.setValue(collection.formattedTitle ?? null);
          this.description.setValue(collection.description ?? null);
          this.style.setValue(collection.style);
          this.list.setValue(collection.list);
          this.requiresAuthentication.setValue(collection.requiresAuthentication ?? false);
          this.program.setValue(collection.partnershipProgram?.key ?? null);

          for (var presentation of presentations) {
            var foregroundImage = collection.foregroundImages?.[presentation];
            if (foregroundImage) {
              var img = new Image();
              img.src = foregroundImage.rawUrl;
              this.foregroundImages[presentation] = {existingId: foregroundImage.id, image: img}
            }

            var backgroundImage = collection.backgroundImages?.[presentation];
            if (backgroundImage) {
              var img = new Image();
              img.src = backgroundImage.rawUrl;
              this.backgroundImages[presentation] = {existingId: backgroundImage.id, image: img}
            }
          }

          this.loading = false;
        },
        error: error => {
          console.log(error)
        }
      })
    } else {
      this.editing = false;
      this.loading = false;

      this.titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Collections", path: ['/content/collections'] }]);
      this.title = new BehaviorSubject<string>("Create");
    }
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  hasInvalidConfiguration(): boolean {
    if (!this.list.value) {
      return false;
    }

    if (this.list.value.type === 'MODEL' && this.style.value == 'SIMPLE') {
      return true;
    }
    if (this.list.value.type === 'LISTING' && this.style.value == 'FLOATING_IMAGE') {
      return true;
    }

    return false;
  }

  hasValidForm(): boolean {
    if (!(this.list.value instanceof List)) {
      return false;
    }
    return this.formGroup.valid;
  }

  foregroundImageForPresentation(presentation: string): InstanceType<typeof Image> | null {
    return this.foregroundImages[presentation]?.image
  }

  backgroundImageForPresentation(presentation: string): InstanceType<typeof Image> | null {
    return this.backgroundImages[presentation]?.image
  }

  onUploadForgroundImageForPresentation(presentation: string, target: EventTarget | null): void {
    this.imagesDirty = true;
    
    var image = this.extractImage(target)
    if (!image) {
      return
    }

    this.foregroundImages[presentation] = {existingId: null, image: image}
  }

  onUploadBackgroundImageForPresentation(presentation: string, target: EventTarget | null): void {
    this.imagesDirty = true;
    
    var image = this.extractImage(target)
    if (!image) {
      return
    }

    this.backgroundImages[presentation] = {existingId: null, image: image}
  }

  private extractImage(target: EventTarget | null): InstanceType<typeof Image> | null {
    if (!(target instanceof HTMLInputElement)) {
      return null;
    }

    var input = target as HTMLInputElement;
    var file = input.files![input.files!.length - 1]
    var img = new Image();
    var reader = new FileReader();
    reader.onload = event => {
      img.src = event.target!.result as string;
    }
    reader.readAsDataURL(file);
    return img;
  }

  onRemoveForegroundImageForPresentation(presentation: string): void {
    this.imagesDirty = true;
    this.foregroundImages[presentation] = { existingId: null, image: null}
  }

  onRemoveBackgroundImageForPresentation(presentation: string): void {
    this.imagesDirty = true;
    this.backgroundImages[presentation] = { existingId: null, image: null}
  }

  onCancel(): void {
    if (this.collectionId) {
      this.router.navigate(['/content/collections', this.collectionId])
    } else {
      this.router.navigate(['/content/collections'])
    }
  }

  onSubmit(): void {
    if (this.submitting) {
      return;
    }
    this.submitting = true;

    this.uploadImages().subscribe({
      next: success => {
        if (!success) {
          console.log("Failed to upload all images.");
          return;
        }

        var params: any = {}
        if (this.name.dirty) {
          params.name = this.name.value;
        }
        if (this.formattedTitle.dirty) {
          params.formattedTitle = this.formattedTitle.value;
        }
        if (this.description.dirty) {
          params.description = this.description.value;
        }
        if (this.style.dirty) {
          params.style = this.style.value;
        }
        if (this.list.dirty && this.list.value != null) {
          params.list = this.list.value.id;
        }
        if (this.requiresAuthentication.dirty) {
          params.requiresAuthentication = this.requiresAuthentication.value;
        }
        if (this.program.dirty) {
          params.partnershipProgram = this.program.value
        }

        params.foregroundImages = {}
        for (var presentation of presentations) {
          if (this.foregroundImages[presentation]) {
            params.foregroundImages[presentation] = this.foregroundImages[presentation].existingId
          } 
        }

        params.backgroundImages = {}
        for (var presentation of presentations) {
          if (this.backgroundImages[presentation]) {
            params.backgroundImages[presentation] = this.backgroundImages[presentation].existingId
          } 
        }

        if (this.collectionId) {
          this.updateCollection(params);
        } else {
          this.createCollection(params);
        }
      }
    })
  }

  createCollection = (params: any) => {
    this.collectionsService.createCollection(params).subscribe({
      next: (collection: Collection) => {
        this.router.navigate(['/content/collections', collection.id]);
      },
      error: response => {
        console.log(response.error);
        this.showError(response.error);
        this.submitting = false;
      }
    })
  };

  updateCollection = (params: any) => {
    this.collectionsService.updateCollection(this.collectionId!, params).subscribe({
      next: (collection: Collection) => {
        this.router.navigate(['/content/collections', collection.id]);
      },
      error: response => {
        console.log(response.error);
        this.showError(response.error);
        this.submitting = false;
      }
    })
  };

  private uploadImages(): Observable<boolean> {
    var ids = [];
    for (var presentation of presentations) {
      var foregroundImage = this.foregroundImages[presentation];
      if (foregroundImage && foregroundImage.image && !foregroundImage.existingId) {
        ids.push(this.imagesService.uploadImage(foregroundImage.image));
      }

      var backgroundImage = this.backgroundImages[presentation];
      if (backgroundImage && backgroundImage.image && !backgroundImage.existingId) {
        ids.push(this.imagesService.uploadImage(backgroundImage.image));
      }
    }

    if (ids.length == 0) {
      return of(true);
    }
    
    return forkJoin(ids).pipe(
      map(imageIds => {
        if (imageIds.length != ids.length) {
          return false;
        }

        var offset = 0;
        for (var presentation of presentations) {
          var foregroundImage = this.foregroundImages[presentation];
          if (foregroundImage && foregroundImage.image && !foregroundImage.existingId) {
            foregroundImage.existingId = imageIds[offset];
            offset += 1;
          }
    
          var backgroundImage = this.backgroundImages[presentation];
          if (backgroundImage && backgroundImage.image && !backgroundImage.existingId) {
            backgroundImage.existingId = imageIds[offset];
            offset += 1;
          }
        }

        return true;
      })
    );
  }

  private showError(error: {error: string, description?: string }) {
    this.dialog.open(ErrorDialog,  {
      width: '500px',
      data: {
        error: error
      }
    });
  }
}
