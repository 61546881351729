import { Dialog } from '@angular/cdk/dialog';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CategoryService } from 'src/app/_services/category.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { ErrorDialog } from 'src/app/common/error-dialog/error-dialog.component';
import Category from 'src/app/models/category.model';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.css']
})
export class CreateCategoryComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Categories", path: ['/catalog', 'categories'] }]);
  title = new BehaviorSubject<string>("Create Category");

  submitting: boolean = false;

  name = new FormControl("", [Validators.required]);
  formGroup = new FormGroup({
    name: this.name,
  });

  constructor(
    private toolbarService: ToolbarService,
    private categoryService: CategoryService,
    private router: Router,
    private dialog: Dialog,
  ) {

  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  onCancel(): void {
    this.router.navigate(['/catalog/categories'])
  }

  onSubmit(): void {
    if (this.submitting) {
      return;
    }
    this.submitting = true;

    var params: any = {}
    if (this.name.dirty) {
      params.name = this.name.value;
    }

    this.createCategory(params);
  }

  private createCategory(params: any): void {
    this.categoryService.createCategory(params).subscribe({
      next: (category: Category) => {
        this.router.navigate(['/catalog/categories']);
      },
      error: response => {
        console.log(response.error);
        this.showError(response.error);
        this.submitting = false;
      }
    })
  };

  private showError(error: {error: string, description?: string }) {
    this.dialog.open(ErrorDialog,  {
      width: '500px',
      data: {
        error: error
      }
    });
  }
}
