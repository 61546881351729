import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BuyersService } from 'src/app/_services/buyers.service';
import { ListingsService } from 'src/app/_services/listings.service';
import { ProxyBidsService } from 'src/app/_services/proxy-bids.service';
import { ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import BuyerProfile from 'src/app/models/buyer-profile.model';
import { clientNames } from 'src/app/models/clients';
import Listing from 'src/app/models/listing.model';
import ProxyBid, { PROXY_BID_STATUSES, PROXY_BID_STATUS_DESCRIPTIONS } from 'src/app/models/proxy-bid.model';

@Component({
  selector: 'app-browse-max-bids',
  templateUrl: './browse-max-bids.component.html',
  styleUrls: ['./browse-max-bids.component.css']
})
export class BrowseMaxBidsComponent implements OnInit, OnDestroy, ToolbarController {
  private static readonly DEFAULT_PAGE_SIZE = 20

  title = new BehaviorSubject<string>('Max Bids');

  listingFilterControl = new FormControl<Listing | null>(null);
  buyerFilterControl = new FormControl<BuyerProfile | null>(null)

  statusFilter!: string | null;
  listingFilter!: number | null;
  buyerFilter!: number | null;
  clientFilter!: string | null;

  page = 0;
  length = 0;
  pageSize = BrowseMaxBidsComponent.DEFAULT_PAGE_SIZE;
  pageSizeOptions: number[] = [20, 40, 60, 80, 100];
  displayedColumns: string[] = ['id', 'status', 'listing', 'price', 'buyer', 'created', 'client'];

  proxyBids: ProxyBid[] = [];
  isLoading = true
  private loadingSubscription: Subscription | null = null

  proxyBidStatuses: object;
  proxyBidStatusDescriptions: object;
  clientNames: object;

  constructor(
    private toolbarService: ToolbarService,
    private proxyBidsService: ProxyBidsService,
    private buyersService: BuyersService,
    private listingsService: ListingsService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.proxyBidStatuses = PROXY_BID_STATUSES;
    this.proxyBidStatusDescriptions = PROXY_BID_STATUS_DESCRIPTIONS;
    this.clientNames = clientNames;

    this.activatedRoute.queryParams.subscribe(params => {
      this.statusFilter = params.status ? params.status : null;
      this.listingFilter = params.listing ? params.listing : null;
      this.buyerFilter = params.buyer ? params.buyer : null;
      this.clientFilter = params.client ? params.client : null;
      this.pageSize = params.pageSize ? params.pageSize : BrowseMaxBidsComponent.DEFAULT_PAGE_SIZE;
      this.page = params.page ? params.page : 0;

      if (this.buyerFilter && !(this.buyerFilterControl.value instanceof BuyerProfile)) {
        buyersService.getBuyerProfile(this.buyerFilter).subscribe({
          next: (buyer: BuyerProfile) => {
            this.buyerFilterControl.setValue(buyer);
          }
        })
      }
      if (this.listingFilter && !(this.listingFilterControl.value instanceof Listing)) {
        listingsService.getListing(this.listingFilter).subscribe({
          next: (listing: Listing) => {
            this.listingFilterControl.setValue(listing);
          }
        })
      }

      this.fetchProxyBids(this.page);
    })

    this.listingFilterControl.valueChanges.subscribe({
      next: () => {
        if (this.listingFilterControl.value instanceof Listing) {
          this.listingFilter = this.listingFilterControl.value.id;
        } else {
          this.listingFilter = null;
        }
        this.updateUrlParametersIfNeeded();
      }
    })
    this.buyerFilterControl.valueChanges.subscribe({
      next: () => {
        if (this.buyerFilterControl.value instanceof BuyerProfile) {
          this.buyerFilter = this.buyerFilterControl.value.id;
        } else {
          this.buyerFilter = null;
        }
        this.updateUrlParametersIfNeeded();
      }
    })
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  fetchProxyBids = (page: number) => {
    this.isLoading = true

    if (this.loadingSubscription != null) {
      this.loadingSubscription.unsubscribe();
    }
    this.loadingSubscription = this.proxyBidsService.getProxyBids(this.pageSize, this.pageSize * page, this.statusFilter, this.listingFilter, this.buyerFilter, this.clientFilter, "-created").subscribe({
      next: (response: any) => {
        this.proxyBids = response.data;
        this.length = response.totalCount;
        this.isLoading = false
        this.loadingSubscription = null;
      },
      error: (error: any) => {
        console.log(error);
        this.proxyBids = [];
        this.length = 0;
        this.isLoading = false
        this.loadingSubscription = null;
      }
    });
  };

  onSelectStatus = (event: any) => {
    this.updateUrlParametersIfNeeded();
  }

  onSelectClient = (event: any) => {
    this.updateUrlParametersIfNeeded();
  }

  pageChangeEvent = (event: PageEvent) => {
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updateUrlParametersIfNeeded();
  }

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      status: this.statusFilter,
      listing: this.listingFilter,
      buyer: this.buyerFilter,
      client: this.clientFilter,
      page: this.page == 0 ? null : this.page,
      pageSize: this.pageSize == BrowseMaxBidsComponent.DEFAULT_PAGE_SIZE ? null : this.pageSize
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }
}
