import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AuthorizationService, Permissions, Privilege } from 'src/app/_services/authorization.service';
import { Material, CatalogService } from 'src/app/_services/catalog.service';
import { ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteDialog } from 'src/app/common/delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-materials-home',
  templateUrl: './materials-home.component.html',
  styleUrls: ['./materials-home.component.css']
})
export class MaterialsHomeComponent implements OnInit, OnDestroy, ToolbarController {
  title = new BehaviorSubject<string>("Materials");
  showAddMaterialButton: boolean;
  showDeleteMaterialButton: boolean;

  displayedColumns: string[] = ['id', 'name', 'actions'];
  materials: Material[] = [];
  refreshingMaterials: boolean = false;

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private catalogService: CatalogService,
    private router: Router,
    private authorizationService: AuthorizationService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.refreshMaterials();


    this.showAddMaterialButton = authorizationService.hasPermission(Permissions.CATALOG_ALL, Privilege.WRITE);
    this.showDeleteMaterialButton = authorizationService.hasPermission(Permissions.CATALOG_ALL, Privilege.WRITE);
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  public onAddMaterialButtonPressed() {
    this.router.navigate(['catalog', 'materials', 'new']);
  }

  public deleteMaterial(material: Material, callback: () => void) {
    this.catalogService.deleteMaterial(material, {
      success: () => {
        this.refreshMaterials();
        callback();
      },
      error: error => {
        this.snackBar.open("Can't delete this material!", '', {duration: 2000});
        callback();
      }
    })
  }

  public onClickDelete(material: Material) {
    this.dialog.open(DeleteDialog, {
      width: '20vw',
      height: '10vh',
      data: {name: material.name, onClickConfirm: (callback: () => void) => this.deleteMaterial(material, callback)}
    })
  }

  private refreshMaterials() {
    if (this.refreshingMaterials) {
      return;
    }

    this.refreshingMaterials = true;
    this.catalogService.getMaterials().pipe(takeWhile(val => !this._destroyed.getValue())).subscribe({
      next: (materials: Material[]) => {
        this.materials = materials;
        this.refreshingMaterials = false;
      },
      error: (error: any) => {
        console.log(error);
        this.refreshingMaterials = false;
      }
    });
  }
}

