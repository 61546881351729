export enum FeatureType {
  CollectionV2 = "COLLECTION_V2"
}

export class Feature {
  public created!: Date;
  public updated!: Date;

  public constructor(
    public id: number,
    public name: string,
    public type: FeatureType,
    public object: object,
    public label: string | null
  ) { }
}
