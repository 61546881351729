<div class="toolbar-container">
  <span class="menu">
    <button mat-icon-button (click)="onSidebarButtonPressed()"><mat-icon>menu</mat-icon></button>
  </span>
  <span class="title">
    <ng-container *ngFor="let path of (titlePath | async)">
      <a [routerLink]="path.path">{{ path.title }}</a> &rarr; 
    </ng-container>
    <span>{{ (title | async) }}</span>
  </span>
  <span class="flex-fill"></span>
  <span class="right">
    <button mat-stroked-button color="warn" (click)="onLogoutPressed()">Logout</button>
  </span>
</div>
