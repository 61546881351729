import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthorizationService, Permissions, Privilege } from 'src/app/_services/authorization.service';
import { SeriesService } from 'src/app/_services/series.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import Series from 'src/app/models/series.model';

@Component({
  selector: 'app-series-detail',
  templateUrl: './series-detail.component.html',
  styleUrls: ['./series-detail.component.css']
})
export class SeriesDetailComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Series", path: ['/catalog', 'series'] }]);
  series: Series | null = null;
  title = new BehaviorSubject<string>("?");

  canEditSeries: boolean;

  deleting = false;

  private _seriesId: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private toolbarService: ToolbarService,
    private seriesService: SeriesService,
    private authorizationService: AuthorizationService,
  ) { 
    const routeParams = this.route.snapshot.paramMap;
    this._seriesId = Number(routeParams.get('seriesId'));
    seriesService.getSeries(this._seriesId).subscribe({
      next: (series: Series) => {
        this.series = series;
        this.title.next(series.displayName || series.name);
      },
      error: () => {
        router.navigate(['/catalog', 'series'])
        // TODO: This may be a different error other than not existing.
        let snackBarRef = this.snackBar.open(`Series (${this._seriesId}) does not exist.`, '', {
          duration: 3000
        });
      }
    });

    this.canEditSeries = authorizationService.hasPermission(Permissions.CATALOG_ALL, Privilege.WRITE);
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  onEditButtonPressed(): void {
    this.router.navigate(['/catalog', 'series', this._seriesId, 'edit'])
  }

  onDeleteButtonPressed(): void {
    if (this.deleting || !this.series) {
      return;
    }
    this.deleting = true;

    this.seriesService.deleteSeries(this._seriesId).subscribe({
      next: () => {
        this.router.navigate(['/catalog', 'series'])
      },
      error: error => {
        alert(error);
        console.log(error);
        this.deleting = false;
      }
    })
  }
}
