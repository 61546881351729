import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthorizationService, Permissions, Privilege } from 'src/app/_services/authorization.service';
import { ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteDialog } from 'src/app/common/delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import Brand from 'src/app/models/brand.model';
import { BrandsService } from 'src/app/_services/brands.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-browse-brands',
  templateUrl: './browse-brands.component.html',
  styleUrls: ['./browse-brands.component.css']
})
export class BrowseBrandsComponent implements OnInit, OnDestroy, ToolbarController {
  private static readonly DEFAULT_PAGE_SIZE = 20

  title = new BehaviorSubject<string>("Brands");

  showAddBrandButton: boolean;
  showDeleteBrandButton: boolean;

  page = 0;
  length = 0;
  pageSize = BrowseBrandsComponent.DEFAULT_PAGE_SIZE;
  pageSizeOptions: number[] = [20, 40, 60, 80, 100];
  displayedColumns: string[] = ['id', 'name', 'actions'];

  brands: Brand[] = [];
  isLoading = true
  private loadingSubscription: Subscription | null = null

  constructor(
    private toolbarService: ToolbarService,
    private brandsService: BrandsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authorizationService: AuthorizationService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.pageSize = params.pageSize ? params.pageSize : BrowseBrandsComponent.DEFAULT_PAGE_SIZE;
      this.page = params.page ? params.page : 0;
      this.fetchBrands();
    })

    this.showAddBrandButton = authorizationService.hasPermission(Permissions.CATALOG_ALL, Privilege.WRITE);
    this.showDeleteBrandButton = authorizationService.hasPermission(Permissions.CATALOG_ALL, Privilege.WRITE);
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  fetchBrands(): void {
    this.isLoading = true

    if (this.loadingSubscription != null) {
      this.loadingSubscription.unsubscribe();
    }
    this.loadingSubscription = this.brandsService.getBrands().subscribe({
      next: (brands: Brand[]) => {
        this.brands = brands;
        this.length = this.brands.length;
        this.pageSize = this.brands.length;
        this.isLoading = false
        this.loadingSubscription = null;
      },
      error: (error: any) => {
        console.log(error);
        this.brands = [];
        this.length = 0;
        this.isLoading = false
        this.loadingSubscription = null;
      }
    });
  };

  public onAddBrandButtonPressed() {
    this.router.navigate(['catalog', 'brands', 'new']);
  }

  public deleteBrand(brand: Brand, callback: () => void) {
    this.brandsService.deleteBrand(brand.id).subscribe({
      next: () => {
        this.fetchBrands();
        callback();
      },
      error: error => {
        this.snackBar.open("Can't delete this brand!", '', {duration: 2000});
        callback();
      }
    })
  }

  public onClickDelete(brand: Brand) {
    this.dialog.open(DeleteDialog, {
      width: '20vw',
      height: '10vh',
      data: {name: brand.name, onClickConfirm: (callback: () => void) => this.deleteBrand(brand, callback)}
    })
  }

  pageChangeEvent(event: PageEvent): void {
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updateUrlParametersIfNeeded();
  }

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      page: this.page == 0 ? null : this.page,
      pageSize: this.pageSize == BrowseBrandsComponent.DEFAULT_PAGE_SIZE ? null : this.pageSize
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }
}
