import { Component, Input, Inject, OnInit } from '@angular/core';
import { ResourceChangeHistory } from 'src/app/models/resource-interaction.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'history-dialog',
  templateUrl: './history-dialog.component.html',
  styleUrls: ['./history-dialog.component.css']
})
export class ResourceChangeHistoryDialog implements OnInit {
  parsedBase?: string;
  constructor(
    public dialogRef: MatDialogRef<ResourceChangeHistoryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ResourceChangeHistory
  ) { }

  ngOnInit(): void { 
    this.parsedBase = JSON.stringify(this.data.initialState, null, 2);
  }

  closeButtonPressed(): void {
    this.dialogRef.close
  }
}
