import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import Brand from 'src/app/models/brand.model';

@Component({
    selector: 'brand-card',
    templateUrl: './brand-card.component.html',
    styleUrls: ['./brand-card.component.css'],
})
export class BrandCard {
    @Input('brand') brand!: Brand;
    @Input('clickable') clickable: boolean = true;

    constructor(
        private router: Router
    ) { }

    onClick(): void {
        if (!this.clickable) {
            return;
        }
        this.router.navigate(['/catalog/brands', this.brand.id]);
    }
}
