import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AuthorizationService, Permissions, Privilege } from 'src/app/_services/authorization.service';
import { DiscountsService } from 'src/app/_services/discounts.service';
import { TitlePathElement, ToolbarService, ToolbarController } from 'src/app/_services/toolbar.service';
import Discount from 'src/app/models/discount.model';

@Component({
  selector: 'app-discount-detail',
  templateUrl: './discount-detail.component.html',
  styleUrls: ['./discount-detail.component.css']
})
export class DiscountDetailComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Discounts", path: ['/marketplace', 'discounts'] }]);
  title = new BehaviorSubject<string>("?");

  discount: Discount | null = null;
  canEditDiscount: boolean;

  deleting = false;

  private _discountId: number;
  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private toolbarService: ToolbarService,
    private discountsService: DiscountsService,
    private authorizationService: AuthorizationService,
  ) {
    const routeParams = this.route.snapshot.paramMap;
    this._discountId = Number(routeParams.get('discountId'));
    discountsService.getDiscount(this._discountId).pipe(takeWhile(val => !this._destroyed.getValue())).subscribe({
      next: (discount: Discount) => {
        this.discount = discount;
        this.title.next(discount.discountCode);
      },
      error: (error: any) => {
        console.log(error);
        router.navigate(['/marketplace', 'discounts'])
        // TODO: This may be a different error other than not existing.
        let snackBarRef = this.snackBar.open(`Discount (${this._discountId}) does not exist.`, '', {
          duration: 3000
        });
      }
    });

    this.canEditDiscount = authorizationService.hasPermission(Permissions.MARKETPLACE_ALL, Privilege.WRITE);
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  onEditButtonPressed(): void {
    this.router.navigate(['/marketplace', 'discounts', this._discountId, 'edit'])
  }

  onDeleteButtonPressed(): void {
    if (this.deleting || !this.discount) {
      return;
    }
    this.deleting = true;

    this.discountsService.deleteDiscount(this.discount.id, {
      success: () => {
        this.router.navigate(['/marketplace', 'discounts'])
      },
      error: error => {
        alert(error);
        console.log(error);
        this.deleting = false;
      }
    })
  }
}
