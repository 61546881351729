<table mat-table [dataSource]="discounts" class="w-100">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef> Id </th>
    <td mat-cell *matCellDef="let discount"> {{discount.id}} </td>
  </ng-container>
  <ng-container matColumnDef="discountCode">
    <th mat-header-cell *matHeaderCellDef> Discount </th>
    <td mat-cell *matCellDef="let discount"> {{discount.discountCode}} </td>
  </ng-container>
  <ng-container matColumnDef="discountAmountCents">
    <th mat-header-cell *matHeaderCellDef> Discount Amount </th>
    <td mat-cell *matCellDef="let discount"> ${{discount.discountAmountCents / 100}} </td>
  </ng-container>
  <ng-container matColumnDef="minPurchasePriceCents">
    <th mat-header-cell *matHeaderCellDef> Minimum Purchase Price </th>
    <td mat-cell *matCellDef="let discount"> ${{discount.minPurchasePriceCents / 100 }} </td>
  </ng-container>
  <ng-container matColumnDef="disabled">
    <th mat-header-cell *matHeaderCellDef> Disabled </th>
    <td mat-cell *matCellDef="let discount"> {{ discount.disabled }} </td>
  </ng-container>
  <ng-container matColumnDef="expiration">
    <th mat-header-cell *matHeaderCellDef> Expiration </th>
    <td mat-cell *matCellDef="let discount"> {{ discount.expiration | date:'MMM d, H:mm aa' }} </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let discount" style="text-align: right;">
      <button mat-icon-button (click)="$event.stopPropagation(); onClickDelete(discount)" *ngIf="showDeleteDiscountButton">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let discount; columns: displayedColumns;" [routerLink]="['/marketplace/discounts/', discount.id]"></tr>
</table>
<span *ngIf="refreshingDiscounts" class="bg-white p-2 loading-spinner-container">
    <mat-spinner [diameter]="30" class="loading-spinner"></mat-spinner>
</span>
<button mat-fab class="fab" (click)="onAddDiscountButtonPressed()" *ngIf="showAddDiscountButton">
  <mat-icon>add</mat-icon>
</button>
