<div class="container" *ngIf="!loading else spinner">
    <mat-card class="my-2">
        <mat-card-title class="mb-4">
            <ng-container *ngIf="!editing">Create collection</ng-container>
            <ng-container *ngIf="editing">Edit collection</ng-container>
        </mat-card-title>
        <mat-card-content>
            <div class="d-flex flex-column">
                <h3 class="mb-0">General Information</h3> 
                <mat-form-field appearance="outline" class="flex-fill">
                    <mat-label>Name</mat-label>
                    <input matInput type="string" placeholder="Heavy Hitters" [formControl]="name" required>
                </mat-form-field>
                <h3 class="mb-0">Appearance</h3>
                <mat-form-field appearance="outline" class="flex-fill">
                    <mat-label>Formatted Title</mat-label>
                    <input matInput type="string" placeholder="*Our* Picks" [formControl]="formattedTitle">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Description</mat-label>
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="6" [formControl]="description"></textarea>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Style</mat-label>
                    <mat-select [formControl]="style" required>
                        <mat-option *ngFor="let style of allStyles" [value]="style">{{style}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <table style="text-align: center; margin-bottom: 12px; border-collapse: separate; border-spacing: 10px;">
                    <th colspan="3" style="padding: 14px">
                        Images
                    </th>
                    <tr>
                        <td></td>
                        <td>Foreground</td>
                        <td>Background</td>
                    </tr>
                    <tr *ngFor="let presentation of allPresentations">
                        <td style="width: 500px">{{presentation}}</td>
                        <td style="min-width: 300px">
                            <ng-container *ngIf="foregroundImageForPresentation(presentation) else addForegroundImage">
                                <image-view [image]="foregroundImageForPresentation(presentation)" (remove)="onRemoveForegroundImageForPresentation(presentation)" [removable]="true"></image-view>
                            </ng-container>
                            <ng-template #addForegroundImage>
                                <button mat-stroked-button type="button" color="primary" (click)="foregroundImageUploader.click()" class="add-image-button">
                                    <mat-icon>add</mat-icon>
                                </button>
                                <input hidden type="file" accept=".png,.jpg,.jpeg" (change)="onUploadForgroundImageForPresentation(presentation, $event.target)" #foregroundImageUploader>
                            </ng-template>
                        </td>
                        <td style="min-width: 300px">
                            <ng-container *ngIf="backgroundImageForPresentation(presentation) else addBackgroundImage">
                                <image-view [image]="backgroundImageForPresentation(presentation)" (remove)="onRemoveBackgroundImageForPresentation(presentation)" [removable]="true"></image-view>
                            </ng-container>
                            <ng-template #addBackgroundImage>
                                <button mat-stroked-button type="button" color="primary" (click)="backgroundImageUploader.click()" class="add-image-button">
                                    <mat-icon>add</mat-icon>
                                </button>
                                <input hidden type="file" accept=".png,.jpg,.jpeg" (change)="onUploadBackgroundImageForPresentation(presentation, $event.target)" #backgroundImageUploader>
                            </ng-template>
                        </td>
                    </tr>
                </table>
                <h3 class="mb-0">Content</h3> 
                <list-select [control]="list" [types]="['MODEL', 'LISTING']" required class="flex-fill"></list-select>
                <h3 class="mb-0">Exclusivity</h3> 
                <mat-checkbox [formControl]="requiresAuthentication">Requires Authentication</mat-checkbox>
                <program-select [control]="program" class="flex-fill"></program-select>
            </div>
        </mat-card-content>
        <mat-card-actions class="d-flex justify-content-between">
            <button mat-raised-button color="warn" (click)="onCancel()" [disabled]="submitting">Cancel</button>
            <div *ngIf="hasInvalidConfiguration()" style="color: red">This style and list type configuration is not supported.</div>
            <div>
                <button mat-raised-button color="primary" (click)="onSubmit()"
                    [disabled]="!hasValidForm() || hasInvalidConfiguration() || submitting || (!formGroup.dirty && !imagesDirty)" [class.spinner]="submitting">
                    <ng-container *ngIf="!editing">Submit</ng-container>
                    <ng-container *ngIf="editing">Save</ng-container>
                </button>
            </div>
        </mat-card-actions>
    </mat-card>
</div>
<ng-template #spinner>
    <mat-card class="m-2 d-flex flex-column align-items-center">
        <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
    </mat-card>
</ng-template>
