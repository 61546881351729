import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import IngestionRequest from 'src/app/models/ingestion-request.model';
import SellerProfile from 'src/app/models/seller-profile.model';
import { IngestionRequestsService } from 'src/app/_services/ingestion-requests.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';

@Component({
  selector: 'app-ingestion-request-create',
  templateUrl: './ingestion-request-create.component.html',
  styleUrls: ['./ingestion-request-create.component.css']
})
export class IngestionRequestCreateComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Listing Requests", path: ['/marketplace', 'ingestion-requests'] }]);
  title = new BehaviorSubject<string>("Create");

  selectedSeller = new FormControl<SellerProfile>(new SellerProfile(), Validators.required);
  externalUrl = new FormControl<String>("", [Validators.required, Validators.pattern('https?:\/\/.+')]);
  notes = new FormControl();

  formGroup = new FormGroup({
    selectedSeller: this.selectedSeller,
    externalUrl: this.externalUrl,
    notes: this.notes,
  });
  
  loading = false;
  submitting = false;

  editing = false;
  private _ingestionRequestId: number | null = null;

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toolbarService: ToolbarService,
    private ingestionRequestService: IngestionRequestsService,
  ) {
    const routeParams = this.route.snapshot.paramMap;
    if (routeParams.get('ingestionRequestId')) {
      this._ingestionRequestId = Number(routeParams.get('ingestionRequestId'));
      this.editing = true;
    }

    if (this._ingestionRequestId) {
      this.loading = true;
      this.ingestionRequestService.getIngestionRequest(this._ingestionRequestId).subscribe({
        next: (ingestionRequest) => {
          this.selectedSeller.setValue(ingestionRequest.sellerProfile);
          this.selectedSeller.markAsUntouched()

          this.externalUrl.setValue(ingestionRequest.url);
          this.externalUrl.markAsUntouched()
          
          this.notes.setValue(ingestionRequest.notes);
          this.notes.markAsUntouched()

          if (ingestionRequest.listing) {
            this.externalUrl.disable()
          }

          this.loading = false;
        }
      })
    }
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }
  
  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  onSelectSeller(seller: SellerProfile): void {
    this.selectedSeller.setValue(seller);
  }

  onCancel(): void {
    if (this.editing) {
      this.router.navigate(['/marketplace', 'ingestion-requests', this._ingestionRequestId]);
    } else {
      this.router.navigate(['/marketplace', 'ingestion-requests']);
    }
  }

  onSubmit(): void {
    if (this.submitting || this.selectedSeller.value == null) {
      return;
    }
    this.submitting = true

    var params: any = { }
    params.sellerProfile = this.selectedSeller.value.id;
    if (this.externalUrl.dirty) {
      params.url = this.externalUrl.value;
    }
    if (this.notes.dirty) {
      if (this.notes.value.length > 0) {
        params.notes = this.notes.value;
      } else {
        params.notes = null
      }
    }

    if (this.editing) {
      this.updateIngestionRequest(params)
    } else {
      this.createIngestionRequest(params)
    }
  }

  private createIngestionRequest(params: any): void {
    this.ingestionRequestService.createIngestionRequest(params).subscribe({
      next: (ingestionRequest) => {
        this.submitting = false;
        this.router.navigate(['/marketplace', 'ingestion-requests', ingestionRequest.id]);
      },
      error: (error) => {
        console.log(error);
        this.submitting = false;
      }
    });
  }

  private updateIngestionRequest(params: any): void {
    this.ingestionRequestService.updateIngestionRequest(this._ingestionRequestId!, params).subscribe({
      next: (ingestionRequest) => {
        this.submitting = false;
        this.router.navigate(['/marketplace', 'ingestion-requests', ingestionRequest.id]);
      },
      error: (error) => {
        console.log(error);
        this.submitting = false;
      }
    });
  }
}
