import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import BuyerProfile from 'src/app/models/buyer-profile.model';
import { SearchService } from 'src/app/_services/search.service';

@Component({
  selector: 'buyer-select',
  templateUrl: './buyer-select.component.html',
})
export class BuyerSelectComponent implements OnInit {
    @Input('control') control!: FormControl;
    @Input('required') required: boolean = false;
    @Input('label') label: string = "Buyer";
    @Input('placeholder') placeholder: string = "Pick a buyer";
    
    autocompleteResults: BuyerProfile[] = []

    constructor(private searchService: SearchService) {

    }

    ngOnInit(): void {
        this.control.valueChanges.pipe(
            debounceTime(100),
            distinctUntilChanged()
        ).subscribe({
            next: value => {
                if (value == null) {
                    return
                }
                if (String(value) !== value) {
                    return
                }

                this.searchService.search(value, [], ["BUYER_PROFILE"]).subscribe({
                    next: results => {
                        this.autocompleteResults = results.results.map(r => this.toBuyer(r.object))
                    }
                })
            }
        })
    }

    displayBuyer(buyer: BuyerProfile): string {
        return buyer?.displayName ?? ""
    }


  private toBuyer(buyer: any): BuyerProfile {
    var buyer = Object.assign(new BuyerProfile(), buyer)
    return buyer
  }
}
