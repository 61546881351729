import Order from "./order.model";
import Refund from "./refund.model";
import Shipment from "./shipment.model";

export default class Return {
    public id!: number;
    public status!: string;
    public cancellationReason!: string;
    public order!: Order;
    public restockingFeeCents!: number;
    public refundMethod!: string;
    public reason!: string;
    public shipment!: Shipment;
    public refund!: Refund;
    public flagged!: boolean;
    public internalNotes!: string;
    public created!: Date;
    public updated!: Date;
    
    get shipmentTrackingLink(): string {
      return `https://www.fedex.com/fedextrack/?tracknumbers=${this.shipment.fedexTrackingNumber}`
    }

    get canCancel(): boolean {
      return this.status === 'AWAITING_RECEIPT' || this.status === 'AWAITING_DECISION';
    }

    get decisionPending(): boolean {
      return this.status === 'AWAITING_DECISION';
    }
  
    get wasDecided(): boolean {
      return this.status === 'ACCEPTED' || (this.status === 'CANCELLED' && this.cancellationReason === 'REJECTED');
    }
}

export const orderedStatuses = [
  'ALL',
  'AWAITING_RECEIPT',
  'AWAITING_DECISION',
  'ACCEPTED',
  'CANCELLED'
]

export const allStatuses: {[key: string]: string } = {
  'ALL': 'All',
  'AWAITING_RECEIPT': 'Waiting to receive watch',
  'AWAITING_DECISION': 'Needs decision',
  'ACCEPTED': 'Accepted',
  'CANCELLED': 'Cancelled'
}

export const returnReasons = [
  'SIZE_NOT_AS_EXPECTED',
  'CONDITION_NOT_AS_EXPECTED',
  'FUNCTIONAL_ISSUES',
  'BUYER_REMORSE',
  'OTHER',
]

export const returnReasonDescriptions: {[key: string]: string } = {
  'SIZE_NOT_AS_EXPECTED': 'Size not as expected',
  'CONDITION_NOT_AS_EXPECTED': 'Condition not as expected',
  'FUNCTIONAL_ISSUES': 'Functional issues',
  'BUYER_REMORSE': 'Buyer doesn\'t like',
  'OTHER': 'Other'
}

export const refundMethodOptions = [
  "ORIGINAL_PAYMENT_METHOD",
  "CREDIT"
]
