<div style="padding: 12px">
  <h3>New Issue</h3>
  <div class="d-flex flex-column" style="gap: 10px">
    <mat-form-field appearance="outline" class="flex-fill">
      <mat-label>Category</mat-label>
      <mat-select [formControl]="category" required>
        <mat-option *ngFor="let c of categoryOptions" [value]="c">
          {{c}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="issuesForCategory.length > 0" appearance="outline" class="flex-fill">
      <mat-label>Issue</mat-label>
      <mat-select [formControl]="issueForCategory" required>
        <mat-option *ngFor="let issue of issuesForCategory" [value]="issue">
          {{issue.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="issueForCategory.value?.question" appearance="outline" class="flex-fill">
      <mat-label>{{issueForCategory.value.question}}</mat-label>
      <textarea matInput [formControl]="answer" [required]="issueForCategory.value.answerRequired"></textarea>
      <mat-hint *ngIf="issueForCategory.value.answerRequired">An Answer is Required</mat-hint>
    </mat-form-field>
  </div>
  <div class="d-flex justify-content-between mt-3">
    <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="onSubmit()">Save</button>
  </div>
</div>
