<div class="card d-flex flex-column" *ngIf="article" (click)="onClick()" [class.clickable]="clickable">
    <div class="image-container">
        <img [src]="article.bannerImage.optimizedUrl" *ngIf="article.bannerImage.optimizedUrl" draggable="false">
    </div>
    <div class="flex-fill">
        <div class="d-flex flex-column text-container">
            <div class="publisher">{{article.publisher | uppercase}}</div>
            <div class="title flex-fill">{{article.title}}</div>
            <div class="published">{{article.published | date: 'longDate'}}</div>
        </div>
    </div>
</div>