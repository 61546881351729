import { Component, Input } from '@angular/core';
import Listing from 'src/app/models/listing.model';
import Image from 'src/app/models/image.model';
import { Router } from '@angular/router';

@Component({
    selector: 'listing-card',
    templateUrl: './listing-card.component.html',
    styleUrls: ['./listing-card.component.css'],
})
export class ListingCard {
    @Input('listing') listing!: Listing;
    @Input('clickable') clickable: boolean = true;

    constructor(
        private router: Router
    ) { }

    onClick(): void {
        if (!this.clickable) {
            return;
        }
        this.router.navigate(['/marketplace/listings', this.listing.id]);
    }

    frontFacingImage(): Image | null {
        for (var image of this.listing.images) {
            if (image.type == 'FRONT') {
                return image.image;
            }
        }
        return null;
    }
}
