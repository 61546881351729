<div class="d-flex flex-column h-100">
    <mat-card class="m-2">
        <mat-card-content class="d-flex flex-md-row flex-column">
            <mat-form-field appearance="outline" class="flex-fill mx-1">
                <mat-label>Status</mat-label>
                <mat-select [(ngModel)]='statusFilter' name='Status' (selectionChange)="onSelectStatus($event)">
                    <mat-option>-</mat-option>
                    <mat-option *ngFor="let status of proxyBidStatuses" [value]="status">
                        {{proxyBidStatusDescriptions[status]}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <listing-select label="Listing" placeholder="Pick a listing" [control]="listingFilterControl" class="flex-fill mx-1"></listing-select>
            <buyer-select [control]="buyerFilterControl" class="flex-fill mx-1"></buyer-select>
            <mat-form-field appearance="outline" class="flex-fill mx-1">
                <mat-label>Client</mat-label>
                <mat-select [(ngModel)]='clientFilter' name='Client' (selectionChange)="onSelectClient($event)">
                    <mat-option [value]="">-</mat-option>
                    <mat-option *ngFor="let item of clientNames | keyvalue" [value]="item.key">
                        {{ item.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card-content>
    </mat-card>
    <mat-card class="mx-2 mb-2 p-0 flex-fill d-flex flex-column overflow-scroll">
        <div class="flex-fill position-relative overflow-scroll">
            <div *ngIf="isLoading"
                class="w-100 h-100 position-absolute d-flex align-items-center justify-content-center"
                style="z-index: 1;">
                <div *ngIf="proxyBids.length > 0" class="w-100 h-100 position-absolute"
                    style="background-color: rgba(0, 0, 0, 0.1);"></div>
                <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
            </div>
            <div class="h-100 w-100 overflow-scroll">
                <table mat-table [dataSource]="proxyBids" class="w-100">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> Id </th>
                        <td mat-cell *matCellDef="let proxyBid"> {{proxyBid.id}} </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> Status </th>
                        <td mat-cell *matCellDef="let proxyBid">
                            <div class="badge-narrow" [ngClass]="{
                  'badge-narrow': proxyBid.status === 'AWAITING_PAYMENT', 
                  'badge-narrow-success': proxyBid.status === 'ACTIVE', 
                  'badge-narrow-orange': proxyBid.status === 'EXHAUSTED', 
                  'badge-narrow-error': proxyBid.status === 'CANCELLED'
                  }">
                                {{ proxyBid.status }}
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="listing">
                        <th mat-header-cell *matHeaderCellDef> Listing </th>
                        <td mat-cell *matCellDef="let proxyBid">
                            <listing-chip [listing]="proxyBid.listing"></listing-chip>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef> Price </th>
                        <td mat-cell *matCellDef="let proxyBid"> {{(proxyBid.maxPriceCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}} </td>
                    </ng-container>
                    <ng-container matColumnDef="buyer">
                        <th mat-header-cell *matHeaderCellDef> Buyer </th>
                        <td mat-cell *matCellDef="let proxyBid"><buyer-chip [buyer]="proxyBid.buyerProfile"></buyer-chip></td>
                    </ng-container>
                    <ng-container matColumnDef="created">
                        <th mat-header-cell *matHeaderCellDef>
                            Created </th>
                        <td mat-cell *matCellDef="let proxyBid"> {{ proxyBid.created | date:'medium'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="client">
                        <th mat-header-cell *matHeaderCellDef> Client </th>
                        <td mat-cell *matCellDef="let proxyBid">
                            <mat-chip-list>
                                <mat-chip *ngIf="proxyBid.client" [routerLink]="['/marketplace/max-bids']"
                                    [queryParams]="{ client: proxyBid.client }" style="font-size: 9pt !important">{{
                                    clientNames[proxyBid.client] }}</mat-chip>
                            </mat-chip-list>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let proxyBid; columns: displayedColumns;" [routerLink]="['/marketplace/max-bids/', proxyBid.id]">
                </table>
            </div>
        </div>
        <mat-paginator [length]="length" [pageIndex]="page" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageChangeEvent($event)" aria-label="Select page">
        </mat-paginator>
    </mat-card>
</div>