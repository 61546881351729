import { Component, Input, OnInit } from '@angular/core';

export class TimelineEvent {
  text!: string;
  timestamp!: Date;
}

@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit {
  static DEFAULT_MAX: number = 4;
  private static LOAD_MORE_INCREMENT: number = 4;

  @Input('events') events?: TimelineEvent[];
  max: number = TimelineComponent.DEFAULT_MAX;

  constructor() { }

  ngOnInit(): void {}

  loadMore(): void {
    this.max += TimelineComponent.LOAD_MORE_INCREMENT;
  }

  showLess(): void {
    this.max = TimelineComponent.DEFAULT_MAX;
  }
}
