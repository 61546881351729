import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router, public authorizationService: AuthorizationService) {
    this.auth.isAuthenticated$.subscribe(authenticated => {
      if (!authenticated) {
        this.auth.loginWithRedirect({appState: {target: this.router.url}})
      } else {
        this.handleAuthenticated()
      }
    })
  }

  private handleAuthenticated() {
    this.authorizationService.initIfNeeded(() => {
      if (!this.authorizationService.hasConsoleAccess()) {
        this.router.navigate(['/unauthorized']);
      }
    })
  }

  canActivate(): boolean {
    if (this.authorizationService.didInit && !this.authorizationService.hasConsoleAccess()) {
        this.router.navigate(['/unauthorized']);
        return false;
    }

    return true;
  }
}
