import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import Collection from '../models/collection.model';
import List from '../models/list.model';

@Injectable({
  providedIn: 'root'
})
export class CollectionsService {
  constructor(
    private http: HttpClient
  ) { }

  public getCollections(pageSize: number, offset: number, name: string | null, sortKey: string | null): Observable<Collection[]> {
    var path = `/content/collections?pageSize=${pageSize}&offset=${offset}`;
    if (!!name) {
      name = encodeURIComponent(name);
      path += `&name[]=${name}`;
    }
    if (!!sortKey) {
      path += `&sort=${sortKey}`
    }
    return this.http.get<Collection[]>(environment.apiUrl + path).pipe(
      map(collections => collections.map(o => this.toCollection(o)))
    );
  }

  public getCollection(id: number): Observable<Collection> {
    var path = `/content/collections/${id}?expand[]=list.items.object`;
    return this.http.get<Collection>(environment.apiUrl + path).pipe(
      map(collection => this.toCollection(collection))
    );
  }

  public createCollection(params: any): Observable<Collection> {
    var path = `/content/collections`;
    return this.http.post<Collection>(environment.apiUrl + path, params);
  }

  public updateCollection(id: number, updates: any): Observable<Collection> {
    var path = `/content/collections/${id}`;
    return this.http.patch<Collection>(environment.apiUrl + path, updates);
  }

  public deleteCollection(id: number): Observable<void> {
    var path = `/content/collections/${id}`;
    return this.http.delete<void>(environment.apiUrl + path);
  }

  private toCollection(collection: any): Collection {
    var collection = Object.assign(new Collection(), collection)
    if (collection.list) {
        collection.list = Object.assign(new List(), collection.list)
      }
    return collection
  }
}
