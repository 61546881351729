import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css']
})
export class SearchResultComponent implements OnInit {
  @Input('image') image?: string;
  @Input('title') title?: string;
  @Input('subtitle') subtitle?: string;

  constructor(
    public router: Router
  ) { }

  ngOnInit(): void { }
}
