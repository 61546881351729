import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ErrorDialog } from 'src/app/common/error-dialog/error-dialog.component';
import List, { types } from 'src/app/models/list.model';
import { ListsService } from 'src/app/_services/lists.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';

const DEFAULT_FILTER : string = "{\"root\": {}}";


@Component({
  selector: 'app-create-list',
  templateUrl: './create-list.component.html',
  styleUrls: ['./create-list.component.css']
})
export class CreateListComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath!: BehaviorSubject<TitlePathElement[]>;
  title!: BehaviorSubject<string>;

  name = new FormControl(null, [Validators.required]);
  type = new FormControl(null, [Validators.required]);
  inventorySensitive = new FormControl(null);
  auctionsOnly = new FormControl(null);
  isAuto = new FormControl(null);
  formGroup = new FormGroup({
    name: this.name,
    type: this.type,
    inventorySensitive: this.inventorySensitive,
    isAuto: this.isAuto,
  });

  loading: boolean = false;
  submitting: boolean = false;

  private _destroyed = new ReplaySubject<boolean>(1);

  @Input() allTypes: string[];

  constructor(
    private toolbarService: ToolbarService,
    private listsService: ListsService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) {
    this.allTypes = types

    this.titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Lists", path: ['/content/lists'] }]);
    this.title = new BehaviorSubject<string>("Create");
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  onCancel(): void {
    this.router.navigate(['/content/lists'])
  }

  onSubmit(): void {
    if (this.submitting) {
      return;
    }

    this.submitting = true;

    var params: any = {}
    if (this.name.dirty) {
      params.name = this.name.value;
    }
    if (this.type.dirty) {
      params.type = this.type.value;
    }
    if (this.inventorySensitive.dirty) {
      params.inventorySensitive = this.inventorySensitive.value;
    }

    if (this.auctionsOnly.dirty) {
      if (this.auctionsOnly.value) {
        params.contentRestriction = "AUCTION_ONLY";
      } else {
        params.contentRestriction = "NONE";
      }
    }

    if (this.isAuto.dirty) {
      params.filter = DEFAULT_FILTER;
    }

    this.createList(params)
  }

  createList = (params: any) => {
    this.listsService.createList(params).subscribe({
      next: (list: List) => {
        this.router.navigate(['/content/lists', list.id]);
      },
      error: response => {
        console.log(response.error);
        this.showError(response.error);
        this.submitting = false;
      }
    })
  };

  private showError(error: {error: string, description?: string }) {
    this.dialog.open(ErrorDialog,  {
      width: '500px',
      data: {
        error: error
      }
    });
  }
}
