import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { DiscountsService } from 'src/app/_services/discounts.service';
import { takeWhile } from 'rxjs/operators';
import Discount from 'src/app/models/discount.model';

@Component({
  selector: 'app-edit-discount',
  templateUrl: './edit-discount.component.html',
  styleUrls: ['./edit-discount.component.css']
})
export class EditDiscountComponent implements OnInit, OnDestroy, ToolbarController {
  private _baseTitlePath = { title: "Discounts", path: ['/marketplace', 'discounts'] };
  titlePath = new BehaviorSubject<TitlePathElement[]>([this._baseTitlePath, {title: "?", path: []}]);
  title = new BehaviorSubject<string>("Edit");

  private _discountId: number
  discount: Discount | null = null

  formGroup = new FormGroup({
    discountCode: new FormControl('', Validators.required),
    discountAmountCents: new FormControl('', Validators.required),
    minPurchasePriceCents: new FormControl(''),
    disabled: new FormControl(false),
    expiration: new FormControl('')
  });
  get formControls() {
    return this.formGroup.controls
  }

  submitting: boolean = false;

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private router: Router,
    private discountsService: DiscountsService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) {
    const routeParams = this.route.snapshot.paramMap;
    this._discountId = Number(routeParams.get('discountId'));
    discountsService.getDiscount(this._discountId).pipe(takeWhile(val => !this._destroyed.getValue())).subscribe({
      next: (discount: Discount) => {
        this.discount = discount;
        if (discount) {
          this.titlePath.next([this._baseTitlePath, { title: (discount.discountCode), path: ['/marketplace', 'discounts', discount.id.toString()] }]);
          this.formControls.discountCode.patchValue(discount.discountCode ?? '');
          this.formControls.discountAmountCents.patchValue(discount.discountAmountCents.toString() ?? '');
          this.formControls.minPurchasePriceCents.patchValue(discount.minPurchasePriceCents?.toString() ?? '');
          if (discount.disabled) {
            this.formControls.disabled.setValue(discount.disabled);
          }
          this.formControls.expiration.patchValue(discount.expiration?.toString() ?? '');
        }
      },
      error: (error: any) => {
        console.log(error);
        router.navigate(['/marketplace', 'discounts'])
        // TODO: This may be a different error other than not existing.
        let snackBarRef = this.snackBar.open(`Discount (${this.discount}) does not exist.`, '', {
          duration: 3000
        });
      }
    });
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return
    }
    this.submitting = true

    var changes: any = {}
    if (this.formControls.discountCode.dirty) {
      changes.discountCode = this.formControls.discountCode.value;
    }

    if (this.formControls.discountAmountCents.dirty) {
      changes.discountAmountCents = this.formControls.discountAmountCents.value;
    }

    if (this.formControls.minPurchasePriceCents.dirty) {
      changes.minPurchasePriceCents = this.formControls.minPurchasePriceCents.value;
    }

    changes.isDisabled = this.formControls.disabled.value;
    changes.expiration = this.formControls.expiration.value;
    this.discountsService.updateDiscount(this._discountId, changes, {
      success: () => {
        this.router.navigate(['/marketplace', 'discounts', this._discountId])
      },
      error: error => {
        this.submitting = false;
        alert(error);
      }
    });
  }
}
