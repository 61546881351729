import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Series from '../models/series.model';

@Injectable({
  providedIn: 'root'
})
export class SeriesService {
  constructor(private http: HttpClient) { }

  public createSeries(params: any): Observable<Series> {
    return this.http.post<Series>(environment.apiUrl + `/catalog/series`, params).pipe(
      map(fetchedSeries => this.toSeries(fetchedSeries))
    );
  }

  public updateSeries(seriesId: number, params: any): Observable<any> {
    return this.http.patch(environment.apiUrl + `/catalog/series/${seriesId}`, params);
  }

  public deleteSeries(seriesId: number): Observable<any>  {
    return this.http.delete(environment.apiUrl + `/catalog/series/${seriesId}`)
  }

  public getSeries(seriesId: number): Observable<Series> {
    return this.http.get<Series>(environment.apiUrl + `/catalog/series/${seriesId}`).pipe(
      map(fetchedSeries => this.toSeries(fetchedSeries))
    );
  }

  public searchSeries(query: string): Observable<Series[]> {
    let params = new HttpParams().set('q', query);
    return this.http.get<Series[]>(environment.apiUrl + "/catalog/series/search", { params: params });
  }
  
  public listSeries(brands: number[] | null): Observable<Series[]> {
    var path = `/catalog/series`;
    if (brands && brands.length > 0) {
        path += '?brands=' + brands.join(",");
    }
    return this.http.get<Series[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
      map(response => {
        return response.body!.map(o => this.toSeries(o))
      })
    );
  }

  private toSeries(fetchedSeries: any): Series {
    var series = Object.assign(new Series(), fetchedSeries);
    return series;
  }
}
