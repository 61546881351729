import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import AuthenticationReport from '../models/authentication-report.model';
import Order from '../models/order.model';
import User from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationReportsService {
  constructor(private http: HttpClient) { }

  public getAuthenticationReports(status: string | null, priority: string | null, result: string | null, blocked: boolean | null, pageSize: number, offset: number): Observable<any> {
      var path = `/verification/authentication-reports?pageSize=${pageSize}&offset=${offset}&expand[]=order.listing.model&expand[]=listing.model`;
      if (!!status) {
        path += `&status[]=${status}`;
      }
      if (!!priority) {
        path += `&priority[]=${priority}`;
      }
      if (!!result) {
        path += `&result[]=${result}`;
      }
      if (blocked != null) {
        path += `&blocked=${blocked}`;
      }
      return this.http.get<AuthenticationReport[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
          map(response => {
            return {
              data: response.body!.map(d => this.toAuthenticationReport(d)),
              totalCount:  response.headers.get('X-Total-Count')
            }
          })
        );
  }

  public getAuthenticationReport(authenticationReportId: number) : Observable<any> {
      var path = "/verification/authentication-reports/" + authenticationReportId + "?expand[]=order.listing.model&expand[]=listing.model&expand[]=completedBy";
      return this.http.get<AuthenticationReport>(environment.apiUrl + path, {observe: 'response'}).pipe(
          map(response =>  this.toAuthenticationReport(response.body)));
  }

  public createAuthenticationReport(template: any): Observable<any> {
    var path = "/verification/authentication-reports";
    return this.http.post<AuthenticationReport>(environment.apiUrl + path, template)
        .pipe(map(response => this.toAuthenticationReport(response)));
  }

  public modifyAuthenticationReport(authenticationReportId: number, template: any): Observable<any> {
    var path = "/verification/authentication-reports/" + authenticationReportId;
    return this.http.patch<AuthenticationReport>(environment.apiUrl + path, template)
        .pipe(map(response => this.toAuthenticationReport(response)));
  }

  private toAuthenticationReport(fetchedAuthenticationReport: any): AuthenticationReport {
      var authenticationReport = Object.assign(new AuthenticationReport(), fetchedAuthenticationReport);
      if (authenticationReport.order) {
        authenticationReport.order = Order.toOrder(authenticationReport.order);
      }
      if (authenticationReport.completedBy) {
        authenticationReport.completedBy = Object.assign(new User(), authenticationReport.completedBy);
      }
      return authenticationReport;
    }
}
