<div class="content-container">
  <div class="form-container">
    <form [formGroup]="formGroup">
      <div class="form-section">
        <div class="form-section-title">
          Details
        </div>
        <div class="w-100 text-center">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Discount Code</mat-label>
            <input matInput placeholder="TEST-BEZEL" formControlName="discountCode" required>
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Discount Amount (Cents)</mat-label>
            <input matInput placeholder="10000" formControlName="discountAmountCents" required>
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Minimum Purchase Price (Cents)</mat-label>
            <input matInput placeholder="10000" formControlName="minPurchasePriceCents">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Expiration</mat-label>
            <input matInput [matDatepicker]="picker1" formControlName="expiration">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>   
          <mat-checkbox class="ms-4" formControlName="disabled">Disabled</mat-checkbox>       
        </div>
      </div>
      <div class="form-section">
        <div class="w-100 text-center">
          <button class="w-25" type="submit" [disabled]="!formGroup.valid || submitting" (click)="onSubmit()" [class.spinner]="submitting" mat-raised-button color="primary">Submit</button>
          <button class="w-25" type="button" [disabled]="submitting" (click)="onClear()" mat-stroked-button color="accent" style="margin-left: 10px">Clear</button>
        </div>
      </div>
    </form>
  </div>
</div>
