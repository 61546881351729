import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, of, forkJoin } from 'rxjs';
import { ImageService } from 'src/app/_services/image.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import Brand from 'src/app/models/brand.model';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { BrandsService } from 'src/app/_services/brands.service';

@Component({
  selector: 'app-edit-brand',
  templateUrl: './edit-brand.component.html',
  styleUrls: ['./edit-brand.component.css'],
})
export class EditBrandComponent implements OnInit, OnDestroy, ToolbarController {
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  private _baseTitlePath = { title: "Brands", path: ['/catalog', 'brands'] };
  titlePath = new BehaviorSubject<TitlePathElement[]>([this._baseTitlePath, {title: "?", path: []}]);
  brand: Brand | null = null;
  title = new BehaviorSubject<string>("Edit");

  formGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    displayName: new FormControl(''),
    description: new FormControl(''),
    aliases: new FormControl<string[]>([])
  });

  get formControls() {
    return this.formGroup.controls;
  }

  wordmarkEdited = false;
  wordmark: {
    // Id of the image if it already exists on the brand.
    existingId?: number,
    image: InstanceType<typeof Image>
  } | null = null;

  logoEdited = false;
  logo: {
    // Id of the image if it already exists on the brand.
    existingId?: number,
    image: InstanceType<typeof Image>
  } | null = null;

  cardImageEdited = false;
  cardImage: {
    // Id of the image if it already exists on the brand.
    existingId?: number,
    image: InstanceType<typeof Image>
  } | null = null;

  bannerImageEdited = false;
  bannerImage: {
    // Id of the image if it already exists on the brand.
    existingId?: number,
    image: InstanceType<typeof Image>
  } | null = null;

  heroModelImageEdited = false;
  heroModelImage: {
    // Id of the image if it already exists on the brand.
    existingId?: number,
    image: InstanceType<typeof Image>
  } | null = null;

  submitting: boolean = false;

  private _brandId: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private toolbarService: ToolbarService,
    private brandsService: BrandsService,
    private imageService: ImageService
  ) {
    const routeParams = this.route.snapshot.paramMap;
    this._brandId = Number(routeParams.get('brandId'));
    brandsService.getBrand(this._brandId).subscribe({
      next: (brand: Brand) => {
        this.brand = brand;
        if (brand) {
          this.titlePath.next([this._baseTitlePath, { title: (brand.name), path: ['/catalog', 'brands', brand.id.toString()] }]);
          this.formControls.name.patchValue(brand.name);
          this.formControls.displayName.patchValue(brand.displayName);
          this.formControls.description.patchValue(brand.description);
          this.formControls.aliases.patchValue(brand.aliases);

          if (brand.wordmark) {
            var img = new Image();
            img.src = brand.wordmark.rawUrl;
            this.wordmark = {
              existingId: brand.wordmark.id,
              image: img
            }
          }
          if (brand.logo) {
            var img = new Image();
            img.src = brand.logo.rawUrl;
            this.logo = {
              existingId: brand.logo.id,
              image: img
            }
          }
          if (brand.cardImage) {
            var img = new Image();
            img.src = brand.cardImage.rawUrl;
            this.cardImage = {
              existingId: brand.cardImage.id,
              image: img
            }
          }
          if (brand.bannerImage) {
            var img = new Image();
            img.src = brand.bannerImage.rawUrl;
            this.bannerImage = {
              existingId: brand.bannerImage.id,
              image: img
            }
          }
          if (brand.heroModelImage) {
            var img = new Image();
            img.src = brand.heroModelImage.rawUrl;
            this.heroModelImage = {
              existingId: brand.heroModelImage.id,
              image: img
            }
          }
        }
      },
      error: (error: any) => {
        console.log(error);
        router.navigate(['/catalog', 'brands'])
        // TODO: This may be a different error other than not existing.
        let snackBarRef = this.snackBar.open(`Brand (${this._brandId}) does not exist.`, '', {
          duration: 3000
        });
      }
    });
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  onSelectWordmark(target: EventTarget | null) {
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    var input = target as HTMLInputElement;
    Array.prototype.forEach.call(input.files, file => {
      var img = new Image();
      var reader = new FileReader();
      reader.onload = event => {
        img.src = event.target!.result as string;
      }
      reader.readAsDataURL(file);
      this.wordmark = {image: img}
      this.wordmarkEdited = true
    });
  }

  onRemoveWordmark() {
    this.wordmark = null;
    this.wordmarkEdited = true;
  }

  onSelectLogo(target: EventTarget | null) {
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    var input = target as HTMLInputElement;
    Array.prototype.forEach.call(input.files, file => {
      var img = new Image();
      var reader = new FileReader();
      reader.onload = event => {
        img.src = event.target!.result as string;
      }
      reader.readAsDataURL(file);
      this.logo = {image: img}
      this.logoEdited = true
    });
  }

  onRemoveLogo() {
    this.logo = null;
    this.logoEdited = true;
  }

  onSelectCardImage(target: EventTarget | null) {
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    var input = target as HTMLInputElement;
    Array.prototype.forEach.call(input.files, file => {
      var img = new Image();
      var reader = new FileReader();
      reader.onload = event => {
        img.src = event.target!.result as string;
      }
      reader.readAsDataURL(file);
      this.cardImage = {image: img}
      this.cardImageEdited = true
    });
  }

  onRemoveCardImage() {
    this.cardImage = null;
    this.cardImageEdited = true;
  }

  onSelectBannerImage(target: EventTarget | null) {
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    var input = target as HTMLInputElement;
    Array.prototype.forEach.call(input.files, file => {
      var img = new Image();
      var reader = new FileReader();
      reader.onload = event => {
        img.src = event.target!.result as string;
      }
      reader.readAsDataURL(file);
      this.bannerImage = {image: img}
      this.bannerImageEdited = true
    });
  }

  onRemoveBannerImage() {
    this.bannerImage = null;
    this.bannerImageEdited = true;
  }

  onSelectHeroModelImage(target: EventTarget | null) {
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    var input = target as HTMLInputElement;
    Array.prototype.forEach.call(input.files, file => {
      var img = new Image();
      var reader = new FileReader();
      reader.onload = event => {
        img.src = event.target!.result as string;
      }
      reader.readAsDataURL(file);
      this.heroModelImage = {image: img}
      this.heroModelImageEdited = true
    });
  }

  onRemoveHeroModelImage() {
    this.heroModelImage = null;
    this.heroModelImageEdited = true;
  }

  addAlias(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value && this.formControls.aliases.value != null) {
      this.formControls.aliases.value.push(value);
      this.formControls.aliases.markAsDirty();
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  removeAlias(name: string): void {
    if (this.formControls.aliases.value == null) {
      return;
    }

    const index = this.formControls.aliases.value.indexOf(name);

    if (index >= 0) {
      this.formControls.aliases.value.splice(index, 1);
      this.formControls.aliases.markAsDirty();
    }
  }

  onCancel() {
    this.router.navigate(['/catalog', 'brands', this._brandId]);
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }
    this.submitting = true;

    var changes: any = {};
    if (this.formControls.name.dirty) {
      changes.name = this.formControls.name.value;
    }
    if (this.formControls.displayName.dirty) {
      var displayName = this.formControls.displayName.value;
      changes.displayName = displayName != null && displayName.length > 0 ? displayName : null;
    }
    if (this.formControls.description.dirty) {
      var description = this.formControls.description.value;
      changes.description = description != null && description.length > 0 ? description : null;
    }
    if (this.formControls.aliases.dirty) {
      var aliases = this.formControls.aliases.value;
      changes.aliases = aliases;
    }

    var wordmarkUpload: Observable<number> = of(-1);
    if (this.wordmarkEdited && this.wordmark) {
      wordmarkUpload = this.imageService.uploadImage(this.wordmark.image);
    }
    var logoUpload: Observable<number> = of(-1);
    if (this.logoEdited && this.logo) {
      logoUpload = this.imageService.uploadImage(this.logo.image);
    }

    var cardImageUpload: Observable<number> = of(-1);
    if (this.cardImageEdited && this.cardImage) {
      cardImageUpload = this.imageService.uploadImage(this.cardImage.image);
    }

    var bannerImageUpload: Observable<number> = of(-1);
    if (this.bannerImageEdited && this.bannerImage) {
      bannerImageUpload = this.imageService.uploadImage(this.bannerImage.image);
    }

    var heroModelImageUpload: Observable<number> = of(-1);
    if (this.heroModelImageEdited && this.heroModelImage) {
      heroModelImageUpload = this.imageService.uploadImage(this.heroModelImage.image);
    }

    forkJoin([wordmarkUpload, logoUpload, cardImageUpload, bannerImageUpload, heroModelImageUpload]).subscribe({
      next: imageIds => {
        if (this.wordmarkEdited) {
          changes.wordmark = this.wordmark ? imageIds[0] : null;
        }
        if (this.logoEdited) {
          changes.logo = this.logo ? imageIds[1] : null;
        }
        if (this.cardImageEdited) {
          changes.cardImage = this.cardImage ? imageIds[2] : null;
        }
        if (this.bannerImageEdited) {
          changes.bannerImage = this.bannerImage ? imageIds[3] : null;
        }
        if (this.heroModelImageEdited) {
          changes.heroModelImage = this.heroModelImage ? imageIds[4] : null;
        }

        this.brandsService.updateBrand(this._brandId, changes).subscribe({
          next: () => {
            this.router.navigate(['/catalog', 'brands', this._brandId]);
          },
          error: error => {
            this.submitting = false;
            if (error.status === 403) {
              alert("You don't have the required permission to perform this operation.")
            } else {
              alert(error);
            }
          }
        })
      },
      error: error => {
        // Failed to upload images.
        this.submitting = false;
        if (error.status === 403) {
          alert("You don't have the required permission to perform this operation.")
        } else {
          alert(error);
        }
      }
    });
  }
}
