import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthorizationService, Permissions, Privilege } from 'src/app/_services/authorization.service';
import { BrandsService } from 'src/app/_services/brands.service';
import { TitlePathElement, ToolbarService, ToolbarController } from 'src/app/_services/toolbar.service';
import Brand from 'src/app/models/brand.model';

@Component({
  selector: 'app-brands-detail',
  templateUrl: './brands-detail.component.html',
  styleUrls: ['./brands-detail.component.css']
})
export class BrandsDetailComponent implements OnInit, OnDestroy, ToolbarController {
  brand: Brand | null = null;
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Brands", path: ['/catalog', 'brands'] }]);
  title = new BehaviorSubject<string>("?");

  canEditBrand: boolean;

  deleting = false;

  private _brandId: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private toolbarService: ToolbarService,
    private brandsService: BrandsService,
    private authorizationService: AuthorizationService,
  ) { 
    const routeParams = this.route.snapshot.paramMap;
    this._brandId = Number(routeParams.get('brandId'));
    brandsService.getBrand(this._brandId).subscribe({
      next: (brand: Brand) => {
        this.brand = brand;
        this.title.next(brand.displayName || brand.name);
      },
      error: (error: any) => {        
        console.log(error);
        router.navigate(['/catalog', 'brands'])
        // TODO: This may be a different error other than not existing.
        let snackBarRef = this.snackBar.open(`Brand (${this._brandId}) does not exist.`, '', {
          duration: 3000
        });
      }
    });

    this.canEditBrand = authorizationService.hasPermission(Permissions.CATALOG_ALL, Privilege.WRITE);
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  onEditButtonPressed(): void {
    this.router.navigate(['/catalog', 'brands', this._brandId, 'edit'])
  }

  onDeleteButtonPressed(): void {
    if (this.deleting || !this.brand) {
      return;
    }
    this.deleting = true;

    this.brandsService.deleteBrand(this._brandId).subscribe({
      next: () => {
        this.router.navigate(['/catalog', 'brands'])
      },
      error: error => {
        alert(error);
        console.log(error);
        this.deleting = false;
      }
    })
  }
}
