<div class="container" *ngIf="proxyBid else loading">
  <div class="w100 d-flex flex-column">
    <mat-card class="m-2">
      <mat-card-title>
        <div class="d-flex justify-content-between align-items-center">
          <span>
            General Information
          </span>
          <span class="d-flex" style="gap: 8px">
            <button mat-raised-button color="primary" [routerLink]="['/marketplace/max-bids/', proxyBid.id, 'edit']" [disabled]="proxyBid.status === 'CANCELLED'">Edit</button>
          </span>
        </div>
      </mat-card-title>
        <mat-card-content>
          <div>
            <div class="labeled-box">
              <div class="label">
                Status
              </div>
              <span>{{proxyBid.status}}<ng-container *ngIf="proxyBid.status=='CANCELLED'"> / {{proxyBid.cancellationReason}}</ng-container></span>
            </div>
            <div class="labeled-box">
                <div class="label">
                  Price
                </div>
                <span>{{(proxyBid.maxPriceCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</span>
              </div>
            <div class="labeled-box">
              <div class="label">
                Created
              </div>
              <span>{{ proxyBid.created | date:'medium' }}</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Updated
              </div>
              <span>{{  proxyBid.updated | date:'medium' }}</span>
            </div>
            <br />
            <div class="labeled-box">
              <div class="label">
                Buyer
              </div>
              <span><buyer-chip [buyer]="proxyBid.buyerProfile"></buyer-chip></span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Listing
              </div>
              <span style="display: inline-block; position: relative"><mat-chip-list><listing-chip [listing]="proxyBid.listing"></listing-chip></mat-chip-list></span>
            </div>
        </div>
        </mat-card-content>
        <mat-card-footer class="p-2 pt-0">

        </mat-card-footer>
      </mat-card>
      <mat-card class="m-2" *ngIf="proxyBid.transaction">
        <mat-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <span>
              Credit Card Hold
            </span>
            <span>
              <button class="leaveButton" *ngIf="proxyBid.transaction.stripeDashboardLink" (click)="onViewPaymentInStripe()" mat-stroked-button>
                View in Stripe
                <mat-icon iconPositionEnd>exit_to_app</mat-icon>
              </button>
            </span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div>
            <div class="labeled-box">
              <div class="label">
                Total
              </div>
              <span>{{(proxyBid.transaction.baseAmountCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Credits Applied
              </div>
              <span>{{proxyBid.transaction.creditsApplied}} ({{(proxyBid.transaction.creditsApplied/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}})</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Amount Due
              </div>
              <span>{{(proxyBid.transaction.paymentAmountCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Status
              </div>
              <span>{{proxyBid.transaction.status}}</span>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <history-button [type]="'proxyBid'" [resourceId]="_proxyBidId" class="m-2"></history-button>
  </div>
</div>
<ng-template #loading>
  <div class="d-flex flex-column align-items-center m-5">
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
  </div>
</ng-template>
