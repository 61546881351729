<ng-container *ngIf="discount else loading">
  <div class="container">
    <div class="section">
      <div class="section-title">
        Details
      </div>
      <div class="section-content">
        <div class="labeled-box">
          <div class="label">
            DiscountCode
          </div>
          {{ discount.discountCode }}
        </div>
        <div class="labeled-box">
          <div class="label">
            Discount Amount
          </div>
          ${{ discount.discountAmountCents / 100 }}
        </div>
        <div class="labeled-box" *ngIf="discount.minPurchasePriceCents">
          <div class="label">
            Minimum Purchase Price
          </div>
          ${{ discount.minPurchasePriceCents / 100 }}
        </div>
        <div class="labeled-box" *ngIf="discount.disabled">
          <div class="label">
            Disabled
          </div>
          {{ discount.disabled }}
        </div>
        <div class="labeled-box" *ngIf="discount.expiration">
          <div class="label">
            Expiration
          </div>
          {{ discount.expiration }}
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section-title">
        Actions
      </div>
      <div class="section-content">
        <button mat-raised-button color="primary" [disabled]="deleting || !canEditDiscount" (click)="onEditButtonPressed()" style="margin-right: 10px">Edit</button>
        <button mat-raised-button color="warn" [disabled]="deleting || !canEditDiscount" (click)="onDeleteButtonPressed()" [class.spinner]="deleting">Delete</button>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
</ng-template>
