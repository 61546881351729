<table mat-table [dataSource]="bids" class="w-100">
    <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> # </th>
        <td mat-cell *matCellDef="let element; let i = index">{{ bids.length - i }}</td>
      </ng-container>
    <ng-container matColumnDef="bidder">
        <th mat-header-cell *matHeaderCellDef>Bidder</th>
        <td mat-cell *matCellDef="let bid">
            <mat-chip-list><buyer-chip [buyer]="bid.buyerProfile"></buyer-chip></mat-chip-list>
        </td>
    </ng-container>
    <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef>Price</th>
        <td mat-cell *matCellDef="let bid"> ${{bid.priceCents / 100}} </td>
    </ng-container>
    <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef>Created</th>
        <td mat-cell *matCellDef="let bid">{{bid.created | date:'medium'}}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let bid; columns: displayedColumns;">
</table>