import { E } from '@angular/cdk/keycodes';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, debounceTime, distinctUntilChanged, pluck } from 'rxjs';
import { BrandsService } from 'src/app/_services/brands.service';
import { CatalogService } from 'src/app/_services/catalog.service';
import { DiagnosticReportsService } from 'src/app/_services/diagnostic-reports.service';
import { ListingsService } from 'src/app/_services/listings.service';
import { OrdersService } from 'src/app/_services/orders.service';
import { ReturnsService } from 'src/app/_services/returns.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { VerificationReportsService } from 'src/app/_services/verification-reports.service';
import Brand from 'src/app/models/brand.model';
import DiagnosticReport, { DIAGNOSTIC_REPORT_PRIORITIES, DIAGNOSTIC_REPORT_PRIORITY_DESCRIPTIONS, DIAGNOSTIC_REPORT_RESULTS, DIAGNOSTIC_REPORT_RESULT_DESCRIPTIONS } from 'src/app/models/diagnostic-report.model';
import Listing from 'src/app/models/listing.model';
import Order from 'src/app/models/order.model';
import VerificationReport from 'src/app/models/verification-report.model';

@Component({
  selector: 'app-diagnostic-report-detail',
  templateUrl: './diagnostic-report-detail.component.html',
  styleUrls: ['./diagnostic-report-detail.component.css']
})
export class DiagnosticReportDetailComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Diagnostic Reports", path: ['/verification', 'diagnostic-reports'] }]);
  title = new BehaviorSubject<string>("?");

  diagnosticReport!: DiagnosticReport | null;
  _diagnosticReportId: number | null = null;

  verificationReport!: VerificationReport | null;

  diagnosticReportPriorities: object;
  diagnosticReportPriorityDescriptions: object;
  diagnosticReportResults: object;
  diagnosticReportResultDescriptions: object;

  isPerformingAction = () => {
    return this.isSaving
        || this.isFinalizing
        || this.isCancelling
  }
  isSaving = false
  isFinalizing = false
  isCancelling = false

  referenceTypeControl = new FormControl<string | null>(null);
  referenceIdControl = new FormControl<string | null>(null);
  brandControl = new FormControl<number | null>(null);
  referenceNumberControl = new FormControl<string | null>(null);
  diagnosticReasonControl = new FormControl<string | null>(null);
  describedConditionControl = new FormControl<string | null>(null);
  watchAsDescribedControl = new FormControl<string | null>(null);
  conditionNotesControl = new FormControl<string | null>(null);
  watchRunningControl = new FormControl<string | null>(null);
  timingPerformancePassedControl = new FormControl<string | null>(null);
  factoryPressurePassedControl = new FormControl<string | null>(null);
  allFunctionsPassedControl = new FormControl<string | null>(null);
  serviceEngravingsControl = new FormControl<string | null>(null);
  serviceRecommendedControl = new FormControl<string | null>(null);
  diagnosticNotesControl = new FormControl<string | null>(null);
  resultControl = new FormControl<string | null>(null);
  formGroup = new FormGroup({
    referenceType: this.referenceTypeControl,
    referenceId: this.referenceIdControl,
    brand: this.brandControl,
    referenceNumber: this.referenceNumberControl,
    diagnosticReason: this.diagnosticReasonControl,
    describedCondition: this.describedConditionControl,
    watchAsDescribed: this.watchAsDescribedControl,
    conditionNotesControl: this.conditionNotesControl,
    watchRunning: this.watchRunningControl,
    timingPerformancePassed: this.timingPerformancePassedControl,
    factoryPressurePassed: this.factoryPressurePassedControl,
    allFunctionsPassed: this.allFunctionsPassedControl,
    serviceEngravings: this.serviceEngravingsControl,
    serviceRecommended: this.serviceRecommendedControl,
    diagnosticNotes: this.diagnosticNotesControl,
    result: this.resultControl,
  });

  allBrands: Brand[] = []

  referencedOrder: Order | null = null;
  referencedListing: Listing | null = null

  constructor(
    private toolbarService: ToolbarService,
    private route: ActivatedRoute,
    private diagnosticReportsService: DiagnosticReportsService,
    private verificationReportsService: VerificationReportsService,
    private catalogService: CatalogService,
    private brandsService: BrandsService,
    private ordersService: OrdersService,
    private listingsService: ListingsService,
    private returnsService: ReturnsService,
    private router: Router,
    private dialog: MatDialog
    ) {
      this.diagnosticReportPriorities = DIAGNOSTIC_REPORT_PRIORITIES;
      this.diagnosticReportPriorityDescriptions = DIAGNOSTIC_REPORT_PRIORITY_DESCRIPTIONS;
      this.diagnosticReportResults = DIAGNOSTIC_REPORT_RESULTS;
      this.diagnosticReportResultDescriptions = DIAGNOSTIC_REPORT_RESULT_DESCRIPTIONS;
      
      this.route.params.pipe(
        pluck("diagnosticReportId")
      ).subscribe({
        next: diagnosticReportId => {
          if (this._diagnosticReportId == diagnosticReportId) {
            return;
          }
  
          this._diagnosticReportId = diagnosticReportId;
          this.title.next(diagnosticReportId.toString());
          this.reloadDiagnosticReport();
        }
      })

      brandsService.getBrands().subscribe({
        next: brands => {
          this.allBrands = brands.sort((first,second) => first.displayName!.localeCompare(second.displayName!))
        }
      })

      this.referenceTypeControl.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe({
        next: value => {
            this.tryLoadingReferences()
        }
      })
      this.referenceIdControl.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe({
        next: value => {
            this.tryLoadingReferences()
        }
      })
    }

    private reloadDiagnosticReport() {
      this.diagnosticReport = null;
      this.diagnosticReportsService.getDiagnosticReport(this._diagnosticReportId!).subscribe({
        next: (diagnosticReport: DiagnosticReport) => {
          this.diagnosticReport = diagnosticReport;

          if (this.diagnosticReport.status == 'PENDING') {
            this.formGroup.enable()
          } else {
            this.formGroup.disable()
          }    

          this.referenceTypeControl.setValue(diagnosticReport.formData.referenceType)
          this.referenceIdControl.setValue(diagnosticReport.formData.referenceId)
          this.brandControl.setValue(diagnosticReport.formData.modelBrandId)
          this.referenceNumberControl.setValue(diagnosticReport.formData.modelReferenceNumber)
          this.diagnosticReasonControl.setValue(diagnosticReport.formData.diagnosticReason);
          this.describedConditionControl.setValue(diagnosticReport.formData.describedCondition);
          this.watchAsDescribedControl.setValue(diagnosticReport.formData.watchAsDescribed);
          this.conditionNotesControl.setValue(diagnosticReport.formData.conditionNotes);
          this.watchRunningControl.setValue(diagnosticReport.formData.watchRunning);
          this.timingPerformancePassedControl.setValue(diagnosticReport.formData.timingPerformancePassed);
          this.factoryPressurePassedControl.setValue(diagnosticReport.formData.factoryPressurePassed);
          this.allFunctionsPassedControl.setValue(diagnosticReport.formData.allFunctionsPassed);
          this.serviceEngravingsControl.setValue(diagnosticReport.formData.hasServiceEngravings);
          this.serviceRecommendedControl.setValue(diagnosticReport.formData.serviceRecommended);
          this.diagnosticNotesControl.setValue(diagnosticReport.formData.diagnosticNotes);

          this.resultControl.setValue(diagnosticReport.result);

          if (this.diagnosticReport.order) {
            this.referenceTypeControl.setValue('ORDER')
            this.referenceIdControl.setValue(String(diagnosticReport.order.id))
            this.referenceTypeControl.disable()
            this.referenceIdControl.disable()
            this.referencedOrder = diagnosticReport.order
            this.referencedListing = diagnosticReport.order.listing
          } else if (this.diagnosticReport.listing) {
            this.referenceTypeControl.setValue('LISTING')
            this.referenceIdControl.setValue(String(diagnosticReport.listing.id))
            this.referenceTypeControl.disable()
            this.referenceIdControl.disable()
            this.referencedOrder = null
            this.referencedListing = diagnosticReport.listing
          } else {
            this.referenceTypeControl.setValue(diagnosticReport.formData.referenceType)
            this.referenceIdControl.setValue(diagnosticReport.formData.referenceId)
          }
          this.tryLoadingReferences()
          this.handleReferencesChanged()

          this.formGroup.markAsPristine()

          this.reloadVerificationReport()
        },
        error: (error: any) => {
          console.log(error);
        }
      });
    }

    private reloadVerificationReport() {
      this.verificationReportsService.getVerificationReports(null, null, null, this._diagnosticReportId, null, null, 1, 0).subscribe({
        next: (response: any) => {
          this.verificationReport = response.data.length > 0 ? response.data[0] : null;
        },
        error: (error: any) => {
          console.log(error);
          this.verificationReport = null
        }
      });
    }

    tryLoadingReferences(): void {
      if (this.diagnosticReport?.order || this.diagnosticReport?.listing) {
        // Don't allow overriding the order or listing references if linked to report
        return
      }

      if (this.referenceIdControl.value && this.referenceIdControl.value.length > 0) {
        if (this.referenceTypeControl.value == 'LISTING') {
          this.listingsService.getListing(Number(this.referenceIdControl.value)).subscribe({
            next: listing => {
              this.referencedOrder = null
              this.referencedListing = listing;
              this.handleReferencesChanged();
            }, 
            error: error => {
              console.log(error)
              this.referencedOrder = null;
              this.referencedListing = null;
            }
          })
        } else if (this.referenceTypeControl.value == 'ORDER') {
          this.ordersService.getOrder(Number(this.referenceIdControl.value)).subscribe({
            next: order => {
              console.log(order)
              this.referencedOrder = order
              this.referencedListing = order.listing;
              this.handleReferencesChanged();
            }, 
            error: error => {
              console.log(error)
              this.referencedOrder = null;
              this.referencedListing = null;
            }
          })
        } else if (this.referenceTypeControl.value == 'RETURN') {
          this.returnsService.getReturn(Number(this.referenceIdControl.value)).subscribe({
            next: r => {
              this.referencedOrder = r.order
              this.referencedListing = r.order.listing;
              this.handleReferencesChanged();
            }, 
            error: error => {
              console.log(error)
              this.referencedOrder = null;
              this.referencedListing = null;
            }
          })
        } else {
          this.referencedOrder = null;
          this.referencedListing = null;
          this.handleReferencesChanged();
        }
      } else {
        this.referencedOrder = null;
        this.referencedListing = null;
        this.handleReferencesChanged();
      }
    }

    private handleReferencesChanged(): void {
      if (this.referencedOrder) {
        this.brandControl.setValue(this.referencedOrder.listing.model.brand.id)
        this.brandControl.disable()
        this.referenceNumberControl.setValue(this.referencedOrder.listing.model.referenceNumber)
        this.referenceNumberControl.disable()
        this.diagnosticReasonControl.setValue('ORDER')
        this.diagnosticReasonControl.disable()
        this.describedConditionControl.setValue(this.referencedOrder.listing.condition)
        this.describedConditionControl.disable()
      } else if (this.referencedListing) {
        this.brandControl.setValue(this.referencedListing.model.brand.id)
        this.brandControl.disable()
        this.referenceNumberControl.setValue(this.referencedListing.model.referenceNumber)
        this.referenceNumberControl.disable()
        if (this.referencedListing.activePricingModel == 'AUCTION') {
          this.diagnosticReasonControl.setValue('AUCTION')
          this.diagnosticReasonControl.disable()
        }
        this.describedConditionControl.setValue(this.referencedListing.condition)
        this.describedConditionControl.disable()
      } else if (this.formGroup.enabled) {
        this.referenceTypeControl.enable()
        this.referenceIdControl.enable()
        this.brandControl.enable()
        this.referenceNumberControl.enable()
        this.diagnosticReasonControl.enable()
        this.describedConditionControl.enable()
      }
    }
    
    ngOnInit(): void {
      this.toolbarService.setController(this);
    }
    
    ngOnDestroy(): void {
      this.toolbarService.removeController(this);
    }

    onSelectNewPriority(newPriority: string): void {
      var originalPriority = this.diagnosticReport!.priority
      this.diagnosticReport!.priority = newPriority
      this.diagnosticReportsService.modifyDiagnosticReport(this._diagnosticReportId!, {
        priority: newPriority
      }).subscribe({
        next: () => {
          // Success!
        },
        error: (error: any) => {
          console.log(error);
          this.diagnosticReport!.priority = originalPriority
        }
      });
    }

    markCancelled(): void {
      this.isCancelling = true
      this.diagnosticReportsService.modifyDiagnosticReport(this._diagnosticReportId!, {status: 'CANCELLED'}).subscribe({
        next: () => {
          this.reloadDiagnosticReport()
          this.isCancelling = false
        },
        error: (error: any) => {
          console.log(error);
          this.isCancelling = false
        }
      });
    }
    
    saveDetails(): void {
      this.isSaving = true
      this.diagnosticReportsService.modifyDiagnosticReport(this._diagnosticReportId!, {
        result: this.resultControl.dirty ? this.resultControl.value : undefined,
        formData: {
          referenceType: this.referenceTypeControl.dirty ? this.referenceTypeControl.value : undefined,
          referenceId: this.referenceIdControl.dirty ? this.referenceIdControl.value : undefined,
          modelBrandId: this.brandControl.value ? this.brandControl.value : null,
          modelReferenceNumber: this.referenceNumberControl.value ? this.referenceNumberControl.value : null,
          diagnosticReason: this.diagnosticReasonControl.value ? this.diagnosticReasonControl.value : null,
          describedCondition: this.describedConditionControl.value ? this.describedConditionControl.value : null,
          watchAsDescribed: this.watchAsDescribedControl.value ? this.watchAsDescribedControl.value : null,
          conditionNotes: this.conditionNotesControl.value ? this.conditionNotesControl.value : null,
          watchRunning: this.watchRunningControl.value ? this.watchRunningControl.value : null,
          timingPerformancePassed: this.timingPerformancePassedControl.value ? this.timingPerformancePassedControl.value : null,
          factoryPressurePassed: this.factoryPressurePassedControl.value ? this.factoryPressurePassedControl.value : null,
          allFunctionsPassed: this.allFunctionsPassedControl.value ? this.allFunctionsPassedControl.value : null,
          hasServiceEngravings: this.serviceEngravingsControl.value ? this.serviceEngravingsControl.value : null,
          serviceRecommended: this.serviceRecommendedControl.value ? this.serviceRecommendedControl.value : null,
          diagnosticNotes: this.diagnosticNotesControl.value ? this.diagnosticNotesControl.value : null,
        },
      }).subscribe({
        next: () => {
          this.reloadDiagnosticReport()
          this.isSaving = false
        },
        error: (error: any) => {
          console.log(error);
          this.isSaving = false
        }
      });
    }

    markComplete(): void {
      this.isFinalizing = true
      this.diagnosticReportsService.modifyDiagnosticReport(this._diagnosticReportId!, {status: 'COMPLETE'}).subscribe({
        next: () => {
          this.reloadDiagnosticReport()
          this.isFinalizing = false
        },
        error: (error: any) => {
          console.log(error);
          this.isFinalizing = false
        }
      });
    }
}
