<div *ngIf="(sellers | async)?.length > 0; else spinner" class="d-flex flex-column h-100">
  <mat-card class="m-2">
    <mat-card-content class="d-flex flex-md-row flex-column">
        <mat-form-field appearance="outline" class="flex-fill mx-1">
            <mat-label>Type</mat-label>
            <mat-select [(ngModel)]='typeFilter' name='Type' (selectionChange)="onChangeTypeFilter($event)">
                <mat-option [value]="">-</mat-option>
                <mat-option *ngFor="let type of allTypes" [value]="type">{{type}}</mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card-content>
  </mat-card>
  <mat-card class="mx-2 mb-2 p-0 h-100 d-flex flex-column overflow-scroll">
    <div class="flex-fill overflow-scroll">
      <table mat-table [dataSource]="dataSource" class="w-100">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> Id </th>
          <td mat-cell *matCellDef="let seller"> {{seller.id}} </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let seller"> {{seller.user.givenName}} {{seller.user.familyName}} </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let seller"> {{seller.user.email}} </td>
        </ng-container>
        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by created"> Created </th>
          <td mat-cell *matCellDef="let seller"> {{seller.created | date : 'medium' }} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let seller; columns: displayedColumns;" [routerLink]="['/marketplace/sellers/', seller.id]">
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[20]"
                    pageSize="20"
                    showFirstLastButtons
                    (page)="onPageChanged($event)"
                    aria-label="Select page">
    </mat-paginator>
  </mat-card>
</div>
<ng-template #spinner>
  <mat-card class="m-2 d-flex flex-column align-items-center">
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
  </mat-card> 
</ng-template>
    