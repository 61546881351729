<div class="container" *ngIf="brand; else spinner">
  <mat-card class="m-2">
    <mat-card-title>Edit Brand</mat-card-title>
    <mat-card-content>
      <h2>General Information</h2>
      <div class="m-2 d-flex flex-column" style="gap: 10px">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Patek Philippe SA" [formControl]="formControls.name" required="true">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Display Name</mat-label>
          <input matInput placeholder="Patek Philippe" [formControl]="formControls.displayName">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <textarea matInput placeholder="Patek Philippe is a brand." [formControl]="formControls.description" [maxLength]="1024" style="min-height: 120px;"></textarea>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-chip-grid #aliasChipList>
              <mat-chip *ngFor="let alias of formControls.aliases.value" (removed)="removeAlias(alias)">
                  {{ alias }}
                  <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                placeholder="Add alias..."
                [matChipInputFor]="aliasChipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="addAlias($event)">
            </mat-chip-grid>
        </mat-form-field>
      </div>
      <h2 class="mt-4">Images</h2>
      <div class="m-2 d-flex flex-wrap" style="gap: 20px; margin-bottom: 32px !important;">
        <div>
          <h5 class="mb-0">Wordmark</h5>
          <div class="image-grid">
            <div class="image-grid-item" *ngIf="wordmark">
              <image-view [image]="wordmark.image" [removable]="!submitting" (remove)="onRemoveWordmark()"></image-view>
            </div>
            <div class="image-grid-item" *ngIf="!wordmark">
                <button mat-stroked-button type="button" color="primary" (click)="wordmarkUploader.click()" class="add-image-button">
                    <mat-icon>add</mat-icon>
                </button>
                <input hidden type="file" accept=".png,.jpg,.jpeg" (change)="onSelectWordmark($event.target)" #wordmarkUploader>
            </div>
          </div>
        </div>
        <div>
          <h5 class="mb-0">Logo</h5>
          <div class="image-grid">
            <div class="image-grid-item" *ngIf="logo">
              <image-view [image]="logo.image" [removable]="!submitting" (remove)="onRemoveLogo()"></image-view>
            </div>
            <div class="image-grid-item" *ngIf="!logo">
                <button mat-stroked-button type="button" color="primary" (click)="logoUploader.click()" class="add-image-button">
                    <mat-icon>add</mat-icon>
                </button>
                <input hidden type="file" accept=".png,.jpg,.jpeg" (change)="onSelectLogo($event.target)" #logoUploader>
            </div>
          </div>
        </div>
        <div>
          <h5 class="mb-0">Card Image</h5>
          <div class="image-grid">
            <div class="image-grid-item" *ngIf="cardImage">
              <image-view [image]="cardImage.image" [removable]="!submitting" (remove)="onRemoveCardImage()"></image-view>
            </div>
            <div class="image-grid-item" *ngIf="!cardImage">
              <button mat-stroked-button type="button" color="primary" (click)="cardImageUploader.click()" class="add-image-button">
                <mat-icon>add</mat-icon>
              </button>
              <input hidden type="file" accept=".png,.jpg,.jpeg" (change)="onSelectCardImage($event.target)" #cardImageUploader>
            </div>
          </div>
        </div>
        <div>
          <h5 class="mb-0">Banner Image</h5>
          <div class="image-grid">
            <div class="image-grid-item" *ngIf="bannerImage">
              <image-view [image]="bannerImage.image" [removable]="!submitting" (remove)="onRemoveBannerImage()"></image-view>
            </div>
            <div class="image-grid-item" *ngIf="!bannerImage">
              <button mat-stroked-button type="button" color="primary" (click)="bannerImageUploader.click()" class="add-image-button">
                <mat-icon>add</mat-icon>
              </button>
              <input hidden type="file" accept=".png,.jpg,.jpeg" (change)="onSelectBannerImage($event.target)" #bannerImageUploader>
            </div>
          </div>
        </div>
        <div>
          <h5 class="mb-0">Hero Model (cluster) Image</h5>
          <div class="image-grid">
            <div class="image-grid-item" *ngIf="heroModelImage">
              <image-view [image]="heroModelImage.image" [removable]="!submitting" (remove)="onRemoveHeroModelImage()"></image-view>
            </div>
            <div class="image-grid-item" *ngIf="!heroModelImage">
              <button mat-stroked-button type="button" color="primary" (click)="heroModelImageUploader.click()" class="add-image-button">
                <mat-icon>add</mat-icon>
              </button>
              <input hidden type="file" accept=".png,.jpg,.jpeg" (change)="onSelectHeroModelImage($event.target)" #heroModelImageUploader>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-footer class="m-3 d-flex justify-content-between">
      <div>
        <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
      </div>
      <div class="d-flex" style="gap: 10px">
        <button type="submit" [disabled]="!formGroup.valid || (!formGroup.dirty && !wordmarkEdited && !logoEdited && !cardImageEdited && !bannerImageEdited && !heroModelImageEdited) || submitting" (click)="onSubmit()"
        [class.spinner]="submitting" mat-raised-button color="primary">Save</button>
      </div>
    </mat-card-footer>
  </mat-card>
</div>
<ng-template #spinner>
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
</ng-template>
