<table mat-table [dataSource]="complications">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> Id </th>
      <td mat-cell *matCellDef="let complication"> {{complication.id}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let complication"> {{complication.name}} </td>
    </ng-container>
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let complication"> {{complication.description}} </td>
      </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell  *matHeaderCellDef >  </th>
      <td mat-cell *matCellDef="let complication" style="text-align: right;">
           <button mat-icon-button (click)="onClickDelete(complication)" *ngIf="showDeleteComplicationButton"><mat-icon>delete</mat-icon></button>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <span *ngIf="refreshingComplications" class="loading-spinner-container">
    <mat-spinner [diameter]="30" class="loading-spinner"></mat-spinner>
  </span>
  <button mat-fab class="fab" (click)="onAddComplicationButtonPressed()" *ngIf="showAddComplicationButton"><mat-icon>add</mat-icon></button>
  