<h2 mat-dialog-title>Change History</h2>
<mat-dialog-content>
            Initial State: {{data.initialState.timestamp}}
            <pre>{{parsedBase}}</pre>
            <div *ngFor="let update of data.revisions">
                Update: {{update.timestamp}} <br>
                <li *ngFor="let change of update.changes">
                    {{change}}
                </li>
            </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" mat-dialog-close>Close</button>
</mat-dialog-actions>