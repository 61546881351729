<div class="container" *ngIf="!loading; else spinner">
    <mat-card class="m-2">
        <mat-card-title>
            <ng-container *ngIf="!editing">Create</ng-container><ng-container *ngIf="editing">Edit</ng-container> Listing Request
        </mat-card-title>
        <mat-card-content>
            <div class="d-flex flex-column" style="gap: 16px">
                <seller-select [value]="selectedSeller.value" (onSelect)="onSelectSeller($event)" class="flex-fill" required [disabled]="editing"></seller-select>
                <mat-form-field appearance="outline" class="flex-fill">
                    <mat-label>URL</mat-label>
                    <input matInput type="string" placeholder="https://abc.xyz/listings/1234" [formControl]="externalUrl" required>
                </mat-form-field>
                <mat-form-field appearance="outline" class="flex-fill">
                    <mat-label>Notes</mat-label>
                    <textarea matInput [formControl]="notes" [maxLength]="1024"></textarea>
                    <mat-hint align="end">{{notes.value?.length || 0}}/1024</mat-hint>
                  </mat-form-field>
            </div>
        </mat-card-content>
        <mat-card-actions class="m-2 d-flex justify-content-between">
            <button mat-raised-button color="warn" (click)="onCancel()" [disabled]="submitting">Cancel</button>
            <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!formGroup.valid || submitting || !formGroup.dirty" [class.spinner]="submitting">
                <ng-container *ngIf="!editing">Submit</ng-container><ng-container *ngIf="editing">Save</ng-container>
            </button>
        </mat-card-actions>
    </mat-card>
</div>
<ng-template #spinner>
    <mat-card class="m-2 d-flex flex-column align-items-center">
      <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
    </mat-card> 
</ng-template>
