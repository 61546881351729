import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private static SIDEBAR_SETTING_KEY = 'sidebar_open';

  public sidebarOpen: BehaviorSubject<boolean>;

  constructor() {
    this.sidebarOpen = new BehaviorSubject<boolean>(this.fetchBooleanSetting(SettingsService.SIDEBAR_SETTING_KEY, true));
  }

  public setSidebarOpenSetting(value: boolean) {
    this.storeBooleanSetting(SettingsService.SIDEBAR_SETTING_KEY, value);
    this.sidebarOpen.next(value);
  }

  private fetchBooleanSetting(name: string, _default: boolean = false): boolean {
    const key = this.getSettingStorageKey(name);
    const storedValue = localStorage.getItem(key);
    if (storedValue == null || storedValue.length != 1) {
      return _default;
    }
    return storedValue === '1';
  }

  private storeBooleanSetting(name: string, value: boolean) {
    const key = this.getSettingStorageKey(name);
    localStorage.setItem(key, value ? '1' : '0');
  }

  private getSettingStorageKey(name: string) {
    return 'setting:' + name;
  }
}
