<div class="container" *ngIf="billingAccount else loading">
    <mat-card class="m-2">
        <mat-card-title>
            <div class="d-flex justify-content-between align-items-center">
                <span>Billing Account Information</span>
                <span>
                    <button mat-raised-button color="primary" (click)="adjustCreditBalanceButtonPressed()"
                        [disabled]="!canAdjustCreditBalance()">Adjust Credit Balance</button>
                </span>
            </div>
        </mat-card-title>
        <mat-card-content>
            <div class="labeled-box">
                <div class="label">
                    Owner
                </div>
                <span>{{ billingAccount.owner.fullName }} ({{ billingAccount.owner.email }})</span>
            </div>
            <div class="labeled-box">
                <div class="label">
                    Credits
                </div>
                <span>{{ billingAccount.creditBalance }} ({{ (billingAccount.creditBalance /
                    100).toLocaleString("en-US", {style:"currency", currency:"USD"}) }})</span>
            </div>
            <div class="labeled-box">
                <div class="label">
                    Created
                </div>
                <span>{{ billingAccount.created | date: 'medium'}}</span>
            </div>
            <div class="labeled-box">
                <div class="label">
                    Updated
                </div>
                <span>{{ billingAccount.updated | date: 'medium'}}</span>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<ng-template #loading>
    <div class="d-flex flex-column align-items-center m-5">
        <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
    </div>
</ng-template>