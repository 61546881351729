import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import Return from 'src/app/models/return.model';
import Shipment from '../models/shipment.model';
import Order from '../models/order.model';

@Injectable({
  providedIn: 'root'
})
export class ReturnsService {
  constructor(private http: HttpClient) { }

  public getReturns(status: string | null, buyer: number | null, order: number | null, flagged: boolean | null, sortKey: string | null, pageSize: number, offset: number): Observable<any> {
    var path = `/marketplace/returns?offset=${offset}&pageSize=${pageSize}&expand[]=order.buyerProfile.user&expand[]=order.listing.model`;
    if (!!status) {
      path += `&status[]=${status}`;
    }
    if (!!buyer) {
      path += `&buyerProfile[]=${buyer}`;
    }
    if (!!order) {
      path += `&order[]=${order}`;
    }
    if (!!flagged) {
      path += `&flagged=${flagged}`;
    }
    if (!!sortKey) {
      path += `&sort=${sortKey}`
    }
    return this.http.get<Return[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
      map(response => {
        return {
          data: response.body!.map(r => this.toReturn(r)),
          totalCount:  response.headers.get('X-Total-Count')
        }
      })
    );
  }

  public createReturn(params: any): Observable<Return> {
    var path = `/marketplace/returns`;
    return this.http.post<Return>(environment.apiUrl + path, params);
  }

  public getReturn(returnId: number): Observable<Return> {
    var path = `/marketplace/returns/${returnId}?`;
    path += 'expand[]=shipment&';
    path += 'expand[]=order.buyerProfile.user&';
    path += 'expand[]=order.listing.model&'
    path += 'expand[]=order.invoice&';
    path += 'expand[]=refund.transaction';

    return this.http.get<Return>(environment.apiUrl + path).pipe(
      map(r => this.toReturn(r))
    );
  }

  public updateReturn(returnId: number, params: any): Observable<Return> {
    return this.http.patch<Return>(environment.apiUrl + `/marketplace/returns/${returnId}`, params);
  }

  public acceptReturn(returnId: number): Observable<Return> {
    return this.http.post<Return>(environment.apiUrl + `/marketplace/returns/${returnId}/accept`, {});
  }

  public rejectReturn(returnId: number): Observable<Return> {
    return this.http.post<Return>(environment.apiUrl + `/marketplace/returns/${returnId}/reject`, {});
  }

  public cancelReturn(returnId: number): Observable<Return> {
    return this.http.post<Return>(environment.apiUrl + `/marketplace/returns/${returnId}/cancel`, {});
  }

  private toReturn(r: any): Return {
    var r = Object.assign(new Return(), r)
    if (r.order) {
        r.order = Order.toOrder(r.order)
    }
    if (r.shipment) {
        r.shipment = Object.assign(new Shipment(), r.shipment)
    }
    return r
  }
}
