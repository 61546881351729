import Listing from './listing.model';
import BuyerProfile from './buyer-profile.model';
import Transaction from './transaction.model';

export default class ProxyBid {
  id!: number;
  listing!: Listing;
  buyerProfile!: BuyerProfile;
  transaction!: Transaction;
  maxPriceCents!: number;
  status!: string;
  cancellationReason!: string | null;
  client!: string | null;
  created!: Date;
  updated!: Date;
}

export const PROXY_BID_STATUSES = [
    'AWAITING_PAYMENT',
    'ACTIVE',
    'EXHAUSTED',
    'CANCELLED'
  ]
  
export const PROXY_BID_STATUS_DESCRIPTIONS: {[key: string]: string } = {
    'AWAITING_PAYMENT': 'Waiting for payment',
    'ACTIVE': 'Active',
    'EXHAUSTED': 'Exhausted',
    'CANCELLED': 'Cancelled'
}
