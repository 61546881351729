<div class="container" *ngIf="offer else loading">
  <div class="w100 d-flex flex-row justify-content-center">
    <div style="width: 100%; max-width: 800px;">
      <mat-card class="m-2">
        <mat-card-title>
          <span class="d-flex justify-content-between align-items-center">
            <span class="d-flex justify-content-between align-items-center" style="gap: 10px">
              General Information
              <mat-chip-list>
                <mat-chip *ngIf="offer.client" [routerLink]="['/marketplace/offers']" [queryParams]="{ client: offer.client }" style="font-size: 9pt !important">{{ clientNames[offer.client] }}</mat-chip>
              </mat-chip-list>
            </span>
            <span>
              <div class="badge-narrow">
                Version {{offer.apiVersion}}
              </div>
            </span>
          </span>
        </mat-card-title>
        <mat-card-content>
          <div>
            <div class="labeled-box">
              <div class="label">
                Status
              </div>
              <span>{{offer.status}}<ng-container *ngIf="offer.status=='CANCELLED'"> / {{offer.cancellationReason}}</ng-container></span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Created
              </div>
              <span>{{offer.displayCreated}}</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Updated
              </div>
              <span>{{offer.displayUpdated}}</span>
            </div>
            <div class="labeled-box" *ngIf="offer.expiration">
              <div class="label">
                Expiration
              </div>
              <span>{{offer.displayExpiration}}</span>
            </div>
            <br />
            <div class="labeled-box">
              <div class="label">
                Buyer
              </div>
              <span><buyer-chip [buyer]="offer.buyerProfile"></buyer-chip></span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Buyer History
              </div>
              <span>{{offer.buyerProfile.totalPurchaseCount}} previous purchase{{offer.buyerProfile.totalPurchaseCount == 1 ? "" : "s"}}</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Listing
              </div>
              <span style="display: inline-block; position: relative"><mat-chip-list><listing-chip [listing]="offer.listing"></listing-chip></mat-chip-list></span>
            </div>
        </div>
        </mat-card-content>
        <mat-card-footer class="p-2 pt-0">
          <button mat-raised-button color="primary" class="ms-2 mb-2" (click)="extendDeadline()" [disabled]="isPerformingAction() || !offer.expiration" [class.spinner]="isExtendingDeadline">Extend Expiration Deadline</button>
          <button mat-raised-button color="warn" class="ms-2 mb-2" [disabled]="isPerformingAction() || !offer.canManuallyCancel" [class.spinner]="isCancelling" [matMenuTriggerFor]="cancelButtonMenu">Cancel</button>
          <mat-menu #cancelButtonMenu="matMenu">
            <button mat-menu-item (click)="cancel(false)" >Cancel and refund hold</button>
            <button mat-menu-item (click)="cancel(true)" [disabled]="offer.status === 'AWAITING_PAYMENT'">Cancel and keep funds</button>
          </mat-menu>
        </mat-card-footer>
      </mat-card>
      <mat-card class="m-2">
        <mat-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <span>
              Credit Card Hold
            </span>
            <span>
              <button class="leaveButton" *ngIf="offer.transaction.stripeDashboardLink" (click)="onViewPaymentInStripe()" mat-stroked-button>
                View in Stripe
                <mat-icon iconPositionEnd>exit_to_app</mat-icon>
              </button>
            </span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div>
            <div class="labeled-box">
              <div class="label">
                Total
              </div>
              <span>{{(offer.transaction.baseAmountCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Credits Applied
              </div>
              <span>{{offer.transaction.creditsApplied}} ({{(offer.transaction.creditsApplied/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}})</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Amount Due
              </div>
              <span>{{(offer.transaction.paymentAmountCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Status
              </div>
              <span>{{offer.transaction.status}}</span>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div style="width: 100%; max-width: 400px">
      <mat-card class="m-2">
        <mat-card-title>
          Negotiation
        </mat-card-title>
        <mat-card-content>
          <price-history [offer]="offer"></price-history>
          <div class="d-flex justify-content-between w100">
            <div>
              <button mat-raised-button color="primary" class="mt-2" (click)="accept()" [disabled]="isPerformingAction() || !offer.canAccept" [class.spinner]="isAccepting">Accept</button>
              <button mat-raised-button color="accent" class="ms-2 mt-2" (click)="counter()" [disabled]="isPerformingAction() || !offer.canCounter" [class.spinner]="isCountering">Counter</button>
            </div>
            <div>
              <button mat-raised-button color="warn" class="mt-2" (click)="decline()" [disabled]="isPerformingAction() || !offer.canDecline" [class.spinner]="isDeclining">Decline</button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="d-flex flex-column align-items-center m-5">
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
  </div>
</ng-template>
