import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ListingsService } from 'src/app/_services/listings.service';
import Listing from 'src/app/models/listing.model';

@Component({
  selector: 'listing-chip',
  templateUrl: './listing-chip.component.html',
  styleUrls: ['./listing-chip.component.css']
})
export class ListingChipComponent implements OnInit, OnDestroy {
  @Input('listing') listing?: Listing;
  @Input('listingId') listingId?: number;
  @Input('capText') capText?: number;

  displayText : string = "";

  constructor(
    private listingsService: ListingsService
  ) {

  }

  ngOnInit(): void {
    if (!this.listing && this.listingId) {
      this.listingsService.getListing(this.listingId).subscribe({
        next: listing => {
          this.listing = listing;
          this.displayText = this.getDisplayText();
        }
      })
    } else {
      this.displayText = this.getDisplayText();
    }
  }

  ngOnDestroy(): void {

  }

  getDisplayText(): string {
    let fullText : string = "" + (this.listing?.manufactureYear ?? "")
        + " " + (this.listing?.model?.displayName ?? "") + " " + this.listing?.model?.referenceNumber;
    fullText = fullText.trim();

    if (this.capText != undefined && fullText.length > this.capText) {
      fullText = fullText.substring(0, this.capText).trim() + "...";
    }
    return fullText;
  }
}
