import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Series from '../models/series.model';
import Model from '../models/model.model';
import Brand from '../models/brand.model';

@Injectable({
  providedIn: 'root'
})
export class ModelsService {
  constructor(private http: HttpClient) { }

  public createModel(params: any): Observable<Model> {
    return this.http.post<Model>(environment.apiUrl + `/catalog/models`, params).pipe(
      map(fetchedModel => this.toModel(fetchedModel))
    );
  }

  public updateModel(modelId: number, params: any): Observable<any> {
    return this.http.patch(environment.apiUrl + `/catalog/models/${modelId}`, params);
  }

  public deleteModel(modelId: number): Observable<any>  {
    return this.http.delete(environment.apiUrl + `/catalog/models/${modelId}`)
  }

  public getModel(modelId: number): Observable<Model> {
    return this.http.get<Model>(environment.apiUrl + `/catalog/models/${modelId}`).pipe(
      map(fetchedModel => this.toModel(fetchedModel))
    );
  }

  public getModels(modelIds: number[]): Observable<Model[]> {
    var path = `/catalog/models?`;
    path += 'ids=' + modelIds.join(",");

    return this.http.get<Model[]>(environment.apiUrl + path).pipe(
      map(fetchedModels => {
        fetchedModels.map(m => this.toModel(m));
        return fetchedModels;
      }
    ));
  }

  public listModels(pageSize: number, offset: number, brands: number[] | null, series: number[] | null, categories: number[] | null, imageCount: number | null): Observable<Model[]> {
    var path = `/catalog/models?limit=${pageSize}&offset=${offset}`;
    if (brands && brands.length > 0) {
        path += '&brands=' + brands.join(",");
    }
    if (!!series) {
      for (var s of series) {
        path += `&series[]=${s}`
      }
    }
    if (!!categories) {
      for (var category of categories) {
        path += `&categories[]=${category}`
      }
    }
    if (!!imageCount) {
      path += '&imageCount=' + imageCount;
    }
    console.log(path)
    return this.http.get<Model[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
      map(response => {
        return response.body!.map(o => this.toModel(o))
      })
    );
  }

  public searchModels(query: string): Observable<Model[]> {
    let params = new HttpParams().set('q', query);
    return this.http.get<Model[]>(environment.apiUrl + "/catalog/models/search", { params: params });
  }

  private toModel(fetchedModel: any): Model {
    var model = Object.assign(new Model(), fetchedModel);
    if (model.brand) {
        model.brand = Object.assign(new Brand(), model.brand);
    }
    if (model.series) {
        model.series = Object.assign(new Series(), model.series);
    }
    return model;
  }
}
