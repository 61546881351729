<ng-container *ngIf="series else loading">
    <div class="content-container">
        <div class="form-container">
            <form [formGroup]="formGroup">
                <div class="form-section">
                    <div class="form-section-title">
                        Details
                    </div>
                    <div class="form-section-content">
                        <mat-form-field appearance="outline">
                            <mat-label>Name</mat-label>
                            <input matInput placeholder="Oyster Perpetual Datejust" formControlName="name"
                                required="true">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Display Name</mat-label>
                            <input matInput placeholder="Datejust" formControlName="displayName">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Brand</mat-label>
                            <mat-select formControlName="brand" required>
                                <mat-option>--</mat-option>
                                <mat-option *ngFor="let brand of brands" [value]="brand.id">
                                    {{brand.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Cluster Name</mat-label>
                            <input matInput placeholder="Royal oaks" formControlName="clusterName">
                        </mat-form-field>
                        <table style="table-layout: fixed; width: 100%;">
                            <tr>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Release Year</mat-label>
                                        <input matInput formControlName="releaseYear" placeholder="1984">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Discontinuation Year</mat-label>
                                        <input matInput formControlName="discontinuationYear" placeholder="2014">
                                    </mat-form-field>
                                </td>
                            </tr>
                        </table>
                        <mat-form-field appearance="outline">
                            <mat-label>Description</mat-label>
                            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="6"
                                formControlName="description"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-section">
                    <div class="form-section-content">
                        <button type="submit" [disabled]="!formGroup.valid || !formGroup.dirty || submitting" (click)="onSubmit()"
                            [class.spinner]="submitting" mat-raised-button color="primary">Update</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-container>
<ng-template #loading>
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
</ng-template>