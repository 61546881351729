import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { TitlePathElement, ToolbarService, ToolbarController } from 'src/app/_services/toolbar.service';
import { Feature } from 'src/app/models/feature.model';
import { FeaturesService } from 'src/app/_services/features.service';
import { MatDialog } from '@angular/material/dialog';
import { pluck } from 'rxjs/operators';
import { DeleteDialog } from 'src/app/common/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-feature-detail',
  templateUrl: './feature-detail.component.html',
  styleUrls: ['./feature-detail.component.scss']
})
export class FeatureDetailComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Features", path: ['/content', 'features'] }]);
  title = new BehaviorSubject<string>("?");

  isLoading = true;
  
  feature!: Feature | null;
  private _featureId!: number;

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private featuresService: FeaturesService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) { 
    this.route.params.pipe(
      pluck("featureId")
    ).subscribe({
      next: featureId => {
        if (this._featureId == featureId) {
          return;
        }

        this._featureId = featureId;
        this.title.next(featureId.toString());
        this.reloadFeature();
      }
    })
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  private reloadFeature() {
    this.isLoading = true;

    this.feature = null;
    this.featuresService.getFeature(this._featureId).subscribe({
      next: feature => {
        this.isLoading = false;
        this.feature = feature;
        this.handleFeatureChanged();
      },
      error: error => {
        console.log(error)
      }
    })
  }

  private handleFeatureChanged() {
    if (this.feature?.name) {
      this.title.next(this.feature.name)
    } else if (this._featureId) {
      this.title.next(this._featureId.toString())
    } else {
      this.title.next('?')
    }
  }
  
  deleteButtonPressed(): void {
    this.dialog.open(DeleteDialog, {
      width: '20vw',
      data: {name: this.feature?.name, onClickConfirm: (callback: () => void) => this.deleteFeature(callback)}
    })
  }

  public deleteFeature(callback: () => void) {
    this.featuresService.deleteFeature(this._featureId).subscribe({
      next: () => {
        callback();
        this.router.navigate(['/content/features'])
      },
      error: error => {
        console.log(error)
        callback();
      }
    })
  }
}
