import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, Subscription } from 'rxjs';
import { AuthorizationService, Privilege, Permissions } from 'src/app/_services/authorization.service';
import { SearchService } from 'src/app/_services/search.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-home',
  templateUrl: './search-home.component.html',
  styleUrls: ['./search-home.component.css']
})
export class SearchHomeComponent implements OnInit {
  isLoading = false
  isFocused = false
  mouseHoveringOverResults = false;
  searchResults: { type: string, results: any[] }[] = []
  private _pendingSearchRequest?: Subscription;

  constructor(
    public searchService: SearchService,
    public authorizationService: AuthorizationService,
    private authService: AuthService,
  ) { 

  }

  ngOnInit(): void { }

  onLoupePressed() {
    window.open(environment.loupeDomain, "_self");
  }

  onLogoutPressed() {
    this.authService.logout({logoutParams: {returnTo: location.origin}});
  }

  hasSearchResults(): boolean {
    return this.searchResults.length > 0
  }

  onSearchTextChanged(text: string) {
    this.searchResults = []
    if (this._pendingSearchRequest) {
      this._pendingSearchRequest.unsubscribe()
      this._pendingSearchRequest = undefined
    }
    
    if (text.length == 0) {
      return
    }

    this.isLoading = true
    this._pendingSearchRequest = this.searchService.search(text).subscribe(result => {
      this.isLoading = false
      this._parseSearchResult(result)
    })
  }

  onFocus() {
    this.isFocused = true;
  }

  onBlur() {
    this.isFocused = false;
  }

  mouseEnterSearchResults() {
    this.mouseHoveringOverResults = true;
  }

  mouseExitSearchResults() {
    this.mouseHoveringOverResults = false;
  }

  private _parseSearchResult(results: any) {
    var resultMap: any = {}
    for (var result of results.results) {
      if (!resultMap[result.type]) {
        resultMap[result.type] = []
      }

      resultMap[result.type].push(result)
    }

    for (var result of results.results) {
      if (!resultMap[result.type]) {
        continue
      }

      this.searchResults.push({
        type: result.type,
        results: resultMap[result.type]
      })
      resultMap[result.type] = undefined
    }
  }

  // TODO: Centralize this logic with ToolbarComponent.
  getSectionHeader(type: string): string {
    if (type == 'MODEL') {
      return 'MODELS'
    } else if (type == 'BUYER_PROFILE') {
      return 'BUYERS'
    } else if (type == 'SELLER_PROFILE') {
      return 'SELLERS'
    } else if (type == 'LISTING') {
      return 'LISTINGS'
    } else if (type == 'ORDER') {
      return 'ORDERS'
    } else if (type == 'BILLING_ACCOUNT') {
      return 'BILLING ACCOUNTS'
    } else {
      return type
    }
  }

  get canAccessCatalog() {
    if (this.authorizationService.didInit && this.authorizationService.hasPermission(Permissions.CATALOG_ALL, Privilege.READ)) {
      return true;
    }
    return false;
  }

  get canAccessMarketplace() {
    if (!this.authorizationService.didInit) {
      return false
    }

    if (this.authorizationService.hasPermission(Permissions.MARKETPLACE_ALL, Privilege.READ)) {
      return true
    }

    if (this.authorizationService.hasPermission(Permissions.MARKETPLACE_SELLER_PROFILES, Privilege.READ)) {
      return true
    }

    if (this.authorizationService.hasPermission(Permissions.MARKETPLACE_LISTINGS, Privilege.READ)) {
      return true
    }

    if (this.authorizationService.hasPermission(Permissions.MARKETPLACE_INGESTION_REQUESTS, Privilege.READ)) {
      return true
    }

    return false
  }

  get canAccessVerification() {
    if (!this.authorizationService.didInit) {
      return false
    }

    if (this.authorizationService.hasPermission(Permissions.VERIFICATION_ALL, Privilege.READ)) {
      return true
    }

    if (this.authorizationService.hasPermission(Permissions.VERIFICATION_VERIFICATION_REPORTS, Privilege.READ)) {
      return true
    }

    return false
  }

  get canAccessContent() {
    if (this.authorizationService.didInit && this.authorizationService.hasPermission(Permissions.EDITORIAL_ALL, Privilege.READ)) {
      return true;
    }
    return false;
  }

  get canAccessPayments() {
    if (this.authorizationService.didInit && this.authorizationService.hasPermission(Permissions.PAYMENT_ALL, Privilege.READ)) {
      return true;
    }
    return false;
  }

  get canAccessNotifications() {
    if (this.authorizationService.didInit && this.authorizationService.hasPermission(Permissions.NOTIFICATIONS_ALL, Privilege.READ)) {
      return true;
    }
    return false;
  }
}
