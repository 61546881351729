<div class="container" *ngIf="feature && !isLoading else loading">
  <mat-card>
      <mat-card-title>
          <div class="d-flex justify-content-between">
              <span>Details</span>
              <span>
                  <button mat-raised-button color="primary" [routerLink]="['/content/features', feature.id, 'edit']">Edit</button>
                  <button mat-raised-button color="warn" class="mx-2" (click)="deleteButtonPressed()">Delete</button>
              </span>
          </div>
      </mat-card-title>
      <mat-card-content>
          <div class="labeled-box">
              <div class="label">Name</div>
              {{ feature.name }}
          </div>
          <div class="labeled-box">
              <div class="label">Created</div>
              {{ feature.created | date: 'medium' }}
          </div>
          <div class="labeled-box">
              <div class="label">Last updated</div>
              {{ feature.updated | date: 'medium' }}
          </div>
      </mat-card-content>
  </mat-card>
  <mat-card>
      <mat-card-title>Appearance</mat-card-title>
      <mat-card-content>
          <div class="labeled-box" style="margin-top: 16px">
              <div class="label">Label</div>
              {{ feature.label }}
          </div>
          <div style="padding-top: 10px">
            <feature-card [feature]="feature" style="margin-right: 6px; margin-left: 6px; margin-top: 2px; display: inline-block;"></feature-card>
          </div>
      </mat-card-content>
  </mat-card>
</div>
<ng-template #loading>
  <div class="d-flex flex-column align-items-center m-5">
      <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
  </div>
</ng-template>