import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule} from '@angular/material/autocomplete';
import { Component, OnDestroy, OnInit, ViewChild, NgZone } from '@angular/core';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelectChange } from '@angular/material/select'
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck, map, startWith, take } from 'rxjs/operators';
import { DeleteDialog } from 'src/app/common/delete-dialog/delete-dialog.component';
import List from 'src/app/models/list.model';
import { DisplayValue, Filter, FilterTypes } from 'src/app/models/list-filter.model';
import { ListsService } from 'src/app/_services/lists.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { FormControl, Validators } from '@angular/forms';
import { CatalogService } from 'src/app/_services/catalog.service';
import { SortableListComponent } from './sortable-list.component';
import { TagsService } from 'src/app/_services/tags.service';
import { ListingsService } from 'src/app/_services/listings.service';
import { BrandsService } from 'src/app/_services/brands.service';
import { SeriesService } from 'src/app/_services/series.service';

@Component({
  selector: 'app-list-detail',
  templateUrl: './list-detail.component.html',
  styleUrls: ['./list-detail.component.scss']
})
export class ListDetailComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Lists", path: ['/content', 'lists'] }]);
  title = new BehaviorSubject<string>("?");
  @ViewChild(SortableListComponent) private sortableListComponent! : SortableListComponent;

  readonly filterDisplayedColumns = ['subject', 'type', 'values'] as const;
  readonly filterDisplayedColumnsEd = ['subject', 'type', 'values', 'delete'] as const;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  readonly sortCriteriaOptions = ["PRICE_ASCENDING", "DATE_ASCENDING", "DATE_DESCENDING", "AUCTION_START_DATE_ASCENDING", "AUCTION_START_DATE_DESCENDING", "AUCTION_END_DATE_ASCENDING", "AUCTION_END_DATE_DESCENDING", "RECOMMENDED_FOR_YOU"] as const;

  isLoading = true;

  name = new FormControl("");
  inventorySensitive = new FormControl(false);
  addItem = new FormControl(null);
  maxLength = new FormControl(0);
  prefix = new FormControl("", Validators.pattern("(\\s*\\d+,?)*"));
  filterValueControllers = new Map<string, FormControl>();
  filterValueObservers = new Map<string, Observable<string[]>>();

  isEditing = false;
  isAutoList = false;
  isSaving = false;
  loadMessage = "";
  errorMessage = "";

  filterData: MatTableDataSource<Filter>;
  list!: List | null;
  uneditedList: List | null = null;
  autocomplete: Map<string, string[]>;
  private _listId!: number;
  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private catalogService: CatalogService,
    private brandsService: BrandsService,
    private seriesService: SeriesService,
    private tagsService: TagsService,
    private listsService: ListsService,
    private listingsService: ListingsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private zone: NgZone
  ) {
    this.route.params.pipe(
      pluck("listId")
    ).subscribe({
      next: listId => {
        if (this._listId == listId) {
          return;
        }

        this._listId = listId;
        this.title.next(listId.toString());
        this.reloadList();
      }
    })
    this.name.valueChanges.subscribe({
      next: value => {
        if (this.isEditing && value != null) {
            this.list!.name = value;
        }
      }
    });
    this.inventorySensitive.valueChanges.subscribe({
      next: value => {
        if (this.isEditing && value != null) {
          this.list!.inventorySensitive = value;
        }
      }
    });
    this.addItem.valueChanges.subscribe({
      next: value => {
        if (value == null) {
          return;
        }

        if (String(value) === value) {
          return;
        }

        this.list!.canAddItem(value, listingsService).subscribe({
          next: checkVal => {
            if (checkVal == true) {
              this.errorMessage = "";
              this.list!.items.unshift(value)
              this.addItem.setValue(null);
              this.sortableListComponent.refreshList();
            } else {
              this.errorMessage = "This list can only hold auctions";
            }
        }});
      }
    });
    this.maxLength.valueChanges.subscribe({
      next: value => {
        if (value != null) {
          this.list!.maxLength = value;
        }
      }
    });
    this.prefix.valueChanges.subscribe({
      next: value => {
        if(this.list?.parsedFilters != null && !this.prefix.hasError("prefix") && value != null) {
          this.list.parsedFilters.prefix = value;
        }
      }
    });
    this.filterData = new MatTableDataSource();
    this.autocomplete = new Map<string, string[]>();
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  private reloadList() {
    this.isLoading = true;

    this.list = null;
    this.listsService.getList(this._listId).subscribe({
      next: list => {
        this.isLoading = false;
        this.list = list;
        this.handleListChanged();
      },
      error: error => {
        console.log(error)
      }
    })
  }

  handleFiltersReady() {
    let filters : Filter[] = this.list?.parsedFilters?.list ?? [];

    this.filterData = new MatTableDataSource(filters);
    this.autocomplete = this.list?.parsedFilters?.autoNames ?? new Map();
    this.prefix.setValue(this.list?.parsedFilters?.prefix ?? " ");

    for (let filter of filters) {
      let fc = new FormControl(null);
      let fo = fc.valueChanges.pipe(
        startWith(null),
        map((val: string | null) => {return this.filteredAutocomplete(val, filter.subject)})
      );
      this.filterValueControllers.set(filter._localId, fc);
      this.filterValueObservers.set(filter._localId, fo);
    }
  }

  getPrefixErrorMessage(): string {
    return "Provide a comma separated list of ids.";
  }

  filteredAutocomplete(newFilterValue: string | null, subject: string): string[] {
    if (newFilterValue == null) {
      return this.autocomplete.get(subject) ?? [];
    }
    const nfv = newFilterValue.toLowerCase();
    return this.autocomplete.get(subject)?.filter(option => option.toLowerCase().includes(nfv)) ?? [];
  }

  private handleListChanged() {
    if (this.list?.name) {
      this.title.next(this.list.name)
    } else if (this._listId) {
      this.title.next(this._listId.toString())
    } else {
      this.title.next('?')
    }

    this.errorMessage = "";
    this.name.setValue(this.list?.name ?? null);
    this.inventorySensitive.setValue(this.list?.inventorySensitive ?? null);
    this.isAutoList = this.list?.isAuto ?? false;
    this.maxLength.setValue(this.list?.maxLength ?? null);
    this.prefix.setValue(this.list?.parsedFilters?.prefix ?? " ");
    this.list?.processFilters(this.catalogService, this.brandsService, this.seriesService, this.tagsService, this.handleFiltersReady.bind(this), this._destroyed);
  }

  deleteButtonPressed(): void {
    this.dialog.open(DeleteDialog, {
      width: '20vw',
      data: {name: this.list?.name, onClickConfirm: (callback: () => void) => this.deleteList(callback)}
    })
  }

  public deleteList(callback: () => void) {
    this.listsService.deleteList(this._listId).subscribe({
      next: () => {
        callback();
        this.router.navigate(['/content/lists'])
      },
      error: error => {
        console.log(error)
        callback();
      }
    })
  }

  editButtonPressed() {
    this.isEditing = true;
    if (this.list != null) {
      this.uneditedList = this.list.backUp();
    }
    this.zone.onMicrotaskEmpty.asObservable().pipe(
      take(1)
    )
    .subscribe(() => {
      if (!this.isAutoList) {
        this.sortableListComponent.refreshList();
      }
    })
  }

  saveButtonPressed() {
    this.isSaving = true;

    var params: any;

    if (this.isAutoList) {
      params = {
        filter: JSON.stringify(this.list?.parsedFilters) ?? ""
      };
    } else {
      params = {
        items: this.list!.items.map(i => i.id)
      };
    }

    if (this.name.dirty) {
      params.name = this.name.value
    }

    if (this.inventorySensitive.dirty) {
      params.inventorySensitive = this.inventorySensitive.value
    }

    if (this.maxLength.dirty) {
      params.maxLength = this.maxLength.value;
    }

    if (this.isAutoList) {
      params.sortCriteria = this.list?.sortCriteria;
    }

    this.listsService.updateList(this.list!.id, params).subscribe({
      next: () => {
        this.isEditing = false;
        this.isSaving = false;
        this.uneditedList = null;
        if (this.isAutoList) {
          this.list?.updateItems(new List());
          this.loadMessage = "Reloading...";
          this.fetchUpdatedItems();
        }
      },
      error: error => {
        console.log(error)
        // TODO: Show error message here.
        this.isSaving = false;
      }
    })

    this.errorMessage = "";
  }

  cancelButtonPressed() {
    this.isEditing = false;
    if (this.list != null && this.uneditedList != null) {
      this.list.restore(this.uneditedList);
      this.list?.processFilters(this.catalogService, this.brandsService, this.seriesService, this.tagsService, this.handleFiltersReady.bind(this), this._destroyed);
      this.uneditedList = null;
    }
    this.errorMessage = "";
  }

  onWheel(event: WheelEvent) {
    if (event.deltaY > 0){
      document.getElementById('scrollContainer')!.scrollLeft += 40;
    } else {
      document.getElementById('scrollContainer')!.scrollLeft -= 40;
    }
  }

  autocompleteSelected(event: MatAutocompleteSelectedEvent, filter: Filter, ele: HTMLInputElement): void {
    const value = (event.option.viewValue || '').trim();
    // Add new value
    let success = this.list?.parsedFilters?.addFilterValue(filter._localId, filter.subject, value) ?? false;

    if (success) {
      this.handleFiltersReady();
    }
    event.option.deselect();
    this.filterValueControllers.get(filter._localId)?.setValue('');
    ele.value = '';
  }

  addFilterValue(event: MatChipInputEvent, filter: Filter): void {
    const value = (event.value || '').trim();
    // Add new value
    let success = this.list?.parsedFilters?.addFilterValue(filter._localId, filter.subject, value) ?? false;
    // Clear the input value
    event.chipInput!.clear();
    this.filterValueControllers.get(filter._localId)?.setValue('');
    if (success) {
      this.handleFiltersReady();
    }
  }

  removeFilterValue(value: DisplayValue, filter:Filter): void {
    let success = this.list?.parsedFilters?.removeFilterValue(filter._localId, value.name) ?? false;
    if (success) {
      this.handleFiltersReady();
    }
  }

  addFilterButtonPressed(): void {
    this.list?.addFilter(this.catalogService, this.brandsService, this.seriesService, this.tagsService, this.handleFiltersReady.bind(this), this._destroyed);
  }

  removeFilterButtonPressed(filter: Filter): void {
    this.list?.removeFilter(filter, this.handleFiltersReady.bind(this));
  }

  onSubjectChange(event: MatSelectChange, filter: Filter): void {
    this.list?.parsedFilters?.changeSubject(filter, event.value);
  }

  onTypeChange(event: MatSelectChange, filter: Filter): void {
    this.list?.parsedFilters?.changeType(filter, event.value);
  }

  private fetchUpdatedItems() {
    this.listsService.getList(this.list!.id).subscribe({
      next: (list: List) => {
        this.loadMessage = "";
        this.list?.updateItems(list);
      }
    });
  }
}
