export enum Action {
  CREATE,
  READ,
  UPDATE,
  DELETE
}

export class ResourceInteraction {
  
  public constructor(
    public id: number,
    public action: Action,
    public namespace: string,
    public resource: string,
    public snapshot: JSON,  
    public timestamp: Date
  ) { }
}

export class ResourceInteractionRevision {
  public constructor(
    public timestamp: string,
    public changes: string[]
  ) {}
}

export class ResourceChangeHistory {
  initialState!: ResourceInteraction;
  revisions!: ResourceInteractionRevision[];
}