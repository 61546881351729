<div class="d-flex flex-column h-100">
  <mat-card class="m-2">
    <mat-card-content class="d-flex flex-md-row flex-column">
        <mat-form-field appearance="outline" class="flex-fill mx-1">
            <mat-label>Status</mat-label>
            <mat-select [(ngModel)]='statusFilter' name='Status' (selectionChange)="onSelectStatus($event)">
                <mat-option>-</mat-option>
                <mat-option *ngFor="let status of dropStatuses" [value]="status">
                    {{dropStatusDescriptions[status]}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card-content>
  </mat-card>
  <mat-card class="mx-2 mb-2 p-0 flex-fill d-flex flex-column overflow-scroll">
    <div class="flex-fill position-relative overflow-scroll">
      <div *ngIf="isLoading" class="w-100 h-100 position-absolute d-flex align-items-center justify-content-center" style="z-index: 1;">
        <div *ngIf="drops.length > 0" class="w-100 h-100 position-absolute" style="background-color: rgba(0, 0, 0, 0.1);"></div>
        <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
      </div>
      <div class="h-100 w-100 overflow-scroll">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="onSort($event)" class="w-100">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
            <td mat-cell *matCellDef="let drop"> {{drop.id}} </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status"> Status </th>
            <td mat-cell *matCellDef="let drop"> 
              <div class="badge-narrow" [ngClass]="{
                'badge-narrow': drop.status === 'DRAFT', 
                'badge-narrow-success': drop.status === 'DROPPED', 
                'badge-narrow-orange': drop.status === 'SCHEDULED', 
                'badge-narrow-error': drop.status === 'CANCELLED'
                }">
                              {{ drop.status }}
                          </div>
                      </td>
          </ng-container>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date" style="min-width: 150px;"> Date </th>
            <td mat-cell *matCellDef="let drop"> {{drop.date | date:'medium' }} </td>
          </ng-container>
          <ng-container matColumnDef="listings">
            <th mat-header-cell *matHeaderCellDef> Listings </th>
            <td mat-cell *matCellDef="let drop">   
              <mat-chip-set>
                <mat-chip *ngFor="let listingId of drop.listingIds" [routerLink]="['/marketplace/listings', listingId]"> {{ listingId }} </mat-chip>
              </mat-chip-set>
             </td>
          </ng-container>
          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by created" style="min-width: 150px;"> Created </th>
            <td mat-cell *matCellDef="let drop"> {{drop.created | date:'medium' }} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let drop; columns: displayedColumns;" [routerLink]="['/marketplace/drops/', drop.id]">
        </table>
      </div>
    </div>
    <mat-paginator [length]="length"
                [pageIndex]="page"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                aria-label="Select page">
    </mat-paginator>
  </mat-card>
  <button mat-fab class="fab" (click)="addButtonPressed()">
    <mat-icon>add</mat-icon>
</button>
</div>
