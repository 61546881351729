<div style="padding: 12px">
  <h2>Promotion</h2>
  <div class="d-flex flex-column" style="gap: 10px">
    <mat-form-field appearance="outline" class="flex-fill">
      <mat-label>Price</mat-label>
      <input matInput type="text" placeholder="1999.99" [formControl]="price">
    </mat-form-field>
    <mat-form-field appearance="outline" class="flex-fill">
      <mat-label>Type</mat-label>
      <mat-select [formControl]="type">
        <mat-option>--</mat-option>
        <mat-option value="AT_RETAIL">Retail Drop (AT_RETAIL)</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="d-flex justify-content-between mt-3">
    <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="onSubmit()">Save</button>
  </div>
</div>
