import { BehaviorSubject, Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { CatalogService, Color, Material } from "../_services/catalog.service";
import Brand from '../models/brand.model';
import Tag from '../models/tag.model';
import { TagsService } from '../_services/tags.service';
import Accessory from './accessory.model';
import { BrandsService } from '../_services/brands.service';
import { SeriesService } from '../_services/series.service';
import Series from './series.model';

export interface DisplayValue {
    name: string;
    mapIndex: number;
}

export interface FilterDescriptor {
    indexed: boolean;
    enum: boolean;
    listingSpecific: boolean;
}

interface FiltersI {
    prefix: number[];
    root: FilterI;
}

interface FilterI {
    subject: string;
    type: string;
    values: string[];
    displayValues: DisplayValue[];
    filters: FilterI[];
}

export const FilterTypes: Map<string, FilterDescriptor> = new Map([
    ["ACCESSORIES",       {indexed: true, enum: false, listingSpecific: true}],
    ["BRAND_ID",          {indexed: true,  enum: false, listingSpecific: false}],
    ["CASE_MATERIAL_ID",  {indexed: true,  enum: false, listingSpecific: false}],
    ["CASE_SIZE",         {indexed: false, enum: false, listingSpecific: false}],
    ["CONDITION",         {indexed: false, enum: true,  listingSpecific: true}],
    ["DIAL_COLOR_ID",     {indexed: true,  enum: false, listingSpecific: false}],
    ["MODEL_IMAGE_COUNT", {indexed: false, enum: false, listingSpecific: false}],
    ["MANUFACTURE_YEAR",  {indexed: false, enum: false, listingSpecific: false}],
    ["PRICE",             {indexed: false, enum: false, listingSpecific: true}],
    ["SELLER_ID",         {indexed: false, enum: false, listingSpecific: true}],
    ["SERIES_ID",         {indexed: true,  enum: false, listingSpecific: false}],
    ["STATUS",            {indexed: false, enum: true,  listingSpecific: true}],
    ["TAG_ID",            {indexed: true,  enum: false, listingSpecific: true}]]);
export const NOT_MAPPED = 0;
export const NOT_FOUND = -1;
const NOSUBJECT = "NONE";

export class Filter implements FilterI {
    _filters: Filter[];
    _localId: string;
    constructor(private initData: FilterI, localId: string) {
        this._localId = localId;
        this._filters = [];
        for (let i = 0; i < initData.filters?.length ?? 0; i++) {
            this._filters.push(new Filter(initData.filters[i], localId + "." + (i + 1)));
        }
    }

    get subject(): string {return this.initData.subject;}
    get type(): string {return this.initData.type;}
    get values(): string[] {return this.initData.values;}
    get displayValues(): DisplayValue[] {return this.initData.displayValues;}
    get filters(): Filter[] {return this._filters;}
    set displayValues(dv: DisplayValue[]) {this.initData.displayValues = dv;}
    set subject(s: string) {this.initData.subject = s;}
    set type(t: string) {this.initData.type = t;}

    get validTypes(): string[] {
        if (this.subject == "NONE") {
            return [];
        }else if (FilterTypes.get(this.subject)?.indexed) {
            return ["HAS_ANY", "HAS_ALL"];
        } else if (FilterTypes.get(this.subject)?.enum) {
            return ["MATCH"];
        }
        return ["HAS_ANY", "HAS_ALL", "RANGE"];
    }

    public simplify() : any {
        let filterData : any[] = [];
        for (let f of this.filters) {
            filterData.push(f.simplify());
        }
        if (this.filters.length > 0) {
            return {
                subject: this.subject,
                type: this.type,
                values: this.values,
                filters: filterData,
            };
        }
        return {
            subject: this.subject,
            type: this.type,
            values: this.values,
        };
    }

    public addFilter(f: Filter) : void {
        f._localId = this._localId + "." + (this._filters.length+1);
        this._filters.push(f);
        this.initData.filters.push(f.initData);
    }

    public removeFilter(f: Filter) : void {
        const index = this._filters.indexOf(f, 0);
        if (index > -1) {
            this._filters.splice(index, 1);
            this.initData.filters.splice(index, 1);
        }
    }

    public static emptyFilter() : Filter {
        return new Filter(<FilterI>{type: "NONE", values: [], subject: NOSUBJECT, displayValues: [], filters: []}, "empty");
    }

    public static emptyAndFilter(localId: string) : Filter {
        return new Filter(<FilterI>{type: "AND", values: [], subject: "AND", displayValues: [], filters: []}, localId);
    }
}

export class Filters {
    public brandData: Map<number, Brand> = new Map<number, Brand>();
    public seriesData: Map<number, Series> = new Map<number, Series>();
    public colorData: Map<number, Color> = new Map<number, Color>();
    public materialData: Map<number, Material> = new Map<number, Material>();
    public tagData: Map<number, Tag> = new Map<number, Tag>();
    public accessoriesData: Map<number, Accessory> = new Map<number, Accessory>();

    public need: Map<string, boolean> = new Map<string, boolean>();
    public fetched: Map<string, boolean> = new Map<string, boolean>();
    public autoNames: Map<string, string[]> = new Map<string, string[]>();

    root? : Filter | null;

    private doneLoadingCallback: () => void = this.emptyCallback;
    private cs?: CatalogService;
    private bs?: BrandsService;
    private ss?: SeriesService;
    private ts?: TagsService;
    private checkCancelled?: BehaviorSubject<boolean>;

    constructor(private initData: FiltersI) {
        for (let subject of FilterTypes.keys()) {
            if (FilterTypes.get(subject)?.indexed) {
                this.need.set(subject, false);
                this.fetched.set(subject, false);
            }
        }

        if (initData.root) {
            if (!initData.root.subject) {
                this.root = Filter.emptyFilter();
                this.root._localId = "r";
            } else if ((initData.root.filters != null || initData.root.type != "AND")) {
                this.root = new Filter(initData.root, "r");
            }
        }
    }

    get list(): Filter[] {
        if (this.root == null) {
            return [];
        }
        if (this.root.type == "AND") {
            return this.root!.filters;
        }
        return [this.root];
    }

    toJSON() {
        if (this.root == null) {
            return {
                root: {}
            };
        }

        return {
            prefix: this.initData.prefix,
            root: this.root.simplify(),
        };
    }

    get prefix(): string {
        let r = "";
        if (this.initData.prefix != null) {
            for (let val of this.initData.prefix) {
                r = r + val + ", ";
            }
        }
        r = r.slice(0, r.lastIndexOf(","));
        return r;
    }

    set prefix(p: string) {
        let pa = p.split(",").map(s => Number.parseInt(s.trim()));
        pa = pa.filter(value => !Number.isNaN(value));
        this.initData.prefix = pa;
    }

    public init(cs: CatalogService, bs: BrandsService, ss: SeriesService, ts: TagsService, callback: () => void, checkCancelled: BehaviorSubject<boolean>) {
        this.cs = cs;
        this.bs = bs;
        this.ss = ss;
        this.ts = ts;
        this.checkCancelled = checkCancelled;
        this.doneLoadingCallback = callback;
        this.startFetchData();
    }

    public rebuild(rootData : FiltersI): void {
        if (rootData.root != null) {
            this.root = new Filter(rootData.root, "r");
        }
    }

    public getAvailableSubjects(listType: string): string[] {
        let avTypes = new Set(FilterTypes.keys());
        for (let subject of this.list.map(f => f.subject)) {
            avTypes.delete(subject);
        }

        let result: string[] = [];
        for (let subject of avTypes) {
            if (listType != "LISTING" && FilterTypes.get(subject)?.listingSpecific) {
                continue;
            }
            result.push(subject);
        }
        return result;
    }

    public addFilter(): boolean {
        for (let filter of this.list) {
            if (filter.subject == NOSUBJECT) {
                return false;
            }
        }

        if (this.root == null) {
            this.root = Filter.emptyFilter();
            this.root._localId = "r";
        } else if (this.root.type == "AND") {
            this.root?.addFilter(Filter.emptyFilter());
        } else {
            let temp = this.root;
            temp._localId = "r.0";
            this.root = Filter.emptyAndFilter("r");
            this.root.addFilter(temp);
            this.root.addFilter(Filter.emptyFilter());
        }

        return true;
    }

    public removeFilter(f: Filter): boolean {
        if (this.root == null) {
            return false;
        }

        if (this.root.subject == f.subject) {
            this.root = null;
        } else {
            this.root.removeFilter(f);
            if (this.root.filters.length == 1) {
                this.root = this.root.filters[0];
            }
        }
        return true;
    }

    public addFilterValue(id: string, subject: string, value: string): boolean {
        let dv : DisplayValue | null = null;
        if (!FilterTypes.has(subject)) {
            return false;
        }

        if (FilterTypes.get(subject)?.indexed) {
            let id = Number.parseInt(value);
            if (!Number.isNaN(id)) {
                dv = this.getDV(subject, value);
            } else {
                id = this.lookupByName(subject, value);
                dv = {name: value, mapIndex: id};
            }
        } else {
            dv = {name: value, mapIndex: NOT_MAPPED};
        }

        if (dv == null || dv.mapIndex == NOT_FOUND) {
            return false;
        }

        for (let filter of this.list) {
            if (filter._localId == id) {
                if (FilterTypes.get(subject)?.indexed) {
                    let value = dv.mapIndex.toString();
                    if (filter.values.find(x => x == value) != undefined) {
                        return false;
                    }
                    filter.values.push(value);
                } else {
                    if (filter.values.find(x => x == dv?.name)) {
                        return false;
                    }
                    filter.values.push(dv.name);
                }
                filter.displayValues.push(dv);
                return true;
            }
        }
        return false;
    }

    public removeFilterValue(id: string, value: string): boolean {
        for (let filter of this.list) {
            if (filter._localId == id) {
                for (let fvi in filter.displayValues) {
                    if (filter.displayValues[fvi].name == value) {
                        filter.displayValues.splice(Number.parseInt(fvi), 1);
                        filter.values.splice(Number.parseInt(fvi), 1);
                        return true;
                    }
                }
            }
        }
        return false;
    }

    public changeSubject(filter: Filter, newSubject: string): void {
        if (FilterTypes.get(newSubject) == null) {
            return;
        }

        filter.subject = newSubject;
        if (FilterTypes.get(newSubject)?.indexed) {
            this.startFetchData();
        } else {
            this.doneLoadingCallback();
        }
    }

    public changeType(filter: Filter, newType: string): void {
        filter.type = newType;
        this.doneLoadingCallback();
    }

    private lookupByName(subject: string, name: string): number {
        if (subject == "BRAND_ID") {
            for (let b of this.brandData.entries()) {
                if (b[1].displayName == name || b[1].name == name) {
                    return b[0];
                }
            }
        } else if (subject == "SERIES_ID") {
            for (let s of this.seriesData.entries()) {
                if (s[1].displayName == name || s[1].name == name) {
                    return s[0];
                }
            }
        } else if (subject == "DIAL_COLOR_ID") {
            for (let c of this.colorData.entries()) {
                if(c[1].name == name) {
                    return c[0];
                }
            }
        } else if (subject == "CASE_MATERIAL_ID") {
            for (let m of this.materialData.entries()) {
                if(m[1].name == name) {
                    return m[0];
                }
            }
        } else if (subject == "TAG_ID") {
            for (let m of this.tagData.entries()) {
                if(m[1].displayValue == name) {
                    return m[0];
                }
            }
        } else if (subject == "ACCESSORIES") {
            for (let a of this.accessoriesData.entries()) {
                if (a[1].name == name) {
                    return a[0]
                }
            }
        }
        return NOT_FOUND;
    }

    private dataLoaded(subject: string) {
        this.fetched.set(subject, true);

        for (let filter of this.list) {
            if (filter.subject == subject && filter.values != null) {
                filter.displayValues = [];
                for (let idstr of filter.values) {
                    filter.displayValues.push(this.getDV(subject, idstr));
                }
            }
        }

        this.checkDoneLoading();
    }

    private getDV(subject: string, idstr: string) : DisplayValue {
        if (subject == "BRAND_ID") {
            let brand: Brand | null = this.brandData?.get(Number.parseInt(idstr)) ?? null;
            if (brand?.displayName != null) {
                return { name: brand.displayName, mapIndex: Number.parseInt(idstr) };
            } else if (brand != null) {
                return { name: brand.name, mapIndex: Number.parseInt(idstr) };
            }
        } else if (subject == "SERIES_ID") {
            let series: Series | null = this.seriesData?.get(Number.parseInt(idstr)) ?? null;
            if (series?.displayName != null) {
                return { name: series.displayName, mapIndex: Number.parseInt(idstr) };
            } else if (series != null) {
                return { name: series.name, mapIndex: Number.parseInt(idstr) };
            }

        } else if (subject == "DIAL_COLOR_ID") {
            let color: Color | null = this.colorData?.get(Number.parseInt(idstr)) ?? null;
            if (color != null) {
                return {name: color.name, mapIndex: Number.parseInt(idstr)}
            }

        } else if (subject == "CASE_MATERIAL_ID") {
            let material: Material | null = this.materialData?.get(Number.parseInt(idstr)) ?? null;
            if (material != null) {
                return { name: material.name, mapIndex: Number.parseInt(idstr) };
            }
        } else if (subject == "TAG_ID") {
            let tag: Tag | null = this.tagData?.get(Number.parseInt(idstr)) ?? null;
            if (tag != null) {
                return {name: tag.displayValue, mapIndex: Number.parseInt(idstr)};
            }
        } else if (subject == "ACCESSORIES") {
            let accessory: Accessory | null = this.accessoriesData.get(Number.parseInt(idstr)) ?? null;
            if (accessory != null) {
                return {name: accessory.name, mapIndex: Number.parseInt(idstr)};
            }
        }
        return { name: subject + " Not Found", mapIndex: NOT_FOUND };
    }

    private checkDoneLoading() {
        for (let category of this.need.keys()) {
            if (this.need.get(category) && !this.fetched.get(category)) {
                return;
            }
        }
        this.doneLoadingCallback();
    }

    private emptyCallback() {
        console.error("Callback was not set for AutoList editing");
    }

    private shouldFetch(subject: string) : boolean {
        let result = this.need.get(subject) && !this.fetched.get(subject);
        if (result != undefined) {
            return result;
        }
        return false;
    }

    public startFetchData(): void {
        for (let subject of this.need.keys()) {
            this.need.set(subject, false);
        }

        for (let filter of this.list) {
            if (FilterTypes.get(filter.subject)?.indexed) {
                this.need.set(filter.subject, true);
                if (this.fetched.get(filter.subject)) {
                    this.dataLoaded(filter.subject);
                }
            } else if (filter.values != null) {
                filter.displayValues = [];
                for (let str of filter.values) {
                    filter.displayValues.push({ name: str, mapIndex: NOT_FOUND });
                }
            }
        }

        if (this.cs == null || this.checkCancelled == null) {
            return;
        }

        if (this.shouldFetch("BRAND_ID")) {
            this.bs?.getBrands().pipe(takeWhile(val => !this?.checkCancelled?.getValue())).subscribe({
                next: (brands: Brand[]) => {
                    let brandNames : string[] = [];
                    for (let brand of brands) {
                        this.brandData.set(brand.id, brand);
                        brandNames.push(brand.name);
                        if (brand.displayName != null && brand.displayName != brand.name) {
                            brandNames.push(brand.displayName);
                        }
                    }
                    this.autoNames.set("BRAND_ID", brandNames);
                    this.dataLoaded("BRAND_ID");
                },
                error: (error: any) => {
                    console.log(error);
                }
            });
        }

        if (this.shouldFetch("SERIES_ID")) {
            this.ss?.listSeries(null).pipe(takeWhile(val => !this.checkCancelled?.getValue())).subscribe({
                next: (series: Series[]) => {
                    let seriesNames : string[] = [];
                    for (let s of series) {
                        this.seriesData.set(s.id, s);
                        seriesNames.push(s.name);
                        if (s.displayName != null && s.displayName != s.name) {
                            seriesNames.push(s.displayName);
                        }
                    }
                    this.autoNames.set("SERIES_ID", seriesNames);
                    this.dataLoaded("SERIES_ID");
                },
                error: (error: any) => {
                    console.log(error);
                }
            });
        }

        if (this.shouldFetch("DIAL_COLOR_ID")) {
            this.cs?.getColors().pipe(takeWhile(val => !this.checkCancelled?.getValue())).subscribe({
                next: (color: Color[]) => {
                    let colorNames : string[] = [];
                    for (let c of color) {
                        this.colorData.set(c.id, c);
                        colorNames.push(c.name);
                    }
                    this.autoNames.set("DIAL_COLOR_ID", colorNames);
                    this.dataLoaded("DIAL_COLOR_ID");
                },
                error: (error: any) => {
                    console.log(error);
                }
            });
        }

        if (this.shouldFetch("CASE_MATERIAL_ID")) {
            this.cs?.getMaterials().pipe(takeWhile(val => !this.checkCancelled?.getValue())).subscribe({
                next: (material: Material[]) => {
                    let materialNames : string[] = [];
                    for (let m of material) {
                        this.materialData.set(m.id, m);
                        materialNames.push(m.name);
                    }
                    this.autoNames.set("CASE_MATERIAL_ID", materialNames);
                    this.dataLoaded("CASE_MATERIAL_ID");
                },
                error: (error: any) => {
                    console.log(error);
                }
            });
        }

        if (this.shouldFetch("TAG_ID")) {
            this.ts?.getTags(null).pipe(takeWhile(val => !this.checkCancelled?.getValue())).subscribe({
                next: (tag: Tag[]) => {
                    let tagNames: string[] = [];
                    for (let t of tag) {
                        this.tagData.set(t.id, t);
                        tagNames.push(t.displayValue);
                    }
                    this.autoNames.set("TAG_ID", tagNames);
                    this.dataLoaded("TAG_ID");
                },
                error: (error: any) => {
                    console.log(error);
                }
            });
        }

        if (this.shouldFetch("ACCESSORIES")) {
            this.cs.getAccessories().pipe(takeWhile(val => !this.checkCancelled?.getValue())).subscribe({
                next: (accessories: Accessory[]) => {
                    let accessoryNames: string[] = [];
                    for (let a of accessories) {
                        this.accessoriesData.set(a.id, a);
                        accessoryNames.push(a.name);
                    }
                    this.autoNames.set("ACCESSORIES", accessoryNames);
                    this.dataLoaded("ACCESSORIES");
                },
                error: (error: any) => {
                    console.log(error);
                }
            });
        }
       this.checkDoneLoading();
    }
}
