<div class="container" *ngIf="collection && !isLoading else loading">
    <mat-card>
        <mat-card-title>
            <div class="d-flex justify-content-between">
                <span>Details</span>
                <span>
                    <button mat-raised-button color="primary" [routerLink]="['/content/collections', collection.id, 'edit']">Edit</button>
                    <button mat-raised-button color="warn" class="mx-2" (click)="deleteButtonPressed()">Delete</button>
                </span>
            </div>
        </mat-card-title>
        <mat-card-content>
            <div class="labeled-box">
                <div class="label">Name</div>
                {{ collection.name }}
            </div>
            <div class="labeled-box">
                <div class="label">Created</div>
                {{ collection.created | date: 'medium' }}
            </div>
            <div class="labeled-box">
                <div class="label">Last updated</div>
                {{ collection.updated | date: 'medium' }}
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card>
        <mat-card-title>Appearance</mat-card-title>
        <mat-card-content>
            <div class="labeled-box" style="margin-top: 16px">
                <div class="label">Style</div>
                {{ collection.style }}
            </div>
            <div class="labeled-box">
                <div class="label">Formatted Title</div>
                {{ collection.formattedTitle }}
            </div>
            <div class="labeled-box">
                <div class="label">Description</div>
                {{ collection.description }}
            </div>
            <div style="padding-top: 10px">
                <collection-card [collection]="collection" style="margin-right: 6px; margin-left: 6px; margin-top: 2px; display: inline-block;"></collection-card>
                <feature-card [feature]="asFeature()" style="margin-right: 6px; margin-left: 6px; margin-top: 2px; display: inline-block;"></feature-card>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card>
        <mat-card-title>
            <div class="d-flex justify-content-between">
                <span>Content</span>
                <span>
                    <button mat-stroked-button class="viewListButton" [routerLink]="['/content/lists', collection.list.id]">View list <mat-icon>launch</mat-icon></button>
                </span>
            </div>
        </mat-card-title>
        <mat-card-content>
            <ng-container *ngIf="collection.list.type == 'MODEL'">
                <div *ngFor="let model of collection.list.items" style="margin-right: 4px; margin-left: 4px; margin-top: 6px; vertical-align:top; display: inline-block;">
                    <model-card [model]="model"></model-card>
                </div>
            </ng-container>
            <ng-container *ngIf="collection.list.type == 'LISTING'">
                <div *ngFor="let listing of collection.list.items" style="margin-right: 4px; margin-left: 4px; margin-top: 6px; vertical-align:top; display: inline-block;">
                    <listing-card [listing]="listing"></listing-card>
                </div>
            </ng-container>
        </mat-card-content>
    </mat-card>
    <mat-card style="margin-bottom: 20px;">
        <mat-card-title>Exclusivity</mat-card-title>
        <mat-card-content>
            <div class="labeled-box" style="margin-top: 16px; min-width: 130px;">
                <div class="label">Requires Authentication</div>
                {{ collection.requiresAuthentication ? 'Yes' : 'No' }}
            </div>
            <div class="labeled-box" style="margin-top: 16px; min-width: 130px;">
                <div class="label">Partnership Program</div>
                {{ collection.partnershipProgram?.key ?? 'None' }}
            </div>
        </mat-card-content>
    </mat-card>
</div>
<ng-template #loading>
    <div class="d-flex flex-column align-items-center m-5">
        <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
    </div>
</ng-template>