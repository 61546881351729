<div class="card" *ngIf="feature" (click)="onClick()" [class.clickable]="clickable">
    <ng-container *ngIf="feature.type == 'COLLECTION_V2'">
        <img [src]="feature.object.backgroundImages.PORTRAIT_CARD?.optimizedUrl" style="position: absolute; width: 100%; height: 100%;" draggable="false">
        <div class="d-flex flex-column" style="position: absolute; top: 26px; width: 80%; left: 50%; transform: translateX(-50%);">
            <div class="label">{{(feature.label | uppercase) ?? 'COLLECTION'}}</div>
            <div class="title">{{feature.object.formattedTitle}}</div>
        </div>
        <img *ngIf="feature.object?.foregroundImages.PORTRAIT_CARD?.optimizedUrl" [src]="feature.object?.foregroundImages.PORTRAIT_CARD?.optimizedUrl" style="position: absolute; width: 100%; height: 50%; bottom: 0; object-fit: cover;" draggable="false">
    </ng-container>

</div>