import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorDialog } from 'src/app/common/error-dialog/error-dialog.component';
import List from 'src/app/models/list.model';
import { HomeFeedsService } from 'src/app/_services/home-feeds.service';

@Component({
  selector: 'app-add-section',
  templateUrl: './add-section.component.html',
  styleUrls: ['./add-section.component.css']
})
export class AddSectionComponent {
  isSubmitting = false

  title = new FormControl(null);
  type = new FormControl(null, Validators.required);
  allowedListTypes: string[] | null = null;
  content: string | null = null;
  list = new FormControl(new List(), Validators.required);
  formGroup = new FormGroup({
    title: this.title,
    type: this.type,
    list: this.list,
  });

  constructor(
    public dialogRef: MatDialogRef<AddSectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public homeFeedService: HomeFeedsService,
    public dialog: MatDialog,
  ) {
    this.type.valueChanges.subscribe({
      next: value => {
        this.content = null;
        if (value === 'MODEL_CLUSTER') {
          this.allowedListTypes = ['MODEL'];
        } else if (value === 'LISTING_CLUSTER') {
          this.allowedListTypes = ['LISTING'];
        } else if (value === 'BRAND_CLUSTER') {
          this.allowedListTypes = ['BRAND'];
        } else if (value === 'COLLECTION_CLUSTER') {
          this.allowedListTypes = ['COLLECTION'];
        } else if (value === 'FEATURE_CLUSTER') {
          this.allowedListTypes = ['FEATURE'];
        } else if (value === 'ARTICLE_CLUSTER') {
          this.allowedListTypes = ['ARTICLE'];
        } else if (value === 'AUCTION_CLUSTER') {
          this.allowedListTypes = ['LISTING'];
          this.content = 'AUCTION_ONLY';
        } else {
          this.allowedListTypes = null;
        }
        this.list.reset()
      }
    })
  }

  formValid(): boolean {
    return this.formGroup.valid && this.list.value instanceof List;
  }

  cancelButtonPressed() {
    this.dialogRef.close({ added: false })
  }

  submitButtonPressed() {
    if (this.isSubmitting || this.list.value == null) {
      return;
    }

    this.isSubmitting = true;

    var params: any = { 
      title: this.title.value,
      type: this.type.value,
      object: this.list.value.id
    }
    this.homeFeedService.addSection(this.data.homeFeed.id, params).subscribe({
      next: () => {
        this.dialogRef.close({ added: true })
      },
      error: response => {
        console.log(response.error);
        this.showError(response.error);
        this.isSubmitting = false;
      }
    })
  }

  private showError(error: {error: string, description?: string }) {
    this.dialog.open(ErrorDialog,  {
      width: '500px',
      data: {
        error: error
      }
    });
  }
}
