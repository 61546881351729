<h3>{{data.image_type}} Image Upload</h3>
<div class="mw-100 mx-auto">
  <input type="file" (change)="fileChangeEvent($event)" />
  <image-cropper
    [autoCrop]="false"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="1 / 1"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
</div>
<div class="d-flex justify-content-between">
  <div class="mt-3">
    <button mat-raised-button color="primary" (click)="onSave()">Save</button>
  </div>
  <div class="mt-3">
    <button mat-raised-button color="secondary" (click)="onClose()">Close</button>
  </div>
</div>
