<div class="d-flex flex-column h-100">
  <mat-card class="m-2">
    <mat-card-content class="d-flex flex-md-row flex-column">
      <mat-form-field appearance="outline" class="flex-fill mx-1">
        <mat-label>Status</mat-label>
        <mat-select [(ngModel)]='statusFilter' name='Status' (selectionChange)="onSelectStatus($event)">
          <mat-option *ngFor="let status of orderedStatuses" [value]="status">
            {{allStatuses[status]}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <buyer-select [control]="buyerFilterControl" class="flex-fill mx-1"></buyer-select>
      <seller-select [valueId]="sellerFilter" (onSelect)="onChangeSellerFilter($event)" class="flex-fill mx-1"></seller-select>
      <mat-form-field appearance="outline" class="flex-fill mx-1">
        <mat-label>Flagged</mat-label>
        <mat-select [(ngModel)]='flaggedFilter' name='Flagged' (selectionChange)="onChangeFlaggedFilter($event)">
            <mat-option [value]="">-</mat-option>
            <mat-option [value]="'true'">YES</mat-option>
            <mat-option [value]="'false'">NO</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="flex-fill mx-1">
        <mat-label>Client</mat-label>
        <mat-select [(ngModel)]='clientFilter' name='Client' (selectionChange)="onChangeClientFilter($event)">
            <mat-option [value]="">-</mat-option>
            <mat-option *ngFor="let item of clientNames | keyvalue" [value]="item.key">
                {{ item.value }}
            </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card-content>
  </mat-card>
  <mat-card class="mx-2 mb-2 p-0 flex-fill d-flex flex-column overflow-scroll">
    <div class="flex-fill position-relative overflow-scroll">
      <div *ngIf="isLoading" class="w-100 h-100 position-absolute d-flex align-items-center justify-content-center" style="z-index: 1;">
        <div *ngIf="orders.length > 0" class="w-100 h-100 position-absolute" style="background-color: rgba(0, 0, 0, 0.1);"></div>
        <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
      </div>
      <div class="h-100 w-100 overflow-scroll">
        <table mat-table matSort (matSortChange)="onSort($event)" [dataSource]="orders" class="w-100">
          <ng-container matColumnDef="flagged">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let ingestionRequest"><mat-icon *ngIf="ingestionRequest.flagged" color="warn">flag</mat-icon></td>
          </ng-container>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> Id </th>
            <td mat-cell *matCellDef="let order"> {{order.id}} </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let order"> 
              <div class="badge-narrow" [ngClass]="{
                'badge-narrow-success': order.status === 'DELIVERED', 
                'badge-narrow-error': order.status === 'CANCELLED'
                }">
                {{order.displayStatus | uppercase}} 
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="listing">
            <th mat-header-cell *matHeaderCellDef> Listing </th>
            <td mat-cell *matCellDef="let order">
              <mat-chip-list>
                <listing-chip [listing]="order.listing"></listing-chip>
              </mat-chip-list>
            </td>
          </ng-container>
          <ng-container matColumnDef="buyer">
            <th mat-header-cell *matHeaderCellDef> Buyer </th>
            <td mat-cell *matCellDef="let order"><buyer-chip [buyer]="order.buyerProfile"></buyer-chip></td>
          </ng-container>
          <ng-container matColumnDef="client">
            <th mat-header-cell *matHeaderCellDef> Client </th>
            <td mat-cell *matCellDef="let order">
              <mat-chip-list>
                <mat-chip *ngIf="order.client" [routerLink]="['/marketplace/orders']" [queryParams]="{ client: order.client }" style="font-size: 9pt !important">{{ clientNames[order.client] }}</mat-chip>
              </mat-chip-list>
            </td>
          </ng-container>
          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by created"> Created </th>
            <td mat-cell *matCellDef="let order"> {{order.displayCreated}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let order; columns: displayedColumns;" [routerLink]="['/marketplace/orders/', order.id]">
        </table>
      </div>
    </div>
    <mat-paginator [length]="length"
                [pageIndex]="page"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                (page)="pageChangeEvent($event)"
                aria-label="Select page">
    </mat-paginator> 
  </mat-card>  
</div>
