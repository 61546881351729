import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { AuthorizationService } from '../_services/authorization.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private authorizationService: AuthorizationService,
  ) {}

  ngOnInit(): void {
    this.authService.isAuthenticated$.subscribe(authenticated => {
      if (!authenticated) {
        this.authService.loginWithRedirect({appState: {targetUrl: this.router.url}})
      } else {
        this.authorizationService.initIfNeeded(() => {
          if (this.authorizationService.hasConsoleAccess()) {
            this.router.navigate(['/'])
          }
        })
      }
    })
    if (this.authorizationService.didInit && this.authorizationService.hasConsoleAccess()) {
      this.router.navigate(['/'])
    }
  }

  logoutButtonPressed(): void {
    this.authService.logout({logoutParams: {returnTo: location.origin}});
  }
}
