import { BraceletStyle, ClaspType, Color, Complication, Gender, Material, MovementType, NumeralType } from "../_services/catalog.service"
import Image from './image.model';
import Brand from './brand.model';
import Category from "./category.model";
import Series from "./series.model";
import BezelType from "./bezel-type.model";

export default class Model {
    id!: number;
    name!: string;
    displayName!: string | null;
    brand!: Brand;
    series!: Series | null;
    referenceNumber!: string;
    gender!: Gender;
    description!: string | null;
    releaseYear!: number;
    discontinuationYear!: number | null;
    images!: [Image];
    caseSize!: string;
    caseMaterials!: Material[];
    thickness!: number | null;
    waterResistance!: number | null;
    bezelType!: BezelType | null;
    bezelMaterial!: Material;
    crystal!: Material;
    lugWidth!: number | null;
    dialColor!: Color;
    numerals!: NumeralType;
    complications!: Complication[];
    movementType!: MovementType;
    caliber!: string | null;
    powerReserve!: number | null;
    numberOfJewels!: number;
    braceletStyle!: BraceletStyle | null;
    braceletMaterials!: Material[] | null;
    braceletColor!: Color | null;
    braceletClaspType!: ClaspType | null;
    braceletClaspMaterials!: Material[] | null;
    categories!: Category[];
  }
