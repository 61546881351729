<div class="container" *ngIf="proxyBid; else spinner">
  <mat-card class="m-2">
    <mat-card-title>Edit Max Bid</mat-card-title>
    <mat-card-content>
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Price ($)</mat-label>
            <input matInput type="text" placeholder="1999.99" [formControl]="price" required="true">
        </mat-form-field>
    </mat-card-content>
    <mat-card-footer class="m-3 d-flex justify-content-between">
      <div>
        <button mat-raised-button color="warn" [routerLink]="['/marketplace/max-bids', proxyBid.id]">Cancel</button>
      </div>
      <div>
        <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!formGroup.valid || !formGroup.dirty || submitting" [class.spinner]="submitting">Save</button>
      </div>
    </mat-card-footer>
  </mat-card>
</div>
<ng-template #spinner>
  <mat-card class="m-2 d-flex flex-column align-items-center">
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
  </mat-card>
</ng-template>