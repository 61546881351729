import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import Tag from 'src/app/models/tag.model';

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  constructor(private http: HttpClient) { }

  public getTags(ids: number[] | null): Observable<any> {
    var path = `/marketplace/tags`;
    if (!!ids) {
      path += `?ids=${ids}`
    }
    return this.http.get<Tag[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
      map(response => {
        return response.body!.map(t => Object.assign(new Tag(), t))
      })
    );
  }

  public createTag(tag: string): Observable<Tag> {
    var template: any = {};
    template.displayValue = tag;

    var path = "/marketplace/tags";
    return this.http.post<Tag>(environment.apiUrl + path, template)
        .pipe(map(response => Object.assign(new Tag(), response)));
  }
}
