<div>
    <div class="d-flex flex-row search-bar-container">
        <mat-icon matPrefix *ngIf="!isLoading" style="width: 26px">search</mat-icon>
        <mat-spinner [diameter]="20" *ngIf="isLoading" style="width: 26px; margin-top: 2px; margin-bottom: 2px; color: black"></mat-spinner>
        <input matInput placeholder="Search for models, buyers, sellers, listings, orders, ..." (input)='onSearchTextChanged($event.target.value)' (blur)="onBlur()" (focus)="onFocus()" class="flex-fill search-bar">
    </div>
    <div class="search-result-container" *ngIf="!isLoading && hasSearchResults()">
        <div class="search-result-section" *ngFor="let section of searchResults">
            <div class="section-header bg-light">
                {{getSectionHeader(section.type)}}
            </div>
            <div class="section-results">
                <div *ngFor="let result of section.results">
                    <ng-container *ngIf="result.type == 'MODEL'">
                        <search-result 
                            [image]="result.object.images[0] ? result.object.images[0].url : 'https://storage.googleapis.com/public-us-standard-bezel_prod/static/model_placeholder.png'"
                            [title]="result.object.displayName" 
                            [subtitle]="result.object.referenceNumber"
                            [routerLink]="['catalog', 'models', result.object.id]">
                        </search-result>
                    </ng-container>
                    <ng-container *ngIf="result.type == 'BUYER_PROFILE'">
                        <search-result
                            [image]="'assets/person.png'" 
                            [title]="result.object.user.fullName" 
                            [subtitle]="result.object.user.email" 
                            [routerLink]="['marketplace', 'buyers', result.object.id]">
                        </search-result>
                    </ng-container>
                    <ng-container *ngIf="result.type == 'SELLER_PROFILE'">
                        <search-result
                            [image]="'assets/storefront.png'" 
                            [title]="result.object.user.fullName" 
                            [subtitle]="result.object.user.email" 
                            [routerLink]="['marketplace', 'sellers', result.object.id]">
                        </search-result>
                    </ng-container>
                    <ng-container *ngIf="result.type == 'LISTING'">
                        <search-result
                            [image]="result.object.images[0].image.url"
                            [title]="(result.object.manufactureYear ? result.object.manufactureYear + ' ' : '') + result.object.model.displayName" 
                            [subtitle]="result.object.condition.toLowerCase() + ' • ' + result.object.status.toLowerCase() + (result.object.inventoryNumber ? ' • ' + result.object.inventoryNumber : '')" 
                            [routerLink]="['marketplace', 'listings', result.object.id]">
                        </search-result>
                    </ng-container>
                    <ng-container *ngIf="result.type == 'ORDER'">
                        <search-result
                            [image]="result.object.listing.images[0].image.url"
                            [title]="(result.object.listing.manufactureYear ? result.object.listing.manufactureYear + ' ' : '') + result.object.listing.model.displayName" 
                            [subtitle]="result.object.status.toLowerCase()" 
                            [routerLink]="['marketplace', 'orders', result.object.id]">
                        </search-result>
                    </ng-container>
                    <ng-container *ngIf="result.type == 'BILLING_ACCOUNT'">
                        <search-result
                            [image]="'assets/payments.png'" 
                            [title]="result.object.owner.fullName" 
                            [subtitle]="result.object.owner.email" 
                            [routerLink]="['payments', 'billing-accounts', result.object.id]">
                        </search-result>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>