import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthenticationReportsService } from 'src/app/_services/authentication-reports.service';
import { ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import AuthenticationReport, { AUTHENTICATION_REPORT_PRIORITIES, AUTHENTICATION_REPORT_PRIORITY_DESCRIPTIONS, AUTHENTICATION_REPORT_RESULTS, AUTHENTICATION_REPORT_RESULT_DESCRIPTIONS, AUTHENTICATION_REPORT_STATUSES, AUTHENTICATION_REPORT_STATUS_DESCRIPTIONS } from 'src/app/models/authentication-report.model';

@Component({
  selector: 'app-browse-authentication-reports',
  templateUrl: './browse-authentication-reports.component.html',
  styleUrls: ['./browse-authentication-reports.component.css']
})
export class BrowseAuthenticationReportsComponent implements OnInit, OnDestroy, ToolbarController {
  title = new BehaviorSubject<string>('Authentication Reports');

  statusFilter!: string | null;
  priorityFilter!: string | null;
  resultFilter!: string | null;
  blockedFilter!: boolean | null;

  authenticationReportStatuses: object;
  authenticationReportStatusDescriptions: object;
  authenticationReportPriorities: object;
  authenticationReportPriorityDescriptions: object;
  authenticationReportResults: object;
  authenticationReportResultDescriptions: object;

  authenticationReports: AuthenticationReport[] = [];
  displayedColumns: string[] = ['icons', 'id', 'status', 'priority', 'reference', 'result', 'created'];
  dataSource: MatTableDataSource<AuthenticationReport>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  page: number = 0;
  sortKey: string | null = null;
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20, 40, 60, 80, 100];
  isLoading = true;
  private loadingSubscription: Subscription | null = null

  isCreatingNewReport = false;

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private authenticationReportsService: AuthenticationReportsService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.authenticationReportStatuses = AUTHENTICATION_REPORT_STATUSES;
    this.authenticationReportStatusDescriptions = AUTHENTICATION_REPORT_STATUS_DESCRIPTIONS;
    this.authenticationReportPriorities = AUTHENTICATION_REPORT_PRIORITIES;
    this.authenticationReportPriorityDescriptions = AUTHENTICATION_REPORT_PRIORITY_DESCRIPTIONS;
    this.authenticationReportResults = AUTHENTICATION_REPORT_RESULTS;
    this.authenticationReportResultDescriptions = AUTHENTICATION_REPORT_RESULT_DESCRIPTIONS;

    this.activatedRoute.queryParams.subscribe(params => {
      this.page = params.page ? params.page : 0;
      this.sortKey = params.sort ? params.sort : null;
      this.statusFilter = params.status ? params.status : null;
      this.priorityFilter = params.priority ? params.priority : null;
      this.resultFilter = params.result ? params.result : null;
      this.blockedFilter = params.blocked ? params.blocked : null;
      this.fetchAuthenticationReports(this.page);
    })
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  get loading(): boolean {
    return this.authenticationReports.length === 0;
  }

  pageChangeEvent(event: PageEvent) {
    this.page = event.pageIndex
    this.updateUrlParametersIfNeeded();
  }
  
  onSelectStatus(event: any) {
    this.updateUrlParametersIfNeeded();
  }

  onSelectPriority(event: any) {
    this.updateUrlParametersIfNeeded();
  }

  onSelectResult(event: any) {
    this.updateUrlParametersIfNeeded();
  }

  onChangeBlockedFilter(event: any) {
    this.updateUrlParametersIfNeeded();
  }

  onSort(): void {
    this.sortKey = (this.sort.direction == "asc" ? "" : "-") + this.sort.active;
    this.updateUrlParametersIfNeeded();
  }

  private fetchAuthenticationReports(index: number) {
    this.isLoading = true;

    if (this.loadingSubscription != null) {
      this.loadingSubscription.unsubscribe();
    }

    this.loadingSubscription = this.authenticationReportsService.getAuthenticationReports(this.statusFilter, this.priorityFilter, this.resultFilter, this.blockedFilter, this.pageSize, this.page * this.pageSize).subscribe({
      next: (response: any) => {
        if (this.sortKey != null) {
          const initSortState: Sort = {active: "a", direction: "desc"};
          
          initSortState.direction = this.sortKey.startsWith("-") ? "asc" : "desc";
    
          this.sort.active = this.sortKey.startsWith("-") ? this.sortKey.substring(1) : this.sortKey;
          this.sort.direction = initSortState.direction;
        }

        this.authenticationReports = response.data;
        this.dataSource.data = this.authenticationReports;
        this.length = response.totalCount;
        this.isLoading = false;
        this.loadingSubscription = null;
      },
      error: (error: any) => {
        console.log(error);
        this.authenticationReports = [];
        this.length = 0;
        this.isLoading = false;
        this.loadingSubscription = null;
      }
    });
  };

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      page: this.page == 0 ? null : this.page,
      sort: this.sortKey == null ? null : this.sortKey,
      status: this.statusFilter,
      priority: this.priorityFilter,
      result: this.resultFilter,
      blocked: this.blockedFilter
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }

  public onCreateAuthenticationReportPressed() {
    if (this.isCreatingNewReport) {
      return
    }
    this.isCreatingNewReport = true
    this.authenticationReportsService.createAuthenticationReport({}).subscribe({
      next: authenticationReport => {
        this.router.navigate(['/verification/authentication-reports', authenticationReport.id])
        this.isCreatingNewReport = false
      },
      error: (error) => {
        console.log(error)
        this.isCreatingNewReport = false
      }
    })
  }
}
