import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, pluck } from 'rxjs';
import { ProxyBidsService } from 'src/app/_services/proxy-bids.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { clientNames } from 'src/app/models/clients';
import ProxyBid from 'src/app/models/proxy-bid.model';

@Component({
  selector: 'app-max-bid-detail',
  templateUrl: './max-bid-detail.component.html',
  styleUrls: ['./max-bid-detail.component.css']
})
export class MaxBidDetailComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Max Bids", path: ['/marketplace', 'max-bids'] }]);
  title = new BehaviorSubject<string>("?");
  
  proxyBid!: ProxyBid | null;
  _proxyBidId: number | null = null;

  clientNames: object;

  constructor(
    private toolbarService: ToolbarService,
    private route: ActivatedRoute,
    private router: Router,
    private proxyBidsService: ProxyBidsService
  ) { 
    this.clientNames = clientNames;
      
    this.route.params.pipe(
      pluck("bidId")
    ).subscribe({
      next: proxyBidId => {
        if (this._proxyBidId == proxyBidId) {
          return;
        }

        this._proxyBidId = proxyBidId;
        this.title.next(proxyBidId.toString());
        this.reloadProxyBid();
      }
    })

  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }
  
  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  private reloadProxyBid() {
    this.proxyBid = null;
    this.proxyBidsService.getProxyBid(this._proxyBidId!).subscribe({
      next: (proxyBid: ProxyBid) => {
        this.proxyBid = proxyBid;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  onViewPaymentInStripe(): void {
    window.open(this.proxyBid?.transaction.stripeDashboardLink, "_blank");
  }
}
