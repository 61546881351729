<ng-container *ngIf="!isLoading else spinner">
    <div class="container">
        <div class="section">
            <div class="section-content">
                <div class="labeled-box">
                    <div class="label">
                        Images
                    </div>
                    <div class="i-grid">
                        <ng-container *ngFor="let modelId of images.keys()">
                            <div (click)="selectModel(modelId)">
                                <img [src]="images.get(modelId).rawUrl"/>
                                <h5 class="mb-0">{{modelId}}</h5>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #spinner>
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
</ng-template>
