import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ErrorDialog } from 'src/app/common/error-dialog/error-dialog.component';
import { Feature, FeatureType } from 'src/app/models/feature.model';
import { FeaturesService } from 'src/app/_services/features.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';

@Component({
  selector: 'app-create-feature',
  templateUrl: './create-feature.component.html',
  styleUrls: ['./create-feature.component.css']
})
export class CreateFeatureComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath!: BehaviorSubject<TitlePathElement[]>;
  title!: BehaviorSubject<string>;
  
  loading: boolean = true;
  editing: boolean = false;
  submitting: boolean = false;

  featureId: number | null = null;
  private _feature: Feature | null = null;

  name = new FormControl("", [Validators.required]);
  label = new FormControl("")
  type = new FormControl<FeatureType>(FeatureType.CollectionV2, [Validators.required])
  object = new FormControl<object>({}, [Validators.required])
  formGroup = new FormGroup({
    name: this.name,
    label: this.label,
    type: this.type,
    object: this.object,
  });

  allTypes: string[];

  private _destroyed = new ReplaySubject<boolean>(1);
  
  constructor(
    private toolbarService: ToolbarService,
    private featuresService: FeaturesService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) { 
    this.allTypes = []
    for (var type in FeatureType) {
      this.allTypes.push((FeatureType as any)[type])
    }

    this.type.valueChanges.subscribe({
      next: value => {
        this.object.reset();
      }
    })

    const routeParams = this.route.snapshot.paramMap;
    if (routeParams.get('featureId')) {
      this.featureId = Number(routeParams.get('featureId'));
      this.editing = true;
    
      this.titlePath = new BehaviorSubject<TitlePathElement[]>([
        { title: "Features", path: ['/content/features'] },
        { title: this.featureId.toString(), path: ['/content/features', this.featureId.toString()] }
      ]);
      this.title = new BehaviorSubject<string>("Edit");

      featuresService.getFeature(this.featureId).subscribe({
        next: feature => {
          this._feature = feature;
          this.titlePath.next([
            { title: "Features", path: ['/content/features'] },
            { title: feature.name, path: ['/content/features', feature.id.toString()] }
          ]);

          this.name.setValue(feature.name);
          this.label.setValue(feature.label);
          this.type.setValue(feature.type);
          this.object.setValue(feature.object);

          this.loading = false;
        },
        error: error => {
          console.log(error)
        }
      })
    } else {
      this.editing = false;
      this.loading = false;

      this.titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Features", path: ['/content/features'] }]);
      this.title = new BehaviorSubject<string>("Create");
    }
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  onCancel(): void {
    if (this.featureId) {
      this.router.navigate(['/content/features', this.featureId])
    } else {
      this.router.navigate(['/content/features'])
    }
  }

  onSubmit(): void {
    if (this.submitting) {
      return;
    }
    this.submitting = true;

    var params: any = {}
    if (this.name.dirty) {
      params.name = this.name.value;
    }
    if (this.label.dirty) {
      params.label = this.label.value;
    }
    if (this.type.dirty) {
      params.type = this.type.value;
    }
    if (this.object.dirty) {
      params.object = (this.object.value as {id: number}).id;
    }

    if (this.featureId) {
      this.updateFeature(params);
    } else {
      this.createFeature(params);
    }
  }

  createFeature = (params: any) => {
    this.featuresService.createFeature(params).subscribe({
      next: (feature: Feature) => {
        this.router.navigate(['/content/features', feature.id]);
      },
      error: response => {
        console.log(response.error);
        this.showError(response.error);
        this.submitting = false;
      }
    })
  };

  updateFeature = (params: any) => {
    this.featuresService.updateFeature(this.featureId!, params).subscribe({
      next: (feature: Feature) => {
        this.router.navigate(['/content/features', feature.id]);
      },
      error: response => {
        console.log(response.error);
        this.showError(response.error);
        this.submitting = false;
      }
    })
  };

  private showError(error: {error: string, description?: string }) {
    this.dialog.open(ErrorDialog,  {
      width: '500px',
      data: {
        error: error
      }
    });
  }
}
