import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AuthorizationService, Permissions, Privilege } from 'src/app/_services/authorization.service';
import { DiscountsService } from 'src/app/_services/discounts.service';
import { ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteDialog } from 'src/app/common/delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import Discount from 'src/app/models/discount.model';

@Component({
  selector: 'app-discounts-home',
  templateUrl: './discounts-home.component.html',
  styleUrls: ['./discounts-home.component.css']
})
export class DiscountsHomeComponent implements OnInit, OnDestroy, ToolbarController {
  title = new BehaviorSubject<string>("Discounts");
  showAddDiscountButton: boolean;
  showDeleteDiscountButton: boolean;

  displayedColumns: string[] = ['id', 'discountCode', 'discountAmountCents', 'minPurchasePriceCents', 'disabled', 'expiration', 'actions'];
  refreshingDiscounts = false;
  discounts: Discount[] = [];

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private discountsService: DiscountsService,
    private router: Router,
    private authorizationService: AuthorizationService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.refreshDiscounts();

    this.showAddDiscountButton = authorizationService.hasPermission(Permissions.MARKETPLACE_ALL, Privilege.WRITE);
    this.showDeleteDiscountButton = authorizationService.hasPermission(Permissions.MARKETPLACE_ALL, Privilege.WRITE);
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  public onAddDiscountButtonPressed() {
    this.router.navigate(['marketplace', 'discounts', 'new']);
  }

  public deleteDiscount(discount: Discount, callback: () => void) {
    this.discountsService.deleteDiscount(discount.id, {
      success: () => {
        this.refreshDiscounts();
        callback();
      },
      error: (error: any) => {
        this.snackBar.open("Can't delete this discount!", '', {duration: 2000});
        callback();
      }
    });
  }

  public onClickDelete(discount: Discount) {
    this.dialog.open(DeleteDialog, {
      width: '20vw',
      height: '10vh',
      data: {name: discount.id, onClickConfirm: (callback: () => void) => this.deleteDiscount(discount, callback)}
    })
  }

  private refreshDiscounts() {
    if (this.refreshingDiscounts) {
      return;
    }

    this.refreshingDiscounts = true;
    this.discountsService.getDiscounts().pipe(takeWhile(val => !this._destroyed.getValue())).subscribe({
      next: (discounts: Discount[]) => {
        this.discounts = discounts;
        this.refreshingDiscounts = false;
      },
      error: (error: any) => {
        this.refreshingDiscounts = false;
        console.log(error);
      }
    });
  }
}

