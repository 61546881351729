import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CategoryService } from 'src/app/_services/category.service';
import { ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { DeleteDialog } from 'src/app/common/delete-dialog/delete-dialog.component';
import Category from 'src/app/models/category.model';

@Component({
  selector: 'app-browse-categories',
  templateUrl: './browse-categories.component.html',
  styleUrls: ['./browse-categories.component.css']
})
export class BrowseCategoriesComponent implements OnInit, OnDestroy, ToolbarController {
  private static readonly DEFAULT_PAGE_SIZE = 20

  title = new BehaviorSubject<string>('Categories');

  page = 0;
  length = 0;
  pageSize = BrowseCategoriesComponent.DEFAULT_PAGE_SIZE;
  pageSizeOptions: number[] = [20, 40, 60, 80, 100];
  displayedColumns: string[] = ['id', 'name', 'actions'];
  
  categories: Category[] = [];
  isLoading = true
  private loadingSubscription: Subscription | null = null

  constructor(
    private toolbarService: ToolbarService,
    private categoryService: CategoryService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.pageSize = params.pageSize ? params.pageSize : BrowseCategoriesComponent.DEFAULT_PAGE_SIZE;
      this.page = params.page ? params.page : 0;

      this.fetchCategories(this.page);
    })
  }

  fetchCategories(page: number): void {
    this.isLoading = true

    if (this.loadingSubscription != null) {
      this.loadingSubscription.unsubscribe();
    }
    this.loadingSubscription = this.categoryService.getCategories(this.pageSize, this.pageSize * page).subscribe({
      next: (categories: Category[]) => {
        this.categories = categories;
        this.length = Number.MAX_VALUE;
        this.isLoading = false
        this.loadingSubscription = null;
      },
      error: (error: any) => {
        console.log(error);
        this.categories = [];
        this.length = 0;
        this.isLoading = false
        this.loadingSubscription = null;
      }
    });
  };
  
  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  private deleteCategory(category: Category, callback: () => void) {
    this.categoryService.deleteCategory(category.id).subscribe({
      next: () => {
        this.fetchCategories(this.page);
        callback();
      },
      error: (error) => {
        this.snackBar.open("Failed to delete category!", '', {duration: 2000});
        callback();
      }
    })
  }

  public onClickDelete(category: Category) {
    this.dialog.open(DeleteDialog, {
      data: {name: category.name, onClickConfirm: (callback: () => void) => this.deleteCategory(category, callback)}
    })
  }

  pageChangeEvent(event: PageEvent): void {
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updateUrlParametersIfNeeded();
  }

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      page: this.page == 0 ? null : this.page,
      pageSize: this.pageSize == BrowseCategoriesComponent.DEFAULT_PAGE_SIZE ? null : this.pageSize
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }
}
