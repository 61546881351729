import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import IntakeReport from '../models/intake-report.model';
import Order from '../models/order.model';
import User from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class IntakeReportsService {
  constructor(private http: HttpClient) { }

  public getIntakeReports(status: string | null, priority: string | null, result: string | null, blocked: boolean | null, pageSize: number, offset: number): Observable<any> {
      var path = `/verification/intake-reports?pageSize=${pageSize}&offset=${offset}&expand[]=order.listing.model&expand[]=listing.model`;
      if (!!status) {
        path += `&status[]=${status}`;
      }
      if (!!priority) {
        path += `&priority[]=${priority}`;
      }
      if (!!result) {
        path += `&result[]=${result}`;
      }
      if (blocked != null) {
        path += `&blocked=${blocked}`;
      }
      return this.http.get<IntakeReport[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
          map(response => {
            return {
              data: response.body!.map(d => this.toIntakeReport(d)),
              totalCount:  response.headers.get('X-Total-Count')
            }
          })
        );
  }

  public getIntakeReport(intakeReportId: number) : Observable<any> {
      var path = "/verification/intake-reports/" + intakeReportId + "?expand[]=order.listing.model&expand[]=listing.model&expand[]=completedBy";
      return this.http.get<IntakeReport>(environment.apiUrl + path, {observe: 'response'}).pipe(
          map(response =>  this.toIntakeReport(response.body)));
  }

  public createIntakeReport(template: any): Observable<any> {
    var path = "/verification/intake-reports";
    return this.http.post<IntakeReport>(environment.apiUrl + path, template)
        .pipe(map(response => this.toIntakeReport(response)));
  }

  public modifyIntakeReport(intakeReportId: number, template: any): Observable<any> {
    var path = "/verification/intake-reports/" + intakeReportId;
    return this.http.patch<IntakeReport>(environment.apiUrl + path, template)
        .pipe(map(response => this.toIntakeReport(response)));
  }

  private toIntakeReport(fetchedIntakeReport: any): IntakeReport {
      var intakeReport = Object.assign(new IntakeReport(), fetchedIntakeReport);
      if (intakeReport.order) {
        intakeReport.order = Order.toOrder(intakeReport.order);
      }
      if (intakeReport.completedBy) {
        intakeReport.completedBy = Object.assign(new User(), intakeReport.completedBy);
      }
      return intakeReport;
    }
}
