import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ResourceChangeHistory, ResourceInteractionRevision, ResourceInteraction } from '../models/resource-interaction.model';

@Injectable({
    providedIn: 'root'
  })
  export class ChangeHistoryService {
    constructor(private http: HttpClient) { }
  
    public getListingHistory(listingId : number) : Observable<any> {
        return this.getHistory("marketplace",["listing_" + listingId]);
    }

    public getProxyBidHistory(proxyBidId: number) : Observable<any> {
        return this.getHistory("marketplace",["proxyBid_" + proxyBidId]);
    }

    private getHistory(namespace: string, resource: string[]) : Observable<any> {
        var path = `/audit/resource-change-history?namespace=${namespace}`
        for (let res of resource) {
            path = path + `&resource[]=${res}`
        }
        return this.http.get<ResourceChangeHistory[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
            map(response => {
                return response.body!.map(rc => Object.assign(new ResourceChangeHistory(), rc));
            })
        );
    }
  }