import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, forkJoin } from 'rxjs';
import { BrandsService } from 'src/app/_services/brands.service';
import { ImageService } from 'src/app/_services/image.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import Brand from 'src/app/models/brand.model';

@Component({
  selector: 'app-brands-create',
  templateUrl: './brands-create.component.html',
  styleUrls: ['./brands-create.component.css']
})
export class BrandsCreateComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Brands", path: ['/catalog', 'brands'] }]);
  title = new BehaviorSubject<string>("Add Brand");

  formGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    displayName: new FormControl(''),
    description: new FormControl('')
  });
  get formControls() {
    return this.formGroup.controls;
  }

  wordmark: InstanceType<typeof Image> | null = null
  logo: InstanceType<typeof Image> | null = null
  cardImage: InstanceType<typeof Image> | null = null
  bannerImage: InstanceType<typeof Image> | null = null
  heroModelImage: InstanceType<typeof Image> | null = null

  submitting: boolean = false;

  constructor(
    private toolbarService: ToolbarService,
    private brandsService: BrandsService,
    private imageService: ImageService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  onSelectWordmark(target: EventTarget | null) {
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    var input = target as HTMLInputElement;
    Array.prototype.forEach.call(input.files, file => {
      var img = new Image();
      var reader = new FileReader();
      reader.onload = event => {
        img.src = event.target!.result as string;
      }
      reader.readAsDataURL(file);
      this.wordmark = img
    });
  }

  onRemoveWordmark() {
    this.wordmark = null;
  }

  onSelectLogo(target: EventTarget | null) {
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    var input = target as HTMLInputElement;
    Array.prototype.forEach.call(input.files, file => {
      var img = new Image();
      var reader = new FileReader();
      reader.onload = event => {
        img.src = event.target!.result as string;
      }
      reader.readAsDataURL(file);
      this.logo = img
    });
  }

  onRemoveLogo() {
    this.logo = null;
  }

  onSelectCardImage(target: EventTarget | null) {
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    var input = target as HTMLInputElement;
    Array.prototype.forEach.call(input.files, file => {
      var img = new Image();
      var reader = new FileReader();
      reader.onload = event => {
        img.src = event.target!.result as string;
      }
      reader.readAsDataURL(file);
      this.cardImage = img
    });
  }

  onRemoveCardImage() {
    this.cardImage = null;
  }

  onSelectBannerImage(target: EventTarget | null) {
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    var input = target as HTMLInputElement;
    Array.prototype.forEach.call(input.files, file => {
      var img = new Image();
      var reader = new FileReader();
      reader.onload = event => {
        img.src = event.target!.result as string;
      }
      reader.readAsDataURL(file);
      this.bannerImage = img
    });
  }

  onRemoveBannerImage() {
    this.bannerImage = null;
  }

  onSelectHeroModelImage(target: EventTarget | null) {
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    var input = target as HTMLInputElement;
    Array.prototype.forEach.call(input.files, file => {
      var img = new Image();
      var reader = new FileReader();
      reader.onload = event => {
        img.src = event.target!.result as string;
      }
      reader.readAsDataURL(file);
      this.heroModelImage = img
    });
  }

  onRemoveHeroModelImage() {
    this.heroModelImage = null;
  }

  onCancel() {
    this.router.navigate(['/catalog/brands']);
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }
    this.submitting = true;

    var brand: any = {
      name: this.formControls.name.value
    }
    var displayName = this.formControls.displayName.value
    if (displayName != null && displayName.length > 0) {
      brand.displayName = displayName
    }
    var description = this.formControls.description.value
    if (description != null && description.length > 0) {
      brand.description = description
    }
    this.brandsService.createBrand(brand).subscribe({
      next: (brand: Brand) => {
        var wordmarkUpload: Observable<number> = of(-1);
        if (this.wordmark) {
          wordmarkUpload = this.imageService.uploadImage(this.wordmark);
        }
        var logoUpload: Observable<number> = of(-1);
        if (this.logo) {
          logoUpload = this.imageService.uploadImage(this.logo);
        }
        var cardImageUpload: Observable<number> = of(-1);
        if (this.cardImage) {
          cardImageUpload = this.imageService.uploadImage(this.cardImage);
        }
        var bannerImageUpload: Observable<number> = of(-1);
        if (this.bannerImage) {
          bannerImageUpload = this.imageService.uploadImage(this.bannerImage);
        }
        var heroModelImageUpload: Observable<number> = of(-1);
        if (this.heroModelImage) {
          heroModelImageUpload = this.imageService.uploadImage(this.heroModelImage);
        }

        forkJoin([wordmarkUpload, logoUpload, cardImageUpload, bannerImageUpload, heroModelImageUpload]).subscribe({
          next: imageIds => {
            var wordmarkImageId = imageIds[0];
            var logoImageId = imageIds[1];
            var cardImageId = imageIds[2];
            var bannerImageId = imageIds[3];
            var heroModelImageId = imageIds[4];

            if (wordmarkImageId > -1 || logoImageId > -1 || cardImageId > -1) {
              var updates: any = {}
              if (wordmarkImageId > -1) {
                updates.wordmark = wordmarkImageId;
              }
              if (logoImageId > -1) {
                updates.logo = logoImageId;
              }
              if (cardImageId > -1) {
                updates.cardImage = cardImageId;
              }
              if (bannerImageId > -1) {
                updates.bannerImage = bannerImageId;
              }
              if (heroModelImageId > -1) {
                updates.heroModelImage = heroModelImageId;
              }
              this.brandsService.updateBrand(brand.id, updates).subscribe({
                next: () => {
                  this.handleBrandCreated(brand.id);
                },
                error: error => {
                  // Failed to patch model images.
                  console.error(error);
                  alert("Failed to attach images to created brand.")
                  this.handleBrandCreated(brand.id);
                }
              })
            } else {
              this.handleBrandCreated(brand.id);
            }
          },
          error: error => {
            // Failed to upload images.
            console.error(error);
            alert("Failed to upload images.")
            this.handleBrandCreated(brand.id);
          }
        });
      },
      error: error => {
        this.submitting = false;
        if (error.status === 403) {
          alert("You don't have the required permission to perform this operation.")
        } else {
          alert(error);
        }
      }
    });
  }

  private handleBrandCreated(newModelId: number) {
    this.router.navigate(['catalog', 'brands']);

    // Display a toast that links to the newly created brands's detail page.
    let snackBarRef = this.snackBar.open('\"' + (this.formControls.displayName.value || this.formControls.name.value) + '\" added to the catalog', 'View', {
      duration: 3000
    });
    snackBarRef.onAction().subscribe(() => {
      this.router.navigate(['catalog', 'brands', newModelId]);
    });
  }

  onClear() {
    this.formGroup.reset();
    this.wordmark = null;
    this.logo = null;
    this.cardImage = null;
    this.bannerImage = null;
    this.heroModelImage = null;
  }
}
