import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  images: string[],
  currentIdx: number
}

@Component({
  selector: 'image-gallery',
  templateUrl: './image-gallery.component.html',
})
export class ImageGalleryDialog implements OnInit, OnDestroy {
  selectedImageIdx!: number

  constructor(    
    public dialogRef: MatDialogRef<ImageGalleryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.selectedImageIdx = data.currentIdx ? data.currentIdx : 0;
  }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  onClickNext() {
    if (this.selectedImageIdx + 1 >= this.data.images.length) {
      this.selectedImageIdx = 0;
    } else {
      this.selectedImageIdx = this.selectedImageIdx + 1;
    }
  }

  onClickPrevious() {
    if (this.selectedImageIdx - 1 < 0) {
      this.selectedImageIdx = this.data.images.length - 1;
    } else {
      this.selectedImageIdx = this.selectedImageIdx - 1;
    }
  }

  get currentImage() {
    return this.data.images[this.selectedImageIdx];
  }

  onClose(): void {
    this.dialogRef.close();
  }
}