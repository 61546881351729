import { environment } from 'src/environments/environment';

export default class User {
  public static readonly DOLPHIN_UUID = environment.dolphinUuid;
  public static readonly PLATFORM_CORE_UUID = environment.platformCoreUuid;

  uuid!: string;
  givenName!: string;
  familyName!: string;
  email!: string;
  emailVerified!: boolean;
  phoneNumber!: string;
  mfaEnabled!: boolean;
  fullName!: boolean;
  created!: Date;

  get displayName() {
    if (this.uuid === User.DOLPHIN_UUID) {
      return "dolphin"
    } else if (this.uuid === User.PLATFORM_CORE_UUID) {
      return "platform-core"
    }

    var labelParts: string[] = [];
    if (this.givenName) {
      labelParts.push(this.givenName);
    }
    if (this.familyName) {
      labelParts.push(this.familyName);
    }

    if (labelParts.length > 0) {
      labelParts.push(`(${this.email})`);
    } else {
      labelParts.push(`${this.email}`);
    }

    return labelParts.join(' ');
  }
}
