<table mat-table [dataSource]="colors">
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Id </th>
        <td mat-cell *matCellDef="let color"> {{color.id}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let color"> {{color.name}} </td>
    </ng-container>
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let color" style="text-align: right;">
            <button mat-icon-button (click)="onClickDelete(color)" *ngIf="showDeleteColorButton">
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<span *ngIf="refreshingColors" class="loading-spinner-container">
    <mat-spinner [diameter]="30" class="loading-spinner"></mat-spinner>
</span>
<button mat-fab class="fab" (click)="onAddColorButtonPressed()" *ngIf="showAddColorButton">
    <mat-icon>add</mat-icon>
</button>