<div class="d-flex flex-column w-100" style="gap: 24px; padding: 20px">
    <div>
        <h2>Update delivery address</h2>
        <p>
            Note: Changing the delivery address will trigger risk assessments to rerun. This may result in new failures.
        </p>
    </div>
    <div class="d-flex flex-column w-100" style="gap: 12px">
        <mat-form-field appearance="outline">
            <mat-label>Full Name</mat-label>
            <input type="text" matInput placeholder="John Doe" [formControl]="fullName" required>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Street Address</mat-label>
            <input type="text" matInput placeholder="5200 Wilshire Blvd" [formControl]="line1" required>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Apt, Suite, Etc</mat-label>
            <input type="text" matInput placeholder="Apt 426" [formControl]="line2">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Line 3</mat-label>
            <input type="text" matInput placeholder="" [formControl]="line3">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>City</mat-label>
            <input type="text" matInput placeholder="Los Angeles" [formControl]="city" required>
        </mat-form-field>
        <div class="d-flex flex-row" style="gap: 12px">
            <mat-form-field appearance="outline">
                <mat-label>State</mat-label>
                <input type="text" matInput placeholder="CA" [formControl]="state" required>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Postal Code</mat-label>
                <input type="text" matInput placeholder="90036" [formControl]="postalCode" required>
            </mat-form-field>
        </div>
        <mat-form-field appearance="outline">
            <mat-label>Country</mat-label>
            <input type="text" matInput placeholder="USA" [formControl]="country" required>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Phone Number</mat-label>
            <input type="text" matInput placeholder="+16508675309" [formControl]="phoneNumber" required>
        </mat-form-field>
    </div>
    <div class="d-flex flex-row justify-content-between">
        <button mat-raised-button color="warn" (click)="onClickCancel()">Cancel</button>
        <button mat-raised-button color="primary" (click)="onClickSave()" [class.spinner]="isSaving" [disabled]="isSaving">Save</button>
    </div>
</div>