<ng-container *ngIf="brand else loading">
    <div class="container">
        <div class="section">
            <div class="section-title">
                Details
            </div>
            <div class="section-content">
                <div class="labeled-box">
                    <div class="label">
                        Name
                    </div>
                    {{ brand.name }}
                </div>
                <div class="labeled-box" *ngIf="brand.displayName">
                    <div class="label">
                        Display Name
                    </div>
                    {{ brand.displayName }}
                </div>
              <div class="labeled-box" *ngIf="brand.description">
                <div class="label">
                  Description
                </div>
                {{ brand.description }}
              </div>
                <br />
                <div class="labeled-box" *ngIf="brand.aliases.length > 0">
                    <div class="label">
                        Aliases
                    </div>
                    {{ brand.aliases.toString() }}
                </div>
            </div>
        </div>
        <div class="section" *ngIf="brand.wordmark || brand.logo || brand.cardImage || brand.bannerImage || brand.heroModelImage">
            <div class="section-title">
                Images
            </div>
            <div class="section-content">
                <div class="labeled-box" *ngIf="brand.wordmark">
                    <div class="label">Wordmark</div>
                    <div class="image-grid">
                        <img [src]="brand.wordmark.rawUrl" />
                    </div>
                </div>
                <div class="labeled-box" *ngIf="brand.logo">
                    <div class="label">Logo</div>
                    <div class="image-grid">
                        <img [src]="brand.logo.rawUrl" />
                    </div>
                </div>
              <div class="labeled-box" *ngIf="brand.cardImage">
                <div class="label">Card</div>
                <div class="image-grid">
                  <img [src]="brand.cardImage.rawUrl" />
                </div>
              </div>
              <div class="labeled-box" *ngIf="brand.bannerImage">
                <div class="label">Banner</div>
                <div class="image-grid">
                  <img [src]="brand.bannerImage.rawUrl" />
                </div>
              </div>
              <div class="labeled-box" *ngIf="brand.heroModelImage">
                <div class="label">Hero Model (Cluster)</div>
                <div class="image-grid">
                  <img [src]="brand.heroModelImage.rawUrl" />
                </div>
              </div>
            </div>
        </div>
        <div class="section">
            <div class="section-title">
                Actions
            </div>
            <div class="section-content">
                <button mat-raised-button color="primary" [disabled]="deleting || !canEditBrand" (click)="onEditButtonPressed()" style="margin-right: 10px">Edit</button>
                <button mat-raised-button color="warn" [disabled]="deleting || !canEditBrand" (click)="onDeleteButtonPressed()" [class.spinner]="deleting">Delete</button>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #loading>
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
</ng-template>
