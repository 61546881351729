import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import Program from 'src/app/models/program.model';
import { ProgramsService } from 'src/app/_services/programs.service';

@Component({
  selector: 'program-select',
  templateUrl: './program-select.component.html',
})
export class ProgramSelectComponent implements OnInit {
    @Input('control') control!: FormControl;
    @Input('required') required: boolean = false;
    
    programs: Program[] = []

    constructor(private programsService: ProgramsService) { }

    ngOnInit(): void {
        this.programsService.getPrograms(-1, 0).subscribe({
            next: programs => {
                this.programs = programs;
            }
        })
    }
}
