import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { JwtHelperService } from "@auth0/angular-jwt";

export class Privilege {
  public static READ = "read";
  public static CREATE = "create";
  public static WRITE = "write";
  public static DELETE = "delete";

  private static heirarchy = [Privilege.READ, Privilege.CREATE, Privilege.WRITE, Privilege.DELETE];

  public static getEncompassingPrivileges(privilege: string): string[] {
    var index = this.heirarchy.indexOf(privilege);
    if (index == -1) {
      return []
    }

    return this.heirarchy.slice(0, index + 1);
}
}

export class Permissions {
  public static PLATFORM_ALL: string = "platform_all";

  public static CATALOG_ALL: string  = "catalog_all";

  public static EDITORIAL_ALL: string  = "editorial_all";

  public static PAYMENT_ALL: string  = "payment_all";
  public static PAYMENT_BILLING_ACCOUNTS: string  = "payment_billing-accounts";
  public static PAYMENT_CREDIT_BALANCE_ADJUSTMENTS: string  = "payment_credit-balance-adjustments";

  public static MARKETPLACE_ALL: string  = "marketplace_all";
  public static MARKETPLACE_SELLER_PROFILES: string  = "marketplace_seller-profiles";
  public static MARKETPLACE_LISTINGS: string  = "marketplace_listings";
  public static MARKETPLACE_DISCOUNTS: string  = "marketplace_discounts";
  public static MARKETPLACE_INGESTION_REQUESTS: string  = "marketplace_ingestion-requests";

  public static VERIFICATION_ALL: string  = "verification_all";
  public static VERIFICATION_VERIFICATION_REPORTS: string  = "verification_verification-reports";

  public static NOTIFICATIONS_ALL: string  = "notifications_all";

  public static SEARCH_ALL: string  = "search_all";
}

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  private jwtHelper = new JwtHelperService()
  private userUuid?: string;
  private permissions: string[] = []

  didInit = false

  constructor(public authenticationService: AuthService) {

  }

  public initIfNeeded(callback?: () => void) {
    if (this.didInit) {
      callback?.()
      return
    }
    this.didInit = true

    this.authenticationService.getAccessTokenSilently().subscribe(token => {
      var decodedToken = this.jwtHelper.decodeToken(token);
      this.userUuid = decodedToken["sub"];
      this.permissions = this.buildEncompassingPermissions(decodedToken["permissions"]);
      callback?.()
    })
  }

  public getAuthenticatedUserUuid(): string {
    if (!this.didInit) {
      throw new Error("Authorization service hasn't been initialized")
    }

    return this.userUuid!
  }

  private buildEncompassingPermissions(scopes: string[]): string[] {
    var result: string[] = []
    for (let scope of scopes) {
      var components = scope.split(":");
      for (let privilege of Privilege.getEncompassingPrivileges(components[1])) {
        result.push(this.formatPermissionPrivilegeToScope(components[0], privilege))
      }
    }
    return result
  }

  private formatPermissionPrivilegeToScope(key: string, privilege: string): string {
    return key + ":" + privilege;
  }

  public hasConsoleAccess(): boolean {
    return this.hasPermission(Permissions.CATALOG_ALL, Privilege.READ)
        || this.hasPermission(Permissions.EDITORIAL_ALL, Privilege.READ)
        || this.hasPermission(Permissions.MARKETPLACE_ALL, Privilege.READ)
        || this.hasPermission(Permissions.MARKETPLACE_SELLER_PROFILES, Privilege.READ)
        || this.hasPermission(Permissions.MARKETPLACE_LISTINGS, Privilege.READ)
        || this.hasPermission(Permissions.MARKETPLACE_DISCOUNTS, Privilege.READ)
        || this.hasPermission(Permissions.MARKETPLACE_INGESTION_REQUESTS, Privilege.READ)
        || this.hasPermission(Permissions.PAYMENT_ALL, Privilege.READ)
        || this.hasPermission(Permissions.PAYMENT_BILLING_ACCOUNTS, Privilege.READ)
        || this.hasPermission(Permissions.PAYMENT_CREDIT_BALANCE_ADJUSTMENTS, Privilege.READ)
        || this.hasPermission(Permissions.NOTIFICATIONS_ALL, Privilege.READ);
  }

  public hasPermission(permission: string, privilege: string): boolean {
    if (this.permissions.indexOf(this.formatPermissionPrivilegeToScope(Permissions.PLATFORM_ALL, privilege)) != -1) {
      return true;
    }

    var nameSpaceAllPermission = permission.split("_")[0] + "_all";
    if (this.permissions.indexOf(this.formatPermissionPrivilegeToScope(nameSpaceAllPermission, privilege))) {
      return true;
    }

    return this.permissions.indexOf(this.formatPermissionPrivilegeToScope(permission, privilege)) != -1;
  }
}
