<div class="container" *ngIf="verificationReport else loading">
  <div class="w100 d-flex flex-row justify-content-center">
    <div style="width: 100%; max-width: 800px;">
      <mat-card class="m-2">
        <mat-card-title>
          <span class="d-flex justify-content-between align-items-center">
            <span class="d-flex justify-content-between align-items-center" style="gap: 10px">
              General Information
            </span>
            <span>
            </span>
          </span>
        </mat-card-title>
        <mat-card-content>
          <div>
            <div class="labeled-box">
              <div class="label">
                Status
              </div>
              <span>{{verificationReport.status}}<ng-container *ngIf="verificationReport.status=='CANCELLED'"> / {{verificationReport.cancellationReason}}</ng-container></span>
            </div>
            <div class="labeled-box" style="cursor: pointer" [matMenuTriggerFor]="prioritySelectMenu">
              <div class="label">
                Priority
              </div>
              <span>{{verificationReport.priority ? verificationReportPriorityDescriptions[verificationReport.priority] : 'None'}}</span>
            </div>
            <mat-menu #prioritySelectMenu="matMenu">
              <button mat-menu-item (click)="onSelectNewPriority(null)">None</button>
              <button mat-menu-item *ngFor="let priority of verificationReportPriorities" (click)="onSelectNewPriority(priority)">{{ verificationReportPriorityDescriptions[priority] }}</button>
            </mat-menu>
            <div class="labeled-box">
              <div class="label">
                Created
              </div>
              <span>{{verificationReport.created | date:'medium'}}</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Updated
              </div>
              <span>{{verificationReport.updated | date:'medium'}}</span>
            </div>
        </div>
        </mat-card-content>
        <mat-card-footer class="p-2 pt-0">
          <button mat-raised-button color="warn" class="ms-2 mb-2" [disabled]="isPerformingAction() || verificationReport.status != 'PENDING'" [class.spinner]="isCancelling" (click)="markCancelled()">Cancel</button>
        </mat-card-footer>
      </mat-card>
      <mat-card class="m-2">
        <mat-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <span>
              Workflow
            </span>
            <span>
            </span>
          </div>
        </mat-card-title>
        <mat-card-content>
            <table class="w100">
                <tr>
                    <th style="padding: 10px 20px 10px 20px;">#</th>
                    <th style="padding: 10px 20px 10px 20px;">Step</th>
                    <th style="padding: 10px 20px 10px 20px;">Status</th>
                    <th style="padding: 10px 20px 10px 20px;">Link</th>
                </tr>
                <tr>
                    <td style="padding: 10px 30px 10px 20px;">1</td>
                    <td style="padding: 10px 30px 10px 20px;">Intake</td>
                    <td style="padding: 10px 30px 10px 20px;">
                      <div class="badge-narrow d-flex flex-row" style="gap: 4px; align-items: center;" [ngClass]="{
                        'badge-narrow-success': verificationReport.intakeReport.status === 'COMPLETE' && verificationReport.intakeReport.result === 'PASSED', 
                        'badge-narrow-error': verificationReport.intakeReport.status === 'COMPLETE' && verificationReport.intakeReport.result === 'FAILED'
                        }">
                            <mat-icon *ngIf="verificationReport.intakeReport.blocked" color="warn" style="font-size: 9pt; color: #5C5C5C; height: fit-content; width: fit-content;">
                              block
                            </mat-icon>
                            <span>{{ verificationReport.intakeReport.status == 'COMPLETE' ? verificationReport.intakeReport.result : verificationReport.intakeReport.status }}</span>
                        </div>
                    </td>
                    <td style="padding: 10px 30px 10px 20px;"><button mat-stroked-button [routerLink]="['/verification/intake-reports', verificationReport.intakeReport.id]">View intake report</button></td>
                </tr>
                <tr>
                    <td style="padding: 10px 30px 10px 20px;">2</td>
                    <td style="padding: 10px 30px 10px 20px;">Diagnostics</td>
                    <td style="padding: 10px 30px 10px 20px;">
                      <div class="badge-narrow d-flex flex-row" style="gap: 4px; align-items: center;" [ngClass]="{
                        'badge-narrow-success': verificationReport.diagnosticReport.status === 'COMPLETE' && verificationReport.diagnosticReport.result === 'PASSED', 
                        'badge-narrow-error': verificationReport.diagnosticReport.status === 'COMPLETE' && verificationReport.diagnosticReport.result === 'FAILED'
                        }">
                            <mat-icon *ngIf="verificationReport.diagnosticReport.blocked" color="warn" style="font-size: 9pt; color: #5C5C5C; height: fit-content; width: fit-content;">
                              block
                            </mat-icon>
                            <span>{{ verificationReport.diagnosticReport.status == 'COMPLETE' ? verificationReport.diagnosticReport.result : verificationReport.diagnosticReport.status }}</span>
                        </div>
                    </td>
                    <td style="padding: 10px 30px 10px 20px;"><button mat-stroked-button [routerLink]="['/verification/diagnostic-reports', verificationReport.diagnosticReport.id]">View diagnostic report</button></td>
                </tr>
                <tr>
                    <td style="padding: 10px 30px 10px 20px;">3</td>
                    <td style="padding: 10px 30px 10px 20px;">Authentication</td>
                    <td style="padding: 10px 30px 10px 20px;">
                      <div class="badge-narrow d-flex flex-row" style="gap: 4px; align-items: center;" [ngClass]="{
                        'badge-narrow-success': verificationReport.authenticationReport.status === 'COMPLETE' && verificationReport.authenticationReport.result === 'PASSED', 
                        'badge-narrow-error': verificationReport.authenticationReport.status === 'COMPLETE' && verificationReport.authenticationReport.result === 'FAILED'
                        }">
                            <mat-icon *ngIf="verificationReport.authenticationReport.blocked" color="warn" style="font-size: 9pt; color: #5C5C5C; height: fit-content; width: fit-content;">
                              block
                            </mat-icon>
                            <span>{{ verificationReport.authenticationReport.status == 'COMPLETE' ? verificationReport.authenticationReport.result : verificationReport.authenticationReport.status }}</span>
                        </div>
                    </td>
                    <td style="padding: 10px 30px 10px 20px;"><button mat-stroked-button [routerLink]="['/verification/authentication-reports', verificationReport.authenticationReport.id]">View authentication report</button></td>
                </tr>
            </table>
        </mat-card-content>
      </mat-card>
      <mat-card class="m-2">
        <mat-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <span>
              Result
            </span>
            <span>
            </span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div>
            <div class="labeled-box">
              <div class="label">
                Status
              </div>
              <span>{{verificationReport.status}}</span>
            </div>
            <div class="labeled-box" *ngIf="verificationReport.result && (verificationReport.status === 'PENDING' || verificationReport.status === 'COMPLETE')">
              <div class="label">
                Result
              </div>
              <span>{{verificationReport.result}}</span>
            </div>
        </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div style="width: 100%; max-width: 400px">
      <mat-card class="m-2">
        <mat-card-title>
          Links
        </mat-card-title>
        <mat-card-content>
            <div class="labeled-box" *ngIf="verificationReport.listing" style="width: 100%">
                <div class="label">
                  Listing
                </div>
                <span><mat-chip-list><listing-chip [listing]="verificationReport.listing"></listing-chip></mat-chip-list></span>
            </div>
            <div class="labeled-box" *ngIf="verificationReport.order" style="width: 100%">
                <div class="label">
                  Order
                </div>
                <span><mat-chip-list><order-chip [order]="verificationReport.order"></order-chip></mat-chip-list></span>
            </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="d-flex flex-column align-items-center m-5">
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
  </div>
</ng-template>
