import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AuthorizationService, Permissions, Privilege } from 'src/app/_services/authorization.service';
import { Complication, CatalogService } from 'src/app/_services/catalog.service';
import { ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteDialog } from 'src/app/common/delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-complications-home',
  templateUrl: './complications-home.component.html',
  styleUrls: ['./complications-home.component.css']
})
export class ComplicationsHomeComponent implements OnInit, OnDestroy, ToolbarController {
  title = new BehaviorSubject<string>("Complications");
  showAddComplicationButton: boolean;
  showDeleteComplicationButton: boolean;

  displayedColumns: string[] = ['id', 'name', 'description', 'actions'];
  complications: Complication[] = [];
  refreshingComplications: boolean = false;

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private catalogService: CatalogService,
    private router: Router,
    private authorizationService: AuthorizationService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.refreshComplications();

    this.showAddComplicationButton = authorizationService.hasPermission(Permissions.CATALOG_ALL, Privilege.WRITE);
    this.showDeleteComplicationButton = authorizationService.hasPermission(Permissions.CATALOG_ALL, Privilege.WRITE);
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  public onAddComplicationButtonPressed() {
    this.router.navigate(['catalog', 'complications', 'new']);
  }

  public deleteComplication(complication: Complication, callback: () => void) {
    this.catalogService.deleteComplication(complication, {
      success: () => {
        this.refreshComplications();
        callback();
      },
      error: error => {
        this.snackBar.open("Can't delete this complication!", '', {duration: 2000});
        callback();
      }
    })
  }

  public onClickDelete(complication: Complication) {
    this.dialog.open(DeleteDialog, {
      width: '20vw',
      height: '10vh',
      data: {name: complication.name, onClickConfirm: (callback: () => void) => this.deleteComplication(complication, callback)}
    })
  }

  private refreshComplications() {
    if (this.refreshingComplications) {
      return;
    }

    this.refreshingComplications = true;
    this.catalogService.getComplications().pipe(takeWhile(val => !this._destroyed.getValue())).subscribe({
      next: (complications: Complication[]) => {
        this.complications = complications;
        this.refreshingComplications = false;
      },
      error: (error: any) => {
        console.log(error);
        this.refreshingComplications = false;
      }
    });
  }
}
