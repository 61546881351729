<div class="d-flex flex-column h-100">
    <mat-card class="m-2">
      <mat-card-content class="d-flex flex-md-row flex-column">
        <mat-form-field appearance="outline" class="mx-1 flex-fill">
          <mat-label>Model</mat-label>
          <input matInput type="search" placeholder="Select a model to filter" class="w-100 h-100" (input)='onSearchTextChanged($event.target.value)' (blur)="onBlur()" (focus)="onFocus()" [(ngModel)]="modelFilterText">
          <button *ngIf="modelFilterText" matSuffix mat-icon-button aria-label="Clear" (click)="clearModelSelection()">
            <mat-icon>close</mat-icon>
          </button>
          <div class="search-results-container" *ngIf="(searchResults | async)!.length > 0 && !hideSearchResults" (mouseenter)="mouseEnterSearchResults()" (mouseleave)="mouseExitSearchResults()">
            <div class="search-result" *ngFor="let model of (searchResults | async)" (click)="onSearchResultClicked(model)">
              <div class="image" *ngIf="model.images?.[0]?.url">
                <img [src]="model.images?.[0]?.url">
              </div>
              <div class="title">
                {{model.brand.displayName ?? model.brand.name}} {{model.displayName ?? model.name}} {{model.referenceNumber}}
              </div>
            </div>
          </div>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <mat-card class="mx-2 mb-2 p-0 flex-fill d-flex flex-column overflow-scroll">
        <div class="flex-fill position-relative overflow-scroll">
        <div *ngIf="isLoading" class="w-100 h-100 position-absolute d-flex align-items-center justify-content-center" style="z-index: 1;">
            <div *ngIf="highlights.length > 0" class="w-100 h-100 position-absolute" style="background-color: rgba(0, 0, 0, 0.1);"></div>
            <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
        </div>
        <div class="h-100 w-100 overflow-scroll">
            <table mat-table matSort (matSortChange)="onSort($event)" [dataSource]="highlights" class="w-100">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Id </th>
                <td mat-cell *matCellDef="let highlight"> {{highlight.id}} </td>
            </ng-container>
            <ng-container matColumnDef="model">
              <th mat-header-cell *matHeaderCellDef> Model </th>
              <td mat-cell *matCellDef="let highlight">
                <mat-chip-list>
                  <model-chip [model]="highlight.model"></model-chip>
                </mat-chip-list>
              </td>
            </ng-container>
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef> Title </th>
              <td mat-cell *matCellDef="let highlight"> {{highlight.formattedTitle}} </td>
            </ng-container>
            <ng-container matColumnDef="priority">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by priority"> Priority </th>
              <td mat-cell *matCellDef="let highlight"> {{highlight.priority}} </td>
            </ng-container>
            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by created" style="width: 240px"> Created </th>
                <td mat-cell *matCellDef="let highlight"> {{highlight.created | date:'medium'}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let highlight; columns: displayedColumns;" [routerLink]="['/content/highlights/', highlight.id]">
            </table>
        </div>
        </div>
        <mat-paginator [length]="length"
                    [pageIndex]="page"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    (page)="pageChangeEvent($event)"
                    aria-label="Select page">
        </mat-paginator> 
    </mat-card>  
</div>
<button mat-fab class="fab" [routerLink]="['/content/highlights/new']">
    <mat-icon>add</mat-icon>
</button>