import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface DialogData {
    name: string,
    action?: string,
    onClickConfirm: (callback: () => void) => void
}

@Component({
    selector: 'delete-dialog',
    templateUrl: './delete-dialog.component.html',
    styleUrls: ['./delete-dialog.component.css']
})

export class DeleteDialog {
    loading!: boolean;

    constructor(
        public dialogRef: MatDialogRef<DeleteDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { 
        this.loading = false;
    }

    onClickConfirm(){
        this.loading = true;
        this.data.onClickConfirm(() => {
            this.loading = false;
            this.dialogRef.close();
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}