import { E } from '@angular/cdk/keycodes';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, debounceTime, distinctUntilChanged, pluck } from 'rxjs';
import { BrandsService } from 'src/app/_services/brands.service';
import { CatalogService } from 'src/app/_services/catalog.service';
import { IntakeReportsService } from 'src/app/_services/intake-reports.service';
import { ListingsService } from 'src/app/_services/listings.service';
import { OrdersService } from 'src/app/_services/orders.service';
import { ReturnsService } from 'src/app/_services/returns.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { VerificationReportsService } from 'src/app/_services/verification-reports.service';
import Accessory from 'src/app/models/accessory.model';
import Brand from 'src/app/models/brand.model';
import IntakeReport, { INTAKE_REPORT_PRIORITIES, INTAKE_REPORT_PRIORITY_DESCRIPTIONS, INTAKE_REPORT_RESULTS, INTAKE_REPORT_RESULT_DESCRIPTIONS } from 'src/app/models/intake-report.model';
import Listing from 'src/app/models/listing.model';
import Order from 'src/app/models/order.model';
import VerificationReport from 'src/app/models/verification-report.model';

@Component({
  selector: 'app-intake-report-detail',
  templateUrl: './intake-report-detail.component.html',
  styleUrls: ['./intake-report-detail.component.css']
})
export class IntakeReportDetailComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Intake Reports", path: ['/verification', 'intake-reports'] }]);
  title = new BehaviorSubject<string>("?");

  intakeReport!: IntakeReport | null;
  _intakeReportId: number | null = null;

  verificationReport!: VerificationReport | null;

  intakeReportPriorities: object;
  intakeReportPriorityDescriptions: object;
  intakeReportResults: object;
  intakeReportResultDescriptions: object;

  isPerformingAction = () => {
    return this.isSaving
        || this.isFinalizing
        || this.isCancelling
  }
  isSaving = false
  isFinalizing = false
  isCancelling = false

  referenceTypeControl = new FormControl<string | null>(null);
  referenceIdControl = new FormControl<string | null>(null);
  brandControl = new FormControl<number | null>(null);
  referenceNumberControl = new FormControl<string | null>(null);
  intakeReasonControl = new FormControl<string | null>(null);
  unpackingIssuesControl = new FormControl<string | null>(null);
  unpackingIssueNotesControl = new FormControl<string | null>(null);
  braceletTypePresentControl = new FormControl<string | null>(null);
  watchPresentControl = new FormControl<string | null>(null);
  braceletPresentControl = new FormControl<string | null>(null);
  linkCountPresentControl = new FormControl<string | null>(null);
  accessoriesPresentControl = new FormControl<string[] | null>(null);
  itemAttestationNotesControl = new FormControl<string | null>(null);
  describedMaxWristSizeControl = new FormControl<string | null>(null);
  measuredMaxWristSizeControl = new FormControl<string | null>(null);
  braceletNotesControl = new FormControl<string | null>(null);
  resultControl = new FormControl<string | null>(null);
  formGroup = new FormGroup({
    referenceType: this.referenceTypeControl,
    referenceId: this.referenceIdControl,
    brand: this.brandControl,
    referenceNumber: this.referenceNumberControl,
    intakeReason: this.intakeReasonControl,
    unpackingIssues: this.unpackingIssuesControl,
    unpackingIssueNotes: this.unpackingIssueNotesControl,
    watchPresent: this.watchPresentControl,
    braceletPresent: this.braceletPresentControl,
    braceletTypePresent: this.braceletTypePresentControl,
    linkCountPresent: this.linkCountPresentControl,
    accessoriesPresent: this.accessoriesPresentControl,
    itemAttestationNotes: this.itemAttestationNotesControl,
    describedMaxWristSize: this.describedMaxWristSizeControl,
    measuredMaxWristSize: this.measuredMaxWristSizeControl,
    braceletNotes: this.braceletNotesControl,
    result: this.resultControl,
  });

  allBrands: Brand[] = []
  allAccessories: Accessory[] = []

  referencedOrder: Order | null = null;
  referencedListing: Listing | null = null

  constructor(
    private toolbarService: ToolbarService,
    private route: ActivatedRoute,
    private intakeReportsService: IntakeReportsService,
    private verificationReportsService: VerificationReportsService,
    private catalogService: CatalogService,
    private brandsService: BrandsService,
    private ordersService: OrdersService,
    private listingsService: ListingsService,
    private returnsService: ReturnsService,
    private router: Router,
    private dialog: MatDialog
    ) {
      this.intakeReportPriorities = INTAKE_REPORT_PRIORITIES;
      this.intakeReportPriorityDescriptions = INTAKE_REPORT_PRIORITY_DESCRIPTIONS;
      this.intakeReportResults = INTAKE_REPORT_RESULTS;
      this.intakeReportResultDescriptions = INTAKE_REPORT_RESULT_DESCRIPTIONS;
      
      this.route.params.pipe(
        pluck("intakeReportId")
      ).subscribe({
        next: intakeReportId => {
          if (this._intakeReportId == intakeReportId) {
            return;
          }
  
          this._intakeReportId = intakeReportId;
          this.title.next(intakeReportId.toString());
          this.reloadIntakeReport();
        }
      })

      brandsService.getBrands().subscribe({
        next: brands => {
          this.allBrands = brands.sort((first,second) => first.displayName!.localeCompare(second.displayName!))
        }
      })
      catalogService.getAccessories().subscribe({
        next: accessories => {
          this.allAccessories = accessories.sort((first,second) => first.name!.localeCompare(second.name!))
        }
      })

      this.referenceTypeControl.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe({
        next: value => {
            this.tryLoadingReferences()
        }
      })
      this.referenceIdControl.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe({
        next: value => {
            this.tryLoadingReferences()
        }
      })
    }

    private reloadIntakeReport() {
      this.intakeReport = null;
      this.intakeReportsService.getIntakeReport(this._intakeReportId!).subscribe({
        next: (intakeReport: IntakeReport) => {
          this.intakeReport = intakeReport;

          if (this.intakeReport.status == 'PENDING') {
            this.formGroup.enable()
          } else {
            this.formGroup.disable()
          }    

          this.referenceTypeControl.setValue(intakeReport.formData.referenceType)
          this.referenceIdControl.setValue(intakeReport.formData.referenceId)
          this.brandControl.setValue(intakeReport.formData.modelBrandId)
          this.referenceNumberControl.setValue(intakeReport.formData.modelReferenceNumber)
          this.intakeReasonControl.setValue(intakeReport.formData.intakeReason);
          this.unpackingIssuesControl.setValue(intakeReport.formData.unpackingIssues);
          this.unpackingIssueNotesControl.setValue(intakeReport.formData.unpackingIssueNotes);
          this.watchPresentControl.setValue(intakeReport.formData.watchPresent);
          this.braceletPresentControl.setValue(intakeReport.formData.braceletPresent);
          this.braceletTypePresentControl.setValue(intakeReport.formData.braceletType);
          this.linkCountPresentControl.setValue(intakeReport.formData.braceletLinkCount);
          this.accessoriesPresentControl.setValue(intakeReport.formData.accessoriesPresent);
          this.itemAttestationNotesControl.setValue(intakeReport.formData.itemAttestationNotes);
          this.describedMaxWristSizeControl.setValue(intakeReport.formData.describedMaxWristSize);
          this.measuredMaxWristSizeControl.setValue(intakeReport.formData.measuredMaxWristSize);
          this.braceletNotesControl.setValue(intakeReport.formData.braceletNotes);
          this.resultControl.setValue(intakeReport.result);

          if (this.intakeReport.order) {
            this.referenceTypeControl.setValue('ORDER')
            this.referenceIdControl.setValue(String(intakeReport.order.id))
            this.referenceTypeControl.disable()
            this.referenceIdControl.disable()
            this.referencedOrder = intakeReport.order
            this.referencedListing = intakeReport.order.listing
          } else if (this.intakeReport.listing) {
            this.referenceTypeControl.setValue('LISTING')
            this.referenceIdControl.setValue(String(intakeReport.listing.id))
            this.referenceTypeControl.disable()
            this.referenceIdControl.disable()
            this.referencedOrder = null
            this.referencedListing = intakeReport.listing
          } else {
            this.referenceTypeControl.setValue(intakeReport.formData.referenceType)
            this.referenceIdControl.setValue(intakeReport.formData.referenceId)
          }
          this.tryLoadingReferences()
          this.handleReferencesChanged()

          this.formGroup.markAsPristine()

          this.reloadVerificationReport()
        },
        error: (error: any) => {
          console.log(error);
        }
      });
    }

    private reloadVerificationReport() {
      this.verificationReportsService.getVerificationReports(null, null, null, this._intakeReportId, null, null, 1, 0).subscribe({
        next: (response: any) => {
          this.verificationReport = response.data.length > 0 ? response.data[0] : null;
        },
        error: (error: any) => {
          console.log(error);
          this.verificationReport = null
        }
      });
    }

    tryLoadingReferences(): void {
      if (this.intakeReport?.order || this.intakeReport?.listing) {
        // Don't allow overriding the order or listing references if linked to report
        return
      }

      if (this.referenceIdControl.value && this.referenceIdControl.value.length > 0) {
        if (this.referenceTypeControl.value == 'LISTING') {
          this.listingsService.getListing(Number(this.referenceIdControl.value)).subscribe({
            next: listing => {
              this.referencedOrder = null
              this.referencedListing = listing;
              this.handleReferencesChanged();
            }, 
            error: error => {
              console.log(error)
              this.referencedOrder = null;
              this.referencedListing = null;
            }
          })
        } else if (this.referenceTypeControl.value == 'ORDER') {
          this.ordersService.getOrder(Number(this.referenceIdControl.value)).subscribe({
            next: order => {
              console.log(order)
              this.referencedOrder = order
              this.referencedListing = order.listing;
              this.handleReferencesChanged();
            }, 
            error: error => {
              console.log(error)
              this.referencedOrder = null;
              this.referencedListing = null;
            }
          })
        } else if (this.referenceTypeControl.value == 'RETURN') {
          this.returnsService.getReturn(Number(this.referenceIdControl.value)).subscribe({
            next: r => {
              this.referencedOrder = r.order
              this.referencedListing = r.order.listing;
              this.handleReferencesChanged();
            }, 
            error: error => {
              console.log(error)
              this.referencedOrder = null;
              this.referencedListing = null;
            }
          })
        } else {
          this.referencedOrder = null;
          this.referencedListing = null;
          this.handleReferencesChanged();
        }
      } else {
        this.referencedOrder = null;
        this.referencedListing = null;
        this.handleReferencesChanged();
      }
    }

    private handleReferencesChanged(): void {
      if (this.referencedOrder) {
        this.brandControl.setValue(this.referencedOrder.listing.model.brand.id)
        this.brandControl.disable()
        this.referenceNumberControl.setValue(this.referencedOrder.listing.model.referenceNumber)
        this.referenceNumberControl.disable()
        this.intakeReasonControl.setValue('ORDER')
        this.intakeReasonControl.disable()
      } else if (this.referencedListing) {
        this.brandControl.setValue(this.referencedListing.model.brand.id)
        this.brandControl.disable()
        this.referenceNumberControl.setValue(this.referencedListing.model.referenceNumber)
        this.referenceNumberControl.disable()
        if (this.referencedListing.activePricingModel == 'AUCTION') {
          this.intakeReasonControl.setValue('AUCTION')
          this.intakeReasonControl.disable()
        }
      } else if (this.formGroup.enabled) {
        this.referenceTypeControl.enable()
        this.referenceIdControl.enable()
        this.brandControl.enable()
        this.referenceNumberControl.enable()
        this.intakeReasonControl.enable()
      }
    }
    
    ngOnInit(): void {
      this.toolbarService.setController(this);
    }
    
    ngOnDestroy(): void {
      this.toolbarService.removeController(this);
    }

    onSelectNewPriority(newPriority: string): void {
      var originalPriority = this.intakeReport!.priority
      this.intakeReport!.priority = newPriority
      this.intakeReportsService.modifyIntakeReport(this._intakeReportId!, {
        priority: newPriority
      }).subscribe({
        next: () => {
          // Success!
        },
        error: (error: any) => {
          console.log(error);
          this.intakeReport!.priority = originalPriority
        }
      });
    }

    markCancelled(): void {
      this.isCancelling = true
      this.intakeReportsService.modifyIntakeReport(this._intakeReportId!, {status: 'CANCELLED'}).subscribe({
        next: () => {
          this.reloadIntakeReport()
          this.isCancelling = false
        },
        error: (error: any) => {
          console.log(error);
          this.isCancelling = false
        }
      });
    }
    
    saveDetails(): void {
      this.isSaving = true
      this.intakeReportsService.modifyIntakeReport(this._intakeReportId!, {
        result: this.resultControl.dirty ? this.resultControl.value : undefined,
        formData: {
          referenceType: this.referenceTypeControl.dirty ? this.referenceTypeControl.value : undefined,
          referenceId: this.referenceIdControl.dirty ? this.referenceIdControl.value : undefined,
          modelBrandId: this.brandControl.value ? this.brandControl.value : null,
          modelReferenceNumber: this.referenceNumberControl.value ? this.referenceNumberControl.value : null,
          intakeReason: this.intakeReasonControl.value ? this.intakeReasonControl.value : null,
          unpackingIssues: this.unpackingIssuesControl.dirty ? this.unpackingIssuesControl.value : undefined,
          unpackingIssueNotes: this.unpackingIssueNotesControl.dirty ? this.unpackingIssueNotesControl.value : undefined,
          watchPresent: this.watchPresentControl.dirty ? this.watchPresentControl.value : undefined,
          braceletPresent: this.braceletPresentControl.dirty ? this.braceletPresentControl.value : undefined,
          braceletType: this.braceletTypePresentControl.dirty ? this.braceletTypePresentControl.value : undefined,
          braceletLinkCount: this.linkCountPresentControl.dirty ? this.linkCountPresentControl.value : undefined,
          accessoriesPresent: this.accessoriesPresentControl.dirty ? this.accessoriesPresentControl.value : undefined,
          itemAttestationNotes: this.itemAttestationNotesControl.dirty ? this.itemAttestationNotesControl.value : undefined,
          describedMaxWristSize: this.describedMaxWristSizeControl.dirty ? this.describedMaxWristSizeControl.value : undefined,
          measuredMaxWristSize: this.measuredMaxWristSizeControl.dirty ? this.measuredMaxWristSizeControl.value : undefined,
          braceletNotes: this.braceletNotesControl.dirty ? this.braceletNotesControl.value : undefined
        },
      }).subscribe({
        next: () => {
          this.reloadIntakeReport()
          this.isSaving = false
        },
        error: (error: any) => {
          console.log(error);
          this.isSaving = false
        }
      });
    }

    markComplete(): void {
      this.isFinalizing = true
      this.intakeReportsService.modifyIntakeReport(this._intakeReportId!, {status: 'COMPLETE'}).subscribe({
        next: () => {
          this.reloadIntakeReport()
          this.isFinalizing = false
        },
        error: (error: any) => {
          console.log(error);
          this.isFinalizing = false
        }
      });
    }
}
