<div class="container" *ngIf="!loading else spinner">
  <mat-card class="my-2">
      <mat-card-title class="mb-4">
          <ng-container *ngIf="!editing">Create feature</ng-container>
          <ng-container *ngIf="editing">Edit feature</ng-container>
      </mat-card-title>
      <mat-card-content>
          <div class="d-flex flex-column">
              <mat-form-field appearance="outline" class="flex-fill">
                  <mat-label>Name</mat-label>
                  <input matInput type="string" placeholder="Heavy Hitters Collection" [formControl]="name" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-fill">
                <mat-label>Label</mat-label>
                <input matInput type="string" placeholder="EVENT" [formControl]="label">
              </mat-form-field>
              <mat-form-field appearance="outline">
                  <mat-label>Type</mat-label>
                  <mat-select [formControl]="type" required>
                      <mat-option *ngFor="let type of allTypes" [value]="type">{{type}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <collection-select *ngIf="type.value == 'COLLECTION_V2'" [control]="object" class="flex-fill" required></collection-select>
          </div>
      </mat-card-content>
      <mat-card-actions class="d-flex justify-content-between">
          <button mat-raised-button color="warn" (click)="onCancel()" [disabled]="submitting">Cancel</button>
          <div>
              <button mat-raised-button color="primary" (click)="onSubmit()"
                  [disabled]="!formGroup.valid || submitting || !formGroup.dirty" [class.spinner]="submitting">
                  <ng-container *ngIf="!editing">Submit</ng-container>
                  <ng-container *ngIf="editing">Save</ng-container>
              </button>
          </div>
      </mat-card-actions>
  </mat-card>
</div>
<ng-template #spinner>
  <mat-card class="m-2 d-flex flex-column align-items-center">
      <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
  </mat-card>
</ng-template>
