import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { pluck } from 'rxjs/operators';
import BillingAccount from 'src/app/models/billing-account.model';
import { AuthorizationService, Privilege, Permissions } from 'src/app/_services/authorization.service';
import { BillingAccountsService } from 'src/app/_services/billing-accounts.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { AdjustCreditBalanceDialogComponent } from '../adjust-credit-balance-dialog/adjust-credit-balance-dialog.component';

@Component({
  selector: 'app-billing-account-detail',
  templateUrl: './billing-account-detail.component.html',
  styleUrls: ['./billing-account-detail.component.css']
})
export class BillingAccountDetailComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Billing Accounts", path: ['/payments', 'billing-accounts'] }]);
  title = new BehaviorSubject<string>("?");

  private _billingAccountId: number | null = null;
  billingAccount!: BillingAccount | undefined | null

  constructor(
    private toolbarService: ToolbarService,
    private billingAccountsService: BillingAccountsService,
    public authorizationService: AuthorizationService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.route.params.pipe(
      pluck("billingAccountId")
    ).subscribe({
      next: billingAccountId => {
        if (this._billingAccountId == billingAccountId) {
          return;
        }

        this._billingAccountId = billingAccountId;
        this.reloadBillingAccount();
      }
    })
  }

  private reloadBillingAccount() {
    this.billingAccount = null;
    this.billingAccountsService.getBillingAccount(this._billingAccountId!).subscribe(billingAccount => {
      this.billingAccount = billingAccount
      this.title.next(`${billingAccount.id}`);
    })
  }

  ngOnInit(): void {
    this.authorizationService.initIfNeeded();
    this.toolbarService.setController(this);
  }
    
  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  canAdjustCreditBalance(): boolean {
    if (this.authorizationService.didInit && this.authorizationService.hasPermission(Permissions.PAYMENT_CREDIT_BALANCE_ADJUSTMENTS, Privilege.READ)) {
      return true;
    }
    return false;
  }

  adjustCreditBalanceButtonPressed() {
    var dialogRef = this.dialog.open(AdjustCreditBalanceDialogComponent, {
      width: '400px',
      data: {billingAccountId: this._billingAccountId},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.updated) {
        this.reloadBillingAccount()
      }
    });
  }
}
