<div class="dialog-content">
    <div class="title">Adjust Credit Balance</div>
    <div class="break"></div>
    <div>
        <form [formGroup]="formGroup">
            <mat-form-field appearance="outline">
                <mat-label>Adjustment Amount (1 credit = 1 cent)</mat-label>
                <input matInput placeholder="4500" formControlName="amount" required>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput placeholder="Credit for trade in of a Rolex Submariner ..."
                    formControlName="description" required></textarea>
            </mat-form-field>
        </form>
    </div>
    <div class="d-flex flex-row-reverse w100">
        <button mat-raised-button color="primary" [disabled]="!formGroup.valid || !formGroup.dirty || isSubmitting"
            [class.spinner]="isSubmitting" (click)="submitButtonPressed();">Submit</button>
        <button mat-button class="mx-2" [disabled]="isSubmitting" (click)="cancelButtonPressed();">Cancel</button>
    </div>
</div>