import Address from "./address.model";

export default class Shipment {
  id!: number;
  sendingAddress!: Address;
  receivingAddress!: Address;
  status!: string;
  lastUpdated!: string;
  shippingLabelUrl!: string;
  fedexTrackingNumber!: string;
  
  get isInTransit(): boolean {
    return this.status == "IN_TRANSIT";
  }

  get isDelivered(): boolean {
    return this.status == "DELIVERED";
  }
}
