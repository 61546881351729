export default class Invoice {
  id!: number;
  basePriceCents!: number;
  purchasePriceCents!: number;
  addonCostCents!: number;
  addons!: [InvoiceAddon]
  discountAmountCents!: number;
  discountCode!: string;
  paymentProcessingFeeCents!: number;
  buyersPremiumCents!: number;
  shippingCostCents!: number;
  totalCostCents!: number;
  totalPriceCents!: number;
  status!: string;
  paymentType!: String;

  bezelCareAddon(): InvoiceAddon | null {
    if (!this.addons) {
      return null;
    }

    for (var addon of this.addons) {
      if (addon.key === 'BEZEL_CARE') {
        return addon;
      }
    }
    return null;
  }
}

class InvoiceAddon {
  key!: string;
  basePriceCents!: number;
  promotionPriceCents?: number;
  priceCents!: number;
}