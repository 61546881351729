<div class="d-flex flex-column h-100">
    <mat-card class="m-2">
        <mat-card-content class="d-flex flex-md-row flex-column">
            <mat-form-field appearance="outline" class="flex-fill mx-1">
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]='typeFilter' name='Type' (selectionChange)="onSelectType($event)">
                    <mat-option *ngFor="let type of allTypes" [value]="type">{{type}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="mx-1 flex-fill">
                <mat-label>Name</mat-label>
                <input matInput type="search" placeholder="New in..." class="w-100 h-100" [ngModel]="nameFilter | async" (ngModelChange)="nameFilter.next($event)">
                <button *ngIf="(nameFilter | async)" matSuffix mat-icon-button aria-label="Clear" (click)="nameFilter.next(null)">
                  <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </mat-card-content>
    </mat-card>
  <mat-card class="mx-2 mb-2 p-0 flex-fill d-flex flex-column overflow-scroll">
      <div class="flex-fill position-relative overflow-scroll">
      <div *ngIf="isLoading" class="w-100 h-100 position-absolute d-flex align-items-center justify-content-center" style="z-index: 1;">
          <div *ngIf="features.length > 0" class="w-100 h-100 position-absolute" style="background-color: rgba(0, 0, 0, 0.1);"></div>
          <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
      </div>
      <div class="h-100 w-100 overflow-scroll">
          <table mat-table matSort (matSortChange)="onSort($event)" [dataSource]="features" class="w-100">
          <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef> Id </th>
              <td mat-cell *matCellDef="let feature"> {{feature.id}} </td>
          </ng-container>
          <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let feature"> {{feature.name}} </td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <td mat-cell *matCellDef="let feature"> {{feature.type}} </td>
        </ng-container>
          <ng-container matColumnDef="created">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by created" style="width: 240px"> Created </th>
              <td mat-cell *matCellDef="let feature"> {{feature.created | date:'medium'}} </td>
          </ng-container>
          <ng-container matColumnDef="updated">
              <th mat-header-cell *matHeaderCellDef style="width: 240px"> Updated </th>
              <td mat-cell *matCellDef="let feature"> {{feature.updated | date:'medium'}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let feature; columns: displayedColumns;" [routerLink]="['/content/features/', feature.id]">
          </table>
      </div>
      </div>
      <mat-paginator [length]="length"
                  [pageIndex]="page"
                  [pageSize]="pageSize"
                  [pageSizeOptions]="pageSizeOptions"
                  (page)="pageChangeEvent($event)"
                  aria-label="Select page">
      </mat-paginator> 
  </mat-card>  
</div>
<button mat-fab class="fab" [routerLink]="['/content/features/new']">
  <mat-icon>add</mat-icon>
</button>