import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { ImageViewComponent } from 'src/app/common/image-view/image-view.component';
import Model from 'src/app/models/model.model';
import { CatalogService, Gender, Color, NumeralType, MovementType, ClaspType, Material, Complication, BraceletStyle } from 'src/app/_services/catalog.service';
import { ImageService } from 'src/app/_services/image.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import Brand from 'src/app/models/brand.model';
import Category from 'src/app/models/category.model';
import { CategoryService } from 'src/app/_services/category.service';
import { BrandsService } from 'src/app/_services/brands.service';
import { SeriesService } from 'src/app/_services/series.service';
import Series from 'src/app/models/series.model';
import { ModelsService } from 'src/app/_services/models.service';
import BezelType from 'src/app/models/bezel-type.model';
import { BezelTypesService } from 'src/app/_services/bezel-types.service';

@Component({
  selector: 'app-edit-model',
  templateUrl: './edit-model.component.html',
  styleUrls: ['./edit-model.component.css']
})
export class EditModelComponent implements OnInit, OnDestroy, ToolbarController {
  private _baseTitlePath = { title: "Models", path: ['/catalog', 'models'] };
  titlePath = new BehaviorSubject<TitlePathElement[]>([this._baseTitlePath, {title: "?", path: []}]);
  title = new BehaviorSubject<string>("Edit Model");
  
  brands: Brand[] = [];
  series: Series[] = [];
  genders: Gender[] = [];
  colors: Color[] = [];
  movementTypes: MovementType[] = [];
  numeralTypes: NumeralType[] = [];
  bezelTypes: BezelType[] = [];
  braceletStyles: BraceletStyle[] = [];
  claspTypes: ClaspType[] = [];
  materials: Material[] = [];
  complications: Complication[] = [];
  categories: Category[] = []

  filteredSeries: Series[] = [];
  filteredBraceletStyles: BraceletStyle[] = [];

  formGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    displayName: new FormControl(''),
    brand: new FormControl<number | null>(null, { nonNullable: true }),
    series: new FormControl<number | null>(null),
    referenceNumber: new FormControl('', Validators.required),
    gender: new FormControl<number | null>(null, Validators.required),
    description: new FormControl(''),
    categories: new FormControl<number[]>([]),
    releaseYear: new FormControl('', [Validators.required, Validators.pattern('\\d{4}')]),
    discontinuationYear: new FormControl('', [Validators.pattern('\\d{4}')]),
    caseMaterials: new FormControl<number[]>([], Validators.required),
    caseSize: new FormControl('', [Validators.required, Validators.pattern(' *\\d+([.]\\d+)?( ?x ?\\d+([.]\\d+)?)? *')]),
    thickness: new FormControl('', [Validators.pattern('\\d+(.\\d+)?')]),
    waterResistance: new FormControl('', Validators.pattern('\\d+(.\\d+)?')),
    bezelType: new FormControl<number | null>(null),
    bezelMaterial: new FormControl<number | null>(null, Validators.required),
    crystal: new FormControl<number | null>(null, Validators.required),
    lugWidth: new FormControl('', [Validators.pattern('\\d+(.\\d+)?')]),
    dialColor: new FormControl<number | null>(null, Validators.required),
    dialNumerals: new FormControl<number | null>(null, Validators.required),
    complications: new FormControl<number[]>([]),
    movementType: new FormControl<number>(0, Validators.required),
    caliber: new FormControl(''),
    powerReserve: new FormControl(0, Validators.pattern('\\d+')),
    numberOfJewels: new FormControl(0, [Validators.required, Validators.pattern('\\d+')]),
    braceletMaterials: new FormControl<number[]>([]),
    braceletColor: new FormControl<number | null>(null),
    braceletStyle: new FormControl<number | null>(null),
    claspType: new FormControl(0),
    claspMaterials: new FormControl<number[]>([]),
  });

  get formControls() {
    return this.formGroup.controls;
  }
  submitting: boolean = false;

  imagesEdited = false;
  images: {
    // Id of the image if it already exists on the model.
    existingId?: number,
    image: InstanceType<typeof Image>
  }[] = []

  model!: Model | null;
  private _modelId: number;
  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private toolbarService: ToolbarService,
    private catalogService: CatalogService,
    private imageService: ImageService,
    private bezelTypesService: BezelTypesService,
    private categoryService: CategoryService,
    private brandsService: BrandsService,
    private seriesService: SeriesService,
    private modelsService: ModelsService,
  ) { 
    const routeParams = this.route.snapshot.paramMap;
    this._modelId = Number(routeParams.get('modelId'));

    modelsService.getModel(this._modelId).subscribe({
      next: (model: Model | null) => {
        if (model == null) {
          return;
        }
        this.model = model;

        this.titlePath.next([this._baseTitlePath, { title: (model.displayName || model.name), path: ['/catalog', 'models', model.id.toString()] }]);
        this.formControls.name.patchValue(model.name);
        this.formControls.displayName.patchValue(model.displayName);
        this.formControls.brand.patchValue(model.brand.id);
        this.formControls.brand.addValidators(Validators.required);
        this.formControls.series.patchValue(model.series ? model.series?.id : null);
        this.formControls.referenceNumber.patchValue(model.referenceNumber);
        this.formControls.gender.patchValue(model.gender.id);
        this.formControls.description.patchValue(model.description);
        this.formControls.categories.patchValue(model.categories.map(c => c.id))
        this.formControls.releaseYear.patchValue(model.releaseYear.toString());
        this.formControls.discontinuationYear.patchValue(model.discontinuationYear?.toString() ?? "");
        this.formControls.caseSize.patchValue(model.caseSize);
        this.formControls.thickness.patchValue(model.thickness?.toString() ?? '');
        this.formControls.waterResistance.patchValue(model.waterResistance?.toString() ?? "");
        this.formControls.bezelType.patchValue(model.bezelType?.id ?? null);
        this.formControls.bezelMaterial.patchValue(model.bezelMaterial.id);
        this.formControls.crystal.patchValue(model.crystal.id);
        this.formControls.lugWidth.patchValue(model.lugWidth?.toString() ?? '');
        this.formControls.caseMaterials.patchValue(model.caseMaterials.map(m => m.id));
        this.formControls.dialColor.patchValue(model.dialColor.id);
        this.formControls.dialNumerals.patchValue(model.numerals.id);
        this.formControls.complications.patchValue(model.complications.map(m => m.id));
        this.formControls.movementType.patchValue(model.movementType.id);
        this.formControls.caliber.patchValue(model.caliber);
        this.formControls.powerReserve.patchValue(model.powerReserve);
        this.formControls.numberOfJewels.patchValue(model.numberOfJewels);
        this.formControls.braceletColor.patchValue(model.braceletColor?.id ?? null);
        this.formControls.claspType.patchValue(model.braceletClaspType?.id ?? null);
        this.formControls.braceletStyle.patchValue(model.braceletStyle?.id ?? null);
        this.formControls.braceletMaterials.patchValue(model.braceletMaterials?.map(m => m.id) ?? []);
        this.formControls.claspMaterials.patchValue(model.braceletClaspMaterials?.map(m => m.id) ?? []);

        this.images = [];
        for (var image of model.images) {
          var img = new Image();
          img.src = image.rawUrl;
          this.images.push({
            existingId: image.id,
            image: img
          })
        }
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    brandsService.getBrands().subscribe({
      next: (brands: Brand[]) => {
        this.brands = brands.sort((a, b) => a.name.localeCompare(b.name));
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    seriesService.listSeries(null).subscribe({
      next: (series: Series[]) => {
        this.series = series.sort((a, b) => a.name.localeCompare(b.name));;
        // Filter to only include series that match the currently selected brand.
        var brandId = this.formControls.brand.value;
        if (brandId) {
          this.filteredSeries = series.filter(series => series.brand.id == brandId);
          this.filteredBraceletStyles = this.braceletStyles.filter(braceletStyle => braceletStyle.brand.id == brandId);
        }
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    catalogService.getGenders().pipe(takeWhile(val => !this._destroyed.getValue())).subscribe({
      next: (genders: Gender[]) => {
        this.genders = genders.sort((a, b) => a.name.localeCompare(b.name));;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    catalogService.getColors().pipe(takeWhile(val => !this._destroyed.getValue())).subscribe({
      next: (colors: Color[]) => {
        this.colors = colors.sort((a, b) => a.name.localeCompare(b.name));;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    catalogService.getMovementTypes().pipe(takeWhile(val => !this._destroyed.getValue())).subscribe({
      next: (movementTypes: MovementType[]) => {
        this.movementTypes = movementTypes.sort((a, b) => a.name.localeCompare(b.name));;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    catalogService.getNumeralTypes().pipe(takeWhile(val => !this._destroyed.getValue())).subscribe({
      next: (numeralTypes: NumeralType[]) => {
        this.numeralTypes = numeralTypes.sort((a, b) => a.name.localeCompare(b.name));;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    catalogService.getClaspTypes().pipe(takeWhile(val => !this._destroyed.getValue())).subscribe({
      next: (claspTypes: ClaspType[]) => {
        this.claspTypes = claspTypes.sort((a, b) => a.name.localeCompare(b.name));;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    catalogService.getBraceletStyles().pipe(takeWhile(val => !this._destroyed.getValue())).subscribe({
      next: (braceletStyles: BraceletStyle[]) => {
        this.braceletStyles = braceletStyles.sort((a, b) => a.name.localeCompare(b.name));;
        this.filteredBraceletStyles = this.braceletStyles.filter(braceletStyle => braceletStyle.brand.id == this.formControls.brand.value);
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    catalogService.getMaterials().pipe(takeWhile(val => !this._destroyed.getValue())).subscribe({
      next: (materials: Material[]) => {
        this.materials = materials.sort((a, b) => a.name.localeCompare(b.name));
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    catalogService.getComplications().pipe(takeWhile(val => !this._destroyed.getValue())).subscribe({
      next: (complications: Complication[]) => {
        this.complications = complications.sort((a, b) => a.name.localeCompare(b.name));
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    this.bezelTypesService.getBezelTypes().subscribe({
      next: (bezelTypes: BezelType[]) => {
        this.bezelTypes = bezelTypes.sort((a, b) => a.name.localeCompare(b.name));
      },
      error: (error: any) => {
        console.log(error);
      }
    });

    this.formControls.brand.valueChanges.pipe(takeWhile(val => !this._destroyed.getValue())).subscribe({
      next: () => {
        var brandId = this.formControls.brand.value;
        if (brandId) {
          // Filter to only include series that match the currently selected brand.
          this.filteredSeries = this.series.filter(series => series.brand.id == brandId);
          this.filteredBraceletStyles = this.braceletStyles.filter(braceletStyle => braceletStyle.brand.id == brandId);
        }
      }
    });
    this.categoryService.getCategories(-1, 0).subscribe({
      next: (categories) => {
        this.categories = categories.sort((a, b) => a.name.localeCompare(b.name))
      }
    })
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  onImageUpload(target: EventTarget | null) {
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    this.imagesEdited = true;

    var input = target as HTMLInputElement;
    Array.prototype.forEach.call(input.files, file => {
      var img = new Image();
      var reader = new FileReader();
      reader.onload = event => {
        img.src = event.target!.result as string;
      }
      reader.readAsDataURL(file);
      this.images.push({ image: img });
    });
  }

  onImageRemove(imageView: ImageViewComponent) {
    this.imagesEdited = true;
    this.images = this.images.filter(images => images.image !== imageView.image);
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }
    this.submitting = true;

    var imageUploads: Observable<number>[] = [];
    if (this.imagesEdited) {
        for (var image of this.images) {
          if (image.existingId) {
            imageUploads.push(of(image.existingId));
          } else {
            imageUploads.push(this.imageService.uploadImage(image.image));
          }
        }
    }

    var changes: any = {};

    if (this.formControls.name.dirty) {
      changes.name = this.formControls.name.value;
    }
    if (this.formControls.displayName.dirty) {
      changes.displayName = this.nullIfEmpty(this.formControls.displayName.value);
    }
    if (this.formControls.brand.dirty) {
      changes.brand = this.formControls.brand.value;
    }
    if (this.formControls.series.dirty) {
      changes.series = this.formControls.series.value;
    }
    if (this.formControls.referenceNumber.dirty) {
      changes.referenceNumber = this.formControls.referenceNumber.value;
    }
    if (this.formControls.gender.dirty) {
      changes.gender = this.formControls.gender.value;
    }
    if (this.formControls.categories.dirty) {
      changes.categories = this.formControls.categories.value;
    }
    if (this.formControls.description.dirty) {
      changes.description = this.nullIfEmpty(this.formControls.description.value);
    }
    if (this.formControls.releaseYear.dirty) {
      changes.releaseYear = this.formControls.releaseYear.value;
    }
    if (this.formControls.discontinuationYear.dirty) {
      changes.discontinuationYear = this.formControls.discontinuationYear.value;
    }
    if (this.formControls.caseMaterials.dirty) {
      changes.caseMaterials = this.formControls.caseMaterials.value;
    }
    if (this.formControls.caseSize.dirty) {
      changes.caseSize = this.formControls.caseSize.value;
    }
    if (this.formControls.thickness.dirty) {
      changes.thickness = this.nullIfEmpty(this.formControls.thickness.value);
    }
    if (this.formControls.waterResistance.dirty) {
      changes.waterResistance = this.nullIfEmpty(this.formControls.waterResistance.value);
    }
    if (this.formControls.bezelType.dirty) {
      changes.bezelType = this.formControls.bezelType.value;
    }
    if (this.formControls.bezelMaterial.dirty) {
      changes.bezelMaterial = this.formControls.bezelMaterial.value;
    }
    if (this.formControls.crystal.dirty) {
      changes.crystal = this.formControls.crystal.value;
    }
    if (this.formControls.lugWidth.dirty) {
      changes.lugWidth = this.nullIfEmpty(this.formControls.lugWidth.value);
    }
    if (this.formControls.dialColor.dirty) {
      changes.dialColor = this.formControls.dialColor.value;
    }
    if (this.formControls.dialNumerals.dirty) {
      changes.numerals = this.formControls.dialNumerals.value;
    }
    if (this.formControls.complications.dirty) {
      changes.complications = this.formControls.complications.value;
    }
    if (this.formControls.movementType.dirty) {
      changes.movementType = this.formControls.movementType.value;
    }
    if (this.formControls.caliber.dirty) {
      changes.caliber = this.nullIfEmpty(this.formControls.caliber.value);
    }
    if (this.formControls.powerReserve.dirty) {
      changes.powerReserve = this.nullIfEmpty(this.formControls.powerReserve.value);
    }
    if (this.formControls.numberOfJewels.dirty) {
      changes.numberOfJewels = this.formControls.numberOfJewels.value;
    }
    if (this.formControls.braceletStyle.dirty) {
      changes.braceletStyle = this.nullIfEmpty(this.formControls.braceletStyle.value)
    }
    if (this.formControls.braceletMaterials.dirty) {
      changes.braceletMaterials = this.formControls.braceletMaterials.value;
    }
    if (this.formControls.braceletColor.dirty) {
      changes.braceletColor = this.nullIfEmpty(this.formControls.braceletColor.value)
    }
    if (this.formControls.claspType.dirty) {
      changes.braceletClaspType = this.nullIfEmpty(this.formControls.claspType.value)
    }
    if (this.formControls.claspMaterials.dirty) {
      changes.braceletClaspMaterials = this.formControls.claspMaterials.value;
    }

    if (imageUploads.length > 0) {
      forkJoin(imageUploads).subscribe({
        next: imageIds => {
          if (this.imagesEdited) {
            changes.images = imageIds;
          }
  
          this.modelsService.updateModel(this._modelId, changes).subscribe({
            next: () => {
              this.router.navigate(['/catalog', 'models', this._modelId]);
            },
            error: error => {
              this.submitting = false;
              console.log(error);
              alert('Failed to update model.');
            }
          })
        },
        error: error => {
          this.submitting = false;
          console.log(error);
          alert('Failed to upload images.');
        }
      })
    } else {
      this.modelsService.updateModel(this._modelId, changes).subscribe({
        next: () => {
          this.router.navigate(['/catalog', 'models', this._modelId]);
        },
        error: error => {
          this.submitting = false;
          console.log(error);
          alert('Failed to update model.');
        }
      })
    }
  }

  private nullIfEmpty(value: any): any | null {
    if (value == null) {
      return null
    }

    if (typeof value == "string" && value.length == 0) {
      return null
    }

    return value
  }
}
