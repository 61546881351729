import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TagsService } from 'src/app/_services/tags.service';
import Tag from 'src/app/models/tag.model';

@Component({
  selector: 'tag-select',
  templateUrl: './tag-select.component.html',
  styleUrls: ['./tag-select.component.css']
})
export class TagSelectComponent implements OnInit {
  @Output('selectionChange') onSelect = new EventEmitter<number[]>();
  @Input('formControl') selectedTags = new FormControl<number[]>([]);

  _tags: Tag[] | null = null;

  constructor(private tagsService: TagsService) {}

  ngOnInit(): void {
    this.tagsService.getTags(null).subscribe({
      next: (tags: Tag[]) => {
        this._tags = tags;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  onSelectTag(): void {
    this.onSelect.emit(this.selectedTags.value!);
  }
}
