import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SettingsService } from '../_services/settings.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  showSidebar: BehaviorSubject<boolean>;

  constructor(
    private settingsService: SettingsService
  ) {
    this.showSidebar = settingsService.sidebarOpen;
  }

  ngOnInit(): void {
    
  }
}
