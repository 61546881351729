import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CatalogService } from 'src/app/_services/catalog.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';

@Component({
  selector: 'app-complications-create',
  templateUrl: './complications-create.component.html',
  styleUrls: ['./complications-create.component.css']
})
export class ComplicationsCreateComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Complications", path: ['/catalog', 'complications'] }]);
  title = new BehaviorSubject<string>("Add Complication");
  
  formGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl(''),
  });
  get formControls() {
    return this.formGroup.controls;
  }
  submitting: boolean = false;

  constructor(
    private toolbarService: ToolbarService,
    private catalogService: CatalogService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  onSubmit() {
    if (this.formGroup.invalid || this.formControls.name.value == null || this.formControls.description.value == null) {
      return;
    }
    this.submitting = true;

    this.catalogService.addComplication(this.formControls.name.value, this.formControls.description.value, {
      success: () => {
        this.router.navigate(['catalog', 'complications']);
      },
      error: error => {
        this.submitting = false;
        alert(error);
      }
    })
  }

  onClear() {
    this.formGroup.reset();
  }
}
