import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Article from 'src/app/models/article.model';
import { ArticlesService } from 'src/app/_services/articles.service';

@Component({
  selector: 'article-select',
  templateUrl: './article-select.component.html',
})
export class ArticleSelectComponent implements OnInit {
    @Input('control') control!: FormControl;
    @Input('required') required: boolean = false;
    @Input('label') label: string = "Article";
    @Input('placeholder') placeholder: string = "Pick an article";
    
    autocompleteResults: Article[] = []

    constructor(private articlesService: ArticlesService) {

    }

    ngOnInit(): void {
        this.control.valueChanges.pipe(
            debounceTime(100),
            distinctUntilChanged()
        ).subscribe({
            next: value => {
                if (value instanceof Article) {
                    return;
                }

                this.articlesService.getArticles(10, 0, '%' + value + '%', null).subscribe({
                    next: articles => {
                        this.autocompleteResults = articles
                    }
                })
            }
        })
    }

    displayArticle(article: Article): string {
        return article?.title ?? ""
    }
}
