import Image from "./image.model";

export default class Article {
  public id!: number;
  public url!: string;
  public title!: string;
  public bannerImage?: Image;
  public publisher!: string;
  public published!: Date;
  public created!: Date;
  public updated!: Date;
}