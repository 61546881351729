import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SettingsService } from 'src/app/_services/settings.service';
import { TitlePathElement, ToolbarService } from 'src/app/_services/toolbar.service';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit, OnDestroy {
  titlePath: BehaviorSubject<TitlePathElement[]>;
  title: BehaviorSubject<string>;

  constructor(
    private authService: AuthService,
    private toolbarService: ToolbarService,
    private settingsService: SettingsService
  ) {
    this.titlePath = toolbarService.titlePath;
    this.title = toolbarService.title;
  }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  onSidebarButtonPressed() {
    this.settingsService.setSidebarOpenSetting(!this.settingsService.sidebarOpen.getValue());
  }

  onLogoutPressed() {
    this.authService.logout({logoutParams: {returnTo: location.origin}})
  }
}
