<div class="container" *ngIf="article && !isLoading else loading">
    <mat-card>
        <mat-card-title>
            <div class="d-flex justify-content-between">
                <span>Details</span>
                <span>
                    <button mat-raised-button color="primary" [routerLink]="['/content/articles', article.id, 'edit']">Edit</button>
                    <button mat-raised-button color="warn" class="mx-2" (click)="deleteButtonPressed()">Delete</button>
                </span>
            </div>
        </mat-card-title>
        <mat-card-content>
            <div class="labeled-box">
                <div class="label">Title</div>
                {{ article.title }}
            </div>
            <div class="labeled-box">
                <div class="label">URL</div>
                <span><a [href]="article.url" target="_blank">{{ article.url }}</a></span>
            </div>
            <div class="labeled-box">
                <div class="label">Publisher</div>
                {{ article.publisher }}
            </div>
            <div class="labeled-box">
                <div class="label">Published</div>
                {{ article.published | date: 'longDate' }}
            </div>
            <div class="labeled-box">
                <div class="label">Created</div>
                {{ article.created | date: 'medium' }}
            </div>
            <div class="labeled-box">
                <div class="label">Last updated</div>
                {{ article.updated | date: 'medium' }}
            </div>
            <div class="labeled-box" *ngIf="article.bannerImage">
                <div class="label">
                    Banner Image
                </div>
                <img [src]="article.bannerImage.rawUrl" />
            </div>
        </mat-card-content>
    </mat-card>
</div>
<ng-template #loading>
    <div class="d-flex flex-column align-items-center m-5">
        <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
    </div>
</ng-template>