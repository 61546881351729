import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { pluck } from 'rxjs/operators';
import BuyerProfile from 'src/app/models/buyer-profile.model';
import { BuyersService } from 'src/app/_services/buyers.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { EditBuyerDetailsComponent } from '../edit-buyer-details/edit-buyer-details.component';
import { OffersService } from 'src/app/_services/offers.service';
import { OrdersService } from 'src/app/_services/orders.service';
import Offer from 'src/app/models/offer.model';
import Order from 'src/app/models/order.model';
import ProxyBid from 'src/app/models/proxy-bid.model';
import { ProxyBidsService } from 'src/app/_services/proxy-bids.service';

@Component({
  selector: 'app-buyers-detail',
  templateUrl: './buyers-detail.component.html',
  styleUrls: ['./buyers-detail.component.scss']
})
export class BuyersDetailComponent implements OnInit, OnDestroy, ToolbarController {
  private static INTERCOM_PREFIX = "https://app.intercom.com/a/apps/yjoqbezd/users/";
  private static INTERCOM_SUFFIX = "/all-conversations";

  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Buyers", path: ['/marketplace', 'buyers'] }]);
  title = new BehaviorSubject<string>("?");

  isPerformingAction = () => {
    return this.isBlocking;
  }
  isBlocking = false;

  private _buyerId: number | null = null;
  buyer!: BuyerProfile | undefined | null

  private _maxNumberOfRecentOffers: number = 5;
  recentOffers: Offer[] = [];
  recentOfferTableCols: string[] = ['id', 'status', 'description', 'price', 'created'];

  private _maxNumberOfRecentBids: number = 5;
  recentBids: ProxyBid[] = [];
  recentBidsTableCols: string[] = ['id', 'status', 'description', 'price', 'created'];

  private _maxNumberOfRecentOrders: number = 5;
  recentOrders: Order[] = [];
  recentOrderTableCols: string[] = ['id', 'status', 'description', 'price', 'created'];

  constructor(
    private toolbarService: ToolbarService,
    private buyersService: BuyersService,
    private offersService: OffersService,
    private ordersService: OrdersService,
    private proxyBidsService: ProxyBidsService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.route.params.pipe(
      pluck("buyerId")
    ).subscribe({
      next: buyerId => {
        if (this._buyerId == buyerId) {
          return;
        }

        this._buyerId = buyerId;
        this.reloadBuyerProfile();
      }
    })
  }

  private reloadBuyerProfile() {
    this.buyer = null;
    this.buyersService.getBuyerProfile(this._buyerId!).subscribe(buyerProfile => {
      this.buyer = buyerProfile
      this.title.next(`${buyerProfile.displayName}`);
    })
    this.offersService.getOffers(this._maxNumberOfRecentOffers, 0, null, null, this._buyerId, null, null, "-created").subscribe(response => {
      this.recentOffers = response.data.slice(0, this._maxNumberOfRecentOffers);
    })
    this.proxyBidsService.getProxyBids(this._maxNumberOfRecentBids, 0, null, null, this._buyerId, null, "-created").subscribe(response => {
      this.recentBids = response.data.slice(0, this._maxNumberOfRecentBids);
    })
    this.ordersService.getOrders(0, null, this._buyerId, null, null, null, "-created").subscribe(response => {
      this.recentOrders = response.data.slice(0, this._maxNumberOfRecentOrders);
    })
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  editButtonPressed(): void {
    var dialogRef = this.dialog.open(EditBuyerDetailsComponent, {
      width: '400px',
      data: {buyerId: this._buyerId},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.updated) {
        this.reloadBuyerProfile()
      }
    });
  }

  canBlock(): boolean {
    return true
  }

  block(): void {
    this.setBlocked(true);
  }

  unblock(): void {
    this.setBlocked(false);
  }

  private setBlocked(blocked: boolean): void {
    this.isBlocking = true;
    this.buyersService.updateBuyerProfile(this._buyerId!, { blocked: blocked }).subscribe({
      next: () => {
        this.isBlocking = false
        this.buyer!.blocked = blocked;
      },
      error: error => {
        console.log(error);
        this.isBlocking = false
      }
    });
  }

  openIntercom(): void {
    window.open(BuyersDetailComponent.INTERCOM_PREFIX + this.buyer?.contact?.intercomId + BuyersDetailComponent.INTERCOM_SUFFIX, "_blank");
  }


  isLoading(): boolean {
    return this.buyer == null;
  }
}
