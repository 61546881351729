import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { DropValidationResponse } from 'src/app/models/drop.model';
import Drop from 'src/app/models/drop.model';

@Injectable({
    providedIn: 'root'
})
export class DropsService {
  constructor(private http: HttpClient) { }

  public getDrops(status: string | null): Observable<any> {
      var path = "/marketplace/drops";
      if (!!status) {
        path += `?status[]=${status}`;
      }
      return this.http.get<Drop[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
          map(response => {
            return {
              data: response.body!.map(d => this.toDrop(d)),
              totalCount:  response.headers.get('X-Total-Count')
            }
          })
        );
  }

  public getDrop(dropId: number, expandListings : boolean) : Observable<any> {
      var path = "/marketplace/drops/" + dropId;
      if (expandListings) {
          path = path + "?expand[]=listings.model"
      }

      return this.http.get<Drop>(environment.apiUrl + path, {observe: 'response'}).pipe(
          map(response =>  this.toDrop(response.body)));
  }

  public modifyDrop(dropId: number, template: any): Observable<any> {
    var path = "/marketplace/drops/" + dropId;
    return this.http.patch<Drop>(environment.apiUrl + path, template)
        .pipe(map(response => this.toDrop(response)));
  }

  public createDrop() : Observable<Drop> {
    var template = {
      status: "DRAFT"
    }
    var path = "/marketplace/drops/";
    return this.http.post<Drop>(environment.apiUrl + path, template)
      .pipe(map(response => this.toDrop(response)));

  }

  public validateDropListing(dropId: number, listingId: number) : Observable<DropValidationResponse> {
    var path = "/marketplace/drops/" + dropId + "/validate/" + listingId;
    return this.http.get<DropValidationResponse>(environment.apiUrl + path,  {observe: 'response'})
      .pipe(map(response => this.toValidationResponse(response)));
  }

  private toDrop(fetchedDrop: any): Drop {
      var drop = Object.assign(new Drop(), fetchedDrop);
      return drop;
    }

  private toValidationResponse(response: any) : DropValidationResponse {
    var dv = Object.assign(new DropValidationResponse(), response.body);
    return dv;
  }
}