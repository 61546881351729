import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SearchResult } from '../models/search-result.model';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(private http: HttpClient) { }

  public search(
    query: string,
    filters: string[] = [],
    types: string[] = ["MODEL", "BUYER_PROFILE", "SELLER_PROFILE", "LISTING", "ORDER", "BILLING_ACCOUNT"],
    expands: string[] = ["user", "model", "listing.model", "owner"]
  ): Observable<{results: SearchResult[]}> {
    var path = `/search?pageSize=8&query=${query}`;
    if (!!filters) {
      for (var filter of filters) {
        path += `&filter[]=${filter}`;
      }
    }
    if (!!types) {
      for (var type of types) {
        path += `&type[]=${type}`
      }
    }
    if (!!expands) {
      for (var expand of expands) {
        path += `&expand[]=${expand}`
      }
    }

    return this.http.get<{results: SearchResult[]}>(environment.apiUrl + path);
  }
}
