import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ErrorDialog } from 'src/app/common/error-dialog/error-dialog.component';
import HomeFeed from 'src/app/models/home-feed.model';
import { HomeFeedsService } from 'src/app/_services/home-feeds.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';

@Component({
  selector: 'app-create-home-feed',
  templateUrl: './create-home-feed.component.html',
  styleUrls: ['./create-home-feed.component.css']
})
export class CreateHomeFeedComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath!: BehaviorSubject<TitlePathElement[]>;
  title!: BehaviorSubject<string>;

  name = new FormControl("", [Validators.required]);
  audience = new FormControl("");
  formGroup = new FormGroup({
    name: this.name,
    audience: this.audience,
  });

  loading: boolean = false;
  editing: boolean = false;
  submitting: boolean = false;
  private _homeFeedId: number | null = null;

  private _destroyed = new ReplaySubject<boolean>(1);

  constructor(
    private toolbarService: ToolbarService,
    private homeFeedsService: HomeFeedsService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) { 
    const routeParams = this.route.snapshot.paramMap;
    if (routeParams.get('homeFeedId')) {
      this._homeFeedId = Number(routeParams.get('homeFeedId'));
      this.editing = true;

      this.titlePath = new BehaviorSubject<TitlePathElement[]>([
        { title: "Home feeds", path: ['/content/home-feeds'] },
        { title: this._homeFeedId!.toString(), path: ['/content/home-feeds', this._homeFeedId!.toString()]}
      ]);
      this.title = new BehaviorSubject<string>("Edit");

      this.loading = true
      this.homeFeedsService.getHomeFeed(this._homeFeedId).subscribe({
        next: homeFeed => {
          this.loading = false;
          this.titlePath.next([
            { title: "Home feeds", path: ['/content/home-feeds'] },
            { title: homeFeed.name ?? homeFeed.id.toString(), path: ['/content/home-feeds', homeFeed.id.toString()]}
          ])

          this.name.setValue(homeFeed.name ?? "null")
          this.audience.setValue(homeFeed.audience)
        },
        error: error => {
          console.log(error)
        }
      })
    } else {
      this.titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Home feeds", path: ['/content/home-feeds'] }]);
      this.title = new BehaviorSubject<string>("Create");
    }
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  onCancel(): void {
    if (this.editing) {
      this.router.navigate(['/content/home-feeds', this._homeFeedId?.toString()])
    } else {
      this.router.navigate(['/content/home-feeds'])
    }
  }

  onSubmit(): void {
    if (this.submitting) {
      return;
    }

    this.submitting = true;

    var params: any = {}
    if (this.name.dirty) {
      params.name = this.name.value;
    }
    if (this.audience.dirty) {
      params.audience = this.audience.value;
    }

    if (this.editing) {
      this.updateHomeFeed(params)
    } else {
      this.createHomeFeed(params)
    }
  }

  createHomeFeed = (params: any) => {
    this.homeFeedsService.createHomeFeed(params).subscribe({
      next: (homeFeed: HomeFeed) => {
        this.router.navigate(['/content/home-feeds', homeFeed.id]);
      },
      error: response => {
        console.log(response.error);
        this.showError(response.error);
        this.submitting = false;
      }
    })
  };

  updateHomeFeed = (params: any) => {
    this.homeFeedsService.updateHomeFeed(this._homeFeedId!, params).subscribe({
      next: (homeFeed: HomeFeed) => {
        this.router.navigate(['/content/home-feeds', homeFeed.id]);
      },
      error: response => {
        console.log(response.error);
        this.showError(response.error);
        this.submitting = false;
      }
    })
  }

  private showError(error: {error: string, description?: string }) {
    this.dialog.open(ErrorDialog,  {
      width: '500px',
      data: {
        error: error
      }
    });
  }
}
