import { Component, OnDestroy, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { ReturnsService } from 'src/app/_services/returns.service';
import Return, {returnReasons, returnReasonDescriptions, refundMethodOptions} from 'src/app/models/return.model';
import { ErrorDialog } from 'src/app/common/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-create-return',
  templateUrl: './create-return.component.html',
  styleUrls: ['./create-return.component.css']
})
export class CreateReturnComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath!: BehaviorSubject<TitlePathElement[]>;
  title!: BehaviorSubject<string>;

  returnReasons = returnReasons
  returnReasonDescriptions = returnReasonDescriptions
  refundMethodOptions = refundMethodOptions

  orderId = new FormControl(null, [Validators.required]);
  restockingFee = new FormControl(null, [Validators.required]);
  refundMethod = new FormControl(null, [Validators.required]);
  reason = new FormControl(null, [Validators.required]);
  formGroup = new FormGroup({
    orderId: this.orderId,
    restockingFee: this.restockingFee,
    refundMethod: this.refundMethod,
    reason: this.reason,
  });

  loading: boolean = false;
  submitting: boolean = false;

  private _destroyed = new ReplaySubject<boolean>(1);
  
  constructor(
    private returnsService: ReturnsService,
    private toolbarService: ToolbarService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) { 
    this.titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Returns", path: ['/marketplace', 'returns'] }]);
    this.title = new BehaviorSubject<string>("Initiate Return");

    this.route.queryParams.subscribe(params => {
      if (params.orderId) {
        this.orderId.setValue(params.orderId);
        this.orderId.disable();
      }
    });
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  onCancel(): void {
    this.location.back()
  }

  onSubmit(): void {
    if (this.submitting) {
      return;
    }

    this.submitting = true;

    var params: any = {}
    params.order = Number(this.orderId.value);
    params.restockingFeeCents = Number(this.restockingFee.value) * 100;
    params.refundMethod = this.refundMethod.value;
    params.reason = this.reason.value;
    this.createReturn(params);
  }

  createReturn = (params: any) => {
    this.returnsService.createReturn(params).subscribe({
      next: (r: Return) => {
        this.router.navigate(['/marketplace/returns', r.id]);
      },
      error: response => {
        console.log(response.error);
        this.showError(response.error);
        this.submitting = false;
      }
    })
  };

  private showError(error: {error: string, description?: string }) {
    this.dialog.open(ErrorDialog,  {
      width: '500px',
      data: {
        error: error
      }
    });
  }
}
