import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Feature } from 'src/app/models/feature.model';

@Component({
    selector: 'feature-card',
    templateUrl: './feature-card.component.html',
    styleUrls: ['./feature-card.component.css'],
})
export class FeatureCard {
    @Input('feature') feature!: Feature;
    @Input('clickable') clickable: boolean = true;

    constructor(
        private router: Router
    ) { }

    onClick(): void {
        if (!this.clickable) {
            return;
        }
        this.router.navigate(['/content/features', this.feature.id]);
    }
}
