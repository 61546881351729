import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { BrandsService } from 'src/app/_services/brands.service';
import { SeriesService } from 'src/app/_services/series.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import Brand from 'src/app/models/brand.model';
import Series from 'src/app/models/series.model';

@Component({
  selector: 'app-series-create',
  templateUrl: './series-create.component.html',
  styleUrls: ['./series-create.component.css']
})
export class SeriesCreateComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Series", path: ['/catalog', 'series'] }]);
  title = new BehaviorSubject<string>("Add Series");
  
  brands: Brand[] = [];

  formGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    displayName: new FormControl(''),
    brand: new FormControl<number>(0, Validators.required),
    releaseYear: new FormControl('', [Validators.pattern('\\d{4}')]),
    discontinuationYear: new FormControl('', [Validators.pattern('\\d{4}')]),
    description: new FormControl(''),
    clusterName: new FormControl('')
  });
  get formControls() {
    return this.formGroup.controls;
  }
  submitting: boolean = false;

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private seriesService: SeriesService,
    private brandsService: BrandsService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) { 
    brandsService.getBrands().pipe(takeWhile(val => !this._destroyed.getValue())).subscribe({
      next: (brands: Brand[]) => {
        this.brands = brands;
      }
    });
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  onSubmit() {
    if (this.formGroup.invalid || this.formControls.name.value == null || this.formControls.brand.value == null) {
      return;
    }
    this.submitting = true;
    var params: any = {
      name: this.formControls.name.value,
      displayName: this.nullIfEmpty(this.formControls.displayName.value),
      brand: this.formControls.brand.value,
      releaseYear: this.nullIfEmpty(this.formControls.releaseYear.value),
      clusterName: this.nullIfEmpty(this.formControls.clusterName.value),
      discontinuationYear: this.nullIfEmpty(this.formControls.discontinuationYear.value),
      description: this.nullIfEmpty(this.formControls.description.value)
    }

    this.seriesService.createSeries(params).subscribe(
      {
      next: (series: Series) => {
        this.router.navigate(['catalog', 'series']);

        // Display a toast that links to the newly created series's detail page.
        let snackBarRef = this.snackBar.open('\"' + this.formControls.name.value + '\" added to the catalog', 'View', {
          duration: 3000
        });
        snackBarRef.onAction().subscribe(() => {
          this.router.navigate(['catalog', 'series', series.id]);
        });
      },
      error: error => {
        this.submitting = false;
        alert(error);
      }
    })
  }

  onClear() {
    this.formGroup.reset();
  }

  private nullIfEmpty(value: any): any | null {
    if (value == null) {
      return null
    }

    if (typeof value == "string" && value.length == 0) {
      return null
    }

    return value
  }
}
