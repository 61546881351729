import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subscription } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { ToolbarService, ToolbarController } from 'src/app/_services/toolbar.service';
import { PageEvent } from '@angular/material/paginator';
import SellerProfile from 'src/app/models/seller-profile.model';
import IngestionRequest, { allStatuses } from 'src/app/models/ingestion-request.model';
import { IngestionRequestsService } from 'src/app/_services/ingestion-requests.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialog } from 'src/app/common/delete-dialog/delete-dialog.component';
import User from 'src/app/models/console/user.model';
import { UsersService } from 'src/app/_services/console/users.service';
import { AuthorizationService } from 'src/app/_services/authorization.service';

@Component({
  selector: 'app-ingestion-requests-home',
  templateUrl: './ingestion-requests-home.component.html',
  styleUrls: ['./ingestion-requests-home.component.css']
})
export class IngestionRequestsHomeComponent implements OnInit, OnDestroy, ToolbarController  {
  private static DEFAULT_PAGE_SIZE = 20;

  ingestionRequests: IngestionRequest[] = [];
  displayedColumns: string[] = ['flagged', 'id', 'status', 'url', 'created', 'updated', 'actions'];

  statusFilter: string = 'ALL';
  sellerFilter!: number;
  flaggedFilter: boolean | null = null;
  assigneeFilter: string | null = null;

  title = new BehaviorSubject<string>('Listing Requests');
  page = 0;
  length = 0;
  pageSize = IngestionRequestsHomeComponent.DEFAULT_PAGE_SIZE;
  pageSizeOptions: number[] = [10,20,40,60,80,100];
  sortKey!: string | null;
  isLoading = true
  private loadingSubscription: Subscription | null = null
  
  consoleUsers!: User[];

  private _destroyed = new BehaviorSubject<boolean>(false);

  @Input() allStatuses: object;
  
  constructor(
    private toolbarService: ToolbarService,
    private ingestionRequestsService: IngestionRequestsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public usersService: UsersService,
    public authorizationService: AuthorizationService,
  ) {
    this.sortKey = '-created';
    this.activatedRoute.queryParams.subscribe(params => {
      this.statusFilter = params.status ? params.status : 'ALL';
      this.sellerFilter = params.seller ? params.seller : null;
      this.flaggedFilter = params.flagged ? params.flagged : null;
      this.assigneeFilter = params.assignee ? params.assignee : null;
      this.page = params.page ? params.page : 0;

      this.fetchIngestionRequests(this.page);
    })
    this.allStatuses = allStatuses;

    this.authorizationService.initIfNeeded(() => {
      console.log(this.authorizationService.getAuthenticatedUserUuid())
      this.usersService.getUsers().subscribe({
        next: users => {
          this.consoleUsers = users.sort((a, b) => {
            if (a.uuid === this.authorizationService.getAuthenticatedUserUuid()) {
              return -1;
            } else if (b.uuid === this.authorizationService.getAuthenticatedUserUuid()) {
              return 1;
            }

            const nameA = a.displayName.toUpperCase();
            const nameB = b.displayName.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          });
        }
      });
    });
  }
    
  ngOnInit(): void {
    this.toolbarService.setController(this);
  }
  
  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  handleMenuClick = (event: any) => {
    event.stopPropagation();
  }

  openInNewTab(ingestionRequest: IngestionRequest) {
    window.open(ingestionRequest.url, '_blank');
  }
  
  flag(ingestionRequest: IngestionRequest) {
    this.ingestionRequestsService.updateIngestionRequest(ingestionRequest.id, { flagged: true }).subscribe({
      next: () => {
        ingestionRequest.flagged = true
      },
      error: error => {
        console.log(error);
      }
    });
  }

  unflag(ingestionRequest: IngestionRequest) {
    this.ingestionRequestsService.updateIngestionRequest(ingestionRequest.id, { flagged: false }).subscribe({
      next: () => {
        ingestionRequest.flagged = false
      },
      error: error => {
        console.log(error);
      }
    });
  }

  archive(ingestionRequest: IngestionRequest) {
    this.dialog.open(DeleteDialog, {
      width: '20vw',
      data: {action: 'archive', name: 'this listing request', onClickConfirm: (callback: () => void) => this.archiveIngestionRequest(ingestionRequest, callback)}
    })
  }
  
  public archiveIngestionRequest(ingestionRequest: IngestionRequest, callback: () => void) {
    this.ingestionRequestsService.archiveIngestionRequest(ingestionRequest.id).subscribe({
      next: () => {
        ingestionRequest.status = 'ARCHIVED'
        callback()
      },
      error: (error: any) => {
        console.log(error);
        callback()
      }
    });
  }

  pageChangeEvent = (event: PageEvent) => {
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updateUrlParametersIfNeeded();
  }

  onSort(event: Sort): void {
    if (event.active == 'created' && event.direction == 'asc') {
      this.sortKey = 'created';
    } else if (event.active == 'created' && event.direction == 'desc') {
      this.sortKey = '-created';
    } else {
      this.sortKey = null;
    }
    this.page = 0;
    this.fetchIngestionRequests(this.page);
  }

  fetchIngestionRequests = (index: number) => {
    this.isLoading = true
    
    var statusForRequest = this.statusFilter === "ALL" ? null : this.statusFilter;

    if (this.loadingSubscription != null) {
      this.loadingSubscription.unsubscribe();
    }
    this.loadingSubscription = this.ingestionRequestsService.getIngestionRequests(this.pageSize * index, this.pageSize, statusForRequest, this.sellerFilter, null, this.flaggedFilter, this.assigneeFilter, this.sortKey).subscribe({
      next: (result: any) => {
        this.ingestionRequests = result.data;
        this.length = result.totalCount;
        this.isLoading = false
        this.loadingSubscription = null;
      },
      error: (error: any) => {
        console.log(error);
        this.ingestionRequests = [];
        this.length = 0;
        this.isLoading = false
        this.loadingSubscription = null;
      }
    });
  };

  onSelectStatus = (event: any) => {
    this.updateUrlParametersIfNeeded();
  }
  
  onChangeSellerFilter(sellerProfile: SellerProfile): void {
    this.sellerFilter = sellerProfile.id;
    this.updateUrlParametersIfNeeded();
  }

  onChangeFlaggedFilter(event: any): void {
    this.updateUrlParametersIfNeeded();
  }

  onChangeAssigneeFilter(event: any): void {
    this.updateUrlParametersIfNeeded();
  }

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      status: this.statusFilter == 'ALL' ? null : this.statusFilter,
      seller: this.sellerFilter == null ? null : this.sellerFilter,
      flagged: this.flaggedFilter == null ? null : this.flaggedFilter,
      assignee: this.assigneeFilter == null ? null : this.assigneeFilter,
      page: this.page == 0 ? null : this.page,
      pageSize: this.pageSize == IngestionRequestsHomeComponent.DEFAULT_PAGE_SIZE ? null : this.pageSize,
    }

    this.router.navigate(
      [], 
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }
}
