import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Bid from '../models/bid.model';
import BuyerProfile from '../models/buyer-profile.model';

@Injectable({
  providedIn: 'root'
})
export class BidsService {
  constructor(private http: HttpClient) { }

  public getBids(listingId : number): Observable<any> {
    var path = `/marketplace/bids?listing[]=${listingId}&expand[]=buyerProfile.user`;
    return this.http.get<Bid[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
      map(response => {
        return response.body!.map(b => this.toBid(b));
      })
    );
  }

  private toBid(fetchedBid: any): Bid {
    var bid = Object.assign(new Bid(), fetchedBid);
    if (bid.buyerProfile) {
      bid.buyerProfile = Object.assign(new BuyerProfile(), bid.buyerProfile);
    }
    return bid;
  }
}
