import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import Listing from 'src/app/models/listing.model';
import { SearchService } from 'src/app/_services/search.service';
import { ListingsService } from 'src/app/_services/listings.service';

export class ListingSelectFilter {
    auctionsOnly: boolean;
    publishedOnly: boolean;

    constructor(auctionsOnly? : boolean, publishedOnly?: boolean) {
        this.auctionsOnly = auctionsOnly ?? false;
        this.publishedOnly = publishedOnly ?? false;
    }

    get hasFilters() {
        return this.auctionsOnly || this.publishedOnly;
    }


    toStrings(): string[] {
      let strings : string[] = [];
      if (this.auctionsOnly) {
        strings.unshift("activePricingModel:auction");
      }
      if (this.publishedOnly) {
        strings.unshift("status:published");
      }
      return strings;
    }
}

@Component({
  selector: 'listing-select',
  templateUrl: './listing-select.component.html',
})
export class ListingSelectComponent implements OnInit {
    @Input('control') control!: FormControl;
    @Input('required') required: boolean = false;
    @Input('label') label: string = "Listing";
    @Input('placeholder') placeholder: string = "Pick a listing";
    @Input('filter') filter: ListingSelectFilter = new ListingSelectFilter();

    autocompleteResults: Listing[] = []
    numFilteredResults = 0;
    baseLabel = this.label;

    isLoading = false

    constructor(
        private searchService: SearchService,
        private listingsService: ListingsService) {

    }

    ngOnInit(): void {
        this.control.valueChanges.pipe(
            debounceTime(200),
            distinctUntilChanged()
        ).subscribe({
            next: value => {
                if (value == null) {
                    return
                }
                if (String(value) !== value) {
                    return
                }

                this.isLoading = true

                let filters : string[] = [];
                if (this.filter.hasFilters) {
                    filters = this.filter.toStrings();
                }

                this.searchService.search(value, filters, ["LISTING"]).subscribe({
                    next: results => {
                        this.autocompleteResults = results.results.map(r => this.toListing(r.object));
                        let numFound = this.autocompleteResults.length;
                        if (numFound > this.autocompleteResults.length) {
                            this.label = this.baseLabel + " - " + (numFound - this.autocompleteResults.length) + " filtered.";
                        }
                        this.isLoading = false
                    },
                    error: error => {
                        console.log(error)
                        this.isLoading = false
                    }
                })
            }
        })
    }

    displayModel(listing: Listing): string {
        if (listing == null) {
            return ""
        }
        return listing.model.displayName ?? listing.model.name ?? ""
    }

    onEnterPressed(): void {
        if (String(this.control.value) !== this.control.value) {
            return
        }

        if (isNaN(this.control.value)) {
            return
        }

        this.isLoading = true
        var listingId = Number(this.control.value)
        this.listingsService.getListing(listingId).subscribe({
            next: listing => {
                this.control.setValue(listing);
                this.isLoading = false
            },
            error: error => {
                console.log(error);
                this.isLoading = false
            }
        })
    }

    private toListing(listing: any): Listing {
        var listing = Object.assign(new Listing(), listing)
        return listing
    }
}
