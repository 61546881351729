import { Filter, Filters, FilterTypes } from './list-filter.model';
import Listing from './listing.model';
import { map, of, BehaviorSubject, Observable } from 'rxjs';
import { CatalogService } from "../_services/catalog.service";
import { ListingsService } from "../_services/listings.service";
import { TagsService } from '../_services/tags.service';
import { BrandsService } from '../_services/brands.service';
import { SeriesService } from '../_services/series.service';

export class ListItem {
  id!: number;
}

export default class List {
  public id!: number;
  public name?: string;
  public type!: string;
  public items!: ListItem[];
  public inventorySensitive?: boolean;
  public created!: Date;
  public updated!: Date;
  public filter?: string;
  public parsedFilters?: Filters | null;
  public sortCriteria?: string;
  public maxLength?: number;
  public contentRestriction?: string;

  get displayType(): string {
    if (this.filter == null) {
      return this.type;
    }
    return this.type.concat(" (generated)");
  }

  get isAuto(): boolean {
    return this.filter != null;
  }

  get isAuctionsOnly(): boolean {
    return this.contentRestriction == "AUCTION_ONLY";
  }

  get availableSubjects() : string[] {
    return Array.from(FilterTypes.keys());
  }

  public updateItems(l: List) {
    this.items = l.items;
  }

  public canAddItem(i: ListItem, ls: ListingsService) : Observable<Boolean> {
    if (this.contentRestriction == "NONE") {
      return of(true);
    }

    return ls.getListing(i.id).pipe(
      map((value: Listing) => {
        return value.activePricingModel == "AUCTION";
      }
    ));
  }

  // Back up the editable fields of the list; this is a partial deep copy.
  public backUp() : List {
    let b = new List();
    b.id = this.id;
    b.name = this.name;
    b.type = this.type;
    b.items = [];
    for (let item of this.items) {
      b.items.push(item);
    }
    b.inventorySensitive = this.inventorySensitive;
    b.filter = JSON.stringify(this.parsedFilters);
    b.sortCriteria = this.sortCriteria;
    b.maxLength = this.maxLength;
    b.contentRestriction = this.contentRestriction;
    return b;
  }

  public restore(b: List) : void {
    this.id = b.id;
    this.name = b.name;
    this.type = b.type;
    this.items = [];
    for (let item of b.items) {
      this.items.push(item);
    }
    this.inventorySensitive = b.inventorySensitive;
    this.filter = b.filter;
    this.sortCriteria = b.sortCriteria;
    this.maxLength = b.maxLength;
    this.contentRestriction = b.contentRestriction;
  }

  public processFilters(cs: CatalogService, bs: BrandsService, ss: SeriesService, ts: TagsService, callback: () => void, checkCancelled: BehaviorSubject<boolean>): void {
    if (this.filter == null) {
      return;
    }
    if (this.parsedFilters == null || this.parsedFilters == undefined) {
      this.parsedFilters = new Filters(JSON.parse(this.filter));
    } else {
      this.parsedFilters.rebuild(JSON.parse(this.filter));
    }

    this.parsedFilters.init(cs, bs, ss, ts, callback, checkCancelled);
  }

  public addFilter(cs: CatalogService, bs: BrandsService, ss: SeriesService, ts: TagsService, callback: () => void, checkCancelled: BehaviorSubject<boolean>) : void {
    if (this.filter == null || this.filter == "") {
      this.filter = "";
      this.processFilters(cs, bs, ss, ts, callback, checkCancelled);
      return;
    }

    if (this.parsedFilters == null || this.parsedFilters == undefined) {
      // Case can occur if the user double clicks add when filters are empty.
      return;
    }

    if (this.parsedFilters.addFilter()) {
      callback();
    }
  }

  public removeFilter(filter: Filter, callback: () => void) : void {
      if (this.parsedFilters?.removeFilter(filter) ?? false) {
        callback();
      }
  }
}

export const types: string[] = [
  'ALL',
  'MODEL',
  'LISTING',
  'BRAND',
  'FEATURE',
  'COLLECTION',
  'ARTICLE',
]
