import { Component, Input, OnInit } from '@angular/core';
import Offer from 'src/app/models/offer.model';

export class PriceHistory {
  priceCents!: number;
  created!: Date;
  initiator!: string;
}

@Component({
  selector: 'price-history',
  templateUrl: './price-history.component.html',
  styleUrls: ['./price-history.component.css']
})
export class PriceHistoryComponent implements OnInit {
  static DEFAULT_MAX: number = 4;
  private static LOAD_MORE_INCREMENT: number = 4;
  math = Math;

  @Input('offer') offer?: Offer;
  max: number = PriceHistoryComponent.DEFAULT_MAX;

  constructor() { }

  ngOnInit(): void {
    this.offer?.priceHistory.push({
      initiator: "LISTED",
      priceCents: this.offer.listing.priceCents
    })
  }

  loadMore(): void {
    this.max += PriceHistoryComponent.LOAD_MORE_INCREMENT;
  }

  showLess(): void {
    this.max = PriceHistoryComponent.DEFAULT_MAX;
  }
}
