import BillingAccount from './billing-account.model';
import User from './user.model';
import Contact from './contact.model';

export default class BuyerProfile {
  id!: number;
  user!: User;
  billingAccount!: BillingAccount;
  trusted!: boolean;
  blocked!: boolean;
  referralCode?: string;
  referralDiscountCents!: number;
  referralRewardCents!: number;
  totalPurchaseCount!: number;
  totalPurchaseValueCents!: number;
  contact?: Contact;

  public hasContactData() : boolean {
    if (this.contact == null) {
      return false;
    }

    return this.contact.assignedContact != null || this.contact.intercomId != null;
  }

  get displayName() {
    var labelParts: string[] = [];
    if (this.user.givenName) {
      labelParts.push(this.user.givenName);
    }
    if (this.user.familyName) {
      labelParts.push(this.user.familyName);
    }
    labelParts.push(`(${this.user.email})`);

    return labelParts.join(' ');
  }
}
