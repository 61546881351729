import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorDialog } from 'src/app/common/error-dialog/error-dialog.component';
import BuyerProfile from 'src/app/models/buyer-profile.model';
import SellerProfile from 'src/app/models/seller-profile.model';
import { BuyersService } from 'src/app/_services/buyers.service';
import { SellersService } from 'src/app/_services/sellers.service';

@Component({
  selector: 'app-edit-buyer-details',
  templateUrl: './edit-buyer-details.component.html',
  styleUrls: ['./edit-buyer-details.component.css']
})
export class EditBuyerDetailsComponent implements OnInit {
  buyer!: BuyerProfile;

  formGroup = new FormGroup({
    trusted: new FormControl(),
    referralCode: new FormControl('', Validators.pattern("^[A-Z]{3}[A-Z0-9]*$")),
    referralDiscount: new FormControl('', Validators.pattern("^[0-9]?[0-9]?[0-9]?([.][0-9]?[0-9]?)?$")),
    referralReward: new FormControl('', Validators.pattern("^[0-9]?[0-9]?[0-9]?([.][0-9]?[0-9]?)?$")),
  });
  get formControls() {
    return this.formGroup.controls;
  }

  isSaving: boolean = false

  constructor(
    private dialogRef: MatDialogRef<EditBuyerDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {buyerId: number},
    private buyersService: BuyersService,
    private dialog: MatDialog
  ) { 
    this.buyersService.getBuyerProfile(this.data.buyerId).subscribe(buyerProfile => {
      this.buyer = buyerProfile;

      this.formGroup.controls.trusted.setValue(this.buyer.trusted);
      if (this.buyer.referralCode != null) {
        this.formGroup.controls.referralCode.setValue(this.buyer.referralCode);
      }
      this.formGroup.controls.referralDiscount.setValue((this.buyer.referralDiscountCents / 100).toString());
      this.formGroup.controls.referralReward.setValue((this.buyer.referralRewardCents / 100).toString());
    })
  }

  ngOnInit(): void { }

  cancelButtonPressed(): void {
    this.close();
  }

  saveButtonPressed(): void {
    this.isSaving = true

    var updateParams: any = { }
    if (this.formGroup.controls.trusted.dirty) {
      updateParams["trusted"] = this.formGroup.controls.trusted.value;
    }

    if (this.formGroup.controls.referralCode.value) {
      updateParams["referralCode"] = this.formGroup.controls.referralCode.value
    } else {
      updateParams["referralCode"] = null
    }

    if (this.formGroup.controls.referralDiscount.value) {
      updateParams["referralDiscountCents"] = Number.parseInt(this.formGroup.controls.referralDiscount.value) * 100;
    }
    if (this.formGroup.controls.referralReward.value) {
      updateParams["referralRewardCents"] = Number.parseInt(this.formGroup.controls.referralReward.value) * 100;
    }

    this.buyersService.updateBuyerProfile(this.data.buyerId, updateParams).subscribe({
      next: () => {
        this.close();
      },
      error: (response) => {
        console.log(response.error);
        this.showError(response.error);
        this.isSaving = false;
      }
    });
  }

  close() {
    this.dialogRef.close({ updated : this.isSaving});    
  }

  isLoading(): boolean {
    return this.buyer == null;
  }

  private showError(error: {error: string, description?: string }) {
    this.dialog.open(ErrorDialog,  {
      width: '500px',
      data: {
        error: error
      }
    });
  }
}
