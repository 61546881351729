import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import Highlight from '../models/highlight.model';

@Injectable({
  providedIn: 'root'
})
export class HighlightsService {
  constructor(
    private http: HttpClient
  ) { }

  public getHighlights(pageSize: number, offset: number, modelId: number | null, sortKey: string | null): Observable<Highlight[]> {
    var path = `/content/highlights?pageSize=${pageSize}&offset=${offset}`;
    if (!!modelId) {
      path += `&modelId[]=${modelId}`;
    }
    if (!!sortKey) {
      path += `&sort=${sortKey}`
    }
    path += "&expand[]=image&expand[]=model"
    return this.http.get<Highlight[]>(environment.apiUrl + path).pipe(
      map(highlight => highlight.map(o => this.toHighlight(o)))
    );
  }

  public getHighlight(id: number): Observable<Highlight> {
    var path = `/content/highlights/${id}?expand[]=image&expand[]=model`;
    return this.http.get<Highlight>(environment.apiUrl + path).pipe(
      map(highlight => this.toHighlight(highlight))
    );
  }

  public createHighlight(params: any): Observable<Highlight> {
    var path = `/content/highlights`;
    return this.http.post<Highlight>(environment.apiUrl + path, params);
  }

  public updateHighlight(id: number, updates: any): Observable<Highlight> {
    var path = `/content/highlights/${id}`;
    return this.http.patch<Highlight>(environment.apiUrl + path, updates);
  }

  public deleteHighlight(id: number): Observable<void> {
    var path = `/content/highlights/${id}`;
    return this.http.delete<void>(environment.apiUrl + path);
  }

  private toHighlight(highlight: any): Highlight {
    var highlight = Object.assign(new Highlight(), highlight)
    return highlight
  }
}
