<span class="main-container">
  <span *ngIf="(showSidebar | async);" class="main-left-content">
    <app-sidebar></app-sidebar>
  </span>
  <span class="main-right-content">
    <span class="main-top-right-content">
      <app-toolbar></app-toolbar>
    </span>
    <span class="main-bottom-right-content bg-light">
      <router-outlet></router-outlet>
    </span>
  </span>
</span>