import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationStart, Router, Event } from '@angular/router';
import { Subscription } from 'rxjs';
import { SearchService } from 'src/app/_services/search.service';

@Component({
  selector: 'app-search-overlay',
  templateUrl: './search-overlay.component.html',
  styleUrls: ['./search-overlay.component.css']
})
export class SearchOverlayComponent implements OnInit {
  isLoading = false
  isFocused = false
  searchResults: { type: string, results: any[] }[] = []
  private _pendingSearchRequest?: Subscription;

  constructor(
    public dialogRef: MatDialogRef<SearchOverlayComponent>,
    public searchService: SearchService,
    private router: Router
  ) { 
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.dialogRef.close();
      }
  });
  }

  ngOnInit(): void {}

  onFocus() {
    this.isFocused = true;
  }

  onBlur() {
    this.isFocused = false;
  }

  hasSearchResults(): boolean {
    return this.searchResults.length > 0
  }

  onSearchTextChanged(text: string) {
    this.searchResults = []
    if (this._pendingSearchRequest) {
      this._pendingSearchRequest.unsubscribe()
      this._pendingSearchRequest = undefined
      this.isLoading = false
    }
    
    if (text.length == 0) {
      return
    }

    this.isLoading = true
    this._pendingSearchRequest = this.searchService.search(text).subscribe(result => {
      this.isLoading = false
      this._parseSearchResult(result)
    })
  }

  private _parseSearchResult(results: any) {
    var resultMap: any = {}
    for (var result of results.results) {
      if (!resultMap[result.type]) {
        resultMap[result.type] = []
      }

      resultMap[result.type].push(result)
    }

    for (var result of results.results) {
      if (!resultMap[result.type]) {
        continue
      }

      this.searchResults.push({
        type: result.type,
        results: resultMap[result.type]
      })
      resultMap[result.type] = undefined
    }
  }

  getSectionHeader(type: string): string {
    if (type == 'MODEL') {
      return 'Models'
    } else if (type == 'COLLECTION') {
      return 'Collections'
    } else if (type == 'BUYER_PROFILE') {
      return 'Buyers'
    } else if (type == 'SELLER_PROFILE') {
      return 'Sellers'
    } else if (type == 'LISTING') {
      return 'Listings'
    } else if (type == 'ORDER') {
      return 'Orders'
    } else if (type == 'BILLING_ACCOUNT') {
      return 'Billing Accounts'
    } else {
      return type
    }
  }
}
