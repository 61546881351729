<ng-container *ngIf="series else loading">
    <div class="container">
        <div class="section">
            <div class="section-title">
                Details
            </div>
            <div class="section-content">
                <div class="labeled-box">
                    <div class="label">
                        Name
                    </div>
                    {{ series.name }}
                </div>
                <div class="labeled-box" *ngIf="series.displayName">
                    <div class="label">
                        Display Name
                    </div>
                    {{ series.displayName }}
                </div>
                <div class="labeled-box">
                    <div class="label">
                        Brand
                    </div>
                    <mat-chip-list>
                        <mat-basic-chip [routerLink]="['/catalog/brands', series.brand.id]">
                            {{series.brand.name}}
                        </mat-basic-chip>
                    </mat-chip-list>
                </div>
                <div class="labeled-box" *ngIf="series.clusterName">
                    <div class="label">
                        Cluster Name
                    </div>
                    {{series.clusterName}}
                </div>
                <div class="labeled-box" *ngIf="series.releaseYear">
                    <div class="label">
                        Release Year
                    </div>
                    {{ series.releaseYear }}
                </div>
                <div class="labeled-box" *ngIf="series.discontinuationYear">
                    <div class="label">
                        Discontinuation Year
                    </div>
                    {{ series.discontinuationYear }}
                </div>
                <div class="labeled-box" *ngIf="series.description">
                    <div class="label">
                        Description
                    </div>
                    {{ series.description }}
                </div>
            </div>
        </div>
        <div class="section">
            <div class="section-title">
                Actions
            </div>
            <div class="section-content">
                <button mat-raised-button color="primary" [disabled]="deleting || !canEditSeries" (click)="onEditButtonPressed()" style="margin-right: 10px">Edit</button>
                <button mat-raised-button color="warn" [disabled]="deleting || !canEditSeries" (click)="onDeleteButtonPressed()"
                    [class.spinner]="deleting">Delete</button>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #loading>
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
</ng-template>