import { Component, Inject, OnDestroy, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageCropperComponent, LoadedImage } from 'ngx-image-cropper';

export interface DialogData {
  image_type: string,
  onClickSave: (image_type: string, image: string) => void
}

@Component({
  selector: 'image-upload',
  templateUrl: './image-upload.component.html',
})
export class ImageUploadDialog implements OnInit, OnDestroy {
  imageChangedEvent: any = '';
  croppedImage: any = '';

  @ViewChild(ImageCropperComponent) private imageCropper!: ImageCropperComponent;

  constructor(
    public dialogRef: MatDialogRef<ImageUploadDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    console.log("image cropped");
  }
  imageLoaded() {
    console.log('loaded image');
  }
  cropperReady() {
    console.log('cropper ready');
  }
  loadImageFailed() {
    console.log('load image failed');
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    this.croppedImage = this.imageCropper.crop('base64')?.base64;

    this.data.onClickSave(this.data.image_type, this.croppedImage);
    this.dialogRef.close();
  }
}
