import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OffersService } from 'src/app/_services/offers.service';

@Component({
  selector: 'app-counter-offer-dialog',
  templateUrl: './counter-offer-dialog.component.html',
  styleUrls: ['./counter-offer-dialog.component.css']
})
export class CounterOfferDialogComponent implements OnInit {

  formGroup = new FormGroup({
    price: new FormControl('', [Validators.required, Validators.pattern("^[-]?[\\d]+$")]),
  });
  get formControls() {
    return this.formGroup.controls;
  }

  isSubmitting = false

  constructor(
    private dialogRef: MatDialogRef<CounterOfferDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {offerId: number},
    private offersService: OffersService
  ) { }

  ngOnInit(): void { }

  cancelButtonPressed() {
    this.close();
  }

  submitButtonPressed() {
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true
    
    let offerId = this.data.offerId;
    let priceCents = Number.parseInt(this.formControls.price?.value ?? "") * 100;
    this.offersService.counterOffer(offerId, priceCents).subscribe({
      next: () => {
        this.dialogRef.close({ updated : true });
      },
      error: (error) => {
        console.log(error);
        this.isSubmitting = false;
      }
    })
  }

  close() {
    this.dialogRef.close({ updated : this.isSubmitting});    
  }
}
