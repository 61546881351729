import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthorizationService, Permissions, Privilege } from 'src/app/_services/authorization.service';
import { ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteDialog } from 'src/app/common/delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SeriesService } from 'src/app/_services/series.service';
import Series from 'src/app/models/series.model';
import { PageEvent } from '@angular/material/paginator';
import Brand from 'src/app/models/brand.model';
import { BrandsService } from 'src/app/_services/brands.service';

@Component({
  selector: 'app-browse-series',
  templateUrl: './browse-series.component.html',
  styleUrls: ['./browse-series.component.css']
})
export class BrowseSeriesComponent implements OnInit, OnDestroy, ToolbarController {
  private static readonly DEFAULT_PAGE_SIZE = 20

  title = new BehaviorSubject<string>("Series");

  showAddSeriesButton: boolean;
  showDeleteSeriesButton: boolean;

  brands: Brand[] | null = [];
  brandFilter: number[] = [];

  page = 0;
  length = 0;
  pageSize = BrowseSeriesComponent.DEFAULT_PAGE_SIZE;
  pageSizeOptions: number[] = [20, 40, 60, 80, 100];
  displayedColumns: string[] = ['id', 'name', 'displayName', 'brand', 'lifespan', 'actions'];

  series: Series[] = [];
  isLoading = true
  private loadingSubscription: Subscription | null = null

  constructor(
    private toolbarService: ToolbarService,
    private seriesService: SeriesService,
    private brandsService: BrandsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authorizationService: AuthorizationService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.pageSize = params.pageSize ? params.pageSize : BrowseSeriesComponent.DEFAULT_PAGE_SIZE;
      this.page = params.page ? params.page : 0;
      this.brandFilter = params.brands ? (typeof params.brands === 'number' || typeof params.brands === 'string' ? [params.brands] : params.brands) : [];
      this.fetchSeries();
    })

    brandsService.getBrands().subscribe({
      next: (brands: Brand[]) => {
        this.brands = brands.sort((a, b) => a.name.localeCompare(b.name));
      }, 
      error: error => {
        console.log(error);
      }
    }) 

    // Only Admin roles and higher can add and delete series.
    this.showAddSeriesButton = authorizationService.hasPermission(Permissions.CATALOG_ALL, Privilege.WRITE);
    this.showDeleteSeriesButton = authorizationService.hasPermission(Permissions.CATALOG_ALL, Privilege.WRITE);
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  fetchSeries(): void {
    this.isLoading = true

    if (this.loadingSubscription != null) {
      this.loadingSubscription.unsubscribe();
    }
    this.loadingSubscription = this.seriesService.listSeries(this.brandFilter).subscribe({
      next: (series: Series[]) => {
        this.series = series;
        this.length = this.series.length;
        this.pageSize = this.series.length;
        this.isLoading = false
        this.loadingSubscription = null;
      },
      error: (error: any) => {
        console.log(error);
        this.series = [];
        this.length = 0;
        this.isLoading = false
        this.loadingSubscription = null;
      }
    });
  };

  public deleteSeries(series: Series, callback: () => void) {
    this.seriesService.deleteSeries(series.id).subscribe({
      next: () => {
        this.fetchSeries();
        callback();
      },
      error: error => {
        this.snackBar.open("Can't delete this series!", '', {duration: 2000});
        callback();
      }
    })
  }

  public onClickDelete(series: Series) {
    this.dialog.open(DeleteDialog, {
      width: '20vw',
      height: '10vh',
      data: {name: series.name, onClickConfirm: (callback: () => void) => this.deleteSeries(series, callback)}
    })
  }

  pageChangeEvent(event: PageEvent): void {
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updateUrlParametersIfNeeded();
  }

  handleFiltersChanged(): void {
    this.updateUrlParametersIfNeeded();
  }

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      page: this.page == 0 ? null : this.page,
      pageSize: this.pageSize == BrowseSeriesComponent.DEFAULT_PAGE_SIZE ? null : this.pageSize,
      brands: this.brandFilter.length > 0 ? this.brandFilter : null,
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }

  compareIds(id1: number, id2: number) {
    return id1 == id2;
  }
}
