<div class="w100 d-flex flex-row justify-content-between pb-2">
    <div>
        <button (click)="loadMore()" mat-button [disabled]="max >= offer!.priceHistory!.length">Load more</button>
    </div>
    <div>
        <button (click)="showLess()" mat-button [disabled]="max == 4">Show less</button>
    </div>
</div>
<div class="labeled-box" *ngFor="let event of offer!.priceHistory.slice().reverse() | slice:math.max(offer!.priceHistory!.length-max, 0):offer!.priceHistory!.length; let i=index;">
    <div class="label">
        {{ event.created | date:'M/d/yy, h:mm a' }}
    </div>
    {{event.initiator}}: {{(event.priceCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}
</div>