<dnd-preview [allBackends]="true">
  <ng-template let-type let-item="item">
    <div class="dcard-preview">
      <ng-container *ngIf="list.type == 'MODEL'">
        <model-card [model]="item.data" [clickable]="false"></model-card>
      </ng-container>
      <ng-container *ngIf="list.type == 'LISTING'">
        <listing-card [listing]="item.data" [clickable]="false"></listing-card>
      </ng-container>
      <ng-container *ngIf="list.type == 'BRAND'">
        <brand-card [brand]="item.data" [clickable]="false"></brand-card>
      </ng-container>
      <ng-container *ngIf="list.type == 'COLLECTION'">
        <collection-card [collection]="item.data" [clickable]="false"></collection-card>
      </ng-container>
      <ng-container *ngIf="list.type == 'FEATURE'">
        <feature-card [feature]="item.data" [clickable]="false"></feature-card>
      </ng-container>
      <ng-container *ngIf="list.type == 'ARTICLE'">
        <article-card [article]="item.data" [clickable]="false"></article-card>
      </ng-container>
    </div>
  </ng-template>
</dnd-preview>

<div class="sortableList">
  <dnd-sortable-list class="dragList" listId="drag-items" [children]="draggableItems" [spec]="dragToReorderSpec"
    [horizontal]=true>
    <ng-template dndSortableTemplate let-context>
      <div [ngClass]="{'dcard': true, 'dcard-placeholder': render.isDragging$ | async}" [dndSortableRender]="context"
        #render="dndSortableRender" [noHTML5Preview]="true" [dragSource]="render.source">
        <ng-container *ngIf="list.type == 'MODEL'">
          <model-card [model]="render.data" [clickable]="false"></model-card>
          <button mat-mini-fab color="warn" class="remove-item" (click)="deleteItemButtonPressed(render.data)">
            <mat-icon>delete</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="list.type == 'LISTING'">
          <listing-card [listing]="render.data" [clickable]="false"></listing-card>
          <button mat-mini-fab color="warn" class="remove-item" (click)="deleteItemButtonPressed(render.data)">
            <mat-icon>delete</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="list.type == 'BRAND'">
          <brand-card [brand]="render.data" [clickable]="false"></brand-card>
          <button mat-mini-fab color="warn" class="remove-item" (click)="deleteItemButtonPressed(render.data)">
            <mat-icon>delete</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="list.type == 'COLLECTION'">
          <collection-card [collection]="render.data" [clickable]="false"></collection-card>
          <button mat-mini-fab color="warn" class="remove-item" (click)="deleteItemButtonPressed(render.data)">
            <mat-icon>delete</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="list.type == 'FEATURE'">
          <feature-card [feature]="render.data" [clickable]="false"></feature-card>
          <button mat-mini-fab color="warn" class="remove-item" (click)="deleteItemButtonPressed(render.data)">
            <mat-icon>delete</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="list.type == 'ARTICLE'">
          <article-card [article]="render.data" [clickable]="false"></article-card>
          <button mat-mini-fab color="warn" class="remove-item" (click)="deleteItemButtonPressed(render.data)">
            <mat-icon>delete</mat-icon>
          </button>
        </ng-container>
      </div>
    </ng-template>
  </dnd-sortable-list>
</div>