import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreditBalanceAdjustmentsService {
  constructor(
    private http: HttpClient
  ) { }

  public createCreditBalanceAdjustment(params: any): Observable<any> {
    var path = `/payment/credit-balance-adjustments`;
    return this.http.post<any>(environment.apiUrl + path, params);
  }
}
