<ng-container *ngIf="model else loading">
    <div class="container">
        <div class="section">
            <div class="section-content">
                <div class="labeled-box" *ngIf="model?.images && model!.images.length > 0">
                    <div class="label">
                        Images
                    </div>
                    <div class="image-grid">
                        <img [src]="image.rawUrl" *ngFor="let image of model?.images" />
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="section-title">
                General
            </div>
            <div class="section-content">
                <div class="labeled-box">
                    <div class="label">
                        Name
                    </div>
                    {{ model.name }}
                </div>
                <div class="labeled-box" *ngIf="model.displayName">
                    <div class="label">
                        Display Name
                    </div>
                    {{ model.displayName }}
                </div>
                <div class="labeled-box">
                    <div class="label">
                        Reference #
                    </div>
                    {{ model.referenceNumber }}
                </div>
                <div class="labeled-box">
                    <div class="label">
                        Brand
                    </div>
                    <mat-chip-list>
                        <mat-basic-chip [routerLink]="['/catalog/brands', model.brand.id]">
                            {{model.brand.displayName ?? model.brand.name }}
                        </mat-basic-chip>
                    </mat-chip-list>
                </div>
                <div class="labeled-box" *ngIf="model.series">
                    <div class="label">
                        Series
                    </div>
                    <mat-chip-list>
                        <mat-basic-chip [routerLink]="['/catalog/series', model.series.id]">
                            {{model.series.displayName ?? model.series.name }}
                        </mat-basic-chip>
                    </mat-chip-list>
                </div>
                <div class="labeled-box">
                    <div class="label">
                        Release Year
                    </div>
                    {{ model.releaseYear }}
                </div>
                <div class="labeled-box" *ngIf="model.discontinuationYear">
                    <div class="label">
                        Discontinuation Year
                    </div>
                    {{ model.discontinuationYear }}
                </div>
                <div class="labeled-box">
                    <div class="label">
                        Gender
                    </div>
                    {{ model.gender.name }}
                </div>
                <div class="labeled-box">
                    <div class="label">
                        Categories
                    </div>
                    {{ extractCategoryNames(model) }}
                </div>
                <div class="labeled-box" *ngIf="model.description">
                    <div class="label">
                        Description
                    </div>
                    {{ model.description }}
                </div>
            </div>
        </div>
        <div class="section">
            <div class="section-title">
                Movement
            </div>
            <div class="section-content">
                <div class="labeled-box">
                    <div class="label">
                        Type
                    </div>
                    {{ model.movementType.name }}
                </div>
                <div class="labeled-box" *ngIf="model.caliber">
                    <div class="label">
                        Caliber
                    </div>
                    {{ model.caliber }}
                </div>
                <div class="labeled-box" *ngIf="model.powerReserve">
                    <div class="label">
                        Power Reserve
                    </div>
                    {{ model.powerReserve }}
                </div>
                <div class="labeled-box">
                    <div class="label">
                        Number of Jewels
                    </div>
                    {{ model.numberOfJewels }}
                </div>
            </div>
        </div>
        <div class="section">
            <div class="section-title">
                Case
            </div>
            <div class="section-content">
                <div class="labeled-box">
                    <div class="label">
                        Material(s)
                    </div>
                    <span *ngFor="let material of model.caseMaterials; let isLast=last">
                        {{material.name}}{{isLast ? '' : ', '}}
                     </span>
                </div>
                <div class="labeled-box">
                    <div class="label">
                        Size (mm)
                    </div>
                    {{ model.caseSize }}
                </div>
                <div class="labeled-box" *ngIf="model.thickness">
                    <div class="label">
                        Thickness (mm)
                    </div>
                    {{ model.thickness }}
                </div>
                <div class="labeled-box" *ngIf="model.waterResistance">
                    <div class="label">
                        Water Resistance (meters)
                    </div>
                    {{ model.waterResistance }}
                </div>
                <div class="labeled-box" *ngIf="model.bezelType">
                    <div class="label">
                        Bezel Type
                    </div>
                    {{ model.bezelType.name }}
                </div>
                <div class="labeled-box">
                    <div class="label">
                        Bezel Material
                    </div>
                    {{ model.bezelMaterial.name }}
                </div>
                <div class="labeled-box">
                    <div class="label">
                        Crystal
                    </div>
                    {{ model.crystal.name }}
                </div>
                <div class="labeled-box" *ngIf="model.lugWidth">
                    <div class="label">
                        Lug Width (mm)
                    </div>
                    {{ model.lugWidth }}
                </div>
            </div>
        </div>
        <div class="section">
            <div class="section-title">
                Dial
            </div>
            <div class="section-content">
                <div class="labeled-box">
                    <div class="label">
                        Color
                    </div>
                    {{ model.dialColor.name }}
                </div>
                <div class="labeled-box">
                    <div class="label">
                        Numerals
                    </div>
                    {{ model.numerals.name }}
                </div>
                <div class="labeled-box" *ngIf="model.complications.length > 0">
                    <div class="label">
                        Complications
                    </div>
                    <ng-container *ngFor="let complication of model.complications, let last = last">
                        {{ complication.name }}<span *ngIf="!last">, </span>
                    </ng-container>             
                </div>
            </div>
        </div>
        <div class="section">
            <div class="section-title">
                Bracelet
            </div>
            <div class="section-content">
                <div class="labeled-box" *ngIf="model.braceletStyle">
                    <div class="label">
                        Style
                    </div>
                    <span>
                        {{model.braceletStyle.name}}
                     </span>
                </div>
                <div class="labeled-box" *ngIf="model.braceletMaterials.length > 0">
                    <div class="label">
                        Material(s)
                    </div>
                    <span *ngFor="let material of model.braceletMaterials; let isLast=last">
                        {{material.name}}{{isLast ? '' : ', '}}
                     </span>
                </div>
                <div class="labeled-box" *ngIf="model.braceletColor">
                    <div class="label">
                        Color
                    </div>
                    {{ model.braceletColor.name }}
                </div>
                <div class="labeled-box" *ngIf="model.braceletClaspType">
                    <div class="label">
                        Clasp Type
                    </div>
                    {{ model.braceletClaspType.name }}
                </div>
                <div class="labeled-box" *ngIf="model.braceletClaspMaterials.length > 0">
                    <div class="label">
                        Clasp Material(s)
                    </div>
                    <span *ngFor="let material of model.braceletClaspMaterials; let isLast=last">
                        {{material.name}}{{isLast ? '' : ', '}}
                     </span>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="section-title">
                Actions
            </div>
            <div class="section-content">
                <button mat-raised-button color="primary" [disabled]="!canEditModel" (click)="onEditButtonPressed()" style="margin-right: 10px">Edit</button>
                <button mat-raised-button color="primary" (click)="onDuplicate()">Duplicate</button>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #loading>
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
</ng-template>
