import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { DeleteDialog } from 'src/app/common/delete-dialog/delete-dialog.component';
import Collection from 'src/app/models/collection.model';
import { Feature, FeatureType } from 'src/app/models/feature.model';
import { CollectionsService } from 'src/app/_services/collections.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';

@Component({
  selector: 'app-collection-detail',
  templateUrl: './collection-detail.component.html',
  styleUrls: ['./collection-detail.component.scss']
})
export class CollectionDetailComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Collections", path: ['/content', 'collections'] }]);
  title = new BehaviorSubject<string>("?");

  isLoading = true;
  
  collection!: Collection | null;
  private _collectionId!: number;

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private collectionsService: CollectionsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) { 
    this.route.params.pipe(
      pluck("collectionId")
    ).subscribe({
      next: collectionId => {
        if (this._collectionId == collectionId) {
          return;
        }

        this._collectionId = collectionId;
        this.title.next(collectionId.toString());
        this.reloadCollection();
      }
    })
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  private reloadCollection() {
    this.isLoading = true;

    this.collection = null;
    this.collectionsService.getCollection(this._collectionId).subscribe({
      next: collection => {
        this.isLoading = false;
        this.collection = collection;
        this.handleCollectionChanged();
      },
      error: error => {
        console.log(error)
      }
    })
  }

  private handleCollectionChanged() {
    if (this.collection?.name) {
      this.title.next(this.collection.name)
    } else if (this._collectionId) {
      this.title.next(this._collectionId.toString())
    } else {
      this.title.next('?')
    }
  }

  asFeature(): Feature {
    return new Feature(0, '', FeatureType.CollectionV2, this.collection as object, null)
  }

  deleteButtonPressed(): void {
    this.dialog.open(DeleteDialog, {
      width: '20vw',
      data: {name: this.collection?.name, onClickConfirm: (callback: () => void) => this.deleteCollection(callback)}
    })
  }

  public deleteCollection(callback: () => void) {
    this.collectionsService.deleteCollection(this._collectionId).subscribe({
      next: () => {
        callback();
        this.router.navigate(['/content/collections'])
      },
      error: error => {
        console.log(error)
        callback();
      }
    })
  }
}
