import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import User from 'src/app/models/console/user.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient) { }

  public getUsers(): Observable<User[]> {
    return this.http.get<User[]>(environment.apiUrl + `/apps/cloud-console/users?pageSize=-1`).pipe(
      map(users => users.map(u => this.toUser(u)))
    );
  }

  private toUser(user: any): User {
    var user = Object.assign(new User(), user)
    return user
  }
}
    