import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Feature, FeatureType } from 'src/app/models/feature.model';
import { FeaturesService } from 'src/app/_services/features.service';
import { ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';

@Component({
  selector: 'app-features-home',
  templateUrl: './features-home.component.html',
  styleUrls: ['./features-home.component.css']
})
export class FeaturesHomeComponent implements OnInit, OnDestroy, ToolbarController {
  title = new BehaviorSubject<string>("Features");

  typeFilter: string = 'ALL'
  nameFilter: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  displayedColumns: string[] = ['id', 'name', 'type', 'created', 'updated'];
  page = 0;
  length = 1000;
  pageSize = 20;
  pageSizeOptions: number[] = [20];
  sortKey: string | null = '-created';

  features: Feature[] = [];
  isLoading = true

  @Input() allTypes: string[];

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private featuresService: FeaturesService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { 
    this.activatedRoute.queryParams.subscribe(params => {
      this.typeFilter = params.type ? params.type : 'ALL';
      this.nameFilter.next(params.name ? params.name : null);
      this.page = params.page ? params.page : 0;

      this.fetchFeatures(this.page);
    })

    this.nameFilter.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(_ => {
        this.page = 0;
        this.updateUrlParametersIfNeeded();
      });

    this.allTypes = ['ALL']
    for (var type in FeatureType) {
      this.allTypes.push((FeatureType as any)[type])
    }
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  pageChangeEvent = (event: PageEvent) => {
    this.page = event.pageIndex;
    this.updateUrlParametersIfNeeded();
  }

  onSort(event: Sort): void {
    if (event.active == 'created' && event.direction == 'asc') {
      this.sortKey = 'created';
    } else if (event.active == 'created' && event.direction == 'desc') {
      this.sortKey = '-created';
    } else {
      this.sortKey = null;
    }
    this.page = 0;
    this.fetchFeatures(this.page);
  }

  fetchFeatures = (index: number) => {
    this.isLoading = true
    
    var typesFilter = this.typeFilter === "ALL" ? [] : [this.typeFilter];
    var nameFilter = this.nameFilter.value ? '%' + this.nameFilter.value + '%' : null;

    this.featuresService.getFeatures(this.pageSize, this.pageSize * index, typesFilter, nameFilter, this.sortKey).subscribe({
      next: (features: Feature[]) => {
        this.features = features;
        this.isLoading = false
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  };

  onSelectType = (event: any) => {
    this.updateUrlParametersIfNeeded();
  }

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      type: this.typeFilter == 'ALL' ? null : this.typeFilter,
      name: this.nameFilter.value,
      page: this.page == 0 ? null : this.page
    }

    this.router.navigate(
      [], 
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }
}
