<div class="container" *ngIf="highlight && !isLoading else loading">
    <mat-card>
        <mat-card-title>
            <div class="d-flex justify-content-between">
                <span>Details</span>
                <span>
                    <button mat-raised-button color="primary" [routerLink]="['/content/highlights', highlight.id, 'edit']">Edit</button>
                    <button mat-raised-button color="warn" class="mx-2" (click)="deleteButtonPressed()">Delete</button>
                </span>
            </div>
        </mat-card-title>
        <mat-card-content>
            <div class="labeled-box">
                <div class="label">Formatted Title</div>
                {{ highlight.formattedTitle }}
            </div>
            <div class="labeled-box">
                <div class="label">Model</div>
                <model-chip [model]="highlight.model"></model-chip>
            </div>
            <div class="labeled-box">
                <div class="label">Priority</div>
                {{ highlight.priority }}
            </div>
            <div class="labeled-box">
                <div class="label">Created</div>
                {{ highlight.created | date: 'longDate' }}
            </div>
            <div class="labeled-box">
                <div class="label">Last updated</div>
                {{ highlight.updated | date: 'medium' }}
            </div>
            <br />
            <div class="labeled-box">
                <div class="label">Description</div>
                {{ highlight.description }}
            </div>
            <br />
            <div class="labeled-box">
                <div class="label">
                    Image
                </div>
                <img [src]="highlight.image.rawUrl" />
            </div>
        </mat-card-content>
    </mat-card>
</div>
<ng-template #loading>
    <div class="d-flex flex-column align-items-center m-5">
        <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
    </div>
</ng-template>