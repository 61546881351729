import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { VerificationReportsService } from 'src/app/_services/verification-reports.service';
import { ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import VerificationReport, { VERIFICATION_REPORT_PRIORITIES, VERIFICATION_REPORT_PRIORITY_DESCRIPTIONS, VERIFICATION_REPORT_RESULTS, VERIFICATION_REPORT_RESULT_DESCRIPTIONS, VERIFICATION_REPORT_STATUSES, VERIFICATION_REPORT_STATUS_DESCRIPTIONS } from 'src/app/models/verification-report.model';

@Component({
  selector: 'app-browse-verification-reports',
  templateUrl: './browse-verification-reports.component.html',
  styleUrls: ['./browse-verification-reports.component.css']
})
export class BrowseVerificationReportsComponent implements OnInit, OnDestroy, ToolbarController {
  title = new BehaviorSubject<string>('Verification Reports');

  statusFilter!: string | null;
  priorityFilter!: string | null;
  resultFilter!: string | null;

  verificationReportStatuses: object;
  verificationReportStatusDescriptions: object;
  verificationReportPriorities: object;
  verificationReportPriorityDescriptions: object;
  verificationReportResults: object;
  verificationReportResultDescriptions: object;

  verificationReports: VerificationReport[] = [];
  displayedColumns: string[] = ['id', 'status', 'priority', 'order', 'result', 'created'];
  dataSource: MatTableDataSource<VerificationReport>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  page: number = 0;
  sortKey: string | null = null;
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20, 40, 60, 80, 100];
  isLoading = true;
  private loadingSubscription: Subscription | null = null

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private verificationReportsService: VerificationReportsService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.verificationReportStatuses = VERIFICATION_REPORT_STATUSES;
    this.verificationReportStatusDescriptions = VERIFICATION_REPORT_STATUS_DESCRIPTIONS;
    this.verificationReportPriorities = VERIFICATION_REPORT_PRIORITIES;
    this.verificationReportPriorityDescriptions = VERIFICATION_REPORT_PRIORITY_DESCRIPTIONS;
    this.verificationReportResults = VERIFICATION_REPORT_RESULTS;
    this.verificationReportResultDescriptions = VERIFICATION_REPORT_RESULT_DESCRIPTIONS;

    this.activatedRoute.queryParams.subscribe(params => {
      this.page = params.page ? params.page : 0;
      this.sortKey = params.sort ? params.sort : null;
      this.statusFilter = params.status ? params.status : null;
      this.priorityFilter = params.priority ? params.priority : null;
      this.resultFilter = params.result ? params.result : null;
      this.fetchVerificationReports(this.page);
    })
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  get loading(): boolean {
    return this.verificationReports.length === 0;
  }

  pageChangeEvent(event: PageEvent) {
    this.page = event.pageIndex
    this.updateUrlParametersIfNeeded();
  }
  
  onSelectStatus(event: any) {
    this.updateUrlParametersIfNeeded();
  }

  onSelectPriority(event: any) {
    this.updateUrlParametersIfNeeded();
  }

  onSelectResult(event: any) {
    this.updateUrlParametersIfNeeded();
  }

  onSort(): void {
    this.sortKey = (this.sort.direction == "asc" ? "" : "-") + this.sort.active;
    this.updateUrlParametersIfNeeded();
  }

  private fetchVerificationReports(index: number) {
    this.isLoading = true;

    if (this.loadingSubscription != null) {
      this.loadingSubscription.unsubscribe();
    }

    this.loadingSubscription = this.verificationReportsService.getVerificationReports(this.statusFilter, this.priorityFilter, this.resultFilter, null, null, null, this.pageSize, this.page * this.pageSize).subscribe({
      next: (response: any) => {
        if (this.sortKey != null) {
          const initSortState: Sort = {active: "a", direction: "desc"};
          
          initSortState.direction = this.sortKey.startsWith("-") ? "asc" : "desc";
    
          this.sort.active = this.sortKey.startsWith("-") ? this.sortKey.substring(1) : this.sortKey;
          this.sort.direction = initSortState.direction;
        }

        this.verificationReports = response.data;
        this.dataSource.data = this.verificationReports;
        this.length = response.totalCount;
        this.isLoading = false;
        this.loadingSubscription = null;
      },
      error: (error: any) => {
        console.log(error);
        this.verificationReports = [];
        this.length = 0;
        this.isLoading = false;
        this.loadingSubscription = null;
      }
    });
  };

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      page: this.page == 0 ? null : this.page,
      sort: this.sortKey == null ? null : this.sortKey,
      status: this.statusFilter,
      priority: this.priorityFilter,
      result: this.resultFilter
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }
}
