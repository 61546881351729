import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Category from '../models/category.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  constructor(private http: HttpClient) { }

  public getCategories(pageSize: number, offset: number): Observable<Category[]> {
    var path = `/catalog/categories?offset=${offset}&pageSize=${pageSize}`;
    return this.http.get<Category[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
      map(response => {
        return response.body!.map(o => this.toCategory(o))
      })
    );
  }

  public getCategory(categoryId: number): Observable<Category> {
    return this.http.get<Category>(environment.apiUrl + `/catalog/categories/${categoryId}`).pipe(
      map(fetchedCategory => this.toCategory(fetchedCategory))
    );
  }

  public createCategory(params: any): Observable<Category> {
    return this.http.post(environment.apiUrl + `/catalog/categories`, params).pipe(
      map(fetchedCategory => this.toCategory(fetchedCategory))
    );
  }

  public updateCategory(categoryId: number, params: any): Observable<Category> {
    return this.http.patch(environment.apiUrl + `/catalog/categories/${categoryId}`, params).pipe(
      map(fetchedCategory => this.toCategory(fetchedCategory))
    );
  }

  public deleteCategory(categoryId: number) {
    return this.http.delete(environment.apiUrl + `/catalog/categories/${categoryId}`)
  }

  private toCategory(fetchedCategory: any): Category {
    var category = Object.assign(new Category(), fetchedCategory);
    return category;
  }
}
