<div class="container" *ngIf="drop && !isLoading else loading">
  <mat-card>
    <mat-card-title>
      <div class="d-flex justify-content-between">
        <span>Details</span>
        <span *ngIf="!isEditing && drop.status != 'CANCELLED' && drop.status != 'DROPPED'" class="d-flex flex-row" style="gap: 8px">
            <button mat-raised-button color="primary" (click)="editButtonPressed()">Edit</button>
            <button mat-raised-button color="warn" (click)="cancelDropButtonPressed()">Cancel Drop</button>
        </span>
        <span *ngIf="isEditing" class="d-flex flex-row" style="gap: 8px">
            <button mat-raised-button color="primary" (click)="saveButtonPressed(false)"
                    [class.spinner]="isSaving" [disabled]="isSaving || issues.length > 0">
              <ng-container *ngIf="drop.status == 'DRAFT'">Save</ng-container>  
              <ng-container *ngIf="drop.status == 'SCHEDULED'">Save and Make Draft</ng-container>      
            </button>
            <button mat-raised-button color="primary" (click)="saveButtonPressed(true)"
                    [class.spinner]="isSaving" [disabled]="isSaving || !dropDate.value">
                    <ng-container *ngIf="drop.status == 'DRAFT'">Save and Make Public</ng-container>  
                    <ng-container *ngIf="drop.status == 'SCHEDULED'">Save</ng-container>     
            </button>
            <button mat-raised-button color="warn" (click)="cancelButtonPressed()" [disabled]="isSaving">Cancel</button>
        </span>
      </div>
    </mat-card-title>
    <mat-card-content>
      <ng-container *ngIf="!isEditing; else editor">
        <div class="labeled-box">
          <div class="label">ID</div>
          {{ drop.id }}
        </div>
        <div class="labeled-box">
          <div class="label">Status</div>
          {{ drop.status }}
        </div>
        <div class="labeled-box">
          <div class="label">Created</div>
          {{ drop.created | date:'medium' }}
        </div>
        <div class="labeled-box">
          <div class="label">Drop Date</div>
          {{ (drop.date | date:'medium') ?? "( not set )" }}
        </div>
      </ng-container>
      <ng-template #editor>
        <mat-form-field appearance="outline" class="flex-fill">
          <mat-label>Drop Date</mat-label>
          <input matInput [ngxMatDatetimePicker]="datePicker" placeholder="Drop" [formControl]="dropDate">
          <mat-hint *ngIf="dropDate.value">{{ dropDateInPST() }} PST</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #datePicker [showSeconds]="false" [enableMeridian]="true">
          </ngx-mat-datetime-picker>
        </mat-form-field>
        <sortable-drops [drop]="drop"></sortable-drops>
      </ng-template>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <ng-container>
      <mat-card-title>
        <div class="d-flex justify-content-between">
          <span>Listings</span>
          <ng-container *ngIf="isEditing">
            <span style="width: 400px; text-align: right;">
              <listing-select [control]="addItem" label="Add a listing" placeholder="5115J" [filter]="aoFilter" style="font-size: 10pt;"></listing-select>
            </span>
          </ng-container>
        </div>
      </mat-card-title>
      <ng-container *ngIf="!isEditing; else listEditor">
        <mat-card-content id="scrollContainer"
                          style="overflow-x: auto; margin: -40px -16px -16px -16px; padding: 44px 16px 28px 16px"
                          (wheel)="onWheel($event)">
          <ng-container *ngIf="errorMessage != ''">
            <span class="warning">
              {{errorMessage}}
            </span>
          </ng-container>
          <ng-container>
            <div *ngFor="let listing of drop.listings"
                  style="margin-right: 4px; margin-left: 4px; margin-top: 6px; vertical-align:top; display: inline-block;">
              <listing-card [listing]="listing"></listing-card>
            </div>
          </ng-container>
        </mat-card-content>
      </ng-container>
      <ng-template #listEditor>
        <sortable-drop [drop]="drop"></sortable-drop>
      </ng-template>
    </ng-container>
  </mat-card>
</div>
<ng-container *ngIf="issues.size > 0 && isEditing">
<mat-card class="mx-2 mb-2 p-0 flex-fill d-flex flex-column overflow-scroll">
  <mat-card-title>
    <div class="d-flex justify-content-between">
      <span>Issues</span>
      <button mat-raised-button (click)="onClickRecheck()">Recheck</button>
    </div>
  </mat-card-title>
  <mat-card-content id="scrollContainer"
  style="overflow-x: auto; margin: -40px -16px -16px -16px; padding: 44px 16px 28px 16px"
  (wheel)="onWheel($event)">
  <div *ngFor="let issue of issues | keyvalue">
    <button mat-raised-button color="primary" (click)="onClickIssue(issue.key)">
    {{issue.value.message}}
    </button>
  </div>
  </mat-card-content>
</mat-card>
</ng-container>
<ng-template #loading>
  <div class="d-flex flex-column align-items-center m-5">
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
  </div>
</ng-template>
