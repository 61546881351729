import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, ReplaySubject, Observable, forkJoin, throwError, of } from 'rxjs';
import { map } from 'rxjs/operators';
import BuyerProfile from 'src/app/models/buyer-profile.model';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import Model from 'src/app/models/model.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import PurchaseRequest, { PURCHASE_REQUEST_STATUS, PURCHASE_REQUEST_STATUS_ENG, UNWORN_RESTRICTION } from 'src/app/models/purchase-request.model';
import { Location } from '@angular/common';
import { ModelsService } from 'src/app/_services/models.service';
import { PurchaseRequestService } from 'src/app/_services/purchase-requests.service';

@Component({
  selector: 'app-listings-create',
  templateUrl: './purchase-requests-create.component.html',
  styleUrls: ['./purchase-requests-create.component.scss']
})
export class PurchaseRequestsCreateComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath!: BehaviorSubject<TitlePathElement[]>;
  title!: BehaviorSubject<string>;

  editing = false;
  loading = false;

  buyerFilterControl = new FormControl<BuyerProfile | null>(null)
  modelFilterControl = new FormControl("");
  modelSearchResults: BehaviorSubject<Model[]> = new BehaviorSubject<Model[]>([]);
  priceFormControl = new FormControl();
  yearRestrict = new FormControl();

  selectedBuyer!: number | null;
  selectedModel!: Model | null;
  unwornRequirementSelect?: string;
  errText!: string;

  private _purchaseRequestId!: number;
  private _purchaseRequest!: PurchaseRequest | null;
  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private modelsService: ModelsService,
    private purchaseRequestsService: PurchaseRequestService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.selectedModel = null;
    this.selectedBuyer = null;
    this.errText = "";

    this.buyerFilterControl.valueChanges.subscribe({
      next: () => {
        if (this.buyerFilterControl.value instanceof BuyerProfile) {
          this.selectedBuyer = this.buyerFilterControl.value.id;
        } else {
          this.selectedBuyer = null;
        }
      }
    });

    this.modelFilterControl.valueChanges.subscribe(value => this.onModelSearch(value || ''));
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  onSubmit() {
    if (this.selectedModel == null) {
      this.errText = "Select a model.";
      return;
    }
    if (this.selectedBuyer == null) {
      this.errText = "Select a buyer.";
      return;
    }
    if (this.priceFormControl.value == null) {
      this.errText = "Select a price";
      return;
    }

    let unwornRestiction : string | null = (this.unwornRequirementSelect == undefined || this.unwornRequirementSelect == "NONE") ? null : this.unwornRequirementSelect;

    var template = {
      model: this.selectedModel.id,
      buyerProfile: this.selectedBuyer,
      requestedPriceCents: this.priceFormControl.value,
      unwornRestriction: unwornRestiction,
      yearRestriction: this.yearRestrict.value
    }
    this.purchaseRequestsService.createPurchaseRequest(template).subscribe(result => 
      {
        this.router.navigate(['/marketplace', 'purchase-requests', result.id]);
      });
  }

  onCancel() {
    this.router.navigate(['/marketplace', 'purchase-requests']);
  }

  clearModelSelection() {
    this.modelFilterControl.setValue("");
    this.selectedModel = null;
  }

  onModelClicked(event: MatAutocompleteSelectedEvent) {
    var model = event.option.value;
    this.selectedModel = model;
  }

  onModelSearch(query: string) {
    if (query.length < 2) {
      this.modelSearchResults.next([]);
      return;
    }

    this.modelsService.searchModels(query).subscribe({
      next: models => {
        this.modelSearchResults.next(models);
      },
      error: error => {
        console.log(error)
      }
    })
  }
}