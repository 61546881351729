<div class="sidebar-container">
  <div class="sidebar-header">
    <h2 [routerLink]="['/']" style="cursor: pointer;">Cloud Console</h2>
  </div>
  <button mat-button class="search-button" (click)="searchPressed()"><mat-icon>search</mat-icon><span>Search</span></button>
  <mat-accordion multi="false">
    <mat-expansion-panel *ngIf="canAccessCatalog" [ngClass]="{'selected-sidebar-section' : selectedSection == 0 }"  [expanded]="selectedSection == 0" class="sidebar-section">
      <mat-expansion-panel-header class="sidebar-section-header">
        <mat-panel-title>
          <mat-icon>book</mat-icon>
          <span>Catalog</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 0 && selectedItemInSection == 0 }" (click)="onCatalogBrandsPressed();">Brands</button>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 0 && selectedItemInSection == 1 }" (click)="onCatalogSeriesPressed();">Series</button>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 0 && selectedItemInSection == 2 }" (click)="onCatalogModelsPressed();">Models</button>
      <mat-divider></mat-divider>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 0 && selectedItemInSection == 3 }" (click)="onCatalogColorsPressed();">Colors</button>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 0 && selectedItemInSection == 4 }" (click)="onCatalogComplicationsPressed();">Complications</button>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 0 && selectedItemInSection == 5 }" (click)="onCatalogMaterialsPressed();">Materials</button>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 0 && selectedItemInSection == 6 }" (click)="onCatalogCategoriesPressed();">Categories</button>
      <mat-divider></mat-divider>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 0 && selectedItemInSection == 7 }" (click)="onCatalogCheckImagesPressed();">Generated Images</button>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="canAccessContent" [ngClass]="{'selected-sidebar-section' : selectedSection == 1 }"  [expanded]="selectedSection == 1" class="sidebar-section">
      <mat-expansion-panel-header class="sidebar-section-header">
        <mat-panel-title>
          <mat-icon>feed</mat-icon>
          <span>Content</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 1 && selectedItemInSection == 0 }" (click)="onContentListsPressed();">Lists</button>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 1 && selectedItemInSection == 1 }" (click)="onContentCollectionsPressed();">Collections</button>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 1 && selectedItemInSection == 2 }" (click)="onContentArticlesPressed();">Articles</button>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 1 && selectedItemInSection == 3 }" (click)="onContentFeaturesPressed();">Features</button>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 1 && selectedItemInSection == 4 }" (click)="onContentHomeFeedsPressed();">Home Feeds</button>
      <mat-divider></mat-divider>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 1 && selectedItemInSection == 5 }" (click)="onContentHighlightsPressed();">Highlights</button>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="canAccessMarketplace" [ngClass]="{'selected-sidebar-section' : selectedSection == 2 }" [expanded]="selectedSection == 2" class="sidebar-section">
      <mat-expansion-panel-header class="sidebar-section-header">
        <mat-panel-title>
          <mat-icon>storefront</mat-icon>
          <span>Marketplace</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 2 && selectedItemInSection == 0 }" (click)="onMarketplaceBuyersPressed();">Buyers</button>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 2 && selectedItemInSection == 1 }" (click)="onMarketplaceSellersPressed();">Sellers</button>
      <mat-divider></mat-divider>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 2 && selectedItemInSection == 2 }" (click)="onMarketplaceListingsPressed();">Listings</button>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 2 && selectedItemInSection == 3 }" (click)="onMarketplaceAuctionsPressed();">Auctions</button>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 2 && selectedItemInSection == 4 }" (click)="onMarketplaceDropsPressed();">Drops</button>
      <mat-divider></mat-divider>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 2 && selectedItemInSection == 5 }" (click)="onMarketplaceOffersPressed();">Offers</button>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 2 && selectedItemInSection == 6 }" (click)="onMarketplaceMaxBidsPressed();">Max Bids</button>
      <mat-divider></mat-divider>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 2 && selectedItemInSection == 7 }" (click)="onMarketplaceOrdersPressed();">Orders</button>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 2 && selectedItemInSection == 8 }" (click)="onMarketplaceReturnsPressed();">Returns</button>
      <mat-divider></mat-divider>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 2 && selectedItemInSection == 9 }" (click)="onMarketplaceDiscountsPressed();">Discounts</button>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 2 && selectedItemInSection == 10 }" (click)="onMarketplaceIngestionRequestsPressed();">Listing Requests</button>
      <mat-divider></mat-divider>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 2 && selectedItemInSection == 11 }" (click)="onMarketplacePurchaseRequestsPressed();">Purchase Requests</button>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="canAccessVerification" [ngClass]="{'selected-sidebar-section' : selectedSection == 3 }" [expanded]="selectedSection == 3" class="sidebar-section">
      <mat-expansion-panel-header class="sidebar-section-header">
        <mat-panel-title>
          <mat-icon>safety_check</mat-icon>
          <span>Verification</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 3 && selectedItemInSection == 0 }" (click)="onVerificationReportsPressed();">Reports</button>
      <mat-divider></mat-divider>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 3 && selectedItemInSection == 1 }" (click)="onIntakeReportsPressed();">Intake</button>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 3 && selectedItemInSection == 2 }" (click)="onDiagnosticReportsPressed();">Diagnostics</button>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 3 && selectedItemInSection == 3 }" (click)="onAuthenticationReportsPressed();">Authentication</button>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="canAccessPayments" [ngClass]="{'selected-sidebar-section' : selectedSection == 4 }" [expanded]="selectedSection == 4" class="sidebar-section">
      <mat-expansion-panel-header class="sidebar-section-header">
        <mat-panel-title>
          <mat-icon>payments</mat-icon>
          <span>Payments</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <button mat-button class="sidebar-button" [ngClass]="{'selected-sidebar-button' : selectedSection == 4 && selectedItemInSection == 0 }" (click)="onPaymentsBillingAccountsPressed();">Billing Accounts</button>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="sidebar-spacer"></div>
  <div class="sidebar-footer">
    <img src="https://storage.googleapis.com/public-us-standard-bezel_prod/static/logo_white.png" style="width: 30px; height: 30px; margin-bottom: 18px;" />
    <p style="line-height: 16px;"><span style="opacity: 0.3">{{environment.version}}</span></p>
  </div>
</div>
