import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BuyersService } from 'src/app/_services/buyers.service';
import { ReturnsService } from 'src/app/_services/returns.service';
import { ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import BuyerProfile from 'src/app/models/buyer-profile.model';
import Return, { allStatuses, orderedStatuses } from 'src/app/models/return.model';

@Component({
  selector: 'app-returns-home',
  templateUrl: './returns-home.component.html',
  styleUrls: ['./returns-home.component.css']
})
export class ReturnsHomeComponent implements OnInit, OnDestroy, ToolbarController {
  returns: Return[] = [];
  displayedColumns: string[] = ['flagged', 'id', 'status', 'order', 'buyer', 'created'];

  buyerFilterControl = new FormControl<BuyerProfile | null>(null)
  
  statusFilter: string = 'ALL';
  buyerFilter!: number | null;
  flaggedFilter: boolean | null = null;

  title = new BehaviorSubject<string>('Returns');
  page = 0;
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20];
  sortKey!: string | null;
  isLoading = true
  private loadingSubscription: Subscription | null = null
  
  private _destroyed = new BehaviorSubject<boolean>(false);

  @Input() allStatuses: object;
  @Input() orderedStatuses: object;
  
  constructor(
    private toolbarService: ToolbarService,
    private returnsService: ReturnsService,
    private buyersService: BuyersService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.sortKey = '-created';
    this.activatedRoute.queryParams.subscribe(params => {
      this.statusFilter = params.status ? params.status : 'ALL';
      this.buyerFilter = params.buyer ? params.buyer : null;
      this.flaggedFilter = params.flagged ? params.flagged : null;
      this.page = params.page ? params.page : 0;

      if (this.buyerFilter && !(this.buyerFilterControl.value instanceof BuyerProfile)) {
        buyersService.getBuyerProfile(this.buyerFilter).subscribe({
          next: (buyer: BuyerProfile) => {
            this.buyerFilterControl.setValue(buyer);
          }
        })
      }

      this.fetchReturns(this.page);
    })
    this.allStatuses = allStatuses;
    this.orderedStatuses = orderedStatuses;
  
    this.buyerFilterControl.valueChanges.subscribe({
      next: () => {
        if (this.buyerFilterControl.value instanceof BuyerProfile) {
          this.buyerFilter = this.buyerFilterControl.value.id;
        } else {
          this.buyerFilter = null;
        }
        this.updateUrlParametersIfNeeded();
      }
    })
  }
    
  ngOnInit(): void {
    this.toolbarService.setController(this);
  }
  
  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  pageChangeEvent = (event: PageEvent) => {
    this.page = event.pageIndex;
    this.updateUrlParametersIfNeeded();
  }

  onSort(event: Sort): void {
    if (event.active == 'created' && event.direction == 'asc') {
      this.sortKey = 'created';
    } else if (event.active == 'created' && event.direction == 'desc') {
      this.sortKey = '-created';
    } else {
      this.sortKey = null;
    }
    this.page = 0;
    this.fetchReturns(this.page);
  }

  fetchReturns = (index: number) => {
    this.isLoading = true
    
    var statusForRequest = this.statusFilter === "ALL" ? null : this.statusFilter;

    if (this.loadingSubscription != null) {
      this.loadingSubscription.unsubscribe();
    }
    this.loadingSubscription = this.returnsService.getReturns(statusForRequest, this.buyerFilter, null, this.flaggedFilter, this.sortKey, this.pageSize, index * this.pageSize).subscribe({
      next: (response: any) => {
        this.returns = response.data;
        this.length = response.totalCount;
        this.isLoading = false
        this.loadingSubscription = null;
      },
      error: (error: any) => {
        console.log(error);
        this.returns = [];
        this.length = 0;
        this.isLoading = false
        this.loadingSubscription = null;
      }
    });
  };

  onSelectStatus = (event: any) => {
    this.updateUrlParametersIfNeeded();
  }

  onChangeFlaggedFilter(event: any): void {
    this.updateUrlParametersIfNeeded();
  }

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      status: this.statusFilter == 'ALL' ? null : this.statusFilter,
      buyer: this.buyerFilter == null ? null : this.buyerFilter,
      flagged: this.flaggedFilter == null ? null : this.flaggedFilter,
      page: this.page == 0 ? null : this.page
    }

    this.router.navigate(
      [], 
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }
}
