import { Component, OnDestroy, OnInit, ViewChild, NgZone} from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import Listing from 'src/app/models/listing.model';
import Drop, { DropValidationResponse } from 'src/app/models/drop.model';
import { DropsService } from 'src/app/_services/drops.service';
import { FormControl } from '@angular/forms';
import { ListingSelectFilter } from 'src/app/common/listing-select/listing-select.component';
import { SortableDropComponent } from './sortable-drop.component';
import { DeleteDialog } from 'src/app/common/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-list-detail',
  templateUrl: './drops-detail.component.html',
  styleUrls: ['./drops-detail.component.scss'],
})
export class DropsDetailComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Drops", path: ['/marketplace', 'drops'] }]);
  title = new BehaviorSubject<string>("?");
  @ViewChild(SortableDropComponent) private sortableDropComponent! : SortableDropComponent;

  isLoading = true;
  isEditing = false;
  loadMessage = "";
  errorMessage = "";
  issues : Map<number, IssueData> = new Map();

  drop! : Drop;
  dropDate = new FormControl<Date | null>(null);
  addItem = new FormControl<null>(null);
  backupDrop : Drop = new Drop();
  aoFilter = new ListingSelectFilter(true, true);

  private _dropId!:number;
  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private dropsService: DropsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private zone: NgZone,
  ) {
    this.route.params.pipe(
      map(x => x?.dropId)
    ).subscribe({
      next: dropId => {
        if (this._dropId == dropId) {
          return;
        }

        this._dropId = dropId;
        this.title.next(dropId.toString());
        this.reload();
      }
    });
    this.addItem.valueChanges.subscribe({
      next: value => {
        if (value == null) {
          return;
        }

        if (String(value) === value) {
          return;
        }

        this.addItem.setValue(null);

        var listing : Listing = Object.assign(new Listing(), value);
        this.dropsService.validateDropListing(this._dropId, listing.id).subscribe({
          next: validateResult => {
            this.drop.listings.unshift(value);
            if (validateResult.state != "VALID") {
              this.sortableDropComponent.flagItem(listing.id, true, this.onItemDeleted.bind(this));
              this.issues.set(listing.id, this.getIssue(validateResult, listing.id));
            }
            this.sortableDropComponent.refreshList();
          }
        });
      }});
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  editButtonPressed(): void {
    if (this.drop.status == "CANCELLED") {
      return;
    }
    this.dropDate.setValue(this.drop.date);
    this.isEditing = true;
    this.backupDrop = this.drop.clone();
    this.zone.onMicrotaskEmpty.asObservable().pipe(
      take(1)
    )
    .subscribe(() => {
      this.sortableDropComponent.refreshList();
    })
  }

  cancelButtonPressed(): void {
    this.isEditing = false;
    this.drop = this.backupDrop;
    this.sortableDropComponent.refreshList();
  }

  saveButtonPressed(publish: boolean): void {
    if (this.issues.size > 0) {
      return;
    }

    this.isEditing = false;

    let newStatus : string;
    if (this.dropDate.value == null || !publish) {
      newStatus = "DRAFT";
    } else {
      newStatus = "SCHEDULED";
    }

    var dropTemplate: any;
    dropTemplate = {
      date: this.dropDate.value,
      listings: this.drop.listings.map(l => l.id),
      status: newStatus,
    };

    this.dropsService.modifyDrop(this._dropId, dropTemplate).subscribe({
      next: drop => {
        this.drop = drop;
      },
      error: error => {
        console.log(error)
      }
    })
  }

  cancelDropButtonPressed(): void {
    this.dialog.open(DeleteDialog, {
      width: '20vw',
      data: {action: 'cancel', name: 'this drop', onClickConfirm: (callback: () => void) => this.verifyOrder(true, callback)}
    })
  }

  private getIssue(response: DropValidationResponse, listingId: number) : IssueData {
    let issue = new IssueData();
    if (response.state == "NOT_EXISTS") {
      issue.url = this.router.serializeUrl(this.router.createUrlTree(["/marketplace/listings/"]));
      issue.message = "Listing #" + listingId + " does not exist";
    } else if (response.state == "NOT_PUBLISHED") {
      issue.url = this.router.serializeUrl(this.router.createUrlTree(["/marketplace/listings/" + listingId]));
      issue.message = "Listing #" + listingId + " is not published";
    } else if (response.state == "NOT_AUCTION") {
      issue.url = this.router.serializeUrl(this.router.createUrlTree(["/marketplace/listings/" + listingId]));
      issue.message = "Listing #" + listingId + " is not an auction";
    } else if (response.state == "ALREADY_IN_DROP") {
      issue.url = this.router.serializeUrl(this.router.createUrlTree(["/marketplace/drops/" + response.dropId]));
      issue.message = "Listing #" + listingId + " is already in drop " + response.dropId;
    } else {
      issue.message = "Listing #" + listingId + " has an unknown problem"; 
    }
    return issue;
  }

  onClickIssue(listingId: number) {
    window.open(this.issues.get(listingId)?.url, "_blank");
  }

  onClickRecheck() {
    for (let listingId of this.issues.keys()) {
      this.dropsService.validateDropListing(this._dropId, listingId).subscribe({
        next: validateResult => {
          if (validateResult.state != "VALID") {
            this.sortableDropComponent.flagItem(listingId, true, this.onItemDeleted.bind(this));
            this.issues.set(listingId, this.getIssue(validateResult, listingId));
          } else {
            this.sortableDropComponent.flagItem(listingId, false, this.onItemDeleted.bind(this));
            this.issues.delete(listingId);
          }
          this.sortableDropComponent.refreshList();
        }
      });
    }
  }

  onItemDeleted(itemId : number) {
    if (this.issues.get(itemId) != null) {
      this.issues.delete(itemId);
    }
  }

  private verifyOrder(isAuthentic: boolean, callback: () => void) {
    var dropTemplate: any;
    dropTemplate = {
      status: "CANCELLED"
    }
    this.dropsService.modifyDrop(this._dropId, dropTemplate).subscribe({
      next: drop => {
        this.drop = drop;
        callback()
      },
      error: error => {
        console.log(error)
        callback()
      }
    })
  }

  private reload() {
    this.isLoading = true;

    this.dropsService.getDrop(this._dropId, true).subscribe({
      next: drop => {
        this.isLoading = false;
        this.drop = drop;
        if (this.isEditing) {
          this.backupDrop = this.drop.clone();
        }
      },
      error: error => {
        console.log(error)
      }
    })
  }

  dropDateInPST(): string {
    if (!this.dropDate?.value) {
      return ""
    }
    return this.dropDate.value.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })
  }
}

class IssueData {
  message!: string;
  url!: string;
}