import Image from "./image.model";
import Model from "./model.model";

export default class Highlight {
    public id!: number;
    public formattedTitle!: string;
    public description!: string;
    public image!: Image;
    public model!: Model;
    public priority!: number;
    public created!: Date;
    public updated!: Date;
}
