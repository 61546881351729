<div class="d-flex flex-column h-100">
  <mat-card class="m-2">
    <mat-card-content class="d-flex flex-md-row flex-column" style="gap: 10px">
        <mat-form-field appearance="outline" class="flex-fill">
            <mat-label>Status</mat-label>
            <mat-select [(ngModel)]='statusFilter' name='Status' (selectionChange)="onSelectStatus($event)">
                <mat-option>-</mat-option>
                <mat-option *ngFor="let status of intakeReportStatuses" [value]="status">
                    {{intakeReportStatusDescriptions[status]}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-fill">
          <mat-label>Priority</mat-label>
          <mat-select [(ngModel)]='priorityFilter' name='Priority' (selectionChange)="onSelectPriority($event)">
              <mat-option>-</mat-option>
              <mat-option *ngFor="let priority of intakeReportPriorities" [value]="priority">
                  {{intakeReportPriorityDescriptions[priority]}}
              </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-fill">
          <mat-label>Result</mat-label>
          <mat-select [(ngModel)]='resultFilter' name='Result' (selectionChange)="onSelectResult($event)">
              <mat-option>-</mat-option>
              <mat-option *ngFor="let result of intakeReportResults" [value]="result">
                  {{intakeReportResultDescriptions[result]}}
              </mat-option>
          </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="flex-fill">
        <mat-label>Blocked</mat-label>
        <mat-select [(ngModel)]='blockedFilter' name='Blocked' (selectionChange)="onChangeBlockedFilter($event)">
            <mat-option [value]="">-</mat-option>
            <mat-option [value]="'true'">YES</mat-option>
            <mat-option [value]="'false'">NO</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card-content>
  </mat-card>
  <mat-card class="mx-2 mb-2 p-0 flex-fill d-flex flex-column overflow-scroll">
    <div class="flex-fill position-relative overflow-scroll">
      <div *ngIf="isLoading" class="w-100 h-100 position-absolute d-flex align-items-center justify-content-center" style="z-index: 1;">
        <div *ngIf="intakeReports.length > 0" class="w-100 h-100 position-absolute" style="background-color: rgba(0, 0, 0, 0.1);"></div>
        <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
      </div>
      <div class="h-100 w-100 overflow-scroll">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="onSort()" class="w-100">
          <ng-container matColumnDef="icons">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let intakeReport">
              <div class="d-flex flex-row align-items-center justify-content-center" style="gap: 10px;">
                <mat-icon *ngIf="intakeReport.blocked" color="warn" style="height: fit-content; width: fit-content; font-size: 12pt; color: #5C5C5C;">block</mat-icon>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
            <td mat-cell *matCellDef="let intakeReport"> {{intakeReport.id}} </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status"> Status </th>
            <td mat-cell *matCellDef="let intakeReport"> 
              <div class="badge-narrow" [ngClass]="{
                'badge-narrow': intakeReport.status === 'CANCELLED', 
                'badge-narrow-success': intakeReport.status === 'COMPLETE', 
                'badge-narrow-orange': intakeReport.status === 'PENDING'
                }">
                              {{ intakeReport.status }}
                          </div>
                      </td>
          </ng-container>
          <ng-container matColumnDef="result">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by result"> Result </th>
            <td mat-cell *matCellDef="let intakeReport"> 
              <div *ngIf="intakeReport.result" class="badge-narrow" [ngClass]="{
                'badge-narrow-success': intakeReport.result === 'PASSED', 
                'badge-narrow-error': intakeReport.result === 'FAILED'
                }">
                              {{ intakeReport.result }}
                          </div>
                      </td>
          </ng-container>
          <ng-container matColumnDef="priority">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by priority"> Priority </th>
            <td mat-cell *matCellDef="let intakeReport"> 
              {{ intakeReport.priority }}
          </ng-container>
          <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef> Reference </th>
            <td mat-cell *matCellDef="let intakeReport">
              <mat-chip-list *ngIf="intakeReport.formData.referenceType == 'LISTING' && intakeReport.formData.referenceId">
                <listing-chip [listingId]="intakeReport.formData.referenceId"></listing-chip>
              </mat-chip-list>
              <mat-chip-list *ngIf="intakeReport.formData.referenceType == 'ORDER' && intakeReport.formData.referenceId">
                <order-chip [orderId]="intakeReport.formData.referenceId"></order-chip>
              </mat-chip-list>
              <mat-chip-list *ngIf="intakeReport.order"><order-chip [order]="intakeReport.order"></order-chip></mat-chip-list>
              <mat-chip-list *ngIf="!intakeReport.order && intakeReport.listing"><listing-chip [listing]="intakeReport.listing"></listing-chip></mat-chip-list>
            </td>
         </ng-container>
          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by created" style="min-width: 150px;"> Created </th>
            <td mat-cell *matCellDef="let intakeReport"> {{intakeReport.created | date:'medium' }} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let intakeReport; columns: displayedColumns;" [routerLink]="['/verification/intake-reports/', intakeReport.id]">
        </table>
      </div>
    </div>
    <mat-paginator [length]="length"
                [pageIndex]="page"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                aria-label="Select page">
    </mat-paginator>
  </mat-card>
  <button mat-fab (click)="onCreateIntakeReportPressed()" class="fab" [disabled]="isCreatingNewReport">
    <mat-icon *ngIf="!isCreatingNewReport">add</mat-icon>
    <mat-spinner *ngIf="isCreatingNewReport" diameter="20" color="secondary"></mat-spinner>
  </button>
</div>
