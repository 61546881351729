import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Feature, FeatureType } from '../models/feature.model';

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {
  constructor(
    private http: HttpClient
  ) { }

  public getFeatures(pageSize: number, offset: number, types: string[] | null, name: string | null, sortKey: string | null): Observable<Feature[]> {
    var path = `/content/features?pageSize=${pageSize}&offset=${offset}`;
    if (!!types) {
      for (var type of types) {
        path += `&type[]=${type}`
      }
    }
    if (!!name) {
      name = encodeURIComponent(name);
      path += `&name[]=${name}`;
    }
    if (!!sortKey) {
      path += `&sort=${sortKey}`
    }
    path += "&expand[]=object"
    return this.http.get<Feature[]>(environment.apiUrl + path).pipe(
      map(features => features.map(o => this.toFeature(o)))
    );
  }

  public getFeature(id: number): Observable<Feature> {
    var path = `/content/features/${id}?expand[]=object`;
    return this.http.get<Feature>(environment.apiUrl + path).pipe(
      map(feature => this.toFeature(feature))
    );
  }

  public createFeature(params: any): Observable<Feature> {
    var path = `/content/features`;
    return this.http.post<Feature>(environment.apiUrl + path, params);
  }

  public updateFeature(id: number, updates: any): Observable<Feature> {
    var path = `/content/features/${id}`;
    return this.http.patch<Feature>(environment.apiUrl + path, updates);
  }

  public deleteFeature(id: number): Observable<void> {
    var path = `/content/features/${id}`;
    return this.http.delete<void>(environment.apiUrl + path);
  }

  private toFeature(feature: any): Feature {
    var feature = Object.assign(new Feature(0, "", FeatureType.CollectionV2, new Object(), ""), feature)
    return feature
  }
}
