<div class="d-flex flex-column h-100">
    <mat-card class="mx-2 mb-2 mt-2 p-0 flex-fill d-flex flex-column overflow-scroll">
        <div class="flex-fill position-relative overflow-scroll">
            <div *ngIf="isLoading"
                class="w-100 h-100 position-absolute d-flex align-items-center justify-content-center"
                style="z-index: 1;">
                <div *ngIf="billingAccounts.length > 0" class="w-100 h-100 position-absolute"
                    style="background-color: rgba(0, 0, 0, 0.1);"></div>
                <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
            </div>
            <div class="h-100 w-100 overflow-scroll">
                <table mat-table matSort (matSortChange)="onSort($event)" [dataSource]="billingAccounts"
                    class="w-100">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> Id </th>
                        <td mat-cell *matCellDef="let billingAccount"> {{billingAccount.id}} </td>
                    </ng-container>
                    <ng-container matColumnDef="owner">
                        <th mat-header-cell *matHeaderCellDef> Owner </th>
                        <td mat-cell *matCellDef="let billingAccount"> {{billingAccount.owner?.fullName}} ({{ billingAccount.owner?.email ?? '??' }}) </td>
                    </ng-container>
                    <ng-container matColumnDef="created">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by created">
                            Created </th>
                        <td mat-cell *matCellDef="let billingAccount"> {{billingAccount.created | date:'medium'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="updated">
                        <th mat-header-cell *matHeaderCellDef> Updated </th>
                        <td mat-cell *matCellDef="let billingAccount"> {{billingAccount.updated | date:'medium'}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let billingAccount; columns: displayedColumns;"
                        [routerLink]="['/payments/billing-accounts/', billingAccount.id]">
                </table>
            </div>
        </div>
        <mat-paginator [length]="length" [pageIndex]="page" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageChangeEvent($event)" aria-label="Select page">
        </mat-paginator>
    </mat-card>
</div>