import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import HomeFeed from '../models/home-feed.model';

@Injectable({
  providedIn: 'root'
})
export class HomeFeedsService {
  constructor(
    private http: HttpClient
  ) { }

  public getHomeFeeds(pageSize: number, offset: number, name: string | null, sortKey: string | null): Observable<HomeFeed[]> {
    var path = `/content/home-feeds?pageSize=${pageSize}&offset=${offset}`;
    if (!!name) {
      name = encodeURIComponent(name);
      path += `&name[]=${name}`;
    }
    if (!!sortKey) {
      path += `&sort=${sortKey}`
    }
    return this.http.get<HomeFeed[]>(environment.apiUrl + path).pipe(
      map(homeFeeds => homeFeeds.map(o => this.toHomeFeed(o)))
    );
  }

  public getHomeFeed(id: number): Observable<HomeFeed> {
    var path = `/content/home-feeds/${id}?expand[]=sections.object.items.model&expand[]=sections.object.items.object&expand[]=sections.object.items.bannerImage`;
    return this.http.get<HomeFeed>(environment.apiUrl + path).pipe(
      map(homeFeed => this.toHomeFeed(homeFeed))
    );
  }

  public createHomeFeed(params: any): Observable<HomeFeed> {
    var path = `/content/home-feeds`;
    return this.http.post<HomeFeed>(environment.apiUrl + path, params);
  }

  public updateHomeFeed(id: number, updates: any): Observable<HomeFeed> {
    var path = `/content/home-feeds/${id}`;
    return this.http.patch<HomeFeed>(environment.apiUrl + path, updates);
  }

  public addSection(id: number, params: any) {
    var path = `/content/home-feeds/${id}/sections`;
    return this.http.post(environment.apiUrl + path, params);
  }

  public updateSection(homeFeedId: number, sectionId: number, params: any) {
    var path = `/content/home-feeds/${homeFeedId}/sections/${sectionId}`;
    return this.http.patch(environment.apiUrl + path, params);
  }

  public removeSection(homeFeedId: number, sectionId: number) {
    var path = `/content/home-feeds/${homeFeedId}/sections/${sectionId}`;
    return this.http.delete(environment.apiUrl + path);
  }

  public deleteHomeFeed(id: number): Observable<void> {
    var path = `/content/home-feeds/${id}`;
    return this.http.delete<void>(environment.apiUrl + path);
  }

  private toHomeFeed(homeFeed: any): HomeFeed {
    var homeFeed = Object.assign(new HomeFeed(), homeFeed)
    return homeFeed
  }
}
