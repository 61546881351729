import { Component, Input, OnInit } from '@angular/core';
import { OrdersService } from 'src/app/_services/orders.service';
import Order from 'src/app/models/order.model';

@Component({
  selector: 'order-chip',
  templateUrl: './order-chip.component.html',
  styleUrls: ['./order-chip.component.css']
})
export class OrderChipComponent implements OnInit {
  @Input('order') order?: Order;
  @Input('orderId') orderId?: number;

  constructor(
    private ordersService: OrdersService
  ) { }

  ngOnInit(): void { 
    if (!this.order && this.orderId) {
      this.ordersService.getOrder(this.orderId).subscribe({
        next: order => {
          this.order = order
        }
      })
    }
  }

  ngOnDestroy(): void { }
}
