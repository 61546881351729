import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, pluck } from 'rxjs';
import { VerificationReportsService } from 'src/app/_services/verification-reports.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import VerificationReport, { VERIFICATION_REPORT_PRIORITIES, VERIFICATION_REPORT_PRIORITY_DESCRIPTIONS, VERIFICATION_REPORT_RESULTS, VERIFICATION_REPORT_RESULT_DESCRIPTIONS } from 'src/app/models/verification-report.model';

@Component({
  selector: 'app-verification-report-detail',
  templateUrl: './verification-report-detail.component.html',
  styleUrls: ['./verification-report-detail.component.css']
})
export class VerificationReportDetailComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Verification Reports", path: ['/verification', 'verification-reports'] }]);
  title = new BehaviorSubject<string>("?");

  verificationReport!: VerificationReport | null;
  _verificationReportId: number | null = null;

  verificationReportPriorities: object;
  verificationReportPriorityDescriptions: object;
  verificationReportResults: object;
  verificationReportResultDescriptions: object;

  isPerformingAction = () => {
    return this.isSaving
        || this.isCancelling
  }
  isSaving = false
  isCancelling = false

  constructor(
    private toolbarService: ToolbarService,
    private route: ActivatedRoute,
    private verificationReportsService: VerificationReportsService,
    private router: Router,
    private dialog: MatDialog
    ) {
      this.verificationReportPriorities = VERIFICATION_REPORT_PRIORITIES;
      this.verificationReportPriorityDescriptions = VERIFICATION_REPORT_PRIORITY_DESCRIPTIONS;
      this.verificationReportResults = VERIFICATION_REPORT_RESULTS;
      this.verificationReportResultDescriptions = VERIFICATION_REPORT_RESULT_DESCRIPTIONS;
      
      this.route.params.pipe(
        pluck("verificationReportId")
      ).subscribe({
        next: verificationReportId => {
          if (this._verificationReportId == verificationReportId) {
            return;
          }
  
          this._verificationReportId = verificationReportId;
          this.title.next(verificationReportId.toString());
          this.reloadVerificationReport();
        }
      })
    }

    private reloadVerificationReport() {
      this.verificationReport = null;
      this.verificationReportsService.getVerificationReport(this._verificationReportId!).subscribe({
        next: (verificationReport: VerificationReport) => {
          this.verificationReport = verificationReport;
        },
        error: (error: any) => {
          console.log(error);
        }
      });
    }
    
    ngOnInit(): void {
      this.toolbarService.setController(this);
    }
    
    ngOnDestroy(): void {
      this.toolbarService.removeController(this);
    }

    onSelectNewPriority(newPriority: string): void {
      var originalPriority = this.verificationReport!.priority
      this.verificationReport!.priority = newPriority
      this.verificationReportsService.modifyVerificationReport(this._verificationReportId!, {
        priority: newPriority
      }).subscribe({
        next: () => {
          // Success!
        },
        error: (error: any) => {
          console.log(error);
          this.verificationReport!.priority = originalPriority
        }
      });
    }

    markCancelled(): void {
      this.isCancelling = true
      this.verificationReportsService.modifyVerificationReport(this._verificationReportId!, {status: 'CANCELLED'}).subscribe({
        next: () => {
          window.location.reload()
        },
        error: (error: any) => {
          console.log(error);
          this.isCancelling = false
        }
      });
    }
}
