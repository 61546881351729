import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import Model from 'src/app/models/model.model';

@Component({
    selector: 'model-card',
    templateUrl: './model-card.component.html',
    styleUrls: ['./model-card.component.css'],
})
export class ModelCard {
    @Input('model') model!: Model;
    @Input('clickable') clickable: boolean = true;

    constructor(
        private router: Router
    ) { }

    onClick(): void {
        if (!this.clickable) {
            return;
        }
        this.router.navigate(['/catalog/models', this.model.id]);
    }
}
