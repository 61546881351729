import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import List from 'src/app/models/list.model';
import { ListItem } from 'src/app/models/list.model';
import { DndSortableModule, SortableSpec, DraggedItem } from '@ng-dnd/sortable';

@Component({
  selector: 'sortable-list',
  templateUrl: './sortable-list.component.html',
  styleUrls: ['./sortable-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortableListComponent {
  @Input() list! : List | null

  draggableItems!: ListItem[];
  dragToReorderSpec: SortableSpec<ListItem> = {
    type: 'DRAGLIST',
    trackBy: x => x.id,
    hover: item => {
      if (this.list?.items != null) {
        this.draggableItems = this.move(item);
      }
    },
    drop: item => {
      if (this.list?.items != null) {
        this.draggableItems = this.move(item);
        this.list.items = this.draggableItems;
      }
    },
    endDrag: item => {
      if (this.list?.items != null) {
        this.draggableItems = this.list.items;
      }
    },
  };

  constructor(
    private changeDetector: ChangeDetectorRef
  ) {
    this.draggableItems = [];
  }

  refreshList() : void {
    if (this.list != null) {
      this.draggableItems = this.list.items.slice(0);
      this.changeDetector.markForCheck();
    }
  }

  deleteItemButtonPressed(item: any) {
    this.list!.items = this.list!.items.filter(s => s.id !== item.id)
    this.draggableItems = this.list!.items ?? [];
  }

  move(item: DraggedItem<ListItem>) : ListItem[] {
    if (this.list?.items != null) {
        const temp = this.list.items.slice(0);
        temp.splice(item.index, 1);
        temp.splice(item.hover.index, 0, item.data);
        return temp;
    }
    return [];
  }
}
