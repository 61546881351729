<div class="container" *ngIf="purchaseRequest && !isLoading else loading">
  <mat-card>
    <mat-card-title>
      Purchase Request
    </mat-card-title>
    <mat-card-content>
      <ng-container>
        <div class="labeled-box">
          <div class="label">ID</div>
          {{ purchaseRequest.id }}
        </div>
        <div class="labeled-box">
          <div class="label">Buyer Profile</div>
          <buyer-chip [buyer]="purchaseRequest.buyerProfile"></buyer-chip>
        </div>
        <div class="labeled-box">
          <div class="label">Model</div>
          <model-chip [model]="purchaseRequest.model"></model-chip>
        </div>
        <div class="labeled-box">
          <div class="label">Requested Price (Cents)</div>
          {{ purchaseRequest.requestedPriceCents }}
        </div>
        <div class="labeled-box">
          <div class="label">Status</div>
          <div class="badge-narrow" [ngClass]="{
            'badge-narrow': purchaseRequest.status === 'AWAITING_PAYMENT', 
            'badge-narrow-success': purchaseRequest.status === 'LIVE', 
            'badge-narrow-orange': purchaseRequest.status === 'CLOSED', 
            }">
                          {{ purchaseRequest.statusEng }}
          </div>
        </div>
        <ng-container *ngIf=hasRestrictions()>
          <div class="labeled-box">
            <div class="label">Unworn Restriction</div>
            {{ !!purchaseRequest.unwornRestriction ? purchaseRequest.unwornRestriction : "None" }}
          </div>
          <div class="labeled-box">
            <div class="label">Year Restriction</div>
            {{ !!purchaseRequest.yearRestriction ? purchaseRequest.yearRestriction : "None" }}
          </div>
          <div class="labeled-box">
            <div class="label">Hold</div>
            {{ !!purchaseRequest.hold ? "Yes " + purchaseRequest.hold.id : "No" }}
          </div>
        </ng-container>
        <div class="labeled-box">
          <div class="label">Expiration</div>
          {{ purchaseRequest.expiration | date:'medium' }}
        </div>
        <div class="labeled-box">
          <div class="label">Created</div>
          {{ purchaseRequest.created | date:'medium' }}
        </div>
      </ng-container>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="purchaseRequestOffers.length > 0">
    <mat-card-title>Offers</mat-card-title>
    <mat-card-content>
      <table mat-table matSort [dataSource]="offersDataSource" (matSortChange)="onSort($event)" class="w-100">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer"> {{purchaseRequestOffer.id}} </td>
        </ng-container>
        <ng-container matColumnDef="listing">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Listing </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer">
            <listing-chip [listing]="purchaseRequestOffer.listing"></listing-chip>
          </td>
        </ng-container>
        <ng-container matColumnDef="offerPrice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Offer Price (cents) </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer"> {{purchaseRequestOffer.offerPriceCents}} </td>
        </ng-container>
        <ng-container matColumnDef="strikethroughPrice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Strikethrough Price (cents) </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer"> {{purchaseRequestOffer.strikethroughPriceCents}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer"> {{purchaseRequestOffer.statusEng}} </td>
        </ng-container>
        <ng-container matColumnDef="onReject">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> On Reject </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer"> {{purchaseRequestOffer.onRejectAction}} </td>
        </ng-container>
        <ng-container matColumnDef="expiration">
          <th mat-header-cell *matHeaderCellDef> Expiration </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer"> {{purchaseRequestOffer.expiration | date:'medium' }} </td>
        </ng-container>
        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef> Created </th>
          <td mat-cell *matCellDef="let purchaseRequestOffer"> {{purchaseRequestOffer.created | date:'medium' }} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let purchaseRequestOffer; columns: displayedColumns;">
      </table>
    </mat-card-content>
  </mat-card>

</div>
<ng-template #loading>
  <div class="d-flex flex-column align-items-center m-5">
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
  </div>
</ng-template>
