import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { DiscountsService } from 'src/app/_services/discounts.service';

@Component({
  selector: 'app-create-discount',
  templateUrl: './create-discount.component.html',
  styleUrls: ['./create-discount.component.css']
})
export class CreateDiscountComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Discounts", path: ['/marketplace', 'discounts'] }]);
  title = new BehaviorSubject<string>("Add Discount");

  formGroup = new FormGroup({
    discountCode: new FormControl('', Validators.required),
    discountAmountCents: new FormControl('', Validators.required),
    minPurchasePriceCents: new FormControl(''),
    disabled: new FormControl(false),
    expiration: new FormControl('')
  });
  get formControls() {
    return this.formGroup.controls;
  }

  submitting: boolean = false;

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private discountsService: DiscountsService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }
    this.submitting = true;

    var discount: any = {
      discountCode: (this.formControls.discountCode.value),
      discountAmountCents: this.formControls.discountAmountCents.value,
      minPurchasePriceCents: this.nullIfEmpty(this.formControls.minPurchasePriceCents.value),
      expiration: this.nullIfEmpty(this.formControls.expiration.value),
      isDisabled: this.formControls.disabled.value
    }
    this.discountsService.addDiscount(discount, {
      success: (discountId: number) => {
        this.handleDiscountCreated(discountId)
      },
      error: (error: any) => {
        this.submitting = false;
        alert(error);
      }
    });
  }

  private handleDiscountCreated(newDiscountId: number) {
    this.router.navigate(['marketplace', 'discounts']);

    // Display a toast that links to the newly created discount's detail page.
    let snackBarRef = this.snackBar.open('\"' + this.formControls.discountCode.value + '\" discount added', 'View', {
      duration: 3000
    });
    snackBarRef.onAction().subscribe(() => {
      this.router.navigate(['marketplace', 'discounts', newDiscountId]);
    });
  }

  onClear() {
    this.formGroup.reset();
  }

  private nullIfEmpty(value: any): any | null {
    if (value == null) {
      return null
    }

    if (typeof value == "string" && value.length == 0) {
      return null
    }

    return value
  }
}
