import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss']
})
export class ImageViewComponent implements OnInit, OnDestroy {
  @Input('image') image?: InstanceType<typeof Image>;
  @Input('removable') removable?: boolean;

  @Output('remove') remove = new EventEmitter<ImageViewComponent>();

  constructor() { }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  onRemoveButtonPressed() {
    this.remove.emit(this);
  }
}
