import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import User from 'src/app/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient) { }

  public getUser(userId: string): Observable<User> {
    return this.http.get<User>(environment.apiUrl + `/identity/users/${userId}`);
  }
}
    