import Listing from './listing.model';
import BuyerProfile from './buyer-profile.model';

export default class Bid {
  id!: number;
  bidderId!: number;
  priceCents!: number;  
  listing!: Listing;
  buyerProfile!: BuyerProfile;
  systemGenerated!: boolean;
  created!: Date;
}
