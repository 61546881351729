import Listing from "./listing.model";
import Order from "./order.model";
import User from "./user.model";

export default class DiagnosticReport {
  id!: number;
  status!: string;
  formData!: any;
  result!: string;
  completedBy!: User;
  completedAt!: Date;
  cancellationReason!: string;
  priority!: string;
  blocked!: boolean;
  created!: Date;
  updated!: Date;
  listing!: Listing;
  order!: Order;
}

export const DIAGNOSTIC_REPORT_STATUSES = [
  'PENDING',
  'COMPLETE',
  'CANCELLED'
]

export const DIAGNOSTIC_REPORT_STATUS_DESCRIPTIONS: {[key: string]: string } = {
  'PENDING': 'Pending',
  'COMPLETE': 'Complete',
  'CANCELLED': 'Cancelled'
}

export const DIAGNOSTIC_REPORT_RESULTS = [
  'PASSED',
  'FAILED'
]

export const DIAGNOSTIC_REPORT_RESULT_DESCRIPTIONS: {[key: string]: string } = {
  'PASSED': 'Passed',
  'FAILED': 'Failed'
}

export const DIAGNOSTIC_REPORT_PRIORITIES = [
  'STANDARD',
  'RUSH'
]

export const DIAGNOSTIC_REPORT_PRIORITY_DESCRIPTIONS: {[key: string]: string } = {
  'STANDARD': 'Standard',
  'RUSH': 'Rush'
}