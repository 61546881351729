<div class="dialog-content">
    <div class="title">Add section</div>
    <div class="break"></div>
    <div class="d-flex flex-column">
        <mat-form-field appearance="outline" class="flex-fill">
            <mat-label>Title</mat-label>
            <input matInput type="string" placeholder="Under 10k" [formControl]="title">
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-fill">
            <mat-label>Type</mat-label>
            <mat-select [formControl]="type" required>
                <mat-option [value]="'MODEL_CLUSTER'">MODEL_CLUSTER</mat-option>
                <mat-option [value]="'LISTING_CLUSTER'">LISTING_CLUSTER</mat-option>
                <mat-option [value]="'COLLECTION_CLUSTER'">COLLECTION_CLUSTER</mat-option>
                <mat-option [value]="'FEATURE_CLUSTER'">FEATURE_CLUSTER</mat-option>
                <mat-option [value]="'BRAND_CLUSTER'">BRAND_CLUSTER</mat-option>
                <mat-option [value]="'ARTICLE_CLUSTER'">ARTICLE_CLUSTER</mat-option>
                <mat-option [value]="'AUCTION_CLUSTER'">AUCTION_CLUSTER</mat-option>
            </mat-select>
        </mat-form-field>
        <list-select [control]="list" [types]="allowedListTypes" [content]="content" required class="flex-fill"
            *ngIf="type.value"></list-select>
    </div>
    <div class="d-flex flex-row-reverse w100">
        <button mat-raised-button color="primary" [disabled]="!formValid() || !formGroup.dirty || isSubmitting"
            [class.spinner]="isSubmitting" (click)="submitButtonPressed();">Add section</button>
        <button mat-button class="mx-2" [disabled]="isSubmitting" (click)="cancelButtonPressed();">Cancel</button>
    </div>
</div>
