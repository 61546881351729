<div class="container" *ngIf="!loading else spinner">
    <mat-card class="my-2">
        <mat-card-title class="mb-4">
            <ng-container *ngIf="!editing">Create Home Feed</ng-container>
            <ng-container *ngIf="editing">Edit Home Feed</ng-container>
        </mat-card-title>
        <mat-card-content>
            <div class="d-flex flex-column">
                <mat-form-field appearance="outline" class="flex-fill">
                    <mat-label>Name</mat-label>
                    <input matInput type="string" placeholder="Home 10/23/2022" [formControl]="name" required>
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="editing">
                    <mat-label>Audience</mat-label>
                    <mat-select [formControl]="audience">
                      <mat-option [value]="'NONE'">NONE</mat-option>
                      <mat-option [value]="'INTERNAL'">INTERNAL (@getbezel.com)</mat-option>
                      <mat-option [value]="'PUBLIC'">PUBLIC (everyone)</mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
        </mat-card-content>
        <mat-card-actions class="d-flex justify-content-between">
            <button mat-raised-button color="warn" (click)="onCancel()" [disabled]="submitting">Cancel</button>
            <div>
                <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!formGroup.valid || submitting || !formGroup.dirty" [class.spinner]="submitting">
                    <ng-container *ngIf="!editing">Submit</ng-container><ng-container *ngIf="editing">Save</ng-container>
                </button>
            </div>
        </mat-card-actions>
    </mat-card>
</div>
<ng-template #spinner>
    <mat-card class="m-2 d-flex flex-column align-items-center">
      <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
    </mat-card> 
</ng-template>