import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShipmentsService {
  constructor(private http: HttpClient) { }

  public markAsReceived(shipmentId: number) {
    return this.http.post(environment.apiUrl + `/shipping/shipments/${shipmentId}/mark-received`, {});
  }

  public markAsInTransit(shipmentId: number) {
    return this.http.post(environment.apiUrl + `/shipping/shipments/${shipmentId}/mark-in-transit`, {});
  }
}
