import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorDialog } from 'src/app/common/error-dialog/error-dialog.component';
import { HomeFeedsService } from 'src/app/_services/home-feeds.service';

@Component({
  selector: 'app-update-section',
  templateUrl: './update-section.component.html',
  styleUrls: ['./update-section.component.css']
})
export class UpdateSectionComponent {
  isSubmitting = false

  title = new FormControl(null);
  formGroup = new FormGroup({
    title: this.title,
  });

  constructor(
    public dialogRef: MatDialogRef<UpdateSectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public homeFeedService: HomeFeedsService,
    public dialog: MatDialog,
  ) { 
    this.title.setValue(data.section.title)
  }

  cancelButtonPressed() {
    this.dialogRef.close({ updated: false })
  }

  submitButtonPressed() {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;

    var params: any = { 
      title: this.title.value
    }
    this.homeFeedService.updateSection(this.data.homeFeed.id, this.data.section.id, params).subscribe({
      next: () => {
        this.dialogRef.close({ updated: true })
      },
      error: response => {
        console.log(response.error);
        this.showError(response.error);
        this.isSubmitting = false;
      }
    })
  }

  private showError(error: {error: string, description?: string }) {
    this.dialog.open(ErrorDialog,  {
      width: '500px',
      data: {
        error: error
      }
    });
  }
}
