import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { pluck } from 'rxjs/operators';
import ListingStats from 'src/app/models/listing-stats.model';
import Listing from 'src/app/models/listing.model';
import Order from 'src/app/models/order.model';
import SellerLevel from 'src/app/models/seller-level.model';
import SellerProfile from 'src/app/models/seller-profile.model';
import { ListingsService } from 'src/app/_services/listings.service';
import { OrdersService } from 'src/app/_services/orders.service';
import { SellersService } from 'src/app/_services/sellers.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { EditSellerDetailsComponent } from '../edit-seller-details/edit-seller-details.component';

@Component({
  selector: 'app-sellers-detail',
  templateUrl: './sellers-detail.component.html',
  styleUrls: ['./sellers-detail.component.css']
})
export class SellersDetailComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Sellers", path: ['/marketplace', 'sellers'] }]);
  title = new BehaviorSubject<string>("?");

  private _sellerId: number | null = null;
  seller!: SellerProfile | null;

  private _sellerLevels: SellerLevel[] | null = null;
  sellerLevel!: SellerLevel;

  publishedListingStats!: ListingStats;

  private _maxNumberOfRecentListings: number = 5;
  recentListings: Listing[] = [];
  recentListingTableCols: string[] = ['id', 'status', 'description', 'price', 'created'];

  private _maxNumberOfRecentOrders: number = 5;
  recentOrders: Order[] = [];
  recentOrderTableCols: string[] = ['id', 'status', 'description', 'price', 'created'];

  constructor(
    private toolbarService: ToolbarService,
    private sellersService: SellersService,
    private listingsService: ListingsService,
    private ordersService: OrdersService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.route.params.pipe(
      pluck("sellerId")
    ).subscribe({
      next: sellerId => {
        if (this._sellerId == sellerId) {
          return;
        }

        this._sellerId = sellerId;
        this.reloadSellerProfile();
      }
    })

    this.sellersService.getSellerLevels().subscribe(sellerLevels => {
      this._sellerLevels = sellerLevels;
      this.updateSellerLevelIfNeeded();
    })
  }

  private reloadSellerProfile() {
    this.seller = null;
    this.sellersService.getSellerProfile(this._sellerId!).subscribe(sellerProfile => {
      this.seller = sellerProfile
      this.title.next(`${sellerProfile.displayName}`);
      this.updateSellerLevelIfNeeded();
    })
    this.listingsService.getStats('published', this._sellerId).subscribe(listingStats => {
      this.publishedListingStats = listingStats
    })
    this.listingsService.getListings({sellerProfileFilter: this._sellerId ? this._sellerId : undefined,sortKey: "-created"})
        .subscribe(response => {
            this.recentListings = response.data.slice(0, this._maxNumberOfRecentListings);
        })
    this.ordersService.getOrders(0, null, null, this._sellerId, null, null, "-created").subscribe(response => {
      this.recentOrders = response.data.slice(0, this._maxNumberOfRecentOrders);
    })
  }

  private updateSellerLevelIfNeeded(): void {
    if (this.seller == null || this._sellerLevels == null) {
      return;
    }
    
    Object.values(this._sellerLevels).forEach(sellerLevel => {
      if (sellerLevel.level == this.seller?.sellerLevel) {
        this.sellerLevel = sellerLevel
      }
    })
  }
    
  ngOnInit(): void {
    this.toolbarService.setController(this);
  }
    
  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  editButtonPressed(): void {
    var dialogRef = this.dialog.open(EditSellerDetailsComponent, {
      width: '400px',
      data: {sellerId: this._sellerId},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.updated) {
        this.reloadSellerProfile()
      }
    });
  }

  isLoading(): boolean {
    return this.seller == null || this._sellerLevels == null || this.publishedListingStats == null;
  }
}
