import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { BuyersService } from 'src/app/_services/buyers.service';
import { Observable } from 'rxjs';
import BuyerProfile from 'src/app/models/buyer-profile.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-buyers-home',
  templateUrl: './buyers-home.component.html',
  styleUrls: ['./buyers-home.component.css']
})
export class BuyersHomeComponent implements OnInit, OnDestroy, ToolbarController {
  private static DEFAULT_PAGE_SIZE = 20;

  title = new BehaviorSubject<string>('Buyers');
  
  blockedFilter: boolean | null = null;
  
  page = 0;
  length = 0;
  pageSize = BuyersHomeComponent.DEFAULT_PAGE_SIZE;
  pageSizeOptions: number[] = [10,20,40,60,80,100];
  sortKey!: string | null;

  displayedColumns: string[] = ['id', 'name', 'email'];

  isLoading = true;
  buyers: BuyerProfile[] = [];

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private buyersService: BuyersService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {   
    this.activatedRoute.queryParams.subscribe(params => {
      this.page = params.page ? params.page : 0;
      this.blockedFilter = params.blocked ? params.blocked : null;

      this.fetchBuyerProfiles(this.page);
    })
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }
  
  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  onChangeBlockedFilter(event: any): void {
    this.updateUrlParametersIfNeeded();
  }

  pageChangeEvent = (event: PageEvent) => {
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updateUrlParametersIfNeeded();
  }

  fetchBuyerProfiles = (index: number) => {
    this.isLoading = true;

    this.buyersService.getBuyerProfiles(this.pageSize, this.pageSize * index, this.blockedFilter, null).subscribe({
      next: (result: any) => {
        this.buyers = result.data;
        this.length = result.totalCount;
        this.isLoading = false;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  };

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      page: this.page == 0 ? null : this.page,
      pageSize: this.pageSize == BuyersHomeComponent.DEFAULT_PAGE_SIZE ? null : this.pageSize,
      blocked: this.blockedFilter == null ? null : this.blockedFilter,
    }

    this.router.navigate(
      [], 
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }
}
