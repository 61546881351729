import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ListingsService } from 'src/app/_services/listings.service';
import Listing from 'src/app/models/listing.model';

@Component({
  selector: 'listing-chip',
  templateUrl: './listing-chip.component.html',
  styleUrls: ['./listing-chip.component.css']
})
export class ListingChipComponent implements OnInit, OnDestroy {
  @Input('listing') listing?: Listing;
  @Input('listingId') listingId?: number;

  constructor(
    private listingsService: ListingsService
  ) { 

  }

  ngOnInit(): void { 
    if (!this.listing && this.listingId) {
      this.listingsService.getListing(this.listingId).subscribe({
        next: listing => {
          this.listing = listing
        }
      })
    }
  }

  ngOnDestroy(): void { 

  }
}
