<dnd-preview [allBackends]="true">
  <ng-template let-item="item">
    <div class="dcard-preview">
        <listing-card [listing]="item.data" [clickable]="false"></listing-card>
    </div>
  </ng-template>
</dnd-preview>

<div class="sortableList">
  <dnd-sortable-list class="dragList" listId="drag-items" [children]="draggableItems" [spec]="dragToReorderSpec"
    [horizontal]=true>
    <ng-template dndSortableTemplate let-context>
      <div [ngClass]="{'dcard': true, 'dcard-placeholder': render.isDragging$ | async}"
        [dndSortableRender]="context" #render="dndSortableRender" [noHTML5Preview]="true" [dragSource]="render.source">
          <listing-card [listing]="render.data" [clickable]="false" [ngClass]="{'redOutline': shouldWarn(render.data)}"></listing-card>
          <button mat-mini-fab color="warn" class="remove-item" (click)="deleteItemButtonPressed(render.data)">
            <mat-icon>delete</mat-icon>
          </button>
      </div>
    </ng-template>
  </dnd-sortable-list>
</div>
