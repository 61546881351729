import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import Discount from 'src/app/models/discount.model';

@Injectable({
  providedIn: 'root'
})
export class DiscountsService {
  constructor(private http: HttpClient) { }

  public addDiscount(discount: {
    discountCode: string,
    discountAmountCents: number,
    affiliate?: string,
    isDisabled?: boolean,
    minPurchasePriceCents?: number,
    expiration?: Date
  }, callback?: { success: (id: number) => void, error: (error: any) => void }) {
    this.http.post(environment.apiUrl + "/marketplace/discounts", discount, {observe: 'response'}).subscribe({
      next: response => {
        const locationHeader = response.headers.get('location');
        if (locationHeader == null) {
          callback?.error('Failed to parse location header for created discount');
          return;
        }
        let newDiscountId = parseInt(locationHeader.split('/').slice(-1)[0]);
        callback?.success(newDiscountId);
      },
      error: error => {
        callback?.error(error);
      }
    })
  }

  public getDiscounts(): Observable<Discount[]> {
    return this.http.get<Discount[]>(environment.apiUrl + "/marketplace/discounts");
  }

  public getDiscount(discountId: number): Observable<Discount> {
        return this.http.get<Discount>(environment.apiUrl + "/marketplace/discounts/" + discountId);
  }

  public updateDiscount(discountId: number, updates: {
    discountCode?: string,
    discountAmountCents?: number,
    minPurchasePriceCents?: number,
    isDisabled?: boolean,
    expiration?: Date
  }, callback?: { success: () => void, error: (error: any) => void }) {
    this.http.patch(environment.apiUrl + "/marketplace/discounts/" + discountId, updates, {observe: 'response'}).subscribe({
      next: body => {
        callback?.success();
      },
      error: error => {
        callback?.error(error);
      }
    })
  }

  public deleteDiscount(discountId: number, callback?: { success: () => void, error: (error: any) => void }) {
    this.http.delete(environment.apiUrl + "/marketplace/discounts/" + discountId).subscribe({
      next: body => {
        callback?.success();
      },
      error: error => {
        callback?.error(error);
      }
    })
  }
}
