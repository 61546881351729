import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import Article from 'src/app/models/article.model';

@Component({
  selector: 'article-card',
  templateUrl: './article-card.component.html',
  styleUrls: ['./article-card.component.css']
})
export class ArticleCardComponent {
  @Input('article') article!: Article;
  @Input('clickable') clickable: boolean = true;

  constructor(
      private router: Router
  ) { }

  onClick(): void {
      if (!this.clickable) {
          return;
      }
      console.log(this.article)
      this.router.navigate(['/content/articles', this.article.id]);
  }
}
