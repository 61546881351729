import Image from "./image.model";

export default class Brand {
  public id!: number;
  public name!: string;
  public displayName!: string | null;
  public description!: string | null;
  public wordmark!: Image | null;
  public logo!: Image | null;
  public cardImage!: Image | null;
  public bannerImage!: Image | null;
  public heroModelImage!: Image | null;
  public aliases!: string[];
}
