import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import Listing from 'src/app/models/listing.model';
import IssueTemplate from '../models/issue-template.model';
import ListingChallenge from '../models/listing-challenge.model';
import ListingStats from '../models/listing-stats.model';
import ListingStatusChangeEvent from '../models/listing-status-change-event.model';



export interface ListingsQuery {
  start?: number;
  status?: string;
  model?: number;
  sellerProfileFilter?: number;
  inventoryNumbers?: string[];
  auctionInfo?:{
    live?: boolean;
    ended?: boolean;
  };
  flagged?: boolean;
  tags?: number[];
  activePricingModel?: string;
  sortKey?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ListingsService {
  constructor(private http: HttpClient) { }
  
  public getListings(query: ListingsQuery): Observable<any> {
    var start = query.start ? query.start : 0;
    var path = `/marketplace/listings?start=${start}&limit=20&expand[]=model&expand[]=sellerProfile.user`;
    if (query?.status) {
      path += `&statuses=${query.status}`;
    }
    if (query?.model) {
      path += `&models=${query.model}`
    }
    if (query?.sellerProfileFilter) {
      path += `&sellerProfile[]=${query.sellerProfileFilter}`
    }
    if (query?.flagged) {
      path += `&flagged=${query.flagged}`;
    }
    if (query?.tags) {
      for (var tagId of query.tags) {
        path += `&tags[]=${tagId}`
      }
    }
    if (query?.activePricingModel) {
      path += `&activePricingModel=${query.activePricingModel}`
    }
    if (query.auctionInfo?.live != undefined) {
      path += `&auctionInfo.live=${query.auctionInfo.live}`
    }
    if (query.auctionInfo?.ended != undefined) {
      path += `&auctionInfo.ended=${query.auctionInfo.ended}`
    }
    if (query?.sortKey) {
      path += `&sort=${query.sortKey}`
    }
    if (query?.inventoryNumbers) {
      query.inventoryNumbers.forEach(inventoryNumber => path += `&inventoryNumber[]=${inventoryNumber}`)
    }
    return this.http.get<Listing[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
      map(response => {
        return {
          data: response.body!.map(l => Object.assign(new Listing(), l)),
          totalCount:  response.headers.get('X-Total-Count')
        }
      })
    );
  }

  public getListingsForPurchaseRequest(sellerId: number, purchaseRequestId: number, offset: number = 0) {
    var path = `/marketplace/listings/purchase-request?sellerId=${sellerId}&purchaseRequestId=${purchaseRequestId}`;
    path += '&expand[]=model&expand[]=sellerProfile.user'; 
    if (offset > 0) {
      path += `&start=${offset}`
    }
    return this.http.get<Listing[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
      map(response => {
        return {
          data: response.body!.map(l => Object.assign(new Listing(), l)),
          totalCount:  response.headers.get('X-Total-Count')
        }
      })
    );
  }
    
  public getListing(listingId: number): Observable<Listing> {
    return this.http.get<Listing>(environment.apiUrl + `/marketplace/listings/${listingId}?expand[]=sellerProfile&expand[]=model&expand[]=listingChallenge&expand[]=sellerProfile.payoutAccount&expand[]=sellerProfile.user&expand[]=issues&expand[]=accessories&expand[]=auctionInfo.guarantorBuyerProfile.user`).pipe(
      map(listing => Object.assign(new Listing(), listing))
    );
  }

  public getListingStatusChangeEvents(listingId: number): Observable<ListingStatusChangeEvent[]> {
    return this.http.get<ListingStatusChangeEvent[]>(environment.apiUrl + `/marketplace/listings/${listingId}/status-change-events?expand[]=user`);
  }

  public getChallenge(sellerProfileId: number): Observable<ListingChallenge> {
    return this.http.post<ListingChallenge>(environment.apiUrl + `/marketplace/listings/challenge`, { sellerProfileId }).pipe(
      map(challenge => Object.assign(new ListingChallenge(), challenge))
    );
  }

  public approveListing(listingId: number, rationale: string) {
    return this.http.post(environment.apiUrl + `/marketplace/listings/${listingId}/publish`, { rationale });
  }
  
  public rejectListing(listingId: number, rationale: string) {
    return this.http.post(environment.apiUrl + `/marketplace/listings/${listingId}/reject`, { rationale });
  }

  public archiveListing(listingId: number, rationale?: string) {
    return this.http.post(environment.apiUrl + `/marketplace/listings/${listingId}/archive`, { rationale });
  }

  public unarchiveListing(listingId: number, rationale?: string) {
    return this.http.post(environment.apiUrl + `/marketplace/listings/${listingId}/unarchive`, { rationale });
  }

  public getIssues(): Observable<IssueTemplate[]> {
    return this.http.get<IssueTemplate[]>(environment.apiUrl + '/marketplace/issues').pipe(
      map(issues => issues.map(i => Object.assign(new IssueTemplate(), i)))
    );
  }

  public createListing(params: any) {
    return this.http.post(environment.apiUrl + `/marketplace/listings`, params);
  }

  public updateListing(listingId: number, params: any) {
    return this.http.patch(environment.apiUrl + `/marketplace/listings/${listingId}`, params)
  }

  public getStats(statuses: string | null, sellerProfileFilter: number | null): Observable<ListingStats> {
    var path = `/marketplace/listings/stats?`;
    if (!!statuses) {
      path += `&statuses=${statuses}`;
    }
    if (!!sellerProfileFilter) {
      path += `&sellerProfile[]=${sellerProfileFilter}`
    }
    return this.http.get<Listing[]>(environment.apiUrl + path).pipe(
      map(listingStats => Object.assign(new ListingStats(), listingStats))
    );
  }
}
