<div style="padding: 12px">
  <h3>New Tag</h3>
  <div class="d-flex flex-column" style="gap: 10px">
    <mat-form-field appearance="outline" class="flex-fill">
      <mat-label>Tag Description</mat-label>
      <input matInput type="string" placeholder="Tag" [formControl]="tagDisplayValue">
    </mat-form-field>
  </div>
  <div class="d-flex justify-content-between mt-3">
    <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="onSubmit()">Save</button>
  </div>
</div>
