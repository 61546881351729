import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import VerificationReport from '../models/verification-report.model';
import Order from '../models/order.model';

@Injectable({
    providedIn: 'root'
})
export class VerificationReportsService {
  constructor(private http: HttpClient) { }

  public getVerificationReports(status: string | null, priority: string | null, result: string | null, intakeReport: number | null, authenticationReport: number | null, diagnosticReport: number | null, pageSize: number, offset: number): Observable<any> {
      var path = `/verification/verification-reports?pageSize=${pageSize}&offset=${offset}&expand[]=order.listing.model&expand[]=listing.model`;
      if (!!status) {
        path += `&status[]=${status}`;
      }
      if (!!priority) {
        path += `&priority[]=${priority}`;
      }
      if (!!result) {
        path += `&result[]=${result}`;
      }
      if (!!intakeReport) {
        path += `&intakeReport[]=${intakeReport}`;
      }
      if (!!authenticationReport) {
        path += `&authenticationReport[]=${authenticationReport}`;
      }
      if (!!diagnosticReport) {
        path += `&diagnosticReport[]=${diagnosticReport}`;
      }
      return this.http.get<VerificationReport[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
          map(response => {
            return {
              data: response.body!.map(d => this.toVerificationReport(d)),
              totalCount:  response.headers.get('X-Total-Count')
            }
          })
        );
  }

  public getVerificationReport(verificationReportId: number) : Observable<any> {
      var path = "/verification/verification-reports/" + verificationReportId + "?expand[]=order.listing.model&expand[]=listing.model&expand[]=intakeReport&expand[]=authenticationReport&expand[]=diagnosticReport";
      return this.http.get<VerificationReport>(environment.apiUrl + path, {observe: 'response'}).pipe(
          map(response =>  this.toVerificationReport(response.body)));
  }

  public modifyVerificationReport(verificationReportId: number, template: any): Observable<any> {
    var path = "/verification/verification-reports/" + verificationReportId;
    return this.http.patch<VerificationReport>(environment.apiUrl + path, template)
        .pipe(map(response => this.toVerificationReport(response)));
  }

  private toVerificationReport(fetchedVerificationReport: any): VerificationReport {
      var verificationReport = Object.assign(new VerificationReport(), fetchedVerificationReport);
      if (verificationReport.order) {
        verificationReport.order = Order.toOrder(verificationReport.order);
      }
      return verificationReport;
    }
}
