<div class="container" *ngIf="homeFeed && !isLoading else loading">
    <div class="header d-flex flex-column">
        <div class="d-flex justify-content-between">
            <div>
                <h1>{{homeFeed.name}}</h1>
            </div>
            <div>
                <b>Created:</b> {{homeFeed.created | date:'medium'}}<br />
                <b>Last updated:</b> {{homeFeed.updated | date:'medium'}}<br />
                <b>Audience:</b> {{homeFeed.audience}}
            </div>
        </div>
        <div>
            <button mat-raised-button color="primary" [routerLink]="['/content/home-feeds', homeFeed.id, 'edit']">Edit</button>
            <button mat-raised-button color="warn" class="mx-2" (click)="deleteButtonPressed()">Delete</button>
        </div>
    </div>
    <br />
    <mat-card *ngFor="let section of homeFeed.sections; index as i" class="section" style="overflow: hidden">
        <div class="d-flex justify-content-between">
            <div>
                <mat-card-title *ngIf="section.title">{{section.title}}</mat-card-title>
                <mat-card-subtitle>{{section.type}}</mat-card-subtitle>
            </div>
            <div>
                <button mat-stroked-button color="primary" (click)="editSectionButtonPressed(section)">Edit</button>
                <button mat-stroked-button color="warn" class="mx-2" (click)="removeSectionButtonPressed(section)" [disabled]="removingSection" [class.spinner]="removingSection == section">Remove</button>
                <button mat-stroked-button class="viewListButton" [routerLink]="['/content/lists', section.object.id]">View list <mat-icon>launch</mat-icon></button>
            </div>
        </div>
        <mat-card-content>
            <div *ngIf="section.type == 'MODEL_CLUSTER'" style="overflow-x: scroll; white-space: nowrap; overflow: visible;">
                <model-card *ngFor="let model of section.object.items" [model]="model" style="display: inline-block; margin-right: 10px;"></model-card>
            </div>
            <div *ngIf="section.type == 'LISTING_CLUSTER'" style="overflow-x: scroll; white-space: nowrap; overflow: visible;">
                <listing-card *ngFor="let listing of section.object.items" [listing]="listing" style="display: inline-block; margin-right: 10px; vertical-align: top;"></listing-card>
            </div>
            <div *ngIf="section.type == 'BRAND_CLUSTER'" style="overflow-x: scroll; white-space: nowrap; overflow: visible;">
                <brand-card *ngFor="let brand of section.object.items" [brand]="brand" style="display: inline-block; margin-right: 10px;"></brand-card>
            </div>
            <div *ngIf="section.type == 'COLLECTION_CLUSTER'" style="overflow-x: scroll; white-space: nowrap; overflow: visible;">
                <collection-card *ngFor="let collection of section.object.items" [collection]="collection" style="display: inline-block; margin-right: 10px;"></collection-card>
            </div>
            <div *ngIf="section.type == 'FEATURE_CLUSTER'" style="overflow-x: scroll; white-space: nowrap; overflow: visible;">
                <feature-card *ngFor="let feature of section.object.items" [feature]="feature" style="display: inline-block; margin-right: 10px; margin-top: 10px"></feature-card>
            </div>
            <div *ngIf="section.type == 'ARTICLE_CLUSTER'" style="overflow-x: scroll; white-space: nowrap; overflow: visible;">
                <article-card *ngFor="let article of section.object.items" [article]="article" style="display: inline-block; margin-right: 10px;"></article-card>
            </div>
            <div class="d-flex" style="margin-top: 6px; margin-bottom: -4px">
                <button mat-button color="34302D" class="flex-fill" [disabled]="i == 0" (click)="increasePositionOfSection(section)"><mat-icon>arrow_upward</mat-icon></button>
                <button mat-button color="34302D" class="flex-fill" [disabled]="i == homeFeed.sections.length - 1" (click)="decreasePositionOfSection(section)"><mat-icon>arrow_downward</mat-icon></button>
            </div>
        </mat-card-content>
    </mat-card>
    <div class="addSectionButton" (click)="addSectionButtonPressed()"><mat-icon>add</mat-icon></div>
</div>
<ng-template #loading>
    <div class="d-flex flex-column align-items-center m-5">
      <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
    </div>
</ng-template>