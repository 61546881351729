import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreditBalanceAdjustmentsService } from 'src/app/_services/credit-balance-adjustments.service';

@Component({
  selector: 'app-adjust-credit-balance-dialog',
  templateUrl: './adjust-credit-balance-dialog.component.html',
  styleUrls: ['./adjust-credit-balance-dialog.component.css']
})
export class AdjustCreditBalanceDialogComponent implements OnInit {

  formGroup = new FormGroup({
    amount: new FormControl('', [Validators.required, Validators.pattern("^[-]?[\\d]+$")]),
    description: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(255)]),
  });
  get formControls() {
    return this.formGroup.controls;
  }

  isSubmitting = false

  constructor(
    private dialogRef: MatDialogRef<AdjustCreditBalanceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {billingAccountId: number},
    private creditBalanceAdjustmentsService: CreditBalanceAdjustmentsService
  ) { 

  }

  ngOnInit(): void {
    
  }

  cancelButtonPressed() {
    this.close();
  }

  submitButtonPressed() {
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true
    
    var params: any = {
      billingAccount: this.data.billingAccountId,
      amount: this.formControls.amount.value,
      description: this.formControls.description.value
    }
    this.creditBalanceAdjustmentsService.createCreditBalanceAdjustment(params).subscribe({
      next: () => {
        this.dialogRef.close({ updated : true });
      },
      error: (error) => {
        console.log(error);
        this.isSubmitting = false;
      }
    })
  }

  close() {
    this.dialogRef.close({ updated : this.isSubmitting});    
  }
}
