import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Collection from 'src/app/models/collection.model';
import { CollectionsService } from 'src/app/_services/collections.service';

@Component({
  selector: 'collection-select',
  templateUrl: './collection-select.component.html',
})
export class CollectionSelectComponent implements OnInit {
    @Input('control') control!: FormControl;
    @Input('required') required: boolean = false;
    @Input('label') label: string = "Collection";
    @Input('placeholder') placeholder: string = "Pick a collection";
    
    autocompleteResults: Collection[] = []

    constructor(private collectionsService: CollectionsService) {

    }

    ngOnInit(): void {
        this.control.valueChanges.pipe(
            debounceTime(100),
            distinctUntilChanged()
        ).subscribe({
            next: value => {
                if (value instanceof Collection) {
                    return;
                }

                this.collectionsService.getCollections(10, 0, '%' + value + '%', null).subscribe({
                    next: collections => {
                        this.autocompleteResults = collections
                    }
                })
            }
        })
    }

    displayCollection(collection: Collection): string {
        return collection?.name ?? ""
    }
}
