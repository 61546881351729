import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImageViewComponent } from 'src/app/common/image-view/image-view.component';
import Model from 'src/app/models/model.model';
import { CatalogService, Gender, Color, NumeralType, MovementType, ClaspType, Material, Complication, BraceletStyle } from 'src/app/_services/catalog.service';
import { ImageService } from 'src/app/_services/image.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import Brand from 'src/app/models/brand.model';
import { CategoryService } from 'src/app/_services/category.service';
import Category from 'src/app/models/category.model';
import { BrandsService } from 'src/app/_services/brands.service';
import Series from 'src/app/models/series.model';
import { SeriesService } from 'src/app/_services/series.service';
import { ModelsService } from 'src/app/_services/models.service';
import BezelType from 'src/app/models/bezel-type.model';
import { BezelTypesService } from 'src/app/_services/bezel-types.service';

@Component({
  selector: 'app-models-create',
  templateUrl: './models-create.component.html',
  styleUrls: ['./models-create.component.css']
})
export class ModelsCreateComponent implements OnInit, OnDestroy, ToolbarController  {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Models", path: ['/catalog', 'models'] }]);
  title = new BehaviorSubject<string>("Add Model");
  
  brands: Brand[] = [];
  series: Series[] = [];
  genders: Gender[] = [];
  colors: Color[] = [];
  movementTypes: MovementType[] = [];
  numeralTypes: NumeralType[] = [];
  bezelTypes: BezelType[] = [];
  claspTypes: ClaspType[] = [];
  braceletStyles: BraceletStyle[] = [];
  materials: Material[] = [];
  complications: Complication[] = [];
  categories: Category[] = []

  filteredSeries: Series[] = [];
  filteredBraceletStyles: BraceletStyle[] = [];

  formGroup = new FormGroup({
    name: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    displayName: new FormControl(''),
    brand: new FormControl<number>(0, { nonNullable: true, validators: [Validators.required] }),
    series: new FormControl('', { nonNullable: true }),
    referenceNumber: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    gender: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    description: new FormControl(''),
    categories: new FormControl<number[]>([]),
    releaseYear: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.pattern('\\d{4}')]}),
    discontinuationYear: new FormControl('', { nonNullable: true, validators:[Validators.pattern('\\d{4}')]}),
    caseMaterials: new FormControl<number[]>([], Validators.required),
    caseSize: new FormControl('',  { nonNullable: true, validators: [Validators.required, Validators.pattern(' *\\d+([.]\\d+)?( ?x ?\\d+([.]\\d+)?)? *')]}),
    thickness: new FormControl('',  { nonNullable: true, validators: [Validators.pattern('\\d+(.\\d+)?')]}),
    waterResistance: new FormControl('',  { nonNullable: true, validators: [Validators.pattern('\\d+(.\\d+)?')]}),
    bezelType: new FormControl<number | null>(null),
    bezelMaterial: new FormControl<number | null>(null, Validators.required),
    crystal: new FormControl<number | null>(null, Validators.required),
    lugWidth: new FormControl('', { nonNullable: true, validators: [Validators.pattern('\\d+(.\\d+)?')]}),
    dialColor: new FormControl<number | null>(null, Validators.required),
    dialNumerals: new FormControl<number | null>(null, Validators.required),
    complications: new FormControl<number[]>([]),
    movementType: new FormControl<number | null>(null, Validators.required),
    caliber: new FormControl(''),
    powerReserve: new FormControl('', { nonNullable: true, validators: [Validators.pattern('\\d+')]}),
    numberOfJewels: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.pattern('\\d+')]}),
    braceletStyle: new FormControl<number | null>(null),
    braceletMaterials: new FormControl<number[]>([]),
    braceletColor: new FormControl<number | null>(null),
    claspType: new FormControl<number | null>(null),
    claspMaterials: new FormControl<number[]>([]),
  });
  get formControls() {
    return this.formGroup.controls;
  }

  images: InstanceType<typeof Image>[] = []

  submitting: boolean = false;

  private _destroyed = new ReplaySubject<boolean>(1);

  constructor(
    private toolbarService: ToolbarService,
    private catalogService: CatalogService,
    private imageService: ImageService,
    private bezelTypesService: BezelTypesService,
    private categoryService: CategoryService,
    private brandsService: BrandsService,
    private seriesService: SeriesService,
    private modelsService: ModelsService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) { 
    this.brandsService.getBrands().subscribe({
      next: (brands: Brand[]) => {
        this.brands = brands.sort((a, b) => a.name.localeCompare(b.name));;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    this.seriesService.listSeries(null).pipe(takeUntil(this._destroyed)).subscribe({
      next: (series: Series[]) => {
        this.series = series.sort((a, b) => a.name.localeCompare(b.name));;
        // Filter to only include series that match the currently selected brand.
        var brand = this.formControls.brand.value;
        if (brand) {
          this.filteredSeries = series.filter(series => series.brand.id == brand);
          this.filteredBraceletStyles = this.braceletStyles.filter(braceletStyle => braceletStyle.brand.id == brand);
        }
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    this.catalogService.getGenders().pipe(takeUntil(this._destroyed)).subscribe({
      next: (genders: Gender[]) => {
        this.genders = genders.sort((a, b) => a.name.localeCompare(b.name));;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    this.catalogService.getColors().pipe(takeUntil(this._destroyed)).subscribe({
      next: (colors: Color[]) => {
        this.colors = colors.sort((a, b) => a.name.localeCompare(b.name));;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    this.catalogService.getMovementTypes().pipe(takeUntil(this._destroyed)).subscribe({
      next: (movementTypes: MovementType[]) => {
        this.movementTypes = movementTypes.sort((a, b) => a.name.localeCompare(b.name));;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    this.catalogService.getNumeralTypes().pipe(takeUntil(this._destroyed)).subscribe({
      next: (numeralTypes: NumeralType[]) => {
        this.numeralTypes = numeralTypes.sort((a, b) => a.name.localeCompare(b.name));;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    this.catalogService.getClaspTypes().pipe(takeUntil(this._destroyed)).subscribe({
      next: (claspTypes: ClaspType[]) => {
        this.claspTypes = claspTypes.sort((a, b) => a.name.localeCompare(b.name));;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    this.catalogService.getBraceletStyles().pipe(takeUntil(this._destroyed)).subscribe({
      next: (braceletStyles: BraceletStyle[]) => {
        this.braceletStyles = braceletStyles.sort((a, b) => a.name.localeCompare(b.name));;
        this.filteredBraceletStyles = this.braceletStyles.filter(braceletStyle => braceletStyle.brand.id == this.formControls.brand.value);
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    this.catalogService.getMaterials().pipe(takeUntil(this._destroyed)).subscribe({
      next: (materials: Material[]) => {
        this.materials = materials.sort((a, b) => a.name.localeCompare(b.name));
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    this.catalogService.getComplications().pipe(takeUntil(this._destroyed)).subscribe({
      next: (complications: Complication[]) => {
        this.complications = complications.sort((a, b) => a.name.localeCompare(b.name));
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    this.bezelTypesService.getBezelTypes().subscribe({
      next: (bezelTypes: BezelType[]) => {
        this.bezelTypes = bezelTypes.sort((a, b) => a.name.localeCompare(b.name));
      },
      error: (error: any) => {
        console.log(error);
      }
    })

    this.formControls.brand.valueChanges.pipe(takeUntil(this._destroyed)).subscribe({
      next: () => {
        var brand = this.formControls.brand.value;
        if (brand) {
          // Filter to only include series that match the currently selected brand.
          this.filteredSeries = this.series.filter(series => series.brand.id == brand);
          this.filteredBraceletStyles = this.braceletStyles.filter(braceletStyle => braceletStyle.brand.id == brand);
        }
      }
    });
    this.categoryService.getCategories(-1, 0).subscribe({
      next: (categories) => {
        this.categories = categories.sort((a, b) => a.name.localeCompare(b.name))
      }
    })
    
    this.route.queryParams.subscribe(params => {
      if (params.duplicateFrom) {
        modelsService.getModel(params.duplicateFrom).subscribe({
          next: (model: Model | null) => {
            if (model == null) {
              return;
            }

            this.formControls.name.patchValue(model.name);
            this.formControls.displayName.patchValue(model.displayName);
            this.formControls.brand.patchValue(model.brand.id);
            this.formControls.series.patchValue(model.series ? model.series.id.toString() : "--");
            this.formControls.referenceNumber.patchValue(model.referenceNumber);
            this.formControls.gender.patchValue(model.gender.id.toString());
            this.formControls.categories.patchValue(model.categories.map(c => c.id));
            this.formControls.description.patchValue(model.description);
            this.formControls.releaseYear.patchValue(model.releaseYear.toString());
            this.formControls.discontinuationYear.patchValue(model.discontinuationYear?.toString() ?? '');
            this.formControls.caseSize.patchValue(model.caseSize);
            this.formControls.thickness.patchValue(model.thickness?.toString() ?? '');
            this.formControls.waterResistance.patchValue(model.waterResistance?.toString() ?? '');
            this.formControls.bezelType.patchValue(model.bezelType?.id ?? null);
            this.formControls.bezelMaterial.patchValue(model.bezelMaterial.id);
            this.formControls.crystal.patchValue(model.crystal.id);
            this.formControls.lugWidth.patchValue(model.lugWidth?.toString() ?? '');
            this.formControls.caseMaterials.patchValue(model.caseMaterials.map(m => m.id));
            this.formControls.dialColor.patchValue(model.dialColor.id);
            this.formControls.dialNumerals.patchValue(model.numerals.id);
            this.formControls.complications.patchValue(model.complications.map(m => m.id))
            this.formControls.movementType.patchValue(model.movementType.id);
            this.formControls.caliber.patchValue(model.caliber);
            this.formControls.powerReserve.patchValue(model.powerReserve?.toString() ?? '');
            this.formControls.numberOfJewels.patchValue(model.numberOfJewels.toString());
            this.formControls.braceletColor.patchValue(model.braceletColor?.id ?? null);
            this.formControls.claspType.patchValue(model.braceletClaspType?.id ?? null);
            this.formControls.braceletStyle.patchValue(model.braceletStyle?.id ?? null);
            this.formControls.braceletMaterials.patchValue(model.braceletMaterials?.map(m => m.id) ?? [])
            this.formControls.claspMaterials.patchValue(model.braceletClaspMaterials?.map(m => m.id) ?? [])

            this.images = [];
            for (var image of model.images) {
              var img = new Image();
              img.src = image.url;
              this.images.push(img);
            }
          },
          error: (error: any) => {
            console.log(error);
          }
        });
      }
    });
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  onImageUpload(target: EventTarget | null) {
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    var input = target as HTMLInputElement;
    Array.prototype.forEach.call(input.files, file => {
      var img = new Image();
      var reader = new FileReader();
      reader.onload = event => {
        img.src = event.target!.result as string;
      }
      reader.readAsDataURL(file);
      this.images.push(img);
    });
  }

  onImageRemove(imageView: ImageViewComponent) {
    this.images = this.images.filter(images => images !== imageView.image);
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }
    this.submitting = true;

    var model: any = {
      name: this.formControls.name.value,
      displayName: this.formControls.displayName.value,
      brand: this.formControls.brand.value,
      referenceNumber: this.formControls.referenceNumber.value,
      gender: this.formControls.gender.value,
      categories: this.formControls.categories.value,
      description: this.formControls.description.value,
      releaseYear: this.formControls.releaseYear.value,
      discontinuationYear: this.formControls.discontinuationYear.value,
      caseMaterials: this.formControls.caseMaterials.value,
      caseSize: this.formControls.caseSize.value,
      thickness: this.nullIfEmpty(this.formControls.thickness.value),
      waterResistance: this.formControls.waterResistance.value,
      bezelType: this.formControls.bezelType.value,
      bezelMaterial: this.formControls.bezelMaterial.value,
      crystal: this.formControls.crystal.value,
      lugWidth: this.nullIfEmpty(this.formControls.lugWidth.value),
      dialColor: this.formControls.dialColor.value,
      numerals: this.formControls.dialNumerals.value,
      complications: this.formControls.complications.value,
      movementType: this.formControls.movementType.value,
      caliber: this.formControls.caliber.value,
      powerReserve: this.formControls.powerReserve.value,
      numberOfJewels: this.formControls.numberOfJewels.value,
      braceletStyle: this.nullIfEmpty(this.formControls.braceletStyle.value),
      braceletMaterials: this.formControls.braceletMaterials.value,
      braceletColor: this.nullIfEmpty(this.formControls.braceletColor.value),
      braceletClaspType: this.nullIfEmpty(this.formControls.claspType.value),
      braceletClaspMaterials: this.formControls.claspMaterials.value
    }
    if (typeof this.formControls.series.value === "number") {
      model["series"] = this.formControls.series.value
    }
    this.modelsService.createModel(model).subscribe({
      next: (model: Model) => {
        var imageUploads: Observable<number>[] = [];
        for (var image of this.images) {
          imageUploads.push(this.imageService.uploadImage(image));
        }

        if (imageUploads.length == 0) {
          this.router.navigate(['catalog', 'models']);
          this.showCreatedToast(this.formControls.displayName.value || this.formControls.name.value, model.id);
          return;
        }

        forkJoin(imageUploads).subscribe({
          next: imageIds => {
            this.modelsService.updateModel(model.id, {images: imageIds}).subscribe({
              next: () => {
                this.router.navigate(['catalog', 'models']);
                this.showCreatedToast(this.formControls.displayName.value || this.formControls.name.value, model.id);
              },
              error: error => {
                // Failed to patch model images.
                console.error(error);
                this.router.navigate(['catalog', 'models']);
                this.showCreatedToast(this.formControls.displayName.value || this.formControls.name.value, model.id);
              }
            })
          }, 
          error: error => {
            // Failed to upload images.
            console.error(error);
            this.router.navigate(['catalog', 'models']);
            this.showCreatedToast(this.formControls.displayName.value || this.formControls.name.value, model.id);
          }
        });
      },
      error: error => {
        this.submitting = false;
        alert(error);
      }
    })
  }

  onClear() {
    this.formGroup.reset();
  }

  private showCreatedToast(title: string, newId: number) {
    let snackBarRef = this.snackBar.open('\"' + title + '\" added to catalog', 'View', {
      duration: 3000
    });
    snackBarRef.onAction().subscribe(() => {
      this.router.navigate(['catalog', 'models', newId]);
    });
  }

  private nullIfEmpty(value: any): any | null {
    if (value == null) {
      return null
    }

    if (typeof value == "string" && value.length == 0) {
      return null
    }

    return value
  }
}
