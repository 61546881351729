import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import DiagnosticReport from '../models/diagnostic-report.model';
import Order from '../models/order.model';
import User from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class DiagnosticReportsService {
  constructor(private http: HttpClient) { }

  public getDiagnosticReports(status: string | null, priority: string | null, result: string | null, blocked: boolean | null, pageSize: number, offset: number): Observable<any> {
      var path = `/verification/diagnostic-reports?pageSize=${pageSize}&offset=${offset}&expand[]=order.listing.model&expand[]=listing.model`;
      if (!!status) {
        path += `&status[]=${status}`;
      }
      if (!!priority) {
        path += `&priority[]=${priority}`;
      }
      if (!!result) {
        path += `&result[]=${result}`;
      }
      if (blocked != null) {
        path += `&blocked=${blocked}`;
      }
      return this.http.get<DiagnosticReport[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
          map(response => {
            return {
              data: response.body!.map(d => this.toDiagnosticReport(d)),
              totalCount:  response.headers.get('X-Total-Count')
            }
          })
        );
  }

  public getDiagnosticReport(diagnosticReportId: number) : Observable<any> {
      var path = "/verification/diagnostic-reports/" + diagnosticReportId + "?expand[]=order.listing.model&expand[]=listing.model&expand[]=completedBy";
      return this.http.get<DiagnosticReport>(environment.apiUrl + path, {observe: 'response'}).pipe(
          map(response =>  this.toDiagnosticReport(response.body)));
  }

  public createDiagnosticReport(template: any): Observable<any> {
    var path = "/verification/diagnostic-reports";
    return this.http.post<DiagnosticReport>(environment.apiUrl + path, template)
        .pipe(map(response => this.toDiagnosticReport(response)));
  }

  public modifyDiagnosticReport(diagnosticReportId: number, template: any): Observable<any> {
    var path = "/verification/diagnostic-reports/" + diagnosticReportId;
    return this.http.patch<DiagnosticReport>(environment.apiUrl + path, template)
        .pipe(map(response => this.toDiagnosticReport(response)));
  }

  private toDiagnosticReport(fetchedDiagnosticReport: any): DiagnosticReport {
      var diagnosticReport = Object.assign(new DiagnosticReport(), fetchedDiagnosticReport);
      if (diagnosticReport.order) {
        diagnosticReport.order = Order.toOrder(diagnosticReport.order);
      }
      if (diagnosticReport.completedBy) {
        diagnosticReport.completedBy = Object.assign(new User(), diagnosticReport.completedBy);
      }
      return diagnosticReport;
    }
}