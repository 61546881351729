<div class="container" *ngIf="ingestionRequest else loading">
    <mat-card class="m-2">
        <mat-card-title>
            <div class="d-flex justify-content-between align-items-center">
                <span><mat-icon color="warn" *ngIf="ingestionRequest.flagged">flag</mat-icon> Request</span>
                <span class="d-flex" style="gap: 10px">
                    <button class="flex-fill" color="primary" [disabled]="ingestionRequest.listing || ingestionRequest.status === 'ARCHIVED'" [routerLink]="['/marketplace/listings/new']" [queryParams]="{ingestionRequest: ingestionRequest.id}" mat-raised-button>Create Listing</button>
                    <button class="flex-fill" *ngIf="ingestionRequest.listing" [routerLink]="['/marketplace/listings/', ingestionRequest.listing.id]" mat-raised-button>View listing <mat-icon iconPositionEnd>launch</mat-icon></button>
                </span>
            </div>
        </mat-card-title>
        <mat-card-content>
            <div class="labeled-box">
                <div class="label">
                    Status
                </div>
                <span>{{ ingestionRequest.status }}</span>
            </div>
            <div class="labeled-box">
                <div class="label">
                    URL
                </div>
                <span><a [href]="ingestionRequest.url" target="_blank">{{ ingestionRequest.url }}</a></span>
            </div>
            <div class="labeled-box" style="cursor: pointer"  [matMenuTriggerFor]="assigneeSelectMenu">
                <div class="label">
                    Assignee
                </div>
                <span>{{ ingestionRequest.assignee?.displayName ?? 'Unassigned' }}</span>
            </div>
            <mat-menu #assigneeSelectMenu="matMenu">
                <button mat-menu-item (click)="onSelectNewAssignee(null)">Unassign</button>
                <button mat-menu-item *ngFor="let user of consoleUsers" (click)="onSelectNewAssignee(user)">{{ user.displayName }}</button>
            </mat-menu>
        </mat-card-content>
    </mat-card>
    <mat-card class="m-2">
        <mat-card-title>
          Notes
        </mat-card-title>
        <mat-card-content [innerText]="ingestionRequest.notes ?? '(empty)'"></mat-card-content>
      </mat-card>
    <mat-card class="m-2">
        <mat-card-title>
            Actions
        </mat-card-title>
        <mat-card-content>
            <button class="m-1" mat-raised-button color="primary" (click)="onEdit()">Edit</button>
            <button class="m-1" *ngIf="!ingestionRequest.flagged" (click)="onFlag(true)" mat-raised-button color="primary" color="accent" [disabled]="archiving || flagging" [class.spinner]="flagging">Flag</button>
            <button class="m-1" *ngIf="ingestionRequest.flagged" (click)="onFlag(false)" mat-raised-button color="primary" color="accent" [disabled]="archiving || flagging" [class.spinner]="flagging">Unflag</button>
            <button class="m-1" color="warn" [disabled]="!ingestionRequest.canArchive || archiving || flagging" (click)="onArchive()" [class.spinner]="archiving" mat-raised-button>Archive</button>
        </mat-card-content>
    </mat-card>
</div>
<ng-template #loading>
    <div class="d-flex flex-column align-items-center m-5">
        <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
    </div>
</ng-template>