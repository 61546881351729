import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subscription } from 'rxjs';
import { ToolbarService, ToolbarController } from 'src/app/_services/toolbar.service';
import Drop, { DROP_STATUSES, DROP_STATUS_DESCRIPTIONS } from 'src/app/models/drop.model';
import { PageEvent } from '@angular/material/paginator';
import { DropsService } from 'src/app/_services/drops.service';
import { Sort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';


@Component({
  selector: 'app-drops-home',
  templateUrl: './drops-home.component.html',
  styleUrls: ['./drops-home.component.css']
})
export class DropsHomeComponent implements OnInit, OnDestroy, ToolbarController, AfterViewInit {
  title = new BehaviorSubject<string>('Drops');

  statusFilter!: string | null;

  dropStatuses: object;
  dropStatusDescriptions: object;

  drops : Drop[] = [];
  displayedColumns: string[] = ['id', 'status', 'date', 'listings', 'created'];
  dataSource: MatTableDataSource<Drop>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  page: number = 0;
  sortKey: string | null = null;
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20, 40, 60, 80, 100];
  isLoading = true;
  private loadingSubscription: Subscription | null = null

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private dropsService: DropsService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.dropStatuses = DROP_STATUSES;
    this.dropStatusDescriptions = DROP_STATUS_DESCRIPTIONS;

    this.activatedRoute.queryParams.subscribe(params => {
      this.page = params.page ? params.page : 0;
      this.sortKey = params.sort ? params.sort : null;
      this.statusFilter = params.status ? params.status : null;
      this.fetchDrops(this.page);
    })
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  get loading(): boolean {
    return this.drops.length === 0;
  }

  pageChangeEvent = (event: PageEvent) => {
    this.page = event.pageIndex
    // this.updateUrlParametersIfNeeded();
  }
  
  onSelectStatus = (event: any) => {
    this.updateUrlParametersIfNeeded();
  }

  fetchDrops = (index: number) => {
    this.isLoading = true;

    if (this.loadingSubscription != null) {
      this.loadingSubscription.unsubscribe();
    }

    this.loadingSubscription = this.dropsService.getDrops(this.statusFilter).subscribe({
      next: (response: any) => {
        if (this.length == 0) {    
          var sortKey = this.sortKey ?? "id";
          this.sort.active = sortKey.startsWith("-") ? sortKey.substring(1) : sortKey;
          this.sort.direction = sortKey.startsWith("-") ? "asc" : "desc";
        }

        this.drops = response.data;
        this.dataSource.data = this.drops;
        this.length = this.drops.length;
        this.isLoading = false;
        this.loadingSubscription = null;
      },
      error: (error: any) => {
        console.log(error);
        this.drops = [];
        this.length = 0;
        this.isLoading = false;
        this.loadingSubscription = null;
      }
    });
  };

  onSort(event: Sort) : void {
    if (event.active && event.direction) {
      this.sortKey = (event.direction == "asc" ? "" : "-") + event.active;
    } else {
      this.sortKey = null;
    }
    this.updateUrlParametersIfNeeded();
  }

  addButtonPressed(): void {
    this.dropsService.createDrop().subscribe({
      next: drop => {
        this.router.navigate(['/marketplace/drops', drop.id]);
      }
    })
  }

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      page: this.page == 0 ? null : this.page,
      sort: this.sortKey == null ? null : this.sortKey,
      status: this.statusFilter,
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }
}
