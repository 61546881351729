import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { ToolbarService, ToolbarController } from 'src/app/_services/toolbar.service';
import { PageEvent } from '@angular/material/paginator';
import BillingAccount from 'src/app/models/billing-account.model';
import { BillingAccountsService } from 'src/app/_services/billing-accounts.service';

@Component({
  selector: 'app-browse-billing-accounts',
  templateUrl: './browse-billing-accounts.component.html',
  styleUrls: ['./browse-billing-accounts.component.css']
})
export class BrowseBillingAccountsComponent implements OnInit, OnDestroy, ToolbarController {
  private static DEFAULT_PAGE_SIZE = 20;

  billingAccounts: BillingAccount[] = [];
  displayedColumns: string[] = ['id', 'owner', 'created', 'updated'];

  title = new BehaviorSubject<string>('Billing Accounts');
  page = 0;
  length = 0;
  pageSize = BrowseBillingAccountsComponent.DEFAULT_PAGE_SIZE;
  pageSizeOptions: number[] = [10,20,40,60,80,100];
  sortKey!: string | null;
  isLoading = true
  
  private _destroyed = new BehaviorSubject<boolean>(false);
  
  constructor(
    private toolbarService: ToolbarService,
    private billingAccountsService: BillingAccountsService,
    private router: Router,
    private activatedRoute: ActivatedRoute
    ) {
      this.sortKey = '-created';
      this.activatedRoute.queryParams.subscribe(params => {
        this.page = params.page ? params.page : 0;
  
        this.fetchBillingAccounts(this.page);
      })
    }
    
  ngOnInit(): void {
    this.toolbarService.setController(this);
  }
  
  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  pageChangeEvent = (event: PageEvent) => {
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updateUrlParametersIfNeeded();
  }

  onSort(event: Sort): void {
    if (event.active == 'created' && event.direction == 'asc') {
      this.sortKey = 'created';
    } else if (event.active == 'created' && event.direction == 'desc') {
      this.sortKey = '-created';
    } else {
      this.sortKey = null;
    }
    this.page = 0;
    this.fetchBillingAccounts(this.page);
  }

  fetchBillingAccounts = (index: number) => {
    this.isLoading = true;

    this.billingAccountsService.getBillingAccounts(this.pageSize, this.pageSize * index, this.sortKey).subscribe({
      next: (result: any) => {
        this.billingAccounts = result.data;
        this.length = result.totalCount;
        this.isLoading = false;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  };

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      page: this.page == 0 ? null : this.page,
      pageSize: this.pageSize == BrowseBillingAccountsComponent.DEFAULT_PAGE_SIZE ? null : this.pageSize,
    }

    this.router.navigate(
      [], 
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }
}
