import { formatDate } from '@angular/common';
import Listing from "./listing.model";
import SellerProfile from './seller-profile.model';
import User from './user.model';

export default class IngestionRequest {
  id!: number;
  status!: string;
  sellerProfile!: SellerProfile
  url!: string;
  listing!: Listing;
  created!: Date;
  updated!: Date;
  flagged!: boolean;
  notes!: string;
  assignee?: User;

  get displayStatus(): string {
    return allStatuses[this.status];
  }

  get displayCreated(): string {
    return formatDate(this.created, 'medium', 'en-US');
  }

  get displayUpdated(): string {
    return formatDate(this.updated, 'medium', 'en-US');
  }

  get canArchive(): boolean {
    return this.status == 'NEEDS_ATTENTION' || this.status == 'PENDING_REVIEW'
  }
}

export const allStatuses: { [key: string]: string } = {
  'ALL': 'All',
  'NEEDS_ATTENTION': 'Needs attention',
  'COMPLETE': 'Complete',
  'ARCHIVED': 'Archived',
}
