<div class="dialog-content">
    <div class="title">Update section</div>
    <div class="break"></div>
    <div class="d-flex flex-column">
        <mat-form-field appearance="outline" class="flex-fill">
            <mat-label>Title</mat-label>
            <input matInput type="string" placeholder="Under 10k" [formControl]="title">
        </mat-form-field>
    </div>
    <div class="d-flex flex-row-reverse w100">
        <button mat-raised-button color="primary" [disabled]="!formGroup.valid || !formGroup.dirty || isSubmitting"
            [class.spinner]="isSubmitting" (click)="submitButtonPressed();">Save</button>
        <button mat-button class="mx-2" [disabled]="isSubmitting" (click)="cancelButtonPressed();">Cancel</button>
    </div>
</div>
