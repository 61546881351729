<div class="container" *ngIf="return else loading">
    <div class="w100 d-flex flex-row justify-content-center">
        <div style="width: 100%; max-width: 800px;">
            <mat-card class="m-2">
                <mat-card-title>
                    <div class="d-flex justify-content-between align-items-center">
                        <span><mat-icon color="warn" *ngIf="return.flagged">flag</mat-icon> General Information</span>
                        <span>
                            <button *ngIf="!return.flagged" (click)="onFlag(true)" mat-raised-button
                                color="warn" [disabled]="isPerformingAction()"
                                [class.spinner]="isFlagging">Flag</button>
                            <button *ngIf="return.flagged" (click)="onFlag(false)" mat-raised-button
                                color="primary" [disabled]="isPerformingAction()"
                                [class.spinner]="isFlagging">Unflag</button>
                        </span>
                    </div>
                </mat-card-title>
                <mat-card-content>
                    <div>
                        <div class="labeled-box">
                            <div class="label">
                                Status
                            </div>
                            <span>{{return.status}}<ng-container *ngIf="return.status=='CANCELLED'"> /
                                    {{return.cancellationReason}}</ng-container></span>
                        </div>
                        <div class="labeled-box">
                            <div class="label">
                                Created
                            </div>
                            <span>{{ return.created | date: 'medium' }}</span>
                        </div>
                        <div class="labeled-box">
                            <div class="label">
                                Updated
                            </div>
                            <span>{{ return.updated | date: 'medium' }}</span>
                        </div>
                        <br />
                        <div class="labeled-box">
                            <div class="label">
                                Order
                            </div>
                            <span><mat-chip-list><order-chip [order]="return.order"></order-chip></mat-chip-list></span>
                        </div>
                        <br />
                        <div class="labeled-box">
                            <div class="label">
                                Reason for return
                            </div>
                            <span>{{return.reason}}</span>
                        </div>
                        <div class="labeled-box">
                            <div class="label">
                                Refund method
                            </div>
                            <span>{{return.refundMethod}}</span>
                        </div>
                        <br />
                        <div class="labeled-box">
                            <div class="label">
                                Buyer
                            </div>
                            <span><mat-chip-list><buyer-chip
                                        [buyer]="return.order.buyerProfile"></buyer-chip></mat-chip-list></span>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-footer class="p-2 pt-0">
                    <button mat-raised-button color="warn" class="ms-2 mb-2" (click)="cancel()"
                        [disabled]="isPerformingAction() || !return.canCancel"
                        [class.spinner]="isCancelling">Cancel</button>
                </mat-card-footer>
            </mat-card>
            <mat-card class="m-2">
                <mat-card-title>
                    Inbound Shipment Information
                </mat-card-title>
                <mat-card-content>
                    <div>
                        <div class="labeled-box">
                            <div class="label">
                                Status
                            </div>
                            <span>{{return.shipment.status}}</span>
                        </div>
                        <div class="labeled-box">
                            <div class="label">
                                Shipping Label
                            </div>
                            <a target='_blank' [href]="return.shipment.shippingLabelUrl">Link</a>
                        </div>
                        <div class="labeled-box">
                            <div class="label">
                                Tracking Number
                            </div>
                            <a target='_blank'
                                [href]="return.shipmentTrackingLink">{{return.shipment.fedexTrackingNumber}}</a>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-footer class="p-2 pt-0">
                    <button mat-raised-button color="primary" class="m-2 mt-0" (click)="markShipmentAsDelivered()"
                        [disabled]="isPerformingAction() || return.shipment.isDelivered"
                        [class.spinner]="isMarkingShipmentAsDelivered">Mark as received</button>
                </mat-card-footer>
            </mat-card>
            <mat-card class="m-2" *ngIf="return.decisionPending || return.wasDecided">
                <mat-card-title>
                    Decision Information
                </mat-card-title>
                <mat-card-content>
                    <div>
                        <div class="labeled-box">
                            <div class="label">
                                Decision
                            </div>
                            <span *ngIf="return.decisionPending">PENDING</span>
                            <span
                                *ngIf="return.status === 'CANCELLED' && return.cancellationReason === 'REJECTED'">REJECTED</span>
                            <span *ngIf="return.status === 'ACCEPTED'">ACCEPTED</span>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-footer class="p-2 pt-0 d-flex justify-content-between align-items-center">
                    <button mat-raised-button color="warn" class="m-2 mt-0" (click)="markAsRejected()"
                        [disabled]="isPerformingAction()" [class.spinner]="isMarkingAsRejected"
                        [disabled]="!return.decisionPending">Reject</button>
                    <button mat-raised-button color="primary" class="m-2 mt-0" (click)="markAsAccepted()"
                        [disabled]="isPerformingAction()" [class.spinner]="isMarkingAsAccepted"
                        [disabled]="!return.decisionPending">Accept</button>
                </mat-card-footer>
            </mat-card>
            <mat-card class="m-2" *ngIf="return.refund">
                <mat-card-title>
                    <div class="d-flex justify-content-between align-items-center">
                        <div>Refund Information</div>
                        <div> 
                            <button class="leaveButton" *ngIf="return.refund.transaction.stripeDashboardLink && return.refund.method === 'ORIGINAL_PAYMENT_METHOD'"
                                (click)="onViewRefundInStripe()" mat-stroked-button>
                                View in Stripe
                                <mat-icon iconPositionEnd>exit_to_app</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-card-title>
                <mat-card-content>
                    <div>
                        <div class="labeled-box">
                            <div class="label">
                                Status
                            </div>
                            <span>{{return.refund.status}}<ng-container *ngIf="return.refund.status=='CANCELLED'"> /
                                {{return.refund.cancellationReason}}</ng-container></span>
                        </div>
                        <div class="labeled-box">
                            <div class="label">
                                Amount
                            </div>
                            <span>{{(return.refund.amountCents/100).toLocaleString("en-US", {style:"currency",
                                currency:"USD"}) }}</span>
                        </div>
                        <div class="labeled-box">
                            <div class="label">
                                Method
                            </div>
                            <span>{{ return.refund.method }}</span>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div style="width: 100%; max-width: 400px">
            <mat-card class="m-2">
                <mat-card-title>
                    Refund Breakdown
                </mat-card-title>
                <mat-card-content>
                    <table style="width: 100%">
                        <tr style="width: 100%">
                            <td style="width: 50%">Purchase Total</td>
                            <td style="width: 50%; text-align: right;">{{
                                (return.order.invoice.totalPriceCents/100).toLocaleString("en-US", {style:"currency",
                                currency:"USD"}) }}</td>
                        </tr>
                        <tr style="width: 100%">
                            <td style="width: 50%">Restocking Fee</td>
                            <td style="width: 50%; text-align: right;">-{{
                                (return.restockingFeeCents/100).toLocaleString("en-US", {style:"currency",
                                currency:"USD"}) }}</td>
                        </tr>
                        <br />
                        <tr style="width: 100%">
                            <td style="width: 50%; font-weight: bold;">Refund Total</td>
                            <td style="width: 50%; text-align: right; font-weight: bold;">
                                {{((return.order.invoice.totalPriceCents -
                                return.restockingFeeCents)/100).toLocaleString("en-US", {style:"currency",
                                currency:"USD"})}}</td>
                        </tr>
                    </table>
                </mat-card-content>
            </mat-card>
            <mat-card class="m-2">
                <mat-card-title>
                    <div class="d-flex justify-content-between align-items-center">
                        <span>Notes</span>
                        <span>
                            <button class="m-1" *ngIf="!isEditingNotes" mat-stroked-button color="primary"
                                (click)="startEditingNotes()">Edit</button>
                            <button class="m-1" *ngIf="isEditingNotes" mat-stroked-button color="warn"
                                [disabled]="isPerformingAction()" (click)="cancelEditingNotes()">Cancel</button>
                            <button class="m-1" *ngIf="isEditingNotes" mat-stroked-button color="primary"
                                [disabled]="isPerformingAction() || !internalNotes.dirty"
                                [class.spinner]="isSavingNotes" (click)="saveNotesEdits()">Save</button>
                        </span>
                    </div>
                </mat-card-title>
                <mat-card-content style="max-height: 340px; overflow-y: scroll">
                    <ng-container *ngIf="!isEditingNotes"><span style="white-space:pre-wrap;">{{ return.internalNotes
                            }}</span></ng-container>
                    <mat-form-field appearance="outline" class="w-100" *ngIf="isEditingNotes">
                        <textarea matInput [formControl]="internalNotes" [maxLength]="1024"
                            style="min-height: 160px;"></textarea>
                        <mat-hint align="end">{{internalNotes.value?.length || 0}}/1024</mat-hint>
                    </mat-form-field>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
<ng-template #loading>
    <div class="d-flex flex-column align-items-center m-5">
        <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
    </div>
</ng-template>