import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  constructor(
    private httpClient: HttpClient
  ) { }

  public uploadImage(image: InstanceType<typeof Image> | null): Observable<number> {
    if (!image) {
      return throwError("Can't upload null image");
    }

    var imageParts = image.src.split(',');
    if (imageParts.length != 2) {
      return throwError("Something is wrong with the image");
    }
    var imageFormat: string;
    if (imageParts[0].includes('image/png')) {
      imageFormat = "PNG";
    } else if (imageParts[0].includes('image/jpeg')) {
      imageFormat = "JPEG";
    } else {
      return throwError(`Unsupported Image Format: ${imageParts[0]}`);
    }

    var body = {
        width: image.naturalWidth,
        height: image.naturalHeight,
        data: imageParts[1],
        dataFormat: imageFormat
    }
    return this.httpClient.post<any>(environment.apiUrl + '/image/images', body, {observe: 'response'}).pipe(
      map(response => {
        const locationHeader = response.headers.get('location');
        if (locationHeader == null) {
          throw new Error('Failed to parse location header for created item')
        }
        return parseInt(locationHeader.split('/').slice(-1)[0]);
      })
    );
  }
}
