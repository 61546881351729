<mat-form-field appearance="outline" style="width: 100%">
    <mat-label>{{label}}</mat-label>
    <input type="text" matInput [placeholder]="placeholder" [formControl]="control" [matAutocomplete]="auto" [required]="required" (keydown.enter)="onEnterPressed()">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayModel">
      <mat-option *ngFor="let listing of autocompleteResults" [value]="listing">
        [id: {{ listing.id }}] {{ listing.model.brand.displayName ?? listing.model.brand.name }} {{ listing.model.displayName ?? listing.model.name }} {{ listing.model.referenceNumber }}
      </mat-option>
    </mat-autocomplete>
    <mat-spinner *ngIf="isLoading" matSuffix diameter="20" style="margin-right: 16px;"></mat-spinner>
</mat-form-field>
