import Brand from "./brand.model";

export default class Series {
    public id!: number;
    public name!: string;
    public displayName?: string;
    public brand!: Brand;
    public releaseYear?: number;
    public discontinuationYear?: number;
    public description?: string;
    public clusterName?: string;
}
