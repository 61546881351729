import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BidsService } from 'src/app/_services/bids.service';
import Bid from 'src/app/models/bid.model';

@Component({
  selector: 'app-bids-table',
  templateUrl: './bids-table.component.html',
  styleUrls: ['./bids-table.component.css']
})
export class BidsTableComponent {
  bids: Bid[] = []
  
  displayedColumns: string[] = ['position', 'bidder', 'price', 'created'];

  constructor(
    private bidsService: BidsService,
    @Inject(MAT_DIALOG_DATA) private data: {listingId: number},
  ) {
    this.bidsService.getBids(data.listingId).subscribe({
      next: (bids: Bid[]) => {
        this.bids = bids.sort((first, second) => {
          return second.id - first.id
        });
      },
      error: (error: any) => {
        console.log(error);
      }
    })
  }
}
