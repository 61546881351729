<div class="search-result w100 h100 d-flex">
    <div *ngIf="image">
        <img [src]="image" />
    </div>
    <div>
        <table>
            <tr *ngIf="title"><td class="title">{{ title }}</td></tr>
            <tr *ngIf="subtitle"><td class="subtitle">{{ subtitle }}</td></tr>
        </table>
    </div>
<div>
