import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { switchMap } from 'rxjs/operators';
import Accessory from '../models/accessory.model';
import Model from '../models/model.model';
import Brand from '../models/brand.model';
import Image from 'src/app/models/image.model';

export class Color {
  public constructor(
    public id: number,
    public name: string
  ) { }
}

export class Complication {
  public constructor(
    public id: number,
    public name: string,
    public description: string
  ) { }
}

export class Material {
  public constructor(
    public id: number,
    public name: string
  ) { }
}

export class Gender {
  public constructor(
    public id: number,
    public name: string
  ) { }
}

export class MovementType {
  public constructor(
    public id: number,
    public name: string
  ) { }
}

export class NumeralType {
  public constructor(
    public id: number,
    public name: string
  ) { }
}

export class ClaspType {
  public constructor(
    public id: number,
    public name: string
  ) { }
}

export class BraceletStyle {
  public constructor(
    public id: number,
    public name: string,
    public brand: Brand,
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  constructor(
    private http: HttpClient
  ) {

  }

  public getMaterials(): Observable<Material[]> {
    return this.http.get<Material[]>(environment.apiUrl + "/catalog/materials");
  }

  public addMaterial(name: string, callback?: { success: () => void, error: (error: any) => void }) {
    var body = {
      name: name
    }
    this.http.post(environment.apiUrl + "/catalog/materials", body).subscribe({
      next: body => {
        callback?.success();
      },
      error: error => {
        callback?.error(error);
      }
    })
  }

  public deleteMaterial(material: Material, callback?: { success: () => void, error: (error: any) => void }) {
    this.http.delete(environment.apiUrl + "/catalog/materials/" + material.id).subscribe({
      next: body => {
        callback?.success();
      },
      error: error => {
        callback?.error(error);
      }
    })
  }

  public getComplications(): Observable<Complication[]> {
    return this.http.get<Complication[]>(environment.apiUrl + "/catalog/complications");
  }

  public addComplication(name: string, description: string, callback?: { success: () => void, error: (error: any) => void }) {
    var body = {
      name: name,
      description: description
    }
    this.http.post(environment.apiUrl + "/catalog/complications", body).subscribe({
      next: body => {
        callback?.success();
      },
      error: error => {
        callback?.error(error);
      }
    })
  }

  public deleteComplication(complication: Complication, callback?: { success: () => void, error: (error: any) => void }) {
    this.http.delete(environment.apiUrl + "/catalog/complications/" + complication.id).subscribe({
      next: body => {
        callback?.success();
      },
      error: error => {
        callback?.error(error);
      }
    })
  }

  public getColors(): Observable<Color[]> {
    return this.http.get<Color[]>(environment.apiUrl + "/catalog/colors");
  }

  public addColor(name: string, callback?: { success: () => void, error: (error: any) => void }) {
    var body = {
      name: name
    }
    this.http.post(environment.apiUrl + "/catalog/colors", body).subscribe({
      next: body => {
        callback?.success();
      },
      error: error => {
        callback?.error(error);
      }
    })
  }

  public deleteColor(color: Color, callback?: { success: () => void, error: (error: any) => void }) {
    this.http.delete(environment.apiUrl + "/catalog/colors/" + color.id).subscribe({
      next: body => {
        callback?.success();
      },
      error: error => {
        callback?.error(error);
      }
    })
  }

  public getGenders(): Observable<Gender[]> {
    return this.http.get<Gender[]>(environment.apiUrl + "/catalog/genders");
  }

  public getMovementTypes(): Observable<MovementType[]> {
    return this.http.get<MovementType[]>(environment.apiUrl + "/catalog/movement-types");
  }

  public getNumeralTypes(): Observable<NumeralType[]> {
    return this.http.get<NumeralType[]>(environment.apiUrl + "/catalog/numeral-types");
  }

  public getClaspTypes(): Observable<ClaspType[]> {
    return this.http.get<ClaspType[]>(environment.apiUrl + "/catalog/clasp-types");
  }

  public getBraceletStyles(): Observable<BraceletStyle[]> {
    return this.http.get<BraceletStyle[]>(environment.apiUrl + "/catalog/bracelet-styles");
  }

  public getGeneratedImages(generationType: string[]) : Observable<Map<number, Image>> {
    var path = environment.apiUrl + "/catalog/models/generated-images?";
    for (let i = 0; i < generationType.length ?? 0; i++) {
      if (i != 0) {
        path += "&"
      }
      path = path + "generationType[]=" + generationType[i];
    }
    return this.http.get<any>(path,  {observe: 'response'}).pipe(
      map(response => {
        let mapResponse = new Map(Object.entries(response.body));
        let parsedResponse : Map<number, Image> = new Map();
        for (let modelId of mapResponse.keys()) {
          let image = Object.assign(new Image(), mapResponse.get(modelId));
          parsedResponse.set(Number.parseInt(modelId), image);
        };
        return parsedResponse;
      }
    ));
  }

  public getAccessories(): Observable<Accessory[]> {
      return this.http.get<Accessory[]>(environment.apiUrl + '/catalog/accessories').pipe(
        map(accessories => accessories.map(a => Object.assign(new Accessory(), a)))
      );
  }
}
