import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';
import { OffersService } from 'src/app/_services/offers.service';
import { ToolbarService, ToolbarController, TitlePathElement } from 'src/app/_services/toolbar.service';
import Offer from 'src/app/models/offer.model';
import { pluck } from 'rxjs/operators';
import { CounterOfferDialogComponent } from '../counter-offer-dialog/counter-offer-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { clientNames } from 'src/app/models/clients'

@Component({
  selector: 'app-offers-detail',
  templateUrl: './offers-detail.component.html',
  styleUrls: ['./offers-detail.component.css']
})
export class OffersDetailComponent implements OnInit, OnDestroy, ToolbarController {
  clientNames: object;

  offer!: Offer | null;
  _offerId: number | null = null;
  rationale: string = '';
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Offers", path: ['/marketplace', 'offers'] }]);
  title = new BehaviorSubject<string>("?");

  // is countering
  isPerformingAction = () => {
    return this.isDeclining 
        || this.isAccepting
        || this.isCountering
        || this.isExtendingDeadline
        || this.isCancelling
  }
  isDeclining = false
  isAccepting = false
  isCountering = false
  isExtendingDeadline = false
  isCancelling = false
  
  constructor(
    private toolbarService: ToolbarService,
    private route: ActivatedRoute,
    private offersService: OffersService,
    private router: Router,
    private dialog: MatDialog
    ) {
      this.clientNames = clientNames;
      
      this.route.params.pipe(
        pluck("offerId")
      ).subscribe({
        next: offerId => {
          if (this._offerId == offerId) {
            return;
          }
  
          this._offerId = offerId;
          this.title.next(offerId.toString());
          this.reloadOffer();
        }
      })
    }

    private reloadOffer() {
      this.offer = null;
      this.offersService.getOffer(this._offerId!).subscribe({
        next: (offer: Offer) => {
          this.offer = offer;
        },
        error: (error: any) => {
          console.log(error);
        }
      });
    }
    
    ngOnInit(): void {
      this.toolbarService.setController(this);
    }
    
    ngOnDestroy(): void {
      this.toolbarService.removeController(this);
    }

    accept(): void {
      this.isAccepting = true;
      this.offersService.acceptOffer(this._offerId!).subscribe({
        next: () => {
          window.location.reload()
        },
        error: (error: any) => {
          console.log(error);
          this.isAccepting = false
        }
      });
    }

    decline(): void {
      this.isDeclining = true;
      this.offersService.declineOffer(this._offerId!).subscribe({
        next: () => {
          window.location.reload()
        },
        error: (error: any) => {
          console.log(error);
          this.isDeclining = false
        }
      });
    }

    counter() {
      this.isCountering = true;
      var dialogRef = this.dialog.open(CounterOfferDialogComponent, {
        width: '400px',
        data: {offerId: this._offerId},
      });
  
      dialogRef.afterClosed().subscribe(result => {
        this.isCountering = false;
        if (result.updated) {
          this.reloadOffer()
        }
      });
    }  
    
    extendDeadline() {
      this.isExtendingDeadline = true
      this.offersService.extendOffer(this._offerId!).subscribe({
        next: () => {
          window.location.reload()
        },
        error: (error: any) => {
          console.log(error);
          this.isExtendingDeadline = false
        }
      });
    }

    cancel(keepHold: boolean) {
      this.isCancelling = true
      this.offersService.cancelOffer(this._offerId!, keepHold).subscribe({
        next: () => {
          window.location.reload()
        },
        error: (error: any) => {
          console.log(error);
          this.isCancelling = false
        }
      });
    }

    onViewPaymentInStripe(): void {
      window.open(this.offer?.transaction.stripeDashboardLink, "_blank");
    }
}
  