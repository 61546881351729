import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subscription } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { OrdersService } from 'src/app/_services/orders.service';
import { ToolbarService, ToolbarController } from 'src/app/_services/toolbar.service';
import Order, { allStatuses, orderedStatuses } from 'src/app/models/order.model';
import { PageEvent } from '@angular/material/paginator';
import { CatalogService } from 'src/app/_services/catalog.service';
import SellerProfile from 'src/app/models/seller-profile.model';
import { FormControl } from '@angular/forms';
import BuyerProfile from 'src/app/models/buyer-profile.model';
import { BuyersService } from 'src/app/_services/buyers.service';
import { clientNames } from 'src/app/models/clients'

@Component({
  selector: 'app-orders-home',
  templateUrl: './orders-home.component.html',
  styleUrls: ['./orders-home.component.css']
})
export class OrdersHomeComponent implements OnInit, OnDestroy, ToolbarController  {
  orders: Order[] = [];
  displayedColumns: string[] = ['flagged', 'id', 'status', 'listing', 'buyer', 'created', 'client'];

  buyerFilterControl = new FormControl<BuyerProfile | null>(null)

  statusFilter: string = 'ALL';
  sellerFilter!: number | null;
  buyerFilter!: number | null;
  clientFilter!: string | null;
  flaggedFilter: boolean | null = null;

  title = new BehaviorSubject<string>('Orders');
  page = 0;
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20];
  sortKey!: string | null;
  isLoading = true
  private loadingSubscription: Subscription | null = null
  
  private _destroyed = new BehaviorSubject<boolean>(false);

  @Input() allStatuses: object;
  @Input() orderedStatuses: object;
  clientNames: object;
  
  constructor(
    private toolbarService: ToolbarService,
    private ordersService: OrdersService,
    private catalogService: CatalogService,
    private buyersService: BuyersService,
    private router: Router,
    private activatedRoute: ActivatedRoute
    ) {
      this.sortKey = '-created';
      this.activatedRoute.queryParams.subscribe(params => {
        this.statusFilter = params.status ? params.status : 'ALL';
        this.buyerFilter = params.buyer ? params.buyer : null;
        this.sellerFilter = params.seller ? params.seller : null;
        this.clientFilter = params.client ? params.client : null;
        this.flaggedFilter = params.flagged ? params.flagged : null;
        this.page = params.page ? params.page : 0;

        if (this.buyerFilter && !(this.buyerFilterControl.value instanceof BuyerProfile)) {
          buyersService.getBuyerProfile(this.buyerFilter).subscribe({
            next: (buyer: BuyerProfile) => {
              this.buyerFilterControl.setValue(buyer);
            }
          })
        }
  
        this.fetchOrders(this.page);
      })

      this.allStatuses = allStatuses;
      this.orderedStatuses = orderedStatuses;
      this.clientNames = clientNames;

      this.buyerFilterControl.valueChanges.subscribe({
        next: () => {
          if (this.buyerFilterControl.value instanceof BuyerProfile) {
            this.buyerFilter = this.buyerFilterControl.value.id;
          } else {
            this.buyerFilter = null;
          }
          this.updateUrlParametersIfNeeded();
        }
      })
    }
    
  ngOnInit(): void {
    this.toolbarService.setController(this);
  }
  
  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  pageChangeEvent = (event: PageEvent) => {
    this.page = event.pageIndex;
    this.updateUrlParametersIfNeeded();
  }

  onSort(event: Sort): void {
    if (event.active == 'created' && event.direction == 'asc') {
      this.sortKey = 'created';
    } else if (event.active == 'created' && event.direction == 'desc') {
      this.sortKey = '-created';
    } else {
      this.sortKey = null;
    }
    this.page = 0;
    this.fetchOrders(this.page);
  }

  fetchOrders = (index: number) => {
    this.isLoading = true
    
    var statusForRequest = this.statusFilter === "ALL" ? null : this.statusFilter;

    if (this.loadingSubscription != null) {
      this.loadingSubscription.unsubscribe();
    }
    this.loadingSubscription = this.ordersService.getOrders(this.pageSize * index, statusForRequest, this.buyerFilter, this.sellerFilter, this.clientFilter, this.flaggedFilter, this.sortKey).subscribe({
      next: (response: any) => {
        this.orders = response.data;
        this.length = response.totalCount;
        this.isLoading = false
        this.loadingSubscription = null;
      },
      error: (error: any) => {
        console.log(error);
        this.orders = [];
        this.length = 0;
        this.isLoading = false
        this.loadingSubscription = null;
      }
    });
  };

  onSelectStatus = (event: any) => {
    this.updateUrlParametersIfNeeded();
  }
  
  onChangeSellerFilter(sellerProfile: SellerProfile): void {
    this.sellerFilter = sellerProfile.id;
    this.updateUrlParametersIfNeeded();
  }

  onChangeClientFilter(event: any): void {
    this.updateUrlParametersIfNeeded();
  }

  onChangeFlaggedFilter(event: any): void {
    this.updateUrlParametersIfNeeded();
  }

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      status: this.statusFilter == 'ALL' ? null : this.statusFilter,
      buyer: this.buyerFilter == null ? null : this.buyerFilter,
      seller: this.sellerFilter == null ? null : this.sellerFilter,
      client: this.clientFilter == null ? null : this.clientFilter,
      flagged: this.flaggedFilter == null ? null : this.flaggedFilter,
      page: this.page == 0 ? null : this.page
    }

    this.router.navigate(
      [], 
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }
}
