import { Component, Input, OnInit } from '@angular/core';
import BuyerProfile from 'src/app/models/buyer-profile.model';

@Component({
  selector: 'buyer-chip',
  templateUrl: './buyer-chip.component.html',
  styleUrls: ['./buyer-chip.component.css']
})
export class BuyerChipComponent implements OnInit {
  private _buyer?: BuyerProfile;
  @Input('buyer') set seller(value: BuyerProfile | undefined ) {
    if (value) {
      this._buyer = Object.assign(new BuyerProfile(), value)
    } else {
      this._buyer = value
    }
  }

  get buyer(): BuyerProfile | undefined {
    return this._buyer;
  }

  constructor() { }

  ngOnInit(): void { }

  ngOnDestroy(): void { }
}
