import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { pluck } from 'rxjs/operators';
import Model from 'src/app/models/model.model';
import { AuthorizationService, Permissions, Privilege } from 'src/app/_services/authorization.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { ModelsService } from 'src/app/_services/models.service';

@Component({
  selector: 'app-models-detail',
  templateUrl: './models-detail.component.html',
  styleUrls: ['./models-detail.component.css']
})
export class ModelsDetailComponent implements OnInit, OnDestroy, ToolbarController {
  model: Model | null = null;
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Models", path: ['/catalog', 'models'] }]);
  title = new BehaviorSubject<string>("?");

  canEditModel: boolean;

  private _modelId: number | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private toolbarService: ToolbarService,
    private modelsService: ModelsService,
    private authorizationService: AuthorizationService,
  ) {    
    this.route.params.pipe(
      pluck("modelId")
    ).subscribe({
      next: modelId => {
        if (this._modelId == modelId) {
          return;
        }

        this._modelId = modelId;
        this.model = null;
        this.loadModel();
      }
    })

    this.canEditModel = authorizationService.hasPermission(Permissions.CATALOG_ALL, Privilege.WRITE);
  }

  private loadModel() {
    this.modelsService.getModel(this._modelId!).subscribe({
      next: (model: Model | null) => {
        this.model = model;
        if (model) {
          this.title.next(model.displayName || model.name);
        } else {
          this.router.navigate(['/catalog', 'models'])
          let snackBarRef = this.snackBar.open(`Model (${this._modelId}) does not exist.`, '', {
            duration: 3000
          });
        }
      }
    });
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  onEditButtonPressed(): void {
    this.router.navigate(['/catalog', 'models', this._modelId, 'edit'])
  }

  onDuplicate(): void {
    this.router.navigate(['/catalog', 'models', 'new'], { queryParams: { duplicateFrom: this.model?.id }});
  }

  extractCategoryNames(model: Model): String {
    if (model.categories.length > 0) {
      return model.categories.map(c => c.name).join(', ');
    } else {
      return '(none)';
    }
  }
}
