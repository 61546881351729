<div class="d-flex flex-column h-100">
  <mat-card class="m-2">
    <mat-card-content class="d-flex flex-md-row flex-column">
        <mat-form-field appearance="outline" class="flex-fill mx-1">
            <mat-label>Status</mat-label>
            <mat-select [(ngModel)]='statusFilter' name='Status' (selectionChange)="onSelectStatus($event)">
                <mat-option>-</mat-option>
                <mat-option *ngFor="let status of verificationReportStatuses" [value]="status">
                    {{verificationReportStatusDescriptions[status]}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-fill mx-1">
          <mat-label>Priority</mat-label>
          <mat-select [(ngModel)]='priorityFilter' name='Priority' (selectionChange)="onSelectPriority($event)">
              <mat-option>-</mat-option>
              <mat-option *ngFor="let priority of verificationReportPriorities" [value]="priority">
                  {{verificationReportPriorityDescriptions[priority]}}
              </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-fill mx-1">
          <mat-label>Result</mat-label>
          <mat-select [(ngModel)]='resultFilter' name='Result' (selectionChange)="onSelectResult($event)">
              <mat-option>-</mat-option>
              <mat-option *ngFor="let result of verificationReportResults" [value]="result">
                  {{verificationReportResultDescriptions[result]}}
              </mat-option>
          </mat-select>
      </mat-form-field>
    </mat-card-content>
  </mat-card>
  <mat-card class="mx-2 mb-2 p-0 flex-fill d-flex flex-column overflow-scroll">
    <div class="flex-fill position-relative overflow-scroll">
      <div *ngIf="isLoading" class="w-100 h-100 position-absolute d-flex align-items-center justify-content-center" style="z-index: 1;">
        <div *ngIf="verificationReports.length > 0" class="w-100 h-100 position-absolute" style="background-color: rgba(0, 0, 0, 0.1);"></div>
        <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
      </div>
      <div class="h-100 w-100 overflow-scroll">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="onSort()" class="w-100">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
            <td mat-cell *matCellDef="let verificationReport"> {{verificationReport.id}} </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status"> Status </th>
            <td mat-cell *matCellDef="let verificationReport"> 
              <div class="badge-narrow" [ngClass]="{
                'badge-narrow': verificationReport.status === 'CANCELLED', 
                'badge-narrow-success': verificationReport.status === 'COMPLETE', 
                'badge-narrow-orange': verificationReport.status === 'PENDING'
                }">
                              {{ verificationReport.status }}
                          </div>
                      </td>
          </ng-container>
          <ng-container matColumnDef="result">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by result"> Result </th>
            <td mat-cell *matCellDef="let verificationReport"> 
              <div *ngIf="verificationReport.result" class="badge-narrow" [ngClass]="{
                'badge-narrow-success': verificationReport.result === 'PASSED', 
                'badge-narrow-error': verificationReport.result === 'FAILED'
                }">
                              {{ verificationReport.result }}
                          </div>
                      </td>
          </ng-container>
          <ng-container matColumnDef="priority">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by priority"> Priority </th>
            <td mat-cell *matCellDef="let verificationReport"> 
              {{ verificationReport.priority }}
          </ng-container>
          <ng-container matColumnDef="order">
            <th mat-header-cell *matHeaderCellDef> Order </th>
            <td mat-cell *matCellDef="let verificationReport"><mat-chip-list><order-chip [order]="verificationReport.order"></order-chip></mat-chip-list></td>
        </ng-container>
          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by created" style="min-width: 150px;"> Created </th>
            <td mat-cell *matCellDef="let verificationReport"> {{verificationReport.created | date:'medium' }} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let verificationReport; columns: displayedColumns;" [routerLink]="['/verification/verification-reports/', verificationReport.id]">
        </table>
      </div>
    </div>
    <mat-paginator [length]="length"
                [pageIndex]="page"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                aria-label="Select page">
    </mat-paginator>
  </mat-card>
</div>
