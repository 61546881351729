import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import Highlight from 'src/app/models/highlight.model';
import Model from 'src/app/models/model.model';
import { HighlightsService } from 'src/app/_services/highlights.service';
import { ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import { ModelsService } from 'src/app/_services/models.service';

@Component({
  selector: 'app-browse-highlights',
  templateUrl: './browse-highlights.component.html',
  styleUrls: ['./browse-highlights.component.css']
})
export class BrowseHighlightsComponent implements OnInit, OnDestroy, ToolbarController {
  title = new BehaviorSubject<string>("Highlights");

  modelFilter: number | null = null;
  modelFilterText!: string | null;
  
  displayedColumns: string[] = ['id', 'model', 'title', 'priority', 'created'];
  page = 0;
  length = 1000;
  pageSize = 20;
  pageSizeOptions: number[] = [20];
  sortKey: string | null = '-created';

  highlights: Highlight[] = [];
  isLoading = true

  // Model filter
  searchResults: BehaviorSubject<Model[]> = new BehaviorSubject<Model[]>([]);
  private mouseHoveringOverResults: boolean = false
  private searchBarFocused: boolean = false
  hideSearchResults: boolean = false
  
  private _destroyed = new BehaviorSubject<boolean>(false);
  
  constructor(
    private toolbarService: ToolbarService,
    private highlightsService: HighlightsService,
    private modelsService: ModelsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { 
    this.activatedRoute.queryParams.subscribe(params => {
      this.modelFilter = params.model ? params.model : null
      this.page = params.page ? params.page : 0;

      if (this.modelFilter != null && this.modelFilterText == null) {
        modelsService.getModel(this.modelFilter).subscribe(model => {
          if (model == null) {
            return;
          }

          this.modelFilterText = `${model.brand.displayName ?? model.brand.name} ${model.displayName ?? model.name}`;
        })
      }

      this.fetchHighlights(this.page);
    })
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  onSearchTextChanged(query: string) {
    if (query.length < 2) {
      this.searchResults.next([]);
      return;
    }

    this.modelsService.searchModels(query).subscribe({
      next: models => {
        this.searchResults.next(models);
      },
      error: error => {
        console.log(error)
      }
    })
  }

  onSearchResultClicked(result: Model) {
    this.modelFilter = result.id;
    this.modelFilterText = `${result.brand.displayName ?? result.brand.name} ${result.displayName ?? result.name}`;
    this.hideSearchResults = true;
    this.searchResults.next([]);
    this.page = 0;
    this.updateUrlParametersIfNeeded();
  }

  onFocus() {
    this.searchBarFocused = true;
    this.calculateSearchResultVisibilityState();
  }

  onBlur() {
    this.searchBarFocused = false;
    this.calculateSearchResultVisibilityState();
  }

  mouseEnterSearchResults() {
    this.mouseHoveringOverResults = true;
    this.calculateSearchResultVisibilityState();
  }

  mouseExitSearchResults() {
    this.mouseHoveringOverResults = false;
    this.calculateSearchResultVisibilityState();
  }

  private calculateSearchResultVisibilityState() {
    this.hideSearchResults = (!this.mouseHoveringOverResults && !this.searchBarFocused);
  }

  clearModelSelection = () => {
    this.modelFilter = null;
    this.modelFilterText = null;
    this.updateUrlParametersIfNeeded();
  }

  pageChangeEvent = (event: PageEvent) => {
    this.page = event.pageIndex;
    this.updateUrlParametersIfNeeded();
  }

  onSort(event: Sort): void {
    if (event.active == 'created' && event.direction == 'asc') {
      this.sortKey = 'created';
    } else if (event.active == 'created' && event.direction == 'desc') {
      this.sortKey = '-created';
    } else if (event.active == 'priority' && event.direction == 'asc') {
      this.sortKey = '-priority';
    } else if (event.active == 'priority' && event.direction == 'desc') {
      this.sortKey = 'priority';
    } else {
      this.sortKey = null;
    }
    this.page = 0;
    this.fetchHighlights(this.page);
  }

  fetchHighlights = (index: number) => {
    this.isLoading = true

    this.highlightsService.getHighlights(this.pageSize, this.pageSize * index, this.modelFilter, this.sortKey).subscribe({
      next: (highlights: Highlight[]) => {
        this.highlights = highlights;
        this.isLoading = false
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  };


  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      model: this.modelFilter,
      page: this.page == 0 ? null : this.page
    }

    this.router.navigate(
      [], 
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }
}
