import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

export interface TitlePathElement {
  title: string;
  path: string[];
}

export interface ToolbarController {
  titlePath?: BehaviorSubject<TitlePathElement[]>;
  title: BehaviorSubject<string>;
}

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {
  public titlePath: BehaviorSubject<TitlePathElement[]>;
  public title: BehaviorSubject<string>;

  private controller: ToolbarController | null;
  private subscriptions: {
    titlePathSubscription?: Subscription,
    titleSubscription: Subscription | null
  };

  constructor() {
    this.titlePath = new BehaviorSubject<TitlePathElement[]>([]);
    this.title = new BehaviorSubject<string>("");
    this.controller = null;
    this.subscriptions = this.emptySubscriptions();
  }

  public setController(controller: ToolbarController | null) {
    this.teardownController();
    if (controller != null) {
      this.setupController(controller);
    } else {
      this.setDefaultValues();
    }
  }

  public removeController(controller: ToolbarController) {
    if (this.controller === controller) {
      this.setController(null);
    }
  }

  private setupController(controller: ToolbarController) {
    this.controller = controller;
    this.subscriptions.titleSubscription = this.controller.title.subscribe(title => {
      this.title.next(title);
    })
    this.subscriptions.titlePathSubscription = this.controller.titlePath?.subscribe(path => {
      this.titlePath.next(path);
    })
  }

  private teardownController() {
    if (this.controller == null) {
      return;
    }

    this.subscriptions.titleSubscription?.unsubscribe();
    this.subscriptions.titlePathSubscription?.unsubscribe();
    this.subscriptions = this.emptySubscriptions();

    this.controller = null;
  }

  private emptySubscriptions() {
    return {
      titleSubscription: null,
      showSearchSubscription: null
    };
  }

  private setDefaultValues() {
    this.titlePath.next([]);
    this.title.next("");
  }
}
