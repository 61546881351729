import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import SellerProfile from 'src/app/models/seller-profile.model';
import { SellersService } from 'src/app/_services/sellers.service';

@Component({
  selector: 'seller-select',
  templateUrl: './seller-select.component.html',
})
export class SellerSelectComponent implements OnInit {
  @Output('onSelect') select = new EventEmitter<SellerProfile>();
  @Input('value') seller?: SellerProfile;
  @Input('valueId') sellerId?: number;
  @Input('required') required: boolean = false;
  @Input('disabled') disabled: boolean = false;

  selectedSeller = new FormControl<SellerProfile | null>(null);
  private _lastSelectedSellerId!: number;
  sellers: SellerProfile[] = []
  filteredSellers!: Observable<SellerProfile[]>;

  constructor(private sellersService: SellersService) {
    this.sellersService.getSellerProfiles(null, null).subscribe({
      next: (sellers: SellerProfile[]) => {
        this.sellers = sellers;
        if (!!this.sellerId) {
          sellers.forEach(seller => {
            if (seller.id == this.sellerId) {
              this.selectedSeller.setValue(seller);
            }
          })
        }
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  ngOnInit(): void {
    this.filteredSellers = this.selectedSeller.valueChanges.pipe(
      startWith(''),
      map(value => this.filterOrClearSearchResults(value))
    );
    if (this.seller) {
      this.selectedSeller.setValue(this.seller);
    }
  }

  onSelectSeller() {
    if (this.selectedSeller.value == undefined || 
      this.selectedSeller.value?.id == this._lastSelectedSellerId) {
      return
    }
    this._lastSelectedSellerId = this.selectedSeller.value?.id;
    this.select.emit(this.selectedSeller.value);
  }

  displaySeller = (seller: SellerProfile) => {
    if (!seller || !seller.user) {
      return "";
    }
    return seller.displayName;
  };

  private filterOrClearSearchResults(value: any): SellerProfile[] {
    var filterValue: string;
    if (value == "") {
      this.onSelectSeller();
      return this.sellers;
    } else if (value instanceof SellerProfile) {
      filterValue = value.displayName.toLowerCase();
    } else {
      filterValue = value.toLowerCase();
    }
    
    return this.sellers.filter(seller => seller.displayName.toLowerCase().includes(filterValue));
  }
}
