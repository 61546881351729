import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import BezelType from '../models/bezel-type.model';

@Injectable({
  providedIn: 'root'
})
export class BezelTypesService {
  constructor(private http: HttpClient) { }

  public getBezelTypes(): Observable<BezelType[]> {
    var path = `/catalog/bezel-types`;
    return this.http.get<BezelType[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
      map(response => {
        return response.body!.map(o => this.toBezelType(o))
      })
    );
  }

  public getBezelType(bezelTypeId: number): Observable<BezelType> {
    return this.http.get<BezelType>(environment.apiUrl + `/catalog/bezel-types/${bezelTypeId}`).pipe(
      map(fetchedObject => this.toBezelType(fetchedObject))
    );
  }

  private toBezelType(fetchedObject: any): BezelType {
    return Object.assign(new BezelType(), fetchedObject);
  }
}
