import { Component, OnDestroy, OnInit, ViewChild, NgZone} from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import {MatTableDataSource} from '@angular/material/table';
import PurchaseRequest, { PURCHASE_REQUEST_STATUS, PURCHASE_REQUEST_STATUS_ENG, UNWORN_RESTRICTION } from 'src/app/models/purchase-request.model';
import PurchaseRequestOffer, { PURCHASE_REQUEST_OFFER_STATUS, PURCHASE_REQUEST_OFFER_STATUS_ENG, ON_REJECT_ACTION } from 'src/app/models/purchase-request-offer.model';
import { PurchaseRequestService } from 'src/app/_services/purchase-requests.service';


@Component({
  selector: 'app-list-detail',
  templateUrl: './purchase-requests-detail.component.html',
  styleUrls: ['./purchase-requests-detail.component.scss'],
})
export class PurchaseRequestsDetailComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Purchase Request", path: ['/marketplace', 'purchase-requests'] }]);
  title = new BehaviorSubject<string>("?");

  offersDataSource: MatTableDataSource<PurchaseRequestOffer>;
  displayedColumns: string[] = ['id', 'status', 'offerPrice', 'strikethroughPrice', 'listing', 'expiration', 'created'];


  isLoading = true;
  loadMessage = "";
  errorMessage = "";

  purchaseRequest? : PurchaseRequest;
  purchaseRequestOffers : PurchaseRequestOffer[] = [];

  private _prId!:number;
  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private purchaseRequestService: PurchaseRequestService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private zone: NgZone,
  ) {
    this.route.params.pipe(
      map(x => x?.purchaseRequestId)
    ).subscribe({
      next: prId => {
        if (this._prId == prId) {
          return;
        }

        this._prId = prId;
        this.title.next(prId.toString());
        this.reload();
      }
    });
    
    this.offersDataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  hasRestrictions(): boolean {
    return this.purchaseRequest?.unwornRestriction != null || this.purchaseRequest?.yearRestriction != null;
  }

  private reload() {
    this.isLoading = true;

    this.purchaseRequestService.getPurchaseRequest(this._prId, true, true).subscribe({
      next: pr => {
        this.isLoading = false;
        this.purchaseRequest = pr;
      },
      error: error => {
        console.log(error);
      }
    });
    this.purchaseRequestService.getPurchaseRequestOffers(null, [this._prId], false).subscribe({
      next: (response: any) => {
        this.purchaseRequestOffers = response.data;
        this.offersDataSource.data = this.purchaseRequestOffers;
      },
      error: error => {
        console.log(error);            
      }
    });
  }
}