<div class="container" *ngIf="authenticationReport else loading">
  <div class="w100 d-flex flex-row justify-content-center">
    <div style="width: 100%; max-width: 800px;">
      <mat-card class="m-2">
        <mat-card-title>
          <span class="d-flex justify-content-between align-items-center">
            <span class="d-flex justify-content-between align-items-center" style="gap: 10px">
              <mat-icon *ngIf="authenticationReport.blocked" color="warn" style="height: fit-content; width: fit-content; font-size: 12pt;">block</mat-icon>
              <span>General Information</span>
            </span>
            <span>
              <button *ngIf="verificationReport" mat-stroked-button [routerLink]="['/verification/verification-reports', verificationReport.id]">View verification report</button>
            </span>
          </span>
        </mat-card-title>
        <mat-card-content>
          <div>
            <div class="labeled-box">
              <div class="label">
                Status
              </div>
              <span>{{authenticationReport.status}}<ng-container *ngIf="authenticationReport.status=='CANCELLED'"> / {{authenticationReport.cancellationReason}}</ng-container></span>
            </div>
            <div class="labeled-box" style="cursor: pointer" [matMenuTriggerFor]="prioritySelectMenu">
              <div class="label">
                Priority
              </div>
              <span>{{authenticationReport.priority ? authenticationReportPriorityDescriptions[authenticationReport.priority] : 'None'}}</span>
            </div>
            <mat-menu #prioritySelectMenu="matMenu">
              <button mat-menu-item (click)="onSelectNewPriority(null)">None</button>
              <button mat-menu-item *ngFor="let priority of authenticationReportPriorities" (click)="onSelectNewPriority(priority)">{{ authenticationReportPriorityDescriptions[priority] }}</button>
            </mat-menu>
            <div class="labeled-box">
              <div class="label">
                Created
              </div>
              <span>{{authenticationReport.created | date:'medium'}}</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Updated
              </div>
              <span>{{authenticationReport.updated | date:'medium'}}</span>
            </div>
        </div>
        </mat-card-content>
        <mat-card-footer class="p-2 pt-0">
          <button mat-raised-button color="warn" class="ms-2 mb-2" [disabled]="isPerformingAction() || authenticationReport.status != 'PENDING'" [class.spinner]="isCancelling" (click)="markCancelled()">Cancel</button>
        </mat-card-footer>
      </mat-card>
      <mat-card class="m-2">
        <mat-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <span>
              Order/Listing/Return/Backfill Number
            </span>
            <span>
            </span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="d-flex flex-row" style="gap: 10px">
            <mat-form-field>
              <mat-select name='referenceType' [formControl]='referenceTypeControl' placeholder="Choose" (blur)="tryLoadingReferences()">
                  <mat-option>-</mat-option>
                  <mat-option [value]="'ORDER'">Order</mat-option>
                  <mat-option [value]="'LISTING'">Listing</mat-option>
                  <mat-option [value]="'RETURN'">Return</mat-option>
                  <mat-option [value]="'BACKFILL'">Backfill</mat-option>
                  <mat-option [value]="'OTHER'">Other</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="flex-fill">
              <mat-label>{{ (referenceTypeControl.value != 'OTHER' ? referenceTypeControl.value : 'Reference') | titlecase}} ID</mat-label>
              <input type="text" matInput [formControl]="referenceIdControl" (blur)="tryLoadingReferences()" (keydown.enter)="tryLoadingReferences()">
            </mat-form-field>          
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="m-2">
        <mat-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <span>
              Model
            </span>
            <span>
            </span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="d-flex flex-row" style="gap: 10px">
            <mat-form-field>
              <mat-label>Brand</mat-label>
              <mat-select name='brand' [formControl]='brandControl' placeholder="Choose">
                  <mat-option>-</mat-option>
                  <mat-option *ngFor="let brand of allBrands" [value]="brand.id">
                    {{brand.displayName}}
                  </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="flex-fill">
              <mat-label>Reference number</mat-label>
              <input type="text" matInput [formControl]="referenceNumberControl">
            </mat-form-field>          
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="m-2">
        <mat-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <span>
              Reason for report
            </span>
            <span>
            </span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <mat-form-field>
            <mat-select name='authenticationReason' [formControl]='authenticationReasonControl' placeholder="Choose">
                <mat-option>-</mat-option>
                <mat-option [value]="'ORDER'">Order</mat-option>
                <mat-option [value]="'AUCTION'">Auction</mat-option>
                <mat-option [value]="'RETURN'">Return</mat-option>
                <mat-option [value]="'CONSIGNMENT'">Consignment</mat-option>
                <mat-option [value]="'TRADE_IN'">Trade-In</mat-option>
                <mat-option [value]="'BACKFILL'">Backfill (Order Replacement)</mat-option>
                <mat-option [value]="'INTERNAL_PURCHASE'">Bezel Inventory/Stock</mat-option>
                <mat-option [value]="'LOST_PACKAGE_RECOVERY'">Lost Package Recovery</mat-option>
                <mat-option [value]="'SERVICE'">Repair/Service</mat-option>
                <mat-option [value]="'RTS'">RTS (Return to Sender)</mat-option>
                <mat-option [value]="'OTHER'">Other</mat-option>
            </mat-select>
          </mat-form-field>  
        </mat-card-content>
      </mat-card>
      <mat-card class="m-2">
        <mat-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <span>
              Result
            </span>
            <span>
            </span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <mat-form-field class="flex-fill mx-1">
            <mat-select name='result' [formControl]='resultControl' placeholder="Choose">
                <mat-option>-</mat-option>
                <mat-option *ngFor="let result of authenticationReportResults" [value]="result">
                    {{authenticationReportResultDescriptions[result]}}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
      <mat-card class="m-2">
        <mat-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <span>
              Finalize
            </span>
            <span>
            </span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div>
            <div class="labeled-box">
              <div class="label">
                Result
              </div>
              <span>{{authenticationReport.result ?? '?'}}</span>
            </div>
            <div class="labeled-box" *ngIf="authenticationReport.status === 'COMPLETE' && authenticationReport.completedAt">
              <div class="label">
                Finalized at
              </div>
              <span>{{ authenticationReport.completedAt | date : 'medium' }}</span>
            </div>
            <div class="labeled-box" *ngIf="authenticationReport.status === 'COMPLETE' && authenticationReport.completedBy">
              <div class="label">
                Finalized by
              </div>
              <span>{{ authenticationReport.completedBy?.displayName ?? "?" }}</span>
            </div>
        </div>
        </mat-card-content>
        <mat-card-footer class="p-3 pt-0 d-flex justify-content-between align-items-center">
            <span></span>
            <span class="d-flex flex-row" style="gap: 8px">
              <button mat-raised-button color="primary" [disabled]="isPerformingAction() || !formGroup.dirty" [class.spinner]="isSaving" (click)="saveDetails()">Save</button>
              <button mat-raised-button color="primary" (click)="markComplete()" [disabled]="isPerformingAction() || !authenticationReport.result || authenticationReport.status != 'PENDING'" [class.spinner]="isFinalizing">Finalize</button>
            </span>
          </mat-card-footer>
      </mat-card>
    </div>
    <div style="width: 100%; max-width: 400px">
      <mat-card class="m-2">
        <mat-card-title>
          Links
        </mat-card-title>
        <mat-card-content>
            <div class="labeled-box" *ngIf="referencedListing" style="width: 100%">
                <div class="label">
                  Listing
                </div>
                <span><mat-chip-list><listing-chip [listing]="referencedListing"></listing-chip></mat-chip-list></span>
            </div>
            <div class="labeled-box" *ngIf="referencedOrder" style="width: 100%">
                <div class="label">
                  Order
                </div>
                <span><mat-chip-list><order-chip [order]="referencedOrder"></order-chip></mat-chip-list></span>
            </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="d-flex flex-column align-items-center m-5">
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
  </div>
</ng-template>
