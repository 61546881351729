import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, pluck } from 'rxjs';
import { ProxyBidsService } from 'src/app/_services/proxy-bids.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import ProxyBid from 'src/app/models/proxy-bid.model';

@Component({
  selector: 'app-edit-max-bid',
  templateUrl: './edit-max-bid.component.html',
  styleUrls: ['./edit-max-bid.component.css']
})
export class EditMaxBidComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([
    { title: "Max Bids", path: ['/marketplace', 'max-bids'] },
    { title: "?", path: ['/marketplace', 'max-bids'] }
  ]);
  title = new BehaviorSubject<string>("Edit");
  
  proxyBid!: ProxyBid | null;
  _proxyBidId: number | null = null;

  price = new FormControl<number | null>(null, Validators.required);

  formGroup = new FormGroup({
    price: this.price,
  });

  submitting = false

  constructor(
    private toolbarService: ToolbarService,
    private route: ActivatedRoute,
    private router: Router,
    private proxyBidsService: ProxyBidsService
  ) { 
    this.route.params.pipe(
      pluck("bidId")
    ).subscribe({
      next: proxyBidId => {
        if (this._proxyBidId == proxyBidId) {
          return;
        }

        this._proxyBidId = proxyBidId;
        this.titlePath.next([
              { title: "Max Bids", path: ['/marketplace', 'max-bids'] },
              { title: proxyBidId.toString(), path: ['/marketplace', 'max-bids', proxyBidId.toString()] }
        ]);
        this.reloadProxyBid();
      }
    })

  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }
  
  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
  }

  private reloadProxyBid() {
    this.proxyBid = null;
    this.proxyBidsService.getProxyBid(this._proxyBidId!).subscribe({
      next: (proxyBid: ProxyBid) => {
        this.proxyBid = proxyBid;

        this.price.setValue(this.proxyBid.maxPriceCents / 100.0);
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  onSubmit() {
    this.submitting = true;
    var params: any = {}
    if (this.price.dirty && this.price.value) {
      params.maxPriceCents = this.price.value * 100;
    }
    this.proxyBidsService.updateProxyBid(this._proxyBidId!, params).subscribe({
      next: () => {
        this.router.navigate(['/marketplace/max-bids', this._proxyBidId!]);
      },
      error: error => {
        console.log(error);
        this.submitting = false;
      }
    });
  }
}
