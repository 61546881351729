import { Component, OnDestroy, OnInit, ViewChild, NgZone} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import {MatTableDataSource} from '@angular/material/table';
import SellerProfile from 'src/app/models/seller-profile.model';
import Listing from 'src/app/models/listing.model';
import PurchaseRequest, { PURCHASE_REQUEST_STATUS, PURCHASE_REQUEST_STATUS_ENG, UNWORN_RESTRICTION } from 'src/app/models/purchase-request.model';
import PurchaseRequestOffer, { PURCHASE_REQUEST_OFFER_STATUS, PURCHASE_REQUEST_OFFER_STATUS_ENG, ON_REJECT_ACTION } from 'src/app/models/purchase-request-offer.model';
import { PurchaseRequestService } from 'src/app/_services/purchase-requests.service';
import { ListingsService } from 'src/app/_services/listings.service';

@Component({
  selector: 'app-list-detail',
  templateUrl: './purchase-requests-detail.component.html',
  styleUrls: ['./purchase-requests-detail.component.scss'],
})
export class PurchaseRequestsDetailComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Purchase Request", path: ['/marketplace', 'purchase-requests'] }]);
  title = new BehaviorSubject<string>("?");

  offersDataSource: MatTableDataSource<PurchaseRequestOffer>;
  displayedColumns: string[] = ['id', 'status', 'offerPrice', 'strikethroughPrice', 'listing', 'expiration', 'created', 'edit'];

  isLoading = true;
  isEditingPR = false;
  isEditingPRO = -1;
  isUpdating = false;
  loadMessage = "";
  errorMessage = "";

  purchaseRequest? : PurchaseRequest;
  purchaseRequestOffers : PurchaseRequestOffer[] = [];
  availableListings! : Listing[] | null;

  priceFormControl = new FormControl();
  offerPriceFormControl = new FormControl();
  expirationFormControl = new FormControl(new Date());
  offerExpirationFormControl = new FormControl(new Date());
  newOfferPriceFormControl = new FormControl();

  newOfferListingFilter!: number | null;
  sellerFilter!: number | null;

  private _prId!:number;
  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private purchaseRequestService: PurchaseRequestService,
    private listingsService: ListingsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private zone: NgZone,
  ) {
    this.route.params.pipe(
      map(x => x?.purchaseRequestId)
    ).subscribe({
      next: prId => {
        if (this._prId == prId) {
          return;
        }

        this._prId = prId;
        this.title.next(prId.toString());
        this.reload();
      }
    });

    this.sellerFilter = null;
    this.newOfferListingFilter = null;

    this.offersDataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  hasRestrictions(): boolean {
    return this.purchaseRequest?.unwornRestriction != null || this.purchaseRequest?.yearRestriction != null;
  }

  doEditPR(): void {
    if (this.isLoading || this.isEditingPRO != -1) {
      return;
    }
    this.priceFormControl.setValue(this.purchaseRequest?.requestedPriceCents ?? 0);
    this.expirationFormControl.setValue(this.purchaseRequest?.expiration ?? new Date());
    this.isEditingPR = true;
  }

  doEditPRO(proID : number): void {
    if (this.isLoading || this.isEditingPR) {
      return;
    }
    this.isEditingPRO = proID;
    let selectedPRO  = this.purchaseRequestOffers.find(pro => pro.id == proID);
    this.offerPriceFormControl.setValue(selectedPRO?.offerPriceCents ?? 0);
    this.offerExpirationFormControl.setValue(selectedPRO?.expiration ?? new Date());
  }

  finishEditPR(): void {
    if (this.purchaseRequest == undefined) {
      return;
    }

    this.isUpdating = true;
    let vars = {
      "requestedPriceCents": this.priceFormControl.value,
      "expiration": this.expirationFormControl.value
    }
    this.purchaseRequestService.updatePurchaseRequest(this.purchaseRequest.id, vars, true).subscribe({
      next: pr => {
        this.isUpdating = false;
        this.purchaseRequest = pr;
      }, error: error => {
        console.log(error);
        this.isUpdating = false;
      }
    });
    this.isEditingPR = false;
  }

  finishEditPRO(): void {
    this.isUpdating = true;
    let vars = {
      "offerPriceCents": this.offerPriceFormControl.value,
      "expiration": this.offerExpirationFormControl.value
    }
    this.purchaseRequestService.updatePurchaseRequestOffer(this.isEditingPRO, vars, true).subscribe({
      next: updatedPro => {
        this.isUpdating = false;
        for (let i = 0; i < this.purchaseRequestOffers.length; i++ ) {
          if (this.purchaseRequestOffers[i].id == updatedPro.id) {
            this.purchaseRequestOffers[i] = updatedPro;
            break;
          }
        }
        this.offersDataSource.data = this.purchaseRequestOffers;

      }, error: error => {
        console.log(error);
        this.isUpdating = false;
      }
    });
    this.isEditingPRO = -1;
  }

  finishCreatePRO() : void {
    if (this.purchaseRequest == null || this.sellerFilter == null || this.newOfferListingFilter == null || this.newOfferPriceFormControl.value == null) {
      return;
    }

    this.isUpdating = true;
    let vars = {
      "purchaseRequest": this.purchaseRequest.id,
      "listing": this.newOfferListingFilter,
      "offerPriceCents" : this.newOfferPriceFormControl.value
    };
    this.purchaseRequestService.createPurchaseRequestOffer(vars).subscribe({
      next: pro => {
        this.isUpdating = false;
        this.purchaseRequestOffers.push(pro);
        this.offersDataSource.data = this.purchaseRequestOffers;

      }, error: error => {
        console.log(error);
        this.isUpdating = false;
      }
    });
    this.availableListings = null;
    this.newOfferListingFilter = null;
    this.newOfferPriceFormControl.setValue("");
  }

  cancelEdit(): void {
    this.isEditingPR = false;
    this.isEditingPRO = -1;
  }

  closePR(): void {
    this.isUpdating = true;
    this.purchaseRequestService.closePurchaseRequest(this._prId).subscribe({
     next: () => {
        this.router.navigate(["../"], {relativeTo: this.route});
      },
      error: (error) => {
        console.log(error);
        this.isUpdating = false;
      }
    });
  }

  closePRO(proId: number): void {
    this.isUpdating = true;
    this.purchaseRequestService.closePurchaseRequestOffer(proId).subscribe({
      next: () => {
         this.isUpdating = false;
         this.reload();
       },
       error: (error) => {
         console.log(error);
         this.isUpdating = false;
       }
    });
  }

  onSelectSellerFilter = (seller: SellerProfile) => {
    if (this.purchaseRequest == undefined) {
      return;
    }

    this.sellerFilter = seller.id;
    this.listingsService.getListingsForPurchaseRequest(seller.id, this.purchaseRequest.id, 0).subscribe({
      next: listings => {
        this.availableListings = listings.data;
      }, error: error => {
        console.log(error);
      }
    });
  };

  getNewOfferListingsMessage() : string | null {
    if (this.sellerFilter == null) {
      return "Choose Seller First";
    }
    if (this.availableListings == null) {
      return "Loading Available Listings";
    }
    if (this.availableListings.length == 0) {
      return "No Valid Listings Available";
    }

    return null;
  }

  canAddOffers() : boolean {
    return !this.isEditingPR && this.isEditingPRO == -1 && !this.isUpdating && this.purchaseRequest?.status != "CLOSED";
  }

  listingDetails() : void {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(["/marketplace/listings/" + this.newOfferListingFilter])
      );
      window.open(url, '_blank');
  }

  private reload() {
    this.isLoading = true;
    this.isUpdating = false;
    this.isEditingPR = false;

    this.purchaseRequestService.getPurchaseRequest(this._prId, true, true).subscribe({
      next: pr => {
        this.isLoading = false;
        this.purchaseRequest = pr;
      },
      error: error => {
        console.log(error);
      }
    });
    this.purchaseRequestService.getPurchaseRequestOffers(null, [this._prId], false).subscribe({
      next: (response: any) => {
        this.purchaseRequestOffers = response.data;
        this.offersDataSource.data = this.purchaseRequestOffers;
      },
      error: error => {
        console.log(error);
      }
    });
  }
}
