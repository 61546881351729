import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { BrandsService } from 'src/app/_services/brands.service';
import { SeriesService } from 'src/app/_services/series.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import Brand from 'src/app/models/brand.model';
import Series from 'src/app/models/series.model';

@Component({
  selector: 'app-edit-series',
  templateUrl: './edit-series.component.html',
  styleUrls: ['./edit-series.component.css']
})
export class EditSeriesComponent implements OnInit, OnDestroy, ToolbarController {
  private _baseTitlePath = { title: "Series", path: ['/catalog', 'series'] };
  titlePath = new BehaviorSubject<TitlePathElement[]>([this._baseTitlePath, {title: "?", path: []}]);
  series: Series | null = null;
  title = new BehaviorSubject<string>("Edit");

  brands: Brand[] = [];

  formGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    displayName: new FormControl<string | null>(''),
    brand: new FormControl<number>(0, { nonNullable: true, validators: [Validators.required] }),
    releaseYear: new FormControl<string>('', [Validators.pattern('\\d{4}')]),
    discontinuationYear: new FormControl<string>('', [Validators.pattern('\\d{4}')]),
    description: new FormControl<string | null>(''),
    clusterName: new FormControl<string | null>('')
  });
  
  get formControls() {
    return this.formGroup.controls;
  }
  submitting: boolean = false;

  private _seriesId: number;
  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private toolbarService: ToolbarService,
    private brandsService: BrandsService,
    private seriesService: SeriesService,
  ) { 
    const routeParams = this.route.snapshot.paramMap;
    this._seriesId = Number(routeParams.get('seriesId'));
    
    brandsService.getBrands().pipe(takeWhile(val => !this._destroyed.getValue())).subscribe({
      next: (brands: Brand[]) => {
        this.brands = brands;
      }
    });
    seriesService.getSeries(this._seriesId).pipe(takeWhile(val => !this._destroyed.getValue())).subscribe({
      next: (series: Series) => {
        this.series = series;
        this.titlePath.next([this._baseTitlePath, { title: (series.displayName || series.name), path: ['/catalog', 'series', series.id.toString()] }]);
        this.formControls.name.patchValue(series.name);
        this.formControls.displayName.patchValue(series.displayName ?? null);
        this.formControls.brand.patchValue(series.brand.id);
        this.formControls.releaseYear.patchValue(series.releaseYear?.toString() ?? '');
        this.formControls.discontinuationYear.patchValue(series.discontinuationYear?.toString() ?? '');
        this.formControls.description.patchValue(series.description ?? null);
        this.formControls.clusterName.patchValue(series.clusterName ?? null);
      },
      error: () => {
        router.navigate(['/catalog', 'series'])
        // TODO: This may be a different error other than not existing.
        let snackBarRef = this.snackBar.open(`Series (${this._seriesId}) does not exist.`, '', {
          duration: 3000
        });
      }
    });
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }
    this.submitting = true;

    var changes: any = {};
    if (this.formControls.name.dirty) {
      changes.name = this.formControls.name.value;
    }
    if (this.formControls.displayName.dirty) {
      changes.displayName = this.nullIfEmpty(this.formControls.displayName.value);
    }
    if (this.formControls.brand.dirty) {
      changes.brand = this.formControls.brand.value;
    }
    if (this.formControls.releaseYear.dirty) {
      changes.releaseYear = this.nullIfEmpty(this.formControls.releaseYear.value);
    }
    if (this.formControls.discontinuationYear.dirty) {
      changes.discontinuationYear = this.nullIfEmpty(this.formControls.discontinuationYear.value);
    }
    if (this.formControls.description.dirty) {
      changes.description = this.nullIfEmpty(this.formControls.description.value);
    }
    if (this.formControls.clusterName.dirty) {
      changes.clusterName = this.formControls.clusterName.value;
    }

    this.seriesService.updateSeries(this._seriesId, changes).subscribe({
      next: () => {
        this.router.navigate(['/catalog', 'series', this._seriesId]);
      },
      error: error => {
        this.submitting = false;
        alert(error);
      }
    });
  }

  private nullIfEmpty(value: any): any {
    if (value == null) {
      return null
    }

    if (typeof value == "string" && value.length == 0) {
      return null
    }

    return value
  }
}
