import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import List from '../models/list.model';

@Injectable({
  providedIn: 'root'
})
export class ListsService {
  constructor(
    private http: HttpClient
  ) { }

  public getLists(pageSize: number, offset: number, types: string[] | null, name: string | null, content: string | null, sortKey: string | null): Observable<List[]> {
    var path = `/content/lists?pageSize=${pageSize}&offset=${offset}`;
    if (!!types) {
      for (var type of types) {
        path += `&type[]=${type}`
      }
    }
    if (!!name) {
      name = encodeURIComponent(name);
      path += `&name[]=${name}`;
    }
    if (!!sortKey) {
      path += `&sort=${sortKey}`;
    }
    if (!!content) {
      path += `&content=${content}`;
    }
    return this.http.get<List[]>(environment.apiUrl + path).pipe(
      map(lists => lists.map(o => this.toList(o)))
    );
  }

  public getList(id: number): Observable<List> {
    var path = `/content/lists/${id}?expand[]=items.model&expand[]=items.object&expand[]=items.bannerImage`;
    return this.http.get<List>(environment.apiUrl + path).pipe(
      map(list => this.toList(list))
    );
  }

  public createList(params: any): Observable<List> {
    var path = `/content/lists`;
    return this.http.post<List>(environment.apiUrl + path, params);
  }

  public updateList(id: number, updates: any): Observable<List> {
    var path = `/content/lists/${id}`;
    return this.http.patch<List>(environment.apiUrl + path, updates);
  }

  public deleteList(id: number): Observable<void> {
    var path = `/content/lists/${id}`;
    return this.http.delete<void>(environment.apiUrl + path);
  }

  private toList(list: any): List {
    var list = Object.assign(new List(), list)
    return list
  }
}
